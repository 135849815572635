import { handleEntraIdLogout } from './EntraId/entraIdauthUtil';

export const handleLogout = async (authType: string, instance?: any) => {
    switch (authType) {
        case 'none':
            // NOOP
            // eslint-disable-next-line no-console
            console.log('none');
            break;
        case 'cognito':
            sessionStorage.clear();
            break;
        case 'entraid': {
            if (instance) {
                handleEntraIdLogout(instance);
            }
            break;
        }
    }
};
