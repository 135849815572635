import { createAction } from 'redux-actions';
import { TenantConfig } from '../types/tenantConfig';

export interface ConfigLoadedPayload {
    config: TenantConfig;
}

export interface ConfigLoadingFailedPayload {

}

const ConfigEvent = {
    configLoaded: createAction<ConfigLoadedPayload>('@@KL_EVT/CONFIG_LOADED'),
    configLoadingFailed: createAction<ConfigLoadingFailedPayload>('@@KL_EVT/CONFIG_LOADING_FAILED'),
};

export default ConfigEvent;
