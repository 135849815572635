import { createAction } from 'redux-actions';

export interface LoadConsentPayload {

}

export interface SetConsentPayload {
    consent: boolean;
}

const ConsentCommand = {
    loadConsent: createAction<LoadConsentPayload>('@@KL_CMD/LOAD_CONSENT'),
    setConsent: createAction<SetConsentPayload>('@@KL_CMD/SET_CONSENT'),
};

export default ConsentCommand;
