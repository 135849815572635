import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import InfoIcon from '@material-ui/icons/Info';
import MapIcon from '@material-ui/icons/Map';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import Routes from '../../../routes';
import { useSelector } from 'react-redux';
import Selector from '../../../selector';

const useStyles = makeStyles(theme => ({
    root: {
        height: '59px',
        backgroundColor: 'white',
        // position: 'sticky',
    },
    actionRoot: {
        color: 'black',
        paddingTop: '0 !important',
        paddingBottom: '0 !important',
        '&:not(:first-child)': {
            borderLeft: '1px solid #9F9F9F',
        },
        '&.MuiBottomNavigationAction-root': {
            maxWidth: '200px',
        },
        '& .MuiSvgIcon-root': {
            color: 'white',
            stroke: 'black',
            strokeWidth: 0.5,
        },
    },
    actionSvgIcon: {
        fontSize: '30px',
    },
    selected: {
        backgroundColor: theme.palette.primary.light + ' !important',
        color: 'white !important',
        '& .MuiSvgIcon-root': {
            color: 'white',
        },
    },
}));

type ToolbarItems = {
    [key: string]: {
        name: string;
        route: string;
        icon: Object;
    };
};

const Toolbar = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const toolbarItems = useSelector(Selector.Config.toolBar);

    const locationsTabActive = Boolean(useRouteMatch({ path: Routes.locationsPath }));
    const ordersTabActive = Boolean(
        useRouteMatch({ path: [ Routes.ordersPath, Routes.vippsPaymentReturnPath, Routes.stripePaymentReturnPath ]}),
    );
    const hintsTabActive = Boolean(useRouteMatch({ path: Routes.hintsPath }));

    const toolbarIcons: ToolbarItems = {
        locations: {
            name: 'locations',
            route: Routes.locationsPath,
            icon: <MapIcon className={classes.actionSvgIcon} />,
        },
        orders: {
            name: 'orders',
            route: Routes.ordersPath,
            icon: <ConfirmationNumberIcon className={classes.actionSvgIcon} />,
        },
        info: {
            name: 'info',
            route: Routes.hintsPath,
            icon: <InfoIcon className={classes.actionSvgIcon} />,
        },
    };
    
    // set active toolbar item
    const activeItem = toolbarItems.findIndex((item: string) => {
        return (
            (locationsTabActive && item === 'locations') ||
            (ordersTabActive && item === 'orders') ||
            (hintsTabActive && item === 'info')
        );
    });

    return (
        <BottomNavigation
            id={'toolbar'}
            value={activeItem > -1 ? activeItem : 0}
            className={classes.root}
            showLabels={true}
        >
            {toolbarItems?.map((item: string) => (
                <BottomNavigationAction
                    key={toolbarIcons[item].route}
                    label={t(`toolbar.${toolbarIcons[item].name}`)}
                    icon={toolbarIcons[item].icon}
                    to={toolbarIcons[item].route}
                    component={Link}
                    classes={{
                        root: classes.actionRoot,
                        selected: classes.selected,
                    }}
                />
            ))}
        </BottomNavigation>
    );
};

export default Toolbar;
