import React from 'react';
import {Box, IconButton, InputLabel, Typography} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircleOutline';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(3),
        position: 'relative',
    },
    box: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        border: '1px solid',
        paddingTop: theme.spacing(-2),
        paddingBottom: theme.spacing(-2),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        position: 'relative',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        borderColor: '#fff8',
    },
    label: {
        color: theme.palette.primary.dark, 
        opacity: '0.8',
        transform: 'scale(0.75)',
        position: 'absolute',
        top: '-20px',
        left: '10px',
        padding: '0 5px',
    },
    labelDisabled: {
        color: theme.palette.action.disabled,
    },
    countLabel: {
        display: 'inline-block',
        fontSize: '32px',
        fontWeight: 700,
        width: '52px',
        textAlign: 'center',
        marginLeft: '-7px',
        marginRight: '-7px',
    },
    button: {
        color: 'white',
    },
}));

interface PaddlerCountSelectProps {
    value: number;
    onChange: (value: number) => void;
    max: number;
    disabled: boolean;
    label?: string;
    style?: React.CSSProperties;
}

const PaddlerCountSelect = (props: PaddlerCountSelectProps) => {
    const classes = useStyles();

    const increase = () => {
        if (props.value + 1 <= props.max) {
            props.onChange(props.value + 1);
        }
    };

    const decrease = () => {
        if (props.value - 1 >= 1) {
            props.onChange(props.value - 1);
        }
    };

    return (
        <Box className={classes.root} style={props.style}>
            {props.label && (
                <InputLabel className={classes.label + (props.disabled ? ' ' + classes.labelDisabled : '')}>
                    {props.label}
                </InputLabel>
            )}
            <Box className={classes.box}>
                <IconButton className={classes.button} onClick={decrease} disabled={props.disabled}>
                    <RemoveCircleIcon />
                </IconButton>
                <Typography className={classes.countLabel + (props.disabled ? ' ' + classes.labelDisabled : '')}>
                    {props.value}
                </Typography>
                <IconButton className={classes.button} onClick={increase} disabled={props.disabled}>
                    <AddCircleIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default PaddlerCountSelect;
