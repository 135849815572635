import { getGroupsId, getIdToken } from '../authentification/Authorizer';
import TenantConfig from './tenantConfig';

export const isUserGranted = async (permission: string): Promise<boolean> => {
    const tenantConfig = await TenantConfig();
    // get groups from user token
    const groupIds = [
        'ALL_USERS',
        ...(getGroupsId(getIdToken()) ?? []),
    ];

    // check if user is granted for permission
    const userIsGrantedForPermission =
        groupIds?.some((groupId) => tenantConfig?.permissions?.[groupId]?.[permission] === true);

    return userIsGrantedForPermission;
};
