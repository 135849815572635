import { composeWithDevTools } from '@redux-devtools/extension';
import * as Sentry from '@sentry/react';
import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { initialState, rootReducer } from './reducer';
import rootSaga from './saga';
import { Environment } from './types/tenantConfig';
import TenantConfig from './util/tenantConfig';

const sagaMiddleware = createSagaMiddleware();
const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

const env = process.env.REACT_APP_ENV as Environment;

let store: any;

(async () => {
    const tenantConfig = await TenantConfig();

    const finalEnhancer = (env !== 'development' && tenantConfig.sentry?.dsn)
        ? composeWithDevTools(applyMiddleware(sagaMiddleware), sentryReduxEnhancer)
        : composeWithDevTools(applyMiddleware(sagaMiddleware));

    store = createStore(
        rootReducer,
        initialState,
        finalEnhancer,
    );

    sagaMiddleware.run(rootSaga);
})();

export { store }; 
