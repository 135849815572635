import { getI18n } from 'react-i18next';
import { Action } from 'redux-actions';
import { call, fork, put, take } from 'redux-saga/effects';
import Command from '../action/command';
import Event from '../action/event';
import { FetchOrderPayload } from '../action/orderCommand';
import Api from '../api';
import { InternalOrder, InternalOrderId } from '../types/order';
import ErrorTracker from '../util/errorTracker';
import Logger from '../util/logger';
import { onEnqueueErrorSnackbar } from './enqueueSnackbarFlow';
import { onPersistOrders } from './manageOrdersFlow';

export const onFetchOrder = function* (
    orderId: InternalOrderId, 
    createdBy: 'admin' | 'user' | 'any', 
    showFinishedWarning?: boolean,
    email?: string): any {
    yield put(Event.Order.orderFetchingBegan({}));

    try {
        // Get order by id from the API
        let internalOrder: InternalOrder;
        if (email) {
            internalOrder = yield call(Api.fetchOrder, orderId, createdBy, email);
        } else {
            internalOrder = yield call(Api.fetchOrder, orderId, createdBy);
        }

        yield call(onPersistOrders, internalOrder);

        // TODO handle finished orders
        // if (internalOrder[0]?.rental?.finishedAt) {
        //     if (showFinishedWarning) {
        //         yield fork(onEnqueueWarningSnackbar, getI18n().t('orders.warning.load_finished_order'));
        //     }

        //     yield put(Event.Order.orderFetchingFailed({}));
        //     return;
        // }

        yield put(Event.Order.orderFetched({ internalOrder }));
    } catch (error) {
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);

        if (error.response && error.response.status === 403) {
            yield fork(onEnqueueErrorSnackbar, getI18n().t('orders.error.booking_in_use'));
            // yield fork(onEnqueueErrorSnackbar, getI18n().t('orders.error.order_expired'));
        } else if (error.response && error.response.status === 404){
            yield fork(onEnqueueErrorSnackbar, getI18n().t('orders.error.booking_not_found'));
        } else {
            yield fork(onEnqueueErrorSnackbar, getI18n().t('orders.error.fetch_order'));
        }

        yield put(Event.Order.orderFetchingFailed({}));
    }
};

export function* fetchOrderFlow() {
    while (true) {
        const action: Action<FetchOrderPayload> = yield take(Command.Order.fetchOrder.toString());
        if (action.payload.email) {
            yield fork(
                onFetchOrder, 
                action.payload.internalOrderId, 
                action.payload.createdBy, 
                action.payload.showFinishedWarning,
                action.payload.email,
            );
        } else {
            yield fork(
                onFetchOrder, 
                action.payload.internalOrderId, 
                action.payload.createdBy, 
                action.payload.showFinishedWarning,
            );
        }
    }
}
