import {Box, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React from 'react';
import {formatMoney} from '../../../../i18n/i18n';
import {AssetGroup} from '../../../../types/assetGroup';
import {ReservationItem} from '../../../../types/bookingCreation';
import { useSelector } from 'react-redux';
import Selector from '../../../../selector';

const useStyles = makeStyles((theme) => ({
    orderDetail: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

interface OrderDetailsItemProps {
    reservationItem: ReservationItem;
    assetGroups: Array<AssetGroup>;
}

const OrderDetailsItem = (props: OrderDetailsItemProps) => {
    const classes = useStyles();
    const tenantConfig = useSelector(Selector.Config.config);

    const assetGroup = props.assetGroups.find(
        (group) => group.id === props.reservationItem.groupId,
    );

    return (
        <Box className={classes.orderDetail}>
            <Typography>
                <strong>{props.reservationItem.amount}</strong>x &nbsp;
                <strong>{assetGroup?.name}</strong>
            </Typography>
            <Typography>
                <strong>{formatMoney(props.reservationItem.price, tenantConfig.currency)}</strong>
            </Typography>
        </Box>
    );
};

export default OrderDetailsItem;
