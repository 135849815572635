import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i18n/i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Logger from './util/logger';
import TenantConfig, { getLogo } from './util/tenantConfig';
import { Environment } from './types/tenantConfig';

const initializeGTM = async(tenantConfig: any) => {
    // Import GTM only
    // if not in development environment
    // AND
    // if GTM ID is provided in tenant config
    if (process.env.REACT_APP_ENV as Environment !== 'development' && tenantConfig.gtm.id) {
        if (tenantConfig.gtm.id) {
            const GTM = await import('./util/gtm');
            GTM.default.initialize();
        }
    }
};

const initializeSentry = async(tenantConfig: any) => {
    // Import Sentry only if not in development environment
    if (process.env.REACT_APP_ENV as Environment !== 'development' && tenantConfig.sentry?.dsn) {
        (async () => {
            const Sentry = await import('@sentry/react');
            Sentry.init({
                dsn: tenantConfig.sentry.dsn,
                environment: process.env.REACT_APP_ENV as Environment,
                release: tenantConfig.app.name + '@' + tenantConfig.app.version,
                beforeSend: (event) => {
                    if (
                        event.extra && typeof event.extra.request_data === 'string' &&
                        (event.extra.request_data.includes('email') || event.extra.request_data.includes('telephone'))
                    ) {
                        delete event.extra.request_data;
                    }
                    return event;
                },
            });
        })();
    }
};

const initializeAuth = async(tenantConfig: any) => {
    let authInstance: any;
    if (tenantConfig.authentication === 'entraid') {
        await (async () => {
            // eslint-disable-next-line no-console
            const Azure = await import('@azure/msal-browser');
            const AzureConfig = await import('./authentification/EntraId/authConfig');
            
            authInstance = new Azure.PublicClientApplication(AzureConfig.msalConfig);
    
            authInstance.initialize().then(() => {
                // Account selection logic is app dependent. Adjust as needed for different use cases.
                const accounts = authInstance.getAllAccounts();
                if (accounts.length > 0) {
                    // eslint-disable-next-line no-console
                    console.log('Setting active account');
                    authInstance.setActiveAccount(accounts[0]);
                } else {
                    // eslint-disable-next-line no-console
                    console.log('No active account found');
                }
    
                authInstance.addEventCallback((event: any) => {
                    if (event.eventType === Azure.EventType.LOGIN_SUCCESS && event.payload) {
                        const payload = event.payload as any;
                        const account = payload.account;
                        authInstance.setActiveAccount(account);
                    }
                });
            });
        })();
    }
    return authInstance;
};

const initializeApp = async() => {
    try {
        const tenantConfig = await TenantConfig();
        const tenantAssetConfig = await getLogo();
        await initializeGTM(tenantConfig);
        await initializeSentry(tenantConfig);
        const authInstance = await initializeAuth(tenantConfig);
        ReactDOM.render(
            <App instance={authInstance} tenantConfig={tenantConfig} tenantAssets={tenantAssetConfig} />,
            document.getElementById('root'),
        );
    } catch(error){
        // eslint-disable-next-line no-console
        console.error('Authentication initialization error:', error);
        ReactDOM.render(
            // Refactor this: We need to style an ErrorPage
            <div>
                <h1>Error</h1>
                <p>Authentication instance is not initialized. Please try again later.</p>
            </div>,
            document.getElementById('root'),
        );
    }
};

initializeApp();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(Logger.for('WebVitals').log);
