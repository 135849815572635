import { Box, Button, FormControl, OutlinedInput, Select, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Selector from '../../selector';
import TenantLogo from '../layout/components/TenantLogo';
import CustomButton from '../../tenants/segmenta/component/CustomButton';
import Command from '../../action/command';
import ConsentDialog from './components/ConsentDialog';
import { useHistory, useParams } from 'react-router';
import Routes from '../../routes';
import GTM from '../../util/gtm';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        paddingTop: '16px',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.default,

    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        display: 'grid',
        justifyItems: 'center',
        fontSize: '30px',
        textTransform: 'none',
    },
    contentWrapper: {
        height: 'calc(100% - 160px)',
        maxWidth: 1000,
        margin: '0 auto',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '10px', 
    },
    grow: {
        flexGrow: 1,
    },
    buttonWrapper: {
        height: '150px',
        alignItems: 'start',
        maxWidth: 1000,
        margin: '0 auto',
        padding: '0px 16px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    logo: {
        width: '200px',
        [theme.breakpoints.up('lg')]: {
            width: '300px',
        },
        margin: '0 auto',
    },
    button: {
        fontSize: theme.typography.button.fontSize,
        fontWeight: theme.typography.button.fontWeight,
        fontStyle: theme.typography.button.fontStyle,
        lineHeight: theme.typography.button.lineHeight,
        height: theme.typography.button.height,
        fontFamily: theme.typography.fontFamily,
        textTransform: 'none',
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.dark,
        borderRadius: theme.shape.borderRadius,
    },
    icon: {
        fill: theme.palette.primary.dark,
    },
    selectMenuPaper: {
        backgroundColor: theme.palette.background.default,
        '& .MuiSelect-select:focus': {
            width: '60%',
            backgroundColor: theme.palette.background.default,
        },
        '& .MuiSelect-select:not(:focus)': {
            backgroundColor: theme.palette.background.default,
            width: '100%',
        },
        height: '62px',
        '& .MuiInputBase-input': {
            color: theme.palette.primary.dark,
        },
    },
    menuItem: {
        color: theme.palette.background.default,
    },
    formControl: {
        paddingTop: theme.spacing(1),
        width: '80%',
        display: 'flex',
        justifyContent: 'center',
    },
}));

const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        '& $notchedOutline': {
            borderColor: theme.palette.primary.dark,
        },
        '&:hover $notchedOutline': {
            borderColor: theme.palette.primary.dark,
        },
        '&$focused $notchedOutline': {
            borderColor: theme.palette.primary.dark,
        },
        color: theme.palette.primary.dark, 
        opacity: '0.8',
    },
    focused: {},
    notchedOutline: {
        color: theme.palette.primary.dark,
        '& legend': {
            opacity: 1,
            visibility: 'visible',
            paddingBottom: '10px',
        },
    },
}));

const LocationsSelectionPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const params = useParams<{ locationId?: string|undefined }>();
    const extremelySmallDevice = useMediaQuery('@media(max-width: 350px)');
    const tenantConfig = useSelector(Selector.Config.config);
    const locations = useSelector(Selector.Location.locations);
    const outlinedInputClasses = useOutlinedInputStyles();
    const [consentDialogOpen, setConsentDialogOpen] = useState<boolean>(false);
    const history = useHistory();
    const theme = useTheme();
    const selectedLocationId = params.locationId ? params.locationId : null;
    const selectedLocation = locations.find(location => location.id === selectedLocationId);
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));


    const onAcceptConsent = () => {
        dispatch(Command.Consent.setConsent({ consent: true }));
        setConsentDialogOpen(false);
    };

    const onStartBooking = () => {
        if (selectedLocationId){
            history.push(Routes.makeBookLocationUrl(selectedLocationId));
        }
    };

    const selectLocation = (locationId: string|null) => {
        history.push(locationId === null ? Routes.locationsPath : Routes.makeLocationPath(locationId));
    };

    useEffect(() => {
        if (locations.length > 0) {
            GTM.trackEecImpressionsView(locations);
        }
    }, [locations.length]);

    useEffect(() => {
        if (selectedLocation) {
            GTM.trackEecProductClick(selectedLocation);

            // Tracked in the location pane on mobile devices
            if (isDesktop) {
                GTM.trackEecProductDetails(selectedLocation);
            }
        }
    }, [selectedLocationId]);

    return (
        <Box className={classes.root}>
            <Box className={classes.contentWrapper}>
                {!extremelySmallDevice && (
                    <TenantLogo type='default' size='large' className={classes.logo} />
                )}
                <Typography className={classes.header}>
                    {t('book_location.location_selection.title')}
                </Typography>
                <Box 
                    style={{ 
                        display: 'flex', 
                        alignItems: 'center', justifyContent: 'center',
                    }}
                >
                    <FormControl variant='outlined' className={classes.formControl}>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select-helper"
                            native={true}
                            variant='outlined'
                            disabled={locations.length === 0}
                            autoFocus={true}
                            // value={locations.length > 0 ? locations[0].id : ''}
                            input={
                                <OutlinedInput 
                                    label={t('book_location.location_selection.label')}
                                    classes={outlinedInputClasses}
                                    fullWidth={true}
                                />
                            }
                            fullWidth={true}
                            onChange={event => selectLocation(event.target.value as string)}
                            inputProps={{
                                classes: {
                                    icon: classes.icon,
                                },
                            }}
                            className={classes.selectMenuPaper}
                            children={<>
                                <option value={0} className={classes.menuItem} />
                                {locations.map(location => (
                                    <option key={location.id} value={location.id} className={classes.menuItem}>
                                        {location.name}
                                    </option>
                                ))}
                            </>}
                        />
                    </FormControl>
                </Box>
                
            </Box>

            <Box className={classes.buttonWrapper}>
                
                <Button
                    className={classes.button}
                    variant={!(selectedLocationId === null || selectedLocationId === '0') ? 'outlined' : 'contained'}
                    fullWidth={true}
                    onClick={onStartBooking}
                    disabled={selectedLocationId === null || selectedLocationId === '0'} 
                    // color={'primary'}
                    // component={RouterLink}
                    // to={Routes.addOrderPath}
                >
                    {t('book_location.location_selection.start_booking')}
                </Button>

                {
                    tenantConfig.authentication === 'none' && (
                        <CustomButton
                            isButtonDark={true}
                            fullWidth={true}
                            onClick={() => setConsentDialogOpen(true)}
                            color={'secondary'}
                            children={t('book_location.location_selection.map_view')}
                            style={{justifyContent: 'flex-end'}}
                        />
                    )
                }
            </Box>
            <ConsentDialog
                open={consentDialogOpen}
                onClose={() => setConsentDialogOpen(false)}
                onContinue={onAcceptConsent}
            />
        </Box>
    );
};

export default LocationsSelectionPage;
