import { Grid, TextField, TextFieldProps } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2.5),
        zIndex: 0,
        '& .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
        },
        '& .MuiFormLabel-root': {
            color: 'white',
        },
        '& .MuiInputBase-input': {
            color: 'white',
            borderRadius: 0,

            /* Prevent weird input color change due to autofill */
            '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:hover': {
                WebkitBoxShadow: '0 0 0 30px var(--dark-background-color) inset !important',
                boxShadow: '0 0 0 30px var(--dark-background-color) inset !important',
                backgroundColor: 'var(--dark-background-color)',
                WebkitTextFillColor: 'white',
                backgroundClip: 'content-box !important',
            },
        },
    },
}));
interface RegistrationInputProps extends Omit<TextFieldProps, 'onChange'> {
    label: string;
    name: string;
    type: string;
    placeholder: string;
    value?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RegistrationInput: React.FC<RegistrationInputProps> = (
    { label, name, type, placeholder, value, onChange, ...props},
) => {
    const classes = useStyles();
    return (
        <Grid item={true} xs={12}>
            <TextField
                variant={'outlined'}
                label={label}
                className={classes.root}
                color={'primary'}
                size={'small'}
                name={name}
                type={type}
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                required={true}
                InputProps={{
                    style: {
                        color: 'black',
                        // borderRadius: '12px',
                        // backgroundColor: '#F3F3F3',
                        height: '54px',
                        width: '380px',
                    },
                }}
                {...props} // Pass other TextFieldProps
            />
        </Grid>
    );
};

export default RegistrationInput;
