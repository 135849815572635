import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import './components/LocationsMap.css';
import LocationPane from './components/LocationPane';
import Selector from '../../selector';
import {Box, IconButton, Snackbar, useMediaQuery, useTheme} from '@material-ui/core';
import {useHistory, useParams} from 'react-router';
import Routes from '../../routes';
import GTM from '../../util/gtm';
import { Transition } from 'react-transition-group';
import LocationDetails from './components/LocationDetails';
import {makeStyles} from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import { t } from 'i18next';

const LocationsMap = React.lazy(() => import('./components/LocationsMap' /* webpackChunkName: "locations-map" */));

const duration = 200;
const sidebarWidth = 550;

const defaultSidebarStyle = {
    transition: `left ${duration}ms ease-in-out`,
    width: sidebarWidth,
    left: -sidebarWidth,
};

const transitionSidebarStyles = {
    entering: { left: 0 },
    entered: { left: 0 },
    exiting: { left: -sidebarWidth },
    exited: { left: -sidebarWidth },
};

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
        backgroundColor: 'var(--map-color-1)',
    },
    desktopContainer: {
        width: '100%',
        height: '100%',
    },
    desktopSidebar: {
        position: 'absolute',
        top: 0,
        height: '100%',
        zIndex: 3,
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(1.5),
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
    },
    desktopSidebarHeader: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    desktopLocationDetails: {
        flex: 1,
        overflowY: 'auto',
    },
    snackbar: {
        top: 8,
        width: '88%',
        position: 'fixed',
        '& .MuiSnackbarContent-root': {
            color: 'white',
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

const LocationsPage = () => {
    const classes = useStyles();
    const locations = useSelector(Selector.Location.locations);
    const params = useParams<{ locationId?: string|undefined }>();
    const history = useHistory();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
    const selectedLocationId = params.locationId ? params.locationId : null;
    const selectedLocation = locations.find(location => location.id === selectedLocationId);

    const selectLocation = (locationId: string|null) => {
        history.push(locationId === null ? Routes.locationsPath : Routes.makeLocationPath(locationId));
    };

    useEffect(() => {
        if (locations.length > 0) {
            GTM.trackEecImpressionsView(locations);
        }
    }, [locations.length]);

    useEffect(() => {
        if (selectedLocation) {
            GTM.trackEecProductClick(selectedLocation);

            // Tracked in the location pane on mobile devices
            if (isDesktop) {
                GTM.trackEecProductDetails(selectedLocation);
            }
        }
    }, [selectedLocationId]);

    const [snackbarOpen, setSnackbarOpen] = useState(true);

    return (
        <Box className={classes.root}>
            <Snackbar
                open={snackbarOpen}
                message={t('general.how_to_use')}
                className={classes.snackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
            {!isDesktop && (<>
                <React.Suspense fallback={null}>
                    <LocationsMap
                        selectedLocationId={selectedLocationId}
                        onSelect={selectLocation}
                    />
                </React.Suspense>
                <LocationPane
                    location={selectedLocation || null}
                    onClose={() => selectLocation(null)}
                />
            </>)}

            {isDesktop && (<>
                <Box className={classes.desktopContainer}>
                    <Transition in={Boolean(selectedLocation)} timeout={150}>
                        {state => (
                            <Box
                                className={classes.desktopSidebar}
                                style={{ ...defaultSidebarStyle, ...(transitionSidebarStyles as any)[state] }}
                            >
                                {selectedLocation && (<>
                                    <Box className={classes.desktopSidebarHeader}>
                                        <IconButton onClick={() => selectLocation(null)} style={{ color: 'white' }}>
                                            <CancelIcon />
                                        </IconButton>
                                    </Box>
                                    <Box className={classes.desktopLocationDetails}>
                                        <LocationDetails location={selectedLocation} />
                                    </Box>
                                </>)}
                            </Box>
                        )}
                    </Transition>
                    <React.Suspense fallback={null}>
                        <LocationsMap
                            selectedLocationId={selectedLocationId}
                            onSelect={selectLocation}
                            safeAreaLeft={sidebarWidth}
                        />
                    </React.Suspense>
                </Box>
            </>)}
        </Box>
    );
};

export default LocationsPage;
