import { Action, handleActions } from 'redux-actions';
import { TenantConfig } from '../types/tenantConfig';
import DefaultConfig from '../util/defaultConfig';
import Event from '../action/event';
import { ConfigLoadedPayload, ConfigLoadingFailedPayload } from '../action/configEvent';

export interface ConfigState {
    config: TenantConfig;
    loaded: boolean;
}

export const initialState: ConfigState = {
    config: DefaultConfig,
    loaded: false,
};

export const reducer = handleActions<ConfigState, any>(
    {
        [Event.Config.configLoaded.toString()]:
            (state, action: Action<ConfigLoadedPayload>): ConfigState => {
                return {
                    ...state,
                    config: action.payload.config,
                    loaded: true,
                };
            },
        [Event.Config.configLoadingFailed.toString()]:
            (state, action: Action<ConfigLoadingFailedPayload>): ConfigState => {
                return {
                    ...state,
                    config: DefaultConfig,
                    loaded: true,
                };
            },
    },
    initialState,
);
