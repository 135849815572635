import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { InputAdornment, TextField } from '@material-ui/core';
import EventIcon from '@material-ui/icons/EventTwoTone';
import React, { useEffect } from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import moment, { Moment } from 'moment';
import Command from '../../../../action/command';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles(theme => ({
    pickerInput: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.primary.dark,
                color: theme.palette.primary.dark,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.dark,
                color: theme.palette.primary.dark,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.dark,
                color: theme.palette.primary.dark,
            },
        },
        '& .MuiInputBase-input': {
            color: theme.palette.primary.dark,
        },
        '& .MuiInputBase-root': {
            color: theme.palette.primary.dark,
        },
    },
    inputLabel: {
        paddingLeft: '10px',       
    },
    label: {
        color: theme.palette.primary.dark, 
        opacity: '0.8',
        '&.Mui-focused.MuiInputLabel-outlined': {
            color: theme.palette.primary.dark,
        },
    },
}));

interface BookingDatePickerProps {
    value: MaterialUiPickersDate;
    onChange: (date: MaterialUiPickersDate) => void;
    shouldDisableDate: (date: MaterialUiPickersDate) => boolean;
    availableDates: string[]; 
}

const BookingDatePicker = (props: BookingDatePickerProps) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Function to parse the date strings into Date objects
    const parseDates = (dates: string[]): Moment[] => dates.map(date => moment(date, 'DD.MM.YYYY'));
    const parsedDates = parseDates(props.availableDates);

    const isDateDisabled = (date: MaterialUiPickersDate): boolean => {
        return !parsedDates.some(parsedDate => 
            parsedDate.isSame(date, 'day'),
        );
    };

    useEffect(() => {
        const tenantName = process.env.REACT_APP_TENANT;
    
        if (tenantName) {
            import(`../../../../tenants/${tenantName}/assets/styles/calendar.css`)
                .catch((error) => {
                    dispatch(Command.Notification.enqueueNotification({
                        severity: 'error',
                        message: t(`Failed to load styles for ${tenantName}, error: ${error}`),
                    }));
                });
        } else {
            dispatch(Command.Notification.enqueueNotification({
                severity: 'error',
                message: t('No tenant name provided in environment variables.'),
            }));
        }
    }, []);
    
    return (
        <>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                    autoOk={true}
                    disablePast={true}
                    maxDate={moment().add(2, 'month').format('YYYY-MM-DD')}
                    shouldDisableDate={isDateDisabled}
                    value={props.value}
                    onChange={props.onChange}
                    format={t('formats.date')}
                    label={t('general.date')}
                    TextFieldComponent={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            InputProps={{
                                classes: {
                                    focused: classes.label,
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <EventIcon fontSize="small" />
                                    </InputAdornment>
                                ),
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.label,
                                },
                            }}
                            className={classes.pickerInput}
                        />
                    )}
                    InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                            <InputAdornment position={'end'}>
                                <EventIcon fontSize={'small'} />
                            </InputAdornment>
                        ),
                    }}
                />
            </MuiPickersUtilsProvider>
        </>
    );
};

export default BookingDatePicker;
