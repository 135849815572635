import { Box} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import TenantLogo from './TenantLogo';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#161616',
        height: '100%',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column-reverse',
    },
    box: {
        padding: theme.spacing(4),
    },
}));

const SplashScreen = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.box}>
                <TenantLogo type="default" size="large" />
            </Box>
        </Box>
    );
};

export default SplashScreen;
