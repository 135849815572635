import { all, call, delay, spawn } from 'redux-saga/effects';
import { calculateDefaultBoardTypeCountsFlow } from './saga/calculateDefaultBoardTypeCountsFlow';
import { cancelBookingCreationFlow } from './saga/cancelBookingCreationFlow';
import { createNewReservationFlow } from './saga/createNewReservationFlow';
import { enqueueSnackbarFlow } from './saga/enqueueSnackbarFlow';
import {fetchAssetGroupsByLocationIdsFlow, fetchAssetGroupsFlow} from './saga/fetchAssetGroupsFlow';
import { fetchLocationsFlow } from './saga/fetchLocationsFlow';
import { fetchOrderFlow } from './saga/fetchOrderFlow';
import { finishRentalFlow } from './saga/finishRentalFlow';
import { loadOrdersFlow } from './saga/manageOrdersFlow';
import { openDoorFlow } from './saga/openDoorFlow';
import { completeBraintreePaymentFlow } from './saga/payment/completeBraintreePaymentFlow';
import { completeStripePaymentFlow } from './saga/payment/completeStripePaymentFlow';
import { completeVippsPaymentFlow } from './saga/payment/completeVippsPaymentFlow';
import { initializePaymentFlow } from './saga/payment/initializePaymentFlow';
import { fetchLocationByIdFlow } from './saga/fetchLocationByIdFlow';
import { redeemCouponFlow } from './saga/redeemCouponFlow';
import { removeReservationFlow } from './saga/removeReservationFlow';
import { reportDamageFlow } from './saga/reportDamageFlow';
import { startBookingCreationFlow } from './saga/startBookingCreationFlow';
import { startOrderItemsFlow } from './saga/startRentalFlow';
import ErrorTracker from './util/errorTracker';
import Logger from './util/logger';
import { fetchAssetGroupAvailabilitiesFlow } from './saga/fetchAssetGroupAvailabilitiesFlow';
import { loadTenantConfigFlow } from './saga/loadTenantConfigFlow';
import {manageBookingStepChangeFlow} from './saga/manageBookingStepChangeFlow';
import {loadBookingFlow, manageBookingFlow} from './saga/manageBookingFlow';
import { fetchOrdersByUserFlow } from './saga/fetchOrdersByUserFlow';
import { loadConsentFlow } from './saga/loadConsentFlow';
import { setConsentFlow } from './saga/setConsentFlow';

const SagaLogger = Logger.for('Saga');

/**
 * Prevents the root saga from terminating entirely due to some error in another saga
 *
 * @param saga
 */
const makeRestartable = (saga: any) => {
    return function* () {
        yield spawn(function* () {
            while (true) {
                try {
                    yield call(saga);
                    SagaLogger.error(
                        'Unexpected root saga termination. The root sagas should live during the whole app lifetime!',
                        saga,
                    );
                    ErrorTracker.trackMessage('Unexpected root saga termination');
                } catch (e) {
                    ErrorTracker.trackException(e);
                    SagaLogger.error('Saga error, the saga will be restarted', e);
                }
                yield delay(1000); // Workaround to avoid infinite error loops
            }
        });
    };
};

const sagas: any = [
    fetchLocationsFlow,
    fetchLocationByIdFlow,
    fetchAssetGroupsFlow,
    fetchAssetGroupsByLocationIdsFlow,
    fetchAssetGroupAvailabilitiesFlow,
    redeemCouponFlow,
    calculateDefaultBoardTypeCountsFlow,
    createNewReservationFlow,
    removeReservationFlow,
    cancelBookingCreationFlow,
    startBookingCreationFlow,
    manageBookingStepChangeFlow,
    loadOrdersFlow,
    fetchOrderFlow,
    fetchOrdersByUserFlow,
    manageBookingFlow,
    loadBookingFlow,
    startOrderItemsFlow,
    openDoorFlow,
    reportDamageFlow,
    finishRentalFlow,
    enqueueSnackbarFlow,
    loadTenantConfigFlow,
    /* payment */
    initializePaymentFlow,
    completeBraintreePaymentFlow,
    completeVippsPaymentFlow,
    completeStripePaymentFlow,
    loadConsentFlow,
    setConsentFlow,
].map(makeRestartable);

export default function* rootSaga() {
    SagaLogger.log('Root saga started');
    yield all(sagas.map((saga: any) => call(saga)));
}
