import flagGermany from './flags/germany.svg';
import flagNorway from './flags/norway.svg';
import flagUnitedKingdom from './flags/united-kingdom.svg';
import mapDbPinBeigeIcon from './icons/map/db-pin-beige.png';
import mapDbPinGrayIcon from './icons/map/db-pin-gray.png';
import mapDbPinOrangeIcon from './icons/map/db-pin-orange.png';
import mapKolulaPinBlueIcon from './icons/map/kolula-pin-blue.png';
import mapKolulaPinGrayIcon from './icons/map/kolula-pin-gray.png';
import mapKolulaPinOrangeIcon from './icons/map/kolula-pin-orange.png';
import mapVonoviaPinBlue from './icons/map/vonovia-pin-blue.png';
import mapVonoviaPinGray from './icons/map/vonovia-pin-gray.png';
import mapVonoviaPinLightBlue from './icons/map/vonovia-pin-lightblue.png';
import privacyIcon from './icons/map/privacyIcon.png';
import uscScanQrCode, { ReactComponent as UscScanQrCode } from './icons/usc/scan-qr-code.svg';
import kolulaSupLogo, { ReactComponent as KolulaSupLogo } from './logos/kolula-sup.svg';
import vonoviaWhiteLogo, { ReactComponent as VonoviaWhiteLogo } from './logos/vonovia-white.svg';
import vonoviaLogo, { ReactComponent as VonoviaLogo } from './logos/vonovia.svg';
import udTransferMoney from './unDraw/undraw-transfer-money.svg';
import udWarning from './unDraw/undraw-warning.svg';
import { ReactComponent as CloseIcon } from './icons/x-circle.svg';
import { ReactComponent as InfoIcon } from './icons/information-circle.svg';
import { ReactComponent as InfoIconWhiteFilled } from './icons/information-circle-white-filled.svg';


export const Assets = {
    flags: {
        germany: flagGermany,
        unitedKingdom: flagUnitedKingdom,
        norway: flagNorway,
    },
    icon: {
        map: {
            kolulaPinOrange: {
                url: mapKolulaPinOrangeIcon,
            },
            kolulaPinBlue: {
                url: mapKolulaPinBlueIcon,
            },
            kolulaPinGray: {
                url: mapKolulaPinGrayIcon,
            },
            dbPinOrange: {
                url: mapDbPinOrangeIcon,
            },
            dbPinBeige: {
                url: mapDbPinBeigeIcon,
            },
            dbPinGray: {
                url: mapDbPinGrayIcon,
            },
            vonoviaPinBlue: {
                url: mapVonoviaPinBlue,
            },
            vonoviaPinGray: {
                url: mapVonoviaPinGray,
            },
            vonoviaPinLightBlue: {
                url: mapVonoviaPinLightBlue,
            },
            privacyIcon: {
                url: privacyIcon,
            },
        },
        usc: {
            scanQrCode: {
                url: uscScanQrCode,
                component: UscScanQrCode,
            },
        },
        infoIcon: {
            component: InfoIcon,
        },
        infoIconWhiteFilled: {
            component: InfoIconWhiteFilled,
        },
        closeIcon: {
            component: CloseIcon,
        },
    },
    logo: {
        kolula: {
            url: vonoviaLogo,
            component: VonoviaLogo,
        },
        kolulaSup: {
            url: kolulaSupLogo,
            component: KolulaSupLogo,
        },
        kolulaWhite: {
            url: vonoviaWhiteLogo,
            component: VonoviaWhiteLogo,
        },
    },
    unDraw: {
        transferMoney: udTransferMoney,
        warning: udWarning,
    },
};

export default Assets;
