import BookingCreationCommand from './bookingCreationCommand';
import ConfigCommand from './configCommand';
import ConsentCommand from './consentCommand';
import LocationCommand from './locationCommand';
import NotificationCommand from './notificationCommand';
import OrderCommand from './orderCommand';
import RentalCommand from './rentalCommand';

const Command = {
    Location: LocationCommand,
    BookingCreation: BookingCreationCommand,
    Order: OrderCommand,
    Rental: RentalCommand,
    Notification: NotificationCommand,
    Config: ConfigCommand,
    Consent: ConsentCommand,
};

export default Command;
