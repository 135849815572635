import {call, fork, put, select, take} from 'redux-saga/effects';
import Command from '../action/command';
import Event from '../action/event';
import Selector from '../selector';
import Api from '../api';
import {Reservation} from '../types/bookingCreation';
import Logger from '../util/logger';
import ErrorTracker from '../util/errorTracker';
import {trackGTMRemoveFromCart} from './helpers/gtm';

export const onCancelBookingCreation = function*() {
    const reservation: null|Reservation = yield select(Selector.BookingCreation.reservation);

    if (reservation) {
        try {
            yield call(Api.removeReservation, reservation.reservationId);
        } catch (error) {
            Logger.for('Saga').error(error);
            ErrorTracker.trackException(error);
        }
    }

    yield call(trackGTMRemoveFromCart);
    yield put(Event.BookingCreation.bookingCreationCanceled({}));
};

export function* cancelBookingCreationFlow() {
    while (true) {
        yield take(Command.BookingCreation.cancelBookingCreation.toString());
        yield fork(onCancelBookingCreation);
    }
}
