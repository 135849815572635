import { PageType, PaymentProvider, TenantConfig } from '../../types/tenantConfig';
import { Faq } from './content/faq';
import { Imprint } from './content/imprint';
import { TermsOfService } from './content/termsOfService';
import { DataProtection } from './content/dataProtection';

const tenant = process.env.REACT_APP_TENANT!;

export const FaqsPage: PageType = {
    type: 'accordion',
    content: {
        de: Faq['de'], 
    },
};
const TermsOfServicePage: PageType = {
    type: 'text',
    content: {
        de: {
            title: 'AGB',
            content: TermsOfService['de'],
        },
    },
};

const DataProtectionPage: PageType = {
    type: 'text',
    content: {
        de: {
            title: 'Datenschutzrichtlinie',
            content: DataProtection['de'],
        },
    },
};

const ImprintPage: PageType = {
    type: 'text',
    content: {
        de: {
            title: 'Impressum',
            content: Imprint['de'],
        },
    },
};

const paymentProvider: PaymentProvider[] = process.env
    .REACT_APP_PAYMENT_PROVIDER
    ? (process.env.REACT_APP_PAYMENT_PROVIDER.split(',') as PaymentProvider[])
    : [];

export const Config: TenantConfig = {
    tenant,
    app: {
        version: process.env.REACT_APP_VERSION!,
        name: process.env.REACT_APP_NAME!,
    },
    mapBox: {
        apiKey: process.env.REACT_APP_MAPBOX_API_KEY!,
    },
    api: {
        baseUrl: process.env.REACT_APP_API_BASE_URL!,
        key: process.env.REACT_APP_API_KEY!,
    },
    gtm: {
        id: process.env.REACT_APP_GTM_ID!,
        auth: process.env.REACT_APP_GTM_AUTH!,
        preview: process.env.REACT_APP_GTM_PREVIEW!,
    },
    seo: {
        appTitle: 'Vonovia Leihbar',
        appDescription: 'Miete Produkte vor Ort - unkompliziert und schnell mit unserer App.',
    },
    languages: ['de'],
    fallbackLng: 'de',
    taxRate: 19,
    currency: 'EUR',
    paymentProvider: paymentProvider,
    map: {
        center: [7.445545508296978, 51.50540628440569] as [number, number],
        zoom: 11,
    },
    phoneInput: {
        defaultCountry: 'de',
        preferredCountries: ['de', 'at', 'ch', 'nl', 'be'],
    },
    prices: [
        { duration: 1, weekdayPrice: 9.99, weekendPrice: 12.99 },
        {
            duration: 1.5,
            weekdayPrice: 13.99,
            weekendPrice: 17.99,
            bestseller: true,
        },
        { duration: 2, weekdayPrice: 17.99, weekendPrice: 23.99 },
        { duration: 3, weekdayPrice: 24.99, weekendPrice: 29.99 },
        { duration: 4, weekdayPrice: 29.99, weekendPrice: 36.99 },
    ],
    contact: {
        phone: '+4989244183791',
        phoneFormatted: '089 244 183 791',
    },
    toolbarItems: ['locations', 'orders', 'info'],
    HintPageComponents: {
        hintPages: { 
            faqs: FaqsPage,
            // testLink: TestLinkPage, 
        },
        legalPages: {
            termsOfService: TermsOfServicePage,
            dataProtection: DataProtectionPage,
            imprint: ImprintPage,
        },
    },
    bookingPreviewType: 'table',
    authentication: 'none',
    permissions: {
        ALL_USERS: { 
            addOrder: true,
        },
    },
    optionalScreens: [
        'orderItemDetails',
        'responsibilityExplanation',
        'orderOverview',
        'partnerUscInformation',
        // 'doorsOverview',
        // 'exceededRental',
    ],
    bookingFlow: [
        'assetGroupSelection',
        'orderData',
        'personalData',
        'confirmation',
        'paymentTypeSelection',
        'payment',
    ],
    startPage: 'none',
    allowCoupons: true,
    allowNewsLetter: false,
    consentRequired: true,
};

export default Config;
