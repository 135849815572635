import React from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import TenantLogo from '../../layout/components/TenantLogo';
import { Order } from '../../../types/order';
import { useSelector } from 'react-redux';
import Selector from '../../../selector';
import {getTimeFormat} from '../util/orderDataStepHelpers';

interface BookingTicketProps {
    order?: Order;
    location?: string;
    reservationStartDate: any;
    reservationEndDate: any;
    amount?: number;
    size?: string; // Hardcoded for now, need to implement an API to retrieve it
}

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.paper || theme.palette.primary.main,
        color: theme.palette.text.secondary || 'white',
        borderRadius: 10,
        position: 'relative',
        margin: `0 ${theme.spacing(0.25)}px 15px ${theme.spacing(0.25)}px`,
        overflow: 'hidden',
        // /* Scale ticket down for iPhone 5; not optimal but usable */
        '@media only screen and (max-height: 555px)': {
            transform: 'scale(0.75)',
            transformOrigin: 'top',
        },
        [theme.breakpoints.up('md')]: {
            width: 350,
            // height: 455,
        },
    },
    rightTextAlign: {
        color: theme.palette.text.secondary || 'white',
        textAlign: 'right',
    },
    text: {
        color: theme.palette.text.secondary || 'white',
    },
    headerLogo: {
        display: 'flext',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50%',
        height: '44px',
        background: theme.palette.background.default || theme.palette.secondary.dark,
        marginTop: '35px',
        paddingLeft: '15px',
        paddingRight: '3px',
        [theme.breakpoints.down('md')]: {
            height: '33px',
        },
    },
    ticket_dot_left: {
        width: '5px',
        height: '10px',
        backgroundColor: theme.palette.background.default,
        position: 'absolute',
        left: 0,
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
        top: '29%',
    },
    ticket_dot_right: {
        width: '5px',
        height: '10px',
        backgroundColor: theme.palette.background.default,
        position: 'absolute',
        right: 0,
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        top: '29%',
    },
    ticket_class: {
        width: '100%',
        padding: '0px 15px',
        margin: '4px 0px',
    },
}));

const BookingTicket = (props: BookingTicketProps) => {
    const {
        order,
        location,
        reservationStartDate,
        reservationEndDate,
        size,
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    // Ticket view after placing the Order ==> we retrieve the location
    const retrievedLocation = useSelector(Selector.Location.makeLocation(order?.locationId ?? ''));

    // const retrievedSize = useSelector(Selector.Location.makeLocationAssetGroups(order?.locationId ?? ''));

    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <Box className={classes.root}>
                <Box className={classes.ticket_dot_left} />
                <Grid container={true} spacing={2} direction="column">
                    <Grid item={true} container={true} justifyContent="space-between" direction="row">
                        <Box className={classes.headerLogo}>
                            <TenantLogo type="default" size="large" />
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    container={true}
                    className={classes.ticket_class}
                    spacing={2}
                    direction="column"
                >
                    <Grid
                        item={true}
                        container={true}
                        spacing={2}
                        justifyContent="space-between"
                        direction="row"
                    >
                        <Grid item={true} justifyContent="flex-start">
                            <Typography className={classes.text}>{t('orders.ticket_overview.start')}</Typography>
                            <Typography
                                className={classes.text}
                                style={{ display: 'flex', flexDirection: 'column' }}
                            >
                                <strong>
                                    {reservationStartDate.format(t('formats.date'))}
                                </strong>

                                <strong>
                                    {getTimeFormat(reservationStartDate)}
                                </strong>
                            </Typography>
                        </Grid>
                        <Grid item={true} spacing={2} justifyContent="flex-end">
                            <Box style={{ textAlign: 'right' }}>
                                <Typography className={classes.text}>{t('orders.ticket_overview.end')}</Typography>
                                <Typography
                                    className={classes.text}
                                    style={{ display: 'flex', flexDirection: 'column' }}
                                >
                                    <strong>{reservationEndDate.format(t('formats.date'))}</strong>
                                    <strong>{getTimeFormat(reservationEndDate)}</strong>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid item={true}>
                        <Typography className={classes.text}>{t('orders.ticket_overview.location')}</Typography>
                        <Typography className={classes.text}>
                            <strong>
                                {
                                    order
                                        ?
                                        retrievedLocation?.name
                                        :
                                        location
                                }
                            </strong>
                        </Typography>
                    </Grid>
                    <Grid item={true}>
                        <Typography className={classes.text}>{t('orders.ticket_overview.booked_item')}</Typography>
                        <Typography className={classes.text}>
                            <strong>{
                                t('orders.ticket_overview.amount_and_size', {
                                    amount: props?.amount,
                                    size: size,
                                })}
                            </strong>
                        </Typography>
                    </Grid>
                    {
                        order
                            ?
                            <Grid item={true}>
                                <Typography className={classes.text}>
                                    {t('orders.ticket_overview.booking_number')}
                                </Typography>
                                <Typography className={classes.text}>
                                    <strong>{order?.bookingNumber}</strong>
                                </Typography>
                            </Grid>
                            :
                            null
                    }

                </Grid>
                <Box className={classes.ticket_dot_right} />
            </Box>
        </Box>
    );
};

export default BookingTicket;
