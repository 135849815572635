/* eslint-disable */

export const Imprint = {

en: `
# Imprint
    
<p>segmenta communications GmbH</p> 
<p>Neumühlen 1 D-22763 Hamburg</p>
`,



de: `
# Impressum
    
<p>segmenta communications GmbH</p>
<p>Neumühlen 1</p>
<p>D-22763 Hamburg</p>
`,

            }
