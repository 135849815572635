import React, {useCallback, useEffect, useState} from 'react';
import {InputAdornment, TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import _debounce from 'lodash/debounce';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(2.5),
        zIndex: 0,
        '& .MuiInputBase-input': {
            color: theme.palette.primary.dark,
            borderRadius: 0,

            /* Style to override autofill background and text color */
            '&:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 100px transparent inset !important',
                boxShadow: '0 0 0 100px transparent inset !important',
                WebkitTextFillColor: theme.palette.primary.dark + ' !important',
                transition: 'background-color 5000s ease-in-out 0s !important',
                backgroundColor: 'blue !important',
            },
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.primary.dark,
                color: theme.palette.primary.dark,
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.dark,
                color: theme.palette.primary.dark,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.dark,
                color: theme.palette.primary.dark,
            },
        },
        '& .MuiInputBase-root': {
            color: theme.palette.primary.dark,
        },
    },
    label: {
        color: theme.palette.primary.dark, 
        opacity: '0.8',
        '&.Mui-focused.MuiInputLabel-outlined': {
            color: theme.palette.primary.dark,
        },
    },
}));

interface DebouncedTextInputProps {
    label?: string;
    initialValue?: string;
    onChange: (value: string) => void;
    valid?: boolean;
    autoComplete?: string;
}

const DebouncedTextInput = (props: DebouncedTextInputProps) => {
    const classes = useStyles();
    const [value, setValue] = useState<string>('');

    const debouncedOnChange = useCallback(
        _debounce(props.onChange, 700, {leading: false, trailing: true}),
        [],
    );

    useEffect(() => {
        if (props.initialValue) {
            setValue(props.initialValue);
        }
    }, []);

    useEffect(() => {
        debouncedOnChange(value);
    }, [value]);

    return (
        <TextField
            value={value}
            onChange={event => setValue(event.target.value)}
            className={classes.root}
            variant={'outlined'}
            label={props.label}
            color={'primary'}
            fullWidth={true}
            size={'small'}
            required={true}
            autoComplete={props.autoComplete}
            InputLabelProps={{
                classes: {
                    root: classes.label,
                },
            }}
            InputProps={{
                endAdornment: props.valid
                    ? <InputAdornment position="end"><CheckIcon color={'primary'} /></InputAdornment>
                    : null,
            }}
        />
    );
};

export default DebouncedTextInput;
