import {Box, Button, Typography} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    errorView: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    actionsWrapper: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
    },
    backButton: {
        marginTop: theme.spacing(1),
    },
    tryAgainButton: {
        marginTop: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
        },
    },
}));

interface InitializationErrorInfoProps {
    showTryAgain: boolean;
    onBackClick: () => void;
    onTryAgainClick: () => void;
}

const InitializationErrorInfo = (props: InitializationErrorInfoProps) => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Box className={classes.errorView}>
            <Typography variant={'body1'} align={'center'}>
                {t('book_location.error.payment_initialization_info')}
            </Typography>
            <Box className={classes.actionsWrapper}>
                <Button onClick={props.onBackClick} variant={'contained'} className={classes.backButton}>
                    {t('book_location.payment.go_to_locations')}
                </Button>
                {props.showTryAgain && (
                    <Button
                        onClick={props.onTryAgainClick}
                        variant={'contained'}
                        color={'secondary'}
                        className={classes.tryAgainButton}
                        children={t('book_location.payment.try_again')}
                    />
                )}
            </Box>
        </Box>
    );
};

export default InitializationErrorInfo;
