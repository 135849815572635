import { createAction } from 'redux-actions';
import { AssetGroup, AssetGroupAvailability } from '../types/assetGroup';
import { Location } from '../types/location';

export interface LocationsFetchedPayload {
    locations: Location[];
}

export interface LocationByIdFetchedPayload {
    location: Location;
}

export interface AvailabilitiesFetchedPayload {
    locationId: string;
    locationAvailability: AssetGroupAvailability;
}

export interface AssetGroupFetchedPayload {
    locationId: string;
    assetGroups: AssetGroup[];
}

export interface AssetGroupFetchedByLocationIdsPayload {
    locationIds: string[];
    assetGroups: AssetGroup[];
}

export interface AssetGroupAvailabilitiesFetchedPayload {
    locationId: string;
    assetGroup: AssetGroup;
}

/* eslint-disable max-len */
const LocationEvent = {
    locationsFetched: createAction<LocationsFetchedPayload>('@@KL_EVT/LOCATIONS_FETCHED'),
    locationByIdFetched: createAction<LocationByIdFetchedPayload>('@@KL_EVT/LOCATION_BY_ID_FETCHED'),
    availabilitiesFetched: createAction<AvailabilitiesFetchedPayload>('@@KL_EVT/AVAILABILITIES_FETCHED'),
    assetGroupsFetched: createAction<AssetGroupFetchedPayload>('@@KL_EVT/ASSETGROUPS_FETCHED'),
    assetGroupsFetchedByLocationIds: createAction<AssetGroupFetchedByLocationIdsPayload>('@@KL_EVT/ASSETGROUPS_BY_LOCATION_IDS_FETCHED'),
    assetGroupAvailabilitiesFetched: createAction<AssetGroupAvailabilitiesFetchedPayload>('@@KL_EVT/ASSETGROUP_AVAILABILITIES_FETCHED'),
};

export default LocationEvent;
