import { Box} from '@material-ui/core';
import React from 'react';
import logo_196x196 from '../../../assets/icons/map/logo_196x196.png';


const RegistrationLogo = () => {
    return (
        <Box>
            <img src={logo_196x196} alt="logo" />
        </Box>
    );
};

export default RegistrationLogo;
