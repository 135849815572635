import TenantConfig from '../util/tenantConfig';

interface TokenPayload {
    exp: number; // Expiry time in Unix timestamp format
}

interface IdTokenPayload {
    groups: string[]; // Groups id
}

export function getGroupsId(idToken: string): string[] | null {
    // This function is used only if you already checked the user is authenticated and used authType is entraid
    // Decode the JWT token
    const tokenParts = idToken?.split('.');
    if (tokenParts?.length !== 3) {
        // Invalid token format
        return null;
    }

    const payload = JSON.parse(atob(tokenParts[1])) as IdTokenPayload;

    if (payload.groups) {
        return payload.groups;
    }

    return null;
}

export function getIdToken() {
    const clientId = process.env.REACT_APP_ENTRAID_CLIENT_ID!;
    // Iterate through local storage keys
    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        if (key && key.includes('idtoken')) {
            const item = getItemFromSessionStorage(key);

            // MSAL stores tokens in objects with a specific structure
            if (item && item.secret && item.clientId === clientId) {
                const idToken = item.secret;
                return idToken;
            }
        }
    }
    return null;
}

export async function getAuthType() {
    const tenantConfig = await TenantConfig();
    return tenantConfig.authentication;
}

function getItemFromSessionStorage(key: string) {
    const item = sessionStorage.getItem(key);
    if (item !== null) {
        return JSON.parse(item);
    }
    return null;
}

function validateToken(token: string) {
    // Decode the JWT token
    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) {
        // Invalid token format
        return false;
    }

    const payload = JSON.parse(atob(tokenParts[1])) as TokenPayload;

    // Check token expiration
    const currentTimestamp = Math.floor(Date.now() / 1000); // Current time in Unix timestamp format
    if (payload.exp < currentTimestamp) {
        // Token is expired
        return false;
    }

    // Token is valid
    return true;

}

function getCognitoToken() {
    return sessionStorage.getItem('accessToken');
}


function getEntraIDToken() {
    const clientId = process.env.REACT_APP_ENTRAID_CLIENT_ID!;
    // Iterate through local storage keys
    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        if (key && key.includes('accesstoken')) {
            const item = getItemFromSessionStorage(key);

            // MSAL stores tokens in objects with a specific structure
            if (item && item.secret && item.clientId === clientId) {
                const authToken = item.secret;
                return authToken;
            }
        }
    }
    return null;
}

export function getAuthToken(authType: string) {
    if (authType && authType === 'cognito') {
        return getCognitoToken();
    }
    if (authType && authType === 'entraid') {
        return getEntraIDToken();
    }
    return null;
}

export async function isAuthenticated() {
    const authType = await getAuthType();

    if (authType === undefined) {
        return false; // Configuration is still loading
    }
    if (authType === 'none') {
        // No authentication required
        return false;
    }
    const accessToken = getAuthToken(authType);
    return accessToken !== null && validateToken(accessToken);
}
