import { ThemeProvider } from '@material-ui/styles';
import React, { Suspense, useEffect, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router';
import { BrowserRouter, Redirect } from 'react-router-dom';
import Command from './action/command';
import { initializeI18n } from './i18n/i18n';
import Notifier from './modules/common/Notifier';
import OrderLinkHandler from './modules/common/OrderLinkHandler';
import AddNewOrderDialog from './modules/hints/AddNewOrderDialog';
import HintsPage from './modules/hints/HintsPage';
import LoaderOverlay from './modules/layout/components/LoaderOverlay';
import SplashScreen from './modules/layout/components/SplashScreen';
import LocationsPage from './modules/locations/LocationsPage';
import OrdersPage from './modules/orders/OrdersPage';
import StripePaymentReturnPage from './modules/payments/StripePaymentReturnPage';
import VippsPaymentReturnPage from './modules/payments/VippsPaymentReturnPage';
import Routes from './routes';
import { store } from './store';
import getTheme from './util/themeConfig';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AuthentificationProvider from './authentification/AuthentificationProvider';
import RouteWatcher from './modules/common/RouteWatcher';
import MainLayout from './modules/layout/components/MainLayout';
import Selector from './selector';
import { TenantConfig as TConfig } from './types/tenantConfig';
import { loadVippsCheckoutSDK } from './util/vipps';
import { loadStripeCheckout } from './util/stripe';
import ConsentPage from './modules/locations/ConsentPage';
import LocationsSelectionPage from './modules/locations/LocationsSelectionPage';

/* eslint-disable max-len */
const BookLocationDialog = React.lazy(() => import('./modules/bookLocation/BookLocationDialog' /* webpackChunkName: "book-location-dialog" */));
const UseOrderDialog = React.lazy(() => import('./modules/useOrder/UseOrderDialog' /* webpackChunkName: "use-order-dialog" */));
const ReportDamageDialog = React.lazy(() => import('./modules/reportDamage/ReportDamageDialog' /* webpackChunkName: "report-damage-dialog" */));
const ReportIssueDialog = React.lazy(() => import('./modules/reportIssue/ReportIssueDialog' /* webpackChunkName: "report-damage-dialog" */));

const HintsPageDialog = React.lazy(() => import('./modules/hints/DialogComponent' /* webpackChunkName: "tips-dialog" */));

const AppRouter = () => {
    const tenantName = process.env.REACT_APP_TENANT;
    const dispatch = useDispatch();
    const consent = useSelector(Selector.Consent.consent);
    const consentLoaded = useSelector(Selector.Consent.loaded);
    const [welcomePage, setWelcomePage] = useState('');
    const history = useHistory();
    const location = useLocation();

    const locationRouteMatch = useRouteMatch<{ locationId: string }>({
        path: [Routes.locationPath, Routes.bookLocationPath],
        exact: true,
    });
    const orderRouteMatch = useRouteMatch<{ orderId: string }>({
        path: [
            Routes.orderDetailsPath,
            Routes.reportDamagePath,
            Routes.reportIssuePath,
            Routes.orderFaqsPath,
        ],
        exact: true,
    });
    const dataPrivacyMatch = useRouteMatch<{ orderId: string }>({
        path: [
            Routes.consentGDPRPagePath,
        ],
        exact: true,
    });

    const excludedOrderIds = ['add-order'];

    const getOrderId = () => {
        if (orderRouteMatch) {
            const orderId = orderRouteMatch.params.orderId;
            if (!excludedOrderIds.includes(orderId)) {
                return orderId;
            }
        }
        return undefined;
    };

    const locationId = locationRouteMatch ? locationRouteMatch.params.locationId! : undefined;
    const orderId = getOrderId();

    const showBookLocationDialog = Boolean(locationRouteMatch && locationRouteMatch.path === Routes.bookLocationPath);
    const showUseOrderDialog = Boolean(orderRouteMatch);
    const showDataPrivacyDialog = Boolean(dataPrivacyMatch);
    
    const showReportDamageDialog = Boolean(useRouteMatch({ path: Routes.reportDamagePath }));
    const showReportIssueDialog = Boolean(useRouteMatch({ path: Routes.reportIssuePath }));
    const showAddNewOrderDialog = Boolean(useRouteMatch({ path: Routes.addOrderPath }));

    const showHintsPageDialog = Boolean(useRouteMatch({ path: Routes.hintsPagePath, exact: true }));

    const tenantConfig = useSelector(Selector.Config.config);
    const firstScreen = useSelector(Selector.Config.firstScreenDisplay);

    const handlePageRefresh = () => {
        const bookLocationPathPattern = /^\/locations\/[^/]+\/book$/;
        
        if (bookLocationPathPattern.test(window.location.pathname)) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (locationId){
            dispatch(Command.Location.fetchAssetGroups({ locationId }));
        }
    }, [locationId]);

    useEffect(() => {
        dispatch(Command.Location.fetchLocations({}));
        dispatch(Command.Consent.loadConsent({}));
        const initializeTenant = async () => {
            // initialize booking flow
            dispatch(Command.BookingCreation.bookingFlowChanged({
                bookingFlow: tenantConfig.bookingFlow || [],
            }));
            // set first screen
            setWelcomePage(firstScreen);
            // TODO check if isLoadingState is needed
        };
        initializeTenant();

        if (handlePageRefresh()) {
            window.location.href = Routes.locationsPath;

        }
    }, []);

    useEffect(() => {
        if (!consentLoaded) return;

        const originalURL = location.pathname + location.search;

        if (consent === null && tenantConfig.consentRequired) {
            history.push(`${Routes.consentPagePath}?redirect=${encodeURIComponent(originalURL)}`);
        }
    }, [consent, consentLoaded]);

    // Wait for consent to load before rendering
    if (!consentLoaded) {
        return <LoaderOverlay open={!consentLoaded} />;
    } 

    // If consent is null, show the ConsentPage and block all other routes
    if (consent === null && tenantConfig.consentRequired === true) {
        return (
            <>
                <Switch>
                    <Route path={Routes.consentPagePath} component={ConsentPage} />
                    <Route path={Routes.consentGDPRPagePath} component={HintsPageDialog} />
                    <Redirect to={Routes.consentPagePath} />
                </Switch>
                <Suspense
                    fallback={showDataPrivacyDialog ? <LoaderOverlay open={true} /> : null}
                >
                    <HintsPageDialog open={showDataPrivacyDialog} />
                </Suspense>
            </>
        );
    }

    return (
        <>
            <Switch>
                {
                    tenantConfig.toolbarItems.includes('locations') && (
                        <Route
                            path={Routes.locationsPath}
                            exact={true}
                            component={consent === true ? LocationsPage : LocationsSelectionPage}
                        />
                    )
                }
                <Route path={Routes.bookLocationPath} component={BookLocationDialog} />
                {/* <Route path={Routes.locationSelectionPagePath} component={LocationsSelectionPage} /> */}
                <Route 
                    path={Routes.locationPath} 
                    component={consent === true ? LocationsPage : LocationsSelectionPage} 
                />
            
                <Route path={Routes.ordersPath} exact={true} component={OrdersPage} />
                <Route path={Routes.orderDetailsPath} component={OrdersPage} />
                <Route
                    path={Routes.vippsPaymentReturnPath}
                    exact={true}
                    component={VippsPaymentReturnPage}
                />
                <Route
                    path={Routes.stripePaymentReturnPath}
                    exact={true}
                    component={StripePaymentReturnPage}
                />
                <Route path={Routes.hintsPath} component={HintsPage} />

                <Route path={'/bookings/:orderId'} component={OrderLinkHandler} />
                <Redirect to={welcomePage} />
            </Switch>

            <Suspense
                fallback={showBookLocationDialog ? <LoaderOverlay open={true} /> : null}
            >
                <BookLocationDialog
                    open={showBookLocationDialog}
                    locationId={locationId}
                />
            </Suspense>
            <Suspense
                fallback={showUseOrderDialog ? <LoaderOverlay open={true} /> : null}
            >
                <UseOrderDialog open={showUseOrderDialog} orderId={orderId} />
                {
                    tenantName === 'segmenta' && showReportIssueDialog
                        ?
                        <ReportIssueDialog open={showReportIssueDialog} orderId={orderId} />
                        :
                        <ReportDamageDialog open={showReportDamageDialog} orderId={orderId} />
                }
            </Suspense>
            <Suspense
                fallback={showHintsPageDialog ? <LoaderOverlay open={true} /> : null}
            >
                <HintsPageDialog open={showHintsPageDialog} />
                <AddNewOrderDialog open={showAddNewOrderDialog} />

            </Suspense>
        </>
    );
};

interface Props {
    tenantConfig: TConfig;
    tenantAssets: any;
    instance: any;
}

const App = (props: Props) => {
    const tenantConfig = props.tenantConfig;
    const tenantAssets = props.tenantAssets;
    const instance = props.instance;

    return (
        <Provider store={store}>
            <Main instance={instance} tenantConfig={tenantConfig} tenantAssets={tenantAssets} />
        </Provider>
    );
};

const Main = (props: Props) => {
    const dispatch = useDispatch();
    dispatch(Command.Config.loadConfig({}));

    const [theme, setTheme] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Add loading state
    const [showSplash, setShowSplash] = useState(true);

    const tenantConfig = props.tenantConfig;
    const tenantAssets = props.tenantAssets;
    const instance = props.instance;

    const consent = useSelector(Selector.Consent.consent);

    useEffect(() => {
        const initializeTenant = async () => {
            try {
                setTheme(await getTheme());
                await initializeI18n(tenantConfig);
                // TODO LOAD TENANT CONFIG
                //  vipps support
                if (tenantConfig.paymentProvider.includes('vipps')) {
                    await loadVippsCheckoutSDK();
                }
                // stripe support
                if (tenantConfig.paymentProvider.includes('stripe')) {
                    await loadStripeCheckout();
                }
                //Once everything is loaded, set isLoading to false
                setIsLoading(false);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching theme:', error);
                setIsLoading(false); // Handle error by setting isLoading to false
            }
        };
        initializeTenant();
    }, []);

    useEffect(() => {
        const splashTimeout = setTimeout(() => {
            setShowSplash(false);
        }, 2000); // Show splash screen for 5 seconds

        return () => clearTimeout(splashTimeout); // Cleanup timeout on component unmount
    }, []);

    if (isLoading || showSplash) {
        // Render the splash screen if isLoading is true
        return <SplashScreen />;
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>{tenantConfig?.seo.appTitle}</title>
                <meta name="description" content={tenantConfig.seo.appDescription} />
                <link rel="icon" type="image/png" href={tenantAssets?.default.small.url} sizes="16x16" />
            </Helmet>
            <Notifier />
            <ThemeProvider theme={theme!}>
                <BrowserRouter>
                    {/* Public Routes without authentication for guest users can be placed here */}
                    {/* EXAMPLE */}
                    {/* <Route path={Routes.locationsPath} component={LocationsPage} /> */}

                    {/* Protected Routes */}
                    <AuthentificationProvider instance={instance}>
                        <>
                            <RouteWatcher />
                            <MainLayout showToolBar={consent !== null ? true : false}>
                                <AppRouter />
                            </MainLayout>
                        </>
                    </AuthentificationProvider>
                </BrowserRouter>
            </ThemeProvider>
        </HelmetProvider>
    );
};

export default App;
