import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RegistrationLogo from './components/RegistrationLogo';
import RegistrationInput from './components/RegistrationInput';
import { useHistory, useLocation } from 'react-router';
import { confirmSignUp } from '../../util/cognitoAuthService';
import Command from '../../action/command';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        height: '100%',
        padding: theme.spacing(0.5),
        paddingTop: theme.spacing(2),
    },
    info1: {
        textAlign: 'center',
        fontFamily: 'Source Sans 3', // Changed to the specified font family
        fontSize: '20px', // Changed to the specified font size
        fontStyle: 'normal', // Changed to the specified font style
        fontWeight: 700, // Changed to the specified font weight
        lineHeight: 'normal',
    },
    form: {
        paddingBottom: theme.spacing(5),
        margin: 'auto',
    },
    button: {
        height: '54px',
        width: '380px',
        borderRadius: '4px',
    },
}));

interface LocationState {
    email: string;
}

const ConfirmRegistration = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const location = useLocation<LocationState>();
    const [email, setEmail] = useState(location.state?.email || '');
    const [confirmationCode, setConfirmationCode] = useState('');

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            await confirmSignUp(email, confirmationCode);
            dispatch(Command.Notification.enqueueNotification({
                severity: 'success',
                message: t('Account confirmed successfully!\nSign in on next page.'),
            }));
            history.push('/login');
        } catch (error) {
            dispatch(Command.Notification.enqueueNotification({
                severity: 'error',
                message: t(`Failed to confirm account: ${error}`),
            }));
        }
    };

    return (
        <Box className={classes.root}>
            <Typography className={classes.info1}>
                {t('authentication.confirm')}
            </Typography>
            <RegistrationLogo />
            <Grid item={true}>
                <Typography variant="body1">Welcome to kolula hub!</Typography>
            </Grid>
            <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container={true} spacing={2}>
                    <RegistrationInput
                        label="Email"
                        name="email"
                        type="email"
                        value={email}
                        placeholder="Please enter your Email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <RegistrationInput
                        label="Confirmation Code"
                        name="ConfirmationCode"
                        type="code"
                        value={confirmationCode}
                        placeholder="Confirmation Code"
                        onChange={(e) => setConfirmationCode(e.target.value)}
                    />
                    <Grid item={true} xs={12}>
                        <Button type="submit" variant="contained" className={classes.button} color="primary">
                            Confirm Account
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default ConfirmRegistration;
