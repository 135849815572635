import { Box, IconButton } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Command from '../../../action/command';
import Selector from '../../../selector';
import { AssetGroup } from '../../../types/assetGroup';
import { Order } from '../../../types/order';
import RentalTime from './RentalTime';
import Assets from '../../../assets/assets';
import KoluluBoxInfoBox from '../../useOrder/box/KolulaBoxInfoBox';

const useStyles = makeStyles((theme) => ({
    root: {
        width: 296,
        height: 386,
        background: '#00607B',
        color: 'white',
        borderRadius: theme.shape.borderRadius,
        position: 'relative',
        margin: `0 ${theme.spacing(0.25)}px`,
        overflow: 'hidden',
        /* Scale ticket down for iPhone 5; not optimal but usable */
        '@media only screen and (max-height: 555px)': {
            // transform: 'scale(0.75)',
            transformOrigin: 'top',
            width: '260px',
        },
        [theme.breakpoints.up('md')]: {
            width: 350,
            height: 455,
        },
    },
    header: {
        margin: `${theme.spacing(1)}px 0 0 ${theme.spacing(2)}px`,
        textAlign: 'left',
    },
    bookingNumber: {
        margin: `-2px 0 0 ${theme.spacing(2)}px`,
    },
    headerWrapper: {
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'space-between',
    },
    headline: {
        margin: `${theme.spacing(4)}px 0 ${theme.spacing(4)}px 0`,
        fontWeight: 'bold',
        padding: `0 ${theme.spacing(2)}px`,
        fontSize: '26px',
        textAlign: 'center',
    },
    orderItem: {
        padding: '0 0 0 25px',
    },
    upperCase: {
        textTransform: 'none',
    },
    headerLogo: {
        width: '90px',
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    ticket_dot_left: {
        width: '5px',
        height: '10px',
        backgroundColor: '#54595f',
        position: 'absolute',
        left: 0,
        borderTopRightRadius: '5px',
        borderBottomRightRadius: '5px',
        top: '29%',
    },
    ticket_dot_right: {
        width: '5px',
        height: '10px',
        backgroundColor: '#54595f',
        position: 'absolute',
        right: 0,
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        top: '29%',
    },
    infoIconButton: {
        width: '50px', 
        height: '50px', 
    },
    infoIcon: {
        width: '30px',
        height: '30px',
    },
}));

interface OrderTicketProps {
    setInfoOpen: (infoBoxOpen: boolean) => void;
    setSelectedOrderId: (infoBoxOpen: string) => void;
    order: Order;
}

const OrderTicket = (props: OrderTicketProps) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const location = useSelector(Selector.Location.makeLocation(props.order.locationId));
    const assetGroups: AssetGroup[] | null = useSelector(
        Selector.Location.makeLocationAssetGroups(props.order.locationId),
    );
    const InfoIconWhiteFilled = Assets.icon.infoIconWhiteFilled.component;
    const showInfo = props.order.items[0].rental && Object.keys(props.order.items[0].rental).length > 0;

    // TODO check asset group loading
    useEffect(() => {
        dispatch(Command.Location.fetchAssetGroups({locationId: props.order.locationId}));
    }, []);
    useEffect(() => {
        dispatch(Command.Location.fetchAssetGroups({locationId: props.order.locationId}));
    }, [props.order.locationId]);

    if (!location && !assetGroups) {
        return null;
    }

    const orderItems = props.order.items.map((item) => {
        const assetGroup = assetGroups?.find((group) => group.id === item.groupId);

        return {
            ...item,
            name: assetGroup?.name,
        };
    });
    return (
        <>

            <Box className={classes.root}>
                <Box className={classes.ticket_dot_left} />
                <Box className={classes.headerWrapper}>
                    <Box>
                        <Typography variant={'body2'} className={classes.header}>
                            {t('orders.booking_number')}
                        </Typography>
                        <Typography variant={'body2'} className={classes.bookingNumber}>
                            {props.order.bookingNumber}
                        </Typography>
                    </Box>
                    {
                        showInfo && 
                        <Box>
                            <IconButton 
                                className={classes.infoIconButton} 
                                onClick={() => {props.setInfoOpen(true); props.setSelectedOrderId(props.order.id);}}
                            >
                                <InfoIconWhiteFilled className={classes.infoIcon} /> 
                            </IconButton>
                        </Box>
                    }
                </Box>

                <Typography variant={'h2'} className={classes.headline}>
                    {location?.name}
                </Typography>

                <Box className={classes.orderItem}>
                    {orderItems.map((item, index) => (
                        item &&
                        <>
                            <Box key={index} style={{paddingBottom: '15px'}}>
                                <RentalTime
                                    startDateTime={item.fromDate}
                                    endDateTime={item.toDate}
                                    preparationTime={location?.preparationTime || 0}
                                />
                                <Typography
                                    style={{
                                        fontWeight: 'bold',
                                        lineHeight: 1.2,
                                        paddingLeft: '27px',
                                        paddingTop: '3px',
                                    }}
                                >
                                    {item.amount || 1}&times; {item.name || 'Artikel'}
                                </Typography>
                            </Box>
                        </>
                    ))}
                </Box>
                <Box className={classes.ticket_dot_right} />
            </Box>
        </>
    );
};

export default OrderTicket;
