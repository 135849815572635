import React, {useEffect, useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogTitle} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CouponCodeInput from '../SelectionStep/CouponCodeInput';
import {useDispatch, useSelector} from 'react-redux';
import Command from '../../../../action/command';
import {useTranslation} from 'react-i18next';
import Selector from '../../../../selector';
import LoaderButton from '../../../layout/components/LoaderButton';

const COUPON_CODE_MIN_LENGTH = 3;

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        backgroundColor: theme.palette.background.default,
    },
    dialogTitle: {
        color: 'white',
    },
    inputWrapper: {
        margin: '8px',
    },
}));

interface RedeemCouponDialogProps {
    open: boolean;
    onClose: () => void;
}

const RedeemCouponDialog = (props: RedeemCouponDialogProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const loading = useSelector(Selector.BookingCreation.makeLoading('couponRedemption'));
    const [couponCode, setCouponCode] = useState<string>('');

    const isButtonDisabled = couponCode.length < COUPON_CODE_MIN_LENGTH;

    const redeemCoupon = () => {
        dispatch(Command.BookingCreation.redeemCoupon({ couponCode }));
    };

    useEffect(() => {
        if (loading && props.open) {
            props.onClose();
        }
    }, [loading]);

    return (
        <Dialog open={props.open} onClose={props.onClose} classes={{paper: classes.dialogPaper}}>
            <DialogTitle className={classes.dialogTitle}>
                {t('book_location.confirmation.redeem_coupon')}
            </DialogTitle>
            <Box className={classes.inputWrapper}>
                <CouponCodeInput value={couponCode} onChange={setCouponCode} />
            </Box>
            <DialogActions>
                <Button
                    size={'small'}
                    children={t('general.cancel')}
                    onClick={props.onClose}
                />
                <LoaderButton
                    key={`${isButtonDisabled}`}
                    loading={loading}
                    color={'secondary'}
                    variant={'contained'}
                    size={'small'}
                    children={t('book_location.confirmation.redeem')}
                    disabled={isButtonDisabled}
                    onClick={redeemCoupon}
                />
            </DialogActions>
        </Dialog>
    );
};

export default RedeemCouponDialog;
