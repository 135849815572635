import { call } from 'redux-saga/effects';
import KolulaStorageManager from '../../storage/KolulaStorageManager';
import ErrorTracker from '../../util/errorTracker';
import Logger from '../../util/logger';

export const setRentalStartedFlag = function* (internalRentalId: string, items: string[]) {
    try {
        const kolulaStorageManager = new KolulaStorageManager();
        yield call(kolulaStorageManager.setRentalStartedFlag, internalRentalId, items);
    } catch (error) {
        ErrorTracker.trackException(error);
        Logger.error(error);
    }
};
