import { getI18n } from 'react-i18next';
import { Action } from 'redux-actions';
import { call, fork, put, select, take } from 'redux-saga/effects';
import Command from '../action/command';
import Event from '../action/event';
import { FinishRentalPayload } from '../action/rentalCommand';
import Api from '../api';
import { Rental } from '../types/rental';
import ErrorTracker from '../util/errorTracker';
import Logger from '../util/logger';
import { onEnqueueErrorSnackbar, onEnqueueSuccessSnackbar } from './enqueueSnackbarFlow';

export const onFinishRental = function* (rental: Rental) {
    // const rental: Rental | null = yield select(Selector.Rental.rental);

    // if (!rental) {
    //     return;
    // }

    // TODO: handle multiple items
    try {
        yield call(Api.finishRental, rental.orderId, [rental.orderItemId], rental.token);
    } catch (error) {
        yield fork(onEnqueueErrorSnackbar, getI18n().t('orders.error.finish_booking'), 8000);
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);

        // For now still assume that the order was finished to give the user a option to leave the process
        yield put(Event.Rental.rentalFinished({orderId: rental.orderId, isFinished: false}));
        return;
    }
    yield put(Event.Rental.rentalFinished({orderId: rental.orderId, isFinished: true}));
    yield fork(onEnqueueSuccessSnackbar, getI18n().t('orders.booking_finished_info'), 8000);
};

export function* finishRentalFlow() {
    while (true) {
        const action: Action<FinishRentalPayload> = yield take(Command.Rental.finishRental.toString());
        yield fork(onFinishRental, action.payload.rental);
    }
}
