import {call, fork, put, take} from 'redux-saga/effects';
import KolulaStorageManager from '../storage/KolulaStorageManager';
import Event from '../action/event';
import Command from '../action/command';
import ErrorTracker from '../util/errorTracker';
import Logger from '../util/logger';
import { SetConsentPayload } from '../action/consentCommand';
import { Action } from 'redux-actions';


export const onSetConsent = function*(consent: boolean) {
    try {
        const kolulaStorageManager = new KolulaStorageManager();
        yield put(Event.Consent.setConsent({ consent }));
        yield call(kolulaStorageManager.setSharingConsent, consent);
        

    } catch (error) {
        ErrorTracker.trackException(error);
        Logger.error(error);
    }
};

export function* setConsentFlow() {
    while (true) {
        const action: Action<SetConsentPayload> = yield take(Command.Consent.setConsent.toString());
        yield fork(onSetConsent, action.payload.consent);
    }
}
