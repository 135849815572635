import {Box, Button, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {formatMoney} from '../../../i18n/i18n';
import Selector from '../../../selector';
import BookingTicket from './BookingTicket';
import OrderDetailsItem from './Confirmation/OrderDetailsItem';
import RedeemCouponDialog from './Confirmation/RedeemCouponDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    locationName: {
        color: theme.palette.secondary.main,
        fontSize: '17px',
        fontWeight: 500,
        textAlign: 'center',
    },
    orderDetails: {
        margin: `${theme.spacing(1)}px 0`,
        padding: `${theme.spacing(1)}px 0`,
        borderTop: '1px solid white',
        borderBottom: '1px solid white',
    },
    pickupDetails: {},
    pickupDatetimeWrapper: {
        display: 'flex',
    },
    pickupDate: {
        flex: 2,
        lineHeight: 'normal',
    },
    pickupTime: {
        flex: 1,
        lineHeight: 'normal',
    },
    pickupDuration: {
        lineHeight: 'normal',
        marginTop: theme.spacing(1.5),
    },
    totalAmount: {
        margin: `${theme.spacing(1)}px 0`,
        padding: `${theme.spacing(1)}px 0`,
        borderTop: '1px solid white',
        display: 'flex',
        justifyContent: 'space-between',
    },
    couponWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(1),
    },
    informationWrapper: {
        textAlign: 'center',
    },
    legalInformation: {
        marginTop: theme.spacing(1),
    },
}));

interface ConfirmationStepProps {
    locationId: string;
}

const ConfirmationStep = (props: ConfirmationStepProps) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const orderData = useSelector(Selector.BookingCreation.orderData);
    const reservation = useSelector(Selector.BookingCreation.reservation)!;
    const location = useSelector(Selector.Location.makeLocation(props.locationId))!;
    const assetGroups = useSelector(Selector.Location.makeLocationAssetGroups(props.locationId));
    const selectedAssetGroup = useSelector(Selector.BookingCreation.assetGroupSelection);
    const [couponDialogOpen, setCouponDialogOpen] = useState<boolean>(false);

    // const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState<boolean>(false);
    // const [termsAndConditionsOpen, setTermsAndConditionsOpen] =
    // useState<boolean>(false);

    const tenantConfig = useSelector(Selector.Config.config);
    // TODO implement coupon code
    const couponCode = undefined;
    // const couponCode = reservation.discount
    //     ? reservation.discount.code
    //     : undefined;

    // TODO check the 0 index
    const reservationStartDate = moment(reservation.reservationItems[0].fromDate);
    const reservationEndDate = moment(reservation.reservationItems[0].toDate);
    const reservationDate = moment(reservationStartDate);
    const pickUpDate = moment(reservationStartDate).subtract(location.preparationTime, 'minutes');

    useEffect(() => {
        if (couponCode) {
            setCouponDialogOpen(false);
        }
    }, [couponCode]);

    // const openPrivacyPolicy = () => {
    //     setPrivacyPolicyOpen(true);
    //     setTermsAndConditionsOpen(false);
    // };

    // const openTermsAndConditions = () => {
    //     setTermsAndConditionsOpen(true);
    //     setPrivacyPolicyOpen(false);
    // };

    const oderDurationText =
        orderData.duration < 120
            ? t('general.minutes_val', {count: orderData.duration})
            : t('general.hours_val', {count: orderData.duration / 60});

    const showPricingInformation = !tenantConfig.paymentProvider?.includes('no_payment');
    const shwowCouponButton = Boolean(tenantConfig.allowCoupons);
    const enableCouponDialog = shwowCouponButton && !couponCode;

    const assetGroup = assetGroups?.find(assetGroup => assetGroup.id === selectedAssetGroup);

    return (
        <Box className={classes.root}>
            {tenantConfig.bookingPreviewType === 'ticket' ? (
                <BookingTicket
                    location={location.name}
                    size={assetGroup?.name}
                    reservationStartDate={reservationStartDate}
                    reservationEndDate={reservationEndDate}
                    amount={reservation.reservationItems?.length}
                />
            ) : (
                <Box>
                    <Typography variant={'h2'} className={classes.locationName}>
                        {t('general.location')} {location.name}
                    </Typography>
                    <Box className={classes.orderDetails}>
                        {reservation.reservationItems.map((item, index) => (
                            <OrderDetailsItem
                                key={index}
                                reservationItem={item}
                                assetGroups={assetGroups || []}
                            />
                        ))}
                        {couponCode && (
                            <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                                <Typography>
                                    {t('general.coupon')} ({couponCode})
                                </Typography>
                                <Typography>
                                    {formatMoney(
                                        -reservation.discount!.amount,
                                        tenantConfig.currency,
                                    )}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Box className={classes.pickupDetails}>
                        <Box className={classes.pickupDatetimeWrapper}>
                            <Typography className={classes.pickupDate}>
                                {t('book_location.confirmation.on')}
                                <br />
                                <strong>{reservationDate.format(t('formats.date'))}</strong>
                            </Typography>
                            <Typography className={classes.pickupTime}>
                                Pick-up
                                <br />
                                <strong>
                                    {reservationDate.format(t('formats.time'))}{' '}
                                    {t('book_location.confirmation.clock')}
                                </strong>
                            </Typography>
                        </Box>
                        <Typography className={classes.pickupDuration}>
                            {t('book_location.confirmation.for')}
                            <br />
                            <strong>{oderDurationText}</strong>
                            <br />
                            {t('book_location.confirmation.pickup_from', {
                                time: pickUpDate.format(t('formats.time')),
                            })}
                        </Typography>
                    </Box>
                    <Box className={classes.totalAmount}>
                        <Typography>
                            <strong>
                                {t('general.total')}
                            </strong>
                            <br />
                            {t('book_location.confirmation.tax', {taxRate: tenantConfig?.taxRate})}
                        </Typography>
                        <Typography style={{textAlign: 'right'}}>
                            <strong>
                                {formatMoney(reservation.payment.totalPrice, tenantConfig.currency)}
                            </strong>
                            <br />
                            {formatMoney(
                                (reservation.payment.totalPrice * tenantConfig?.taxRate!) / 100,
                                tenantConfig.currency,
                            )}
                        </Typography>
                    </Box>
                </Box>
            )}
            <Box>
                {enableCouponDialog && (
                    <Box className={classes.couponWrapper}>
                        <Button
                            variant={'contained'}
                            size={'small'}
                            color={'secondary'}
                            onClick={() => setCouponDialogOpen(true)}
                            children={t('book_location.confirmation.redeem_coupon')}
                        />
                        <RedeemCouponDialog
                            open={couponDialogOpen}
                            onClose={() => setCouponDialogOpen(false)}
                        />
                    </Box>
                )}
                <Box className={classes.informationWrapper}>
                    {showPricingInformation && (
                        <Typography variant={'body2'}>
                            {t('book_location.confirmation.tax_information', {
                                taxRate: tenantConfig?.taxRate,
                            })}
                        </Typography>
                    )}
                    {/* <Typography variant={'body2'} className={classes.legalInformation}>
                        <Trans
                            i18nKey={'book_location.confirmation.legal_information'}
                            components={{
                                l1: <Link href={'#'} onClick={() => openPrivacyPolicy()} />,
                                l2: (
                                    <Link href={'#'} onClick={() => openTermsAndConditions()} />
                                ),
                            }}
                        />
                    </Typography> */}
                </Box>
            </Box>
        </Box>
    );
};

export default ConfirmationStep;
