import {TextField} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        zIndex: 0,
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
        },
        '& .MuiFormLabel-root': {
            color: 'white',
        },
        '& .MuiInputBase-input': {
            color: 'white',
        },
    },
}));

interface CouponCodeInputProps {
    value: string;
    onChange: (value: string) => void;
}

const CouponCodeInput = (props: CouponCodeInputProps) => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <TextField
            value={props.value}
            onChange={event => props.onChange(event.target.value)}
            className={classes.root}
            variant={'outlined'}
            label={t('book_location.order_data.coupon_code')}
            color={'secondary'}
            fullWidth={true}
            size={'small'}
        />
    );
};

export default CouponCodeInput;
