import { createAction } from 'redux-actions';
import { Rental } from '../types/rental';

export interface RentalFetchingBeganPayload {
}

export interface RentalFetchedPayload {
    rental: Rental;
}

export interface RentalFetchingFailedPayload {
}

export interface RentalsLoadedPayload {
    rentals: Rental[];
}

export interface RentalStartingBeganPayload {
    orderId: string;
    itemIds: string[];
}

export interface RentalStartedPayload {
    rental: Rental;
    startedTimestamp: Date;
}

export interface RentalStartingFailedPayload {
}

export interface RentalFinishedPayload {
    orderId: string;
    isFinished: boolean;
}

export interface ReportingDamageBeganPayload {
}

export interface ReportingDamageFailedPayload {
}

export interface DamageReportedPayload {
}

export interface DoorsVisibleTimestampResetPayload {
}

export interface RentalStartWithTooFewResourcesPayload {
    availableCount: number;
    bookedCount: number;
}

/* eslint-disable max-len */
const RentalEvent = {
    rentalFetchingBegan: createAction<RentalFetchingBeganPayload>('@@KL_EVT/RENTAL_FETCHING_BEGAN'),
    rentalFetched: createAction<RentalFetchedPayload>('@@KL_EVT/RENTAL_FETCHED'),
    singleRentalFetched: createAction<RentalFetchedPayload>('@@KL_EVT/SINGLE_RENTAL_FETCHED'),
    rentalFetchingFailed: createAction<RentalFetchingFailedPayload>('@@KL_EVT/RENTAL_FETCHING_FAILED'),
    rentalsLoaded: createAction<RentalsLoadedPayload>('@@KL_EVT/RENTALS_LOADED'),
    rentalStartingBegan: createAction<RentalStartingBeganPayload>('@@KL_EVT/RENTAL_STARTING_BEGAN'),
    rentalStarted: createAction<RentalStartedPayload>('@@KL_EVT/RENTAL_STARTED'),
    rentalStartingFailed: createAction<RentalStartingFailedPayload>('@@KL_EVT/RENTAL_STARTING_FAILED'),
    rentalFinished: createAction<RentalFinishedPayload>('@@KL_EVT/RENTAL_FINISHED'),
    reportingDamageBegan: createAction<ReportingDamageBeganPayload>('@@KL_EVT/REPORTING_DAMAGE_BEGAN'),
    reportingDamageFailed: createAction<ReportingDamageFailedPayload>('@@KL_EVT/REPORTING_DAMAGE_FAILED'),
    damageReported: createAction<DamageReportedPayload>('@@KL_EVT/DAMAGE_REPORTED'),
    doorsVisibleTimestampReset: createAction<DoorsVisibleTimestampResetPayload>('@@KL_EVT/DOORS_VISIBLE_TIMESTAMP_RESET'),
    rentalStartWithTooFewResources: createAction<RentalStartWithTooFewResourcesPayload>('@@KL_EVT/TOO_FEW_RESOURCES'),
};

export default RentalEvent;
