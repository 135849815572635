import { PageType, PaymentProvider, TenantConfig } from '../../types/tenantConfig';
import { DataProtection } from './content/dataProtection';
import { Faq } from './content/faq';
import { Imprint } from './content/imprint';
import { TermsOfUse } from './content/termsOfUse';
const tenant = process.env.REACT_APP_TENANT!;

export const FaqsPage: PageType = {
    type: 'accordion',
    content: {
        en: Faq['en'],
        de: Faq['de'], 
    },
};
const TermsOfUsePage: PageType = {
    type: 'text',
    content: {
        en: {
            title: 'Terms of Use',
            content: TermsOfUse['en'],
        },
        de: {
            title: 'Nutzungsbedingungen',
            content: TermsOfUse['de'],
        },
    },
};

const DataProtectionPage: PageType = {
    type: 'text',
    content: {
        en: {
            title: 'Data Protection',
            content: DataProtection['en'],
        },
        de: {
            title: 'Datenschutzrichtlinien',
            content: DataProtection['de'],
        },
    },
};

const ImprintPage: PageType = {
    type: 'text',
    content: {
        en: {
            title: 'Imprint',
            content: Imprint['en'],
        },
        de: {
            title: 'Impressum',
            content: Imprint['de'],
        },
    },
};

// Example for a link hint page
// const TestLinkPage: PageType = {
//     type: 'link',
//     content: {
//         en: {
//             title: 'Test Link Page',
//             content: 'https://test.com',
//         },
//         de: {
//             title: 'Test Link Page',
//             content: 'https://test.com',
//         },
//     },
// };

const paymentProvider: PaymentProvider[] = process.env
    .REACT_APP_PAYMENT_PROVIDER
    ? (process.env.REACT_APP_PAYMENT_PROVIDER.split(',') as PaymentProvider[])
    : [];

export const Config: TenantConfig = {
    tenant,
    app: {
        version: process.env.REACT_APP_VERSION!,
        name: process.env.REACT_APP_NAME!,
    },
    mapBox: {
        apiKey: process.env.REACT_APP_MAPBOX_API_KEY!,
    },
    api: {
        baseUrl: process.env.REACT_APP_API_BASE_URL!,
        key: process.env.REACT_APP_API_KEY!,
    },
    gtm: {
        id: process.env.REACT_APP_GTM_ID!,
        auth: process.env.REACT_APP_GTM_AUTH!,
        preview: process.env.REACT_APP_GTM_PREVIEW!,
    },
    seo: {
        appTitle: 'Segmenta Mitarbeiter Storage',
        appDescription: 'Mitarbeiter Storage',
    },
    languages: ['de'],
    fallbackLng: 'de',
    taxRate: 19,
    currency: 'EUR',
    paymentProvider: paymentProvider,
    map: {
        center: [10.3935449, 50.5763016] as [number, number],
        zoom: 4.8,
    },
    phoneInput: {
        defaultCountry: 'de',
        preferredCountries: ['de', 'at', 'ch', 'nl', 'be'],
    },
    prices: [
        { duration: 1, weekdayPrice: 9.99, weekendPrice: 12.99 },
        {
            duration: 1.5,
            weekdayPrice: 13.99,
            weekendPrice: 17.99,
            bestseller: true,
        },
        { duration: 2, weekdayPrice: 17.99, weekendPrice: 23.99 },
        { duration: 3, weekdayPrice: 24.99, weekendPrice: 29.99 },
        { duration: 4, weekdayPrice: 29.99, weekendPrice: 36.99 },
    ],
    contact: {
        phone: '+49404411300',
        phoneFormatted: '+49 40-44 11 30-0',
    },
    toolbarItems: ['orders', 'info'],
    HintPageComponents: {
        hintPages: { 
            faqs: FaqsPage,
            // testLink: TestLinkPage, 
        },
        legalPages: {
            termsOfUse: TermsOfUsePage,
            dataProtection: DataProtectionPage,
            imprint: ImprintPage,
        },
    },
    bookingPreviewType: 'ticket',
    authentication: 'entraid',
    permissions: {
        // Permanent Group
        '14fd8557-94af-48b4-b634-9a0cf29181e4': { 
            addOrder: true,
            createOrder: true,
        },
        // Temporary Group
        '2d52d462-1074-4db5-87ef-24d837d3e208': {
            addOrder: false,
            createOrder: true,
        },
        '9aee5fd9-0c95-4b3f-b01f-04c48e8ecb7f': { 
            addOrder: true,
            createOrder: true,
        },
        '1e79167e-21c0-456a-878c-44d2fd4b6f64': { 
            addOrder: false,
            createOrder: true,
        },
    },
    optionalScreens: [
        'orderItemDetails',
        'responsibilityExplanation',
        'orderOverview',
        'partnerUscInformation',
    ],
    bookingFlow: [
        'assetGroupSelection',
        'orderData',
        'confirmation',
        'payment',
    ],
    startPage: 'none',
    allowCoupons: false,
    allowNewsLetter: false,
    consentRequired: false,
};

export default Config;
