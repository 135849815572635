import React from 'react';
import {Box, TextField, Typography} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import Selector from '../../../selector';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        boxSizing: 'border-box',
        marginTop: theme.spacing(3),
        padding: theme.spacing(2),
    },
    input: {
        marginTop: theme.spacing(3),
        '& .MuiFormLabel-root': {
            color: theme.palette.primary.dark,
        },
        '& .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.dark,
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.dark,
        },
        '& .MuiInputBase-input': {
            color: theme.palette.primary.dark,
        },
    },
    text: {
        fontSize: theme.typography.h1.fontSize,
    },
}));

interface AddNewOrderFormProps {
    emailValue: string;
    orderNumbervalue: string;
    onChangeOrderNumber: (value: string) => void;
    onChangeEmail: (value: string) => void;
    fullHeight?: boolean;
}

const AddNewOrderForm = (props: AddNewOrderFormProps) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const tenantConfig = useSelector(Selector.Config.config);

    const handleOnChangeTicketNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;

        // TODO check format
        // if (!newValue.match(/^[0-9]*$/)) {
        //     return;
        // }

        props.onChangeOrderNumber(newValue);
    };

    const handleOnChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;

        // TODO check format
        // if (!newValue.match(/^[0-9]*$/)) {
        //     return;
        // }

        props.onChangeEmail(newValue);
    };

    return (
        <Box className={classes.root} style={props.fullHeight ? { height: '100%' } : undefined}>
            <Typography
                variant={'h1'} 
                className={classes.text}
            >
                {t('orders.header.enter_ticket_number')}
            </Typography>
            <TextField
                className={classes.input}
                fullWidth={true}
                label={t('orders.ticket_number')}
                variant={'outlined'}
                color={'secondary'}
                value={props.orderNumbervalue}
                onChange={handleOnChangeTicketNumber}
                inputProps={{
                    type: 'text',
                    // pattern: '[0-9]*',
                }}
            />
            {
                tenantConfig.authentication === 'none' && 
                <TextField
                    className={classes.input}
                    fullWidth={true}
                    label={t('orders.email')}
                    variant={'outlined'}
                    color={'secondary'}
                    value={props.emailValue}
                    onChange={handleOnChangeEmail}
                    inputProps={{
                        type: 'email',
                        // pattern: '[0-9]*',
                    }}
                />
            }
        </Box>
    );
};

export default AddNewOrderForm;
