import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import CustomButton from '../../tenants/segmenta/component/CustomButton';

const useStyles = makeStyles(theme => ({
    dialogText: {
        color: theme.palette.primary.main,
    },
    infoIcon: {
        width: '18px',
        fontWeight: 'bold',
        verticalAlign: 'middle',
    },
    cancelButton: {
        width: '109px',
        height: '44px',
        gap: '10px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
    },
    confirmButton: {
        width: '100px',
        height: '44px',
        gap: '10px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        boxShadow: 'none',
    },
}));

interface PageClosingDialogProps {
    open: boolean;
    content: any;
    onClose: () => void;
    onContinue: () => void;
}

const GeneralDialog = (props: PageClosingDialogProps) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const onConfirmFinishRental = () => {
        props.onContinue();
        props.onClose();
    };

    return (
        <Dialog
            open={props.open}
        >
            <DialogTitle className={classes.dialogText}>
                {t('use_order.closing_dialog.title')}
            </DialogTitle>
            <DialogContent>
                <Typography className={classes.dialogText} variant={'body2'}>
                    {props.content}
                </Typography>
            </DialogContent>
            <DialogActions>
                <CustomButton
                    className={classes.cancelButton}
                    isButtonDark={false}
                    fullWidth={true}
                    variant={'outlined'}
                    onClick={() => props.onClose()}
                    color='secondary'
                    children={t('use_order.stop_using.cancel_button')}
                />
                <CustomButton
                    className={classes.confirmButton}
                    isButtonDark={true}
                    fullWidth={true}
                    variant={'contained'}
                    onClick={onConfirmFinishRental}
                    color='primary'
                    children={t('use_order.closing_dialog.close')}
                />
            </DialogActions>
        </Dialog>
    );
};

export default GeneralDialog;
