import i18nInstance from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import de from './de.json';
import en from './en.json';
import nb from './nb.json';
import { TenantConfig } from '../types/tenantConfig';

export const initializeI18n = async (tenantConfig: TenantConfig) => {

    // initialize with the default translations
    i18nInstance
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            detection: {
                order: ['querystring', 'localStorage', 'navigator'],
            },
            resources: {
                de: { translations: de },
                en: { translations: en },
                nb: { translations: nb },
            },
            supportedLngs: tenantConfig.languages, // Using dynamic language configuration
            fallbackLng: tenantConfig.fallbackLng ?? tenantConfig.languages[0], // Using dynamic fallback language
            debug: true,
            ns: ['translations'],
            defaultNS: 'translations',
            interpolation: {
                escapeValue: false,
                format: (value, format) => {
                    if (typeof value === 'number' && format === 'number')
                        return formatNumber(value);
                    if (typeof value === 'number' && format === 'money')
                        return formatMoney(value, tenantConfig.currency);
                    return value;
                },
            },
        });

    // override the default translations with the tenant specific translations
    const tenantName = process.env.REACT_APP_TENANT;
    await tenantConfig.languages.forEach(async (language) => {
        try {
            const resource = await import(`../tenants/${tenantName}/i18n/${language}.json`);
            i18nInstance.addResourceBundle(language, 'translations', resource, true, true);
        } catch (error) {
            // ignore
        }
    });
};
export const supportedLanguages = ['de', 'en', 'nb'];

export const i18n = i18nInstance;

export const persistLanguage = (language: string): void => {
    if (!supportedLanguages.includes(language)) {
        return;
    }

    localStorage.setItem('i18nextLng', language);
};

export const getCurrentLocale = () => {
    switch (i18nInstance.language) {
        case 'de': return 'de-DE';
        case 'nb': return 'nb-NO';
        default: return 'en-US';
    }
};

export const formatNumber = (value: number, options?: Intl.NumberFormatOptions): string =>
    value.toLocaleString(getCurrentLocale(), options);

export const formatMoney = (value: number, currency: string): string => {
    return Intl.NumberFormat(getCurrentLocale(), {
        style: 'currency',
        currency: currency,
    }).format(value);
};
