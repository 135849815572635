import { ReduxState } from '../reducer';
import { AssetGroup, AssetGroupAvailability } from '../types/assetGroup';
import { Location } from '../types/location';

const stateKey = 'location';

const LocationSelector = {
    locations: (state: ReduxState) => state[stateKey].locations,

    makeLocation: (locationId: string) => (state: ReduxState): Location | null =>
        state[stateKey].locations.find(location => location.id === locationId) || null,
    makeLocationAssetGroups: (locationId: string) => (state: ReduxState): AssetGroup[] | null => {
        for (const location of state[stateKey].locations) {
            if (location.id === locationId) {
                return location.assetGroups;
            }
        }
        return null;
    },

    makeLocationAssetGroupAvailabilities: (locationId: string, groupId: string) =>
        (state: ReduxState): AssetGroupAvailability | null => {
            const assetGroups: AssetGroup[] = state[stateKey].locations.find(
                location => location.id === locationId)?.assetGroups ?? [];
            // const assetGroups: AssetGroups = state[stateKey].locations[locationId];
            // TODO check why the location id is used as key
            const group = assetGroups.find(group => group.id === groupId);
            return group?.availabilities ?? null;
        },

    // makeLocationAccessories: (locationId: string) => (state: ReduxState): string[] =>
    //     state[stateKey].locations.find(location => location.id === locationId)?.accessories || [],
    // makeLocationAssetGroups: (locationId: string) => (state: ReduxState): AssetGroup[] =>
    //     state[stateKey].locations.find(location => location.id === locationId)?.groups || [],
    // makeOption: (locationId: string, optionId: string) => (state: ReduxState): Option|null => {
    //     if (!state[stateKey].availabilities[locationId]) {
    //         return null;
    //     }

    //     // for (const optionsData of Object.values(state[stateKey].availabilities[locationId].options)) {
    //     // const option = Object.values(optionsData).find(optionData => optionData.id === optionId);
    //     // if (option) {
    //     //     return option;
    //     // }
    //     // }

    //     return null;
    // },
};

export default LocationSelector;
