import React, { ReactNode, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import Routes from '../../routes';
import { validateCognitoAccessToken } from '../../util/cognitoAuthService';
import RegistrationByMeans from '../../modules/Registration/RegistrationByMeans';
import RegistrationPage from '../../modules/Registration/RegistrationViaEmail';
import ConfirmRegistration from '../../modules/Registration/ConfirmRegistration';
import CognitoLogin from '../../modules/Registration/Login';
import ForgottenPassword from '../../modules/Registration/ForgottenPassword';
import ResetPassword from '../../modules/Registration/ResetPassword';


type Props = {
  children?: ReactNode;
};

const CognitoAuthPrivider = ({ children }: Props) => {
    const accessToken = sessionStorage.getItem('accessToken') as string;
    const [isAuthenticated, setIsAuthenticated] = useState<Boolean>(false);

    useEffect(() => {
        const validateUserToken = async () => {
            try {
                await validateCognitoAccessToken(accessToken);
                setIsAuthenticated(true);
            } catch (err) {
                setIsAuthenticated(false);
            }
        };
        validateUserToken();
    }, []);

    const CognitoAuthComponents = <Switch>
        <Route path={Routes.registrationPath} component={RegistrationByMeans} />
        <Route path={Routes.registerViaMail} component={RegistrationPage} />
        <Route path={Routes.confirmPath} component={ConfirmRegistration} />
        <Route path={Routes.login} component={CognitoLogin} />
        <Route path={Routes.forgottenPassword} component={ForgottenPassword} />
        <Route path={Routes.resetPassword} component={ResetPassword} />
        <Redirect to={Routes.login} />
    </Switch>;

    return <>{isAuthenticated ? <>{ children}</> : <>{CognitoAuthComponents}</>}</>;
};

export default CognitoAuthPrivider;
