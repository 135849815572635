import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import CognitoAuthPrivider from './Cognito/CognitoAuthProvider';
import EntraIdAuthProvider from './EntraId/EntraIdAuthProvider';
import { useSelector } from 'react-redux';
import Selector from '../selector';

export default function AuthentificationProvider (
    { instance, children }:
    { instance: any, children: React.ReactNode }) {
    
    // Fetch auth type from tenant config
    const authType = useSelector(Selector.Config.config);

    if (authType.authentication === undefined) {
        return null; // Return null while configuration is being loaded to prevent a looping reload
    }


    switch (authType.authentication) {
        case 'none':
            return <>{children}</>;
        case 'cognito':
            return (
                <CognitoAuthPrivider>
                    {children}
                </CognitoAuthPrivider>
            );
        case 'entraid':
            return (
                <MsalProvider instance={instance as PublicClientApplication}>
                    <EntraIdAuthProvider>
                        {children}
                    </EntraIdAuthProvider>
                </MsalProvider>
            );
    }
};
