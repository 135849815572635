import React from 'react';
import {useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import Routes from '../../routes';
import {useDispatch} from 'react-redux';
import Command from '../../action/command';
import {useParams} from 'react-router';

const OrderLinkHandler = () => {
    const dispatch = useDispatch();
    const params = useParams<{ orderId: string }>();
    const internalOrderId = params.orderId;

    useEffect(() => {
        dispatch(Command.Order.fetchOrder({ internalOrderId, createdBy: 'any', showFinishedWarning: true }));
    }, [internalOrderId]);

    return <Redirect to={Routes.ordersPath} />;
};

export default OrderLinkHandler;
