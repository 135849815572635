import moment from 'moment';
import { Action, handleActions } from 'redux-actions';
import {
    AssetCountSelectedPayload,
    AssetGroupActivatedPayload,
    AssetGroupCountChangedPayload,
    AssetGroupDeactivatedPayload,
    BookingCreationCanceledPayload,
    BookingCreationCompletedPayload,
    BookingCreationStartedPayload, BookingStepChangedPayload,
    BraintreePaymentInitializedPayload,
    CouponRedemptionLoadingPayload,
    DateSelectedPayload,
    DurationSelectedPayload,
    EmailChangedPayload,
    FirstNameChangedPayload,
    LastNameChangedPayload,
    NewsletterConsentChangedPayload,
    PaymentCompletedPayload,
    PaymentCompletionFailedPayload,
    PaymentInitializationFailedPayload,
    PaymentInitializationRetryStartedPayload,
    PaymentTypeChangedPayload,
    PersonalDataChangedPayload,
    PhoneChangedPayload,
    ReservationCreatedPayload,
    ReservationRemovedPayload,
    StripePaymentInitializedPayload,
    TimeSelectedPayload,
    UscCustomerIdChangedPayload,
    VippsPaymentInitializedPayload,
} from '../action/bookingCreationEvent';
import Event from '../action/event';
import { BookingCreationStep } from '../modules/bookLocation/BookLocationPage';
import {
    BraintreePaymentToken,
    PersonalData,
    PersonalDataValidation,
    Reservation,
    StripeToken,
    VippsToken,
} from '../types/bookingCreation';
import { OrderId } from '../types/order';
import { getEnumNameByNumber, getEnumNumberByName } from '../util/EnumUtil';
import DefaultConfig from '../util/defaultConfig';
import {
    isValidEmail,
    isValidPhone,
    isValidUscCustomerId,
} from '../util/validation';
import {BookingFlowPayload} from '../action/bookingCreationCommand';

export interface BookingCreationSelection {
    date: moment.Moment | null;
    time: string;
    duration: number;
    amount: number;

}

export interface BookingCreationState {
    locationId: string | null;
    multipleBoardTypes: boolean;
    partner: 'usc' | null;
    step: number;
    nextEnabled: boolean;
    orderData: BookingCreationSelection;
    assetGroupId: string | null;
    personalData: PersonalData;
    personalDataValidation: PersonalDataValidation;
    reservation: null | Reservation;
    payment: {
        provider: 'braintree' | 'vipps' | 'stripe' | 'no_payment' | null;
        paymentType: string;
        paymentInitFailed: boolean;
        orderId: null | OrderId;
        braintree: {
            paymentToken: null | BraintreePaymentToken;
        };
        vipps: {
            token: null | VippsToken;
            checkoutFrontendUrl: null | string;
        };
        stripe: {
            token: null | StripeToken;
            checkoutFrontendUrl: null | string;
        };
    };
    loading: {
        couponRedemption: boolean;
        paymentCompletion: boolean;
    };
    bookingFlow: string[];
}

export const initialState: BookingCreationState = {
    locationId: null,
    multipleBoardTypes: false,
    partner: null,
    step: 0,
    nextEnabled: false,
    orderData: {
        date: null,
        time: '',
        duration: 0,
        amount: 1,
    },
    assetGroupId: null,
    personalData: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        newsletterConsent: false,
    },
    personalDataValidation: {
        firstName: false,
        lastName: false,
        phone: false,
        email: false,
        uscCustomerId: false,
    },
    reservation: null,
    payment: {
        provider: null,
        paymentType: '',
        paymentInitFailed: false,
        orderId: null,
        braintree: {
            paymentToken: null,
        },
        vipps: {
            token: null,
            checkoutFrontendUrl: null,
        },
        stripe: {
            token: null,
            checkoutFrontendUrl: null,
        },
    },
    loading: {
        couponRedemption: false,
        paymentCompletion: false,
    },
    bookingFlow: [],
};

// const calculateTotalBoardSelectionCount = (boardSelection: {grouptId: string | null, quantity: number}) =>
//     Object.values(boardSelection).reduce((acc, value) => acc + value, 0);

export const reducer = handleActions<BookingCreationState, any>(
    {
        [Event.BookingCreation.bookingFlowChanged.toString()]: (
            state,
            action: Action<BookingFlowPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                bookingFlow: [...action.payload.bookingFlow],
            };
        },
        [Event.BookingCreation.bookingCreationStarted.toString()]: (
            state,
            action: Action<BookingCreationStartedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                locationId: action.payload.locationId,
                personalData: {
                    ...initialState.personalData,
                },
            };
        },
        [Event.BookingCreation.bookingCreationCanceled.toString()]: (
            state,
            action: Action<BookingCreationCanceledPayload>,
        ): BookingCreationState => {
            return initialState;
        },
        [Event.BookingCreation.navigatedToNextStep.toString()]: (
            state,
            action: Action<{}>,
        ): BookingCreationState => {
            // TODO: replace with redux state and tenant config
            const paymentProvider = DefaultConfig.paymentProvider;
            let nextStep = state.step;
            do {
                nextStep++;
                if (state.step === BookingCreationStep.assetGroupSelection) {
                    // If we are on the first step there is only one possible next step
                    // noop
                } else if (state.step === BookingCreationStep.orderData) {
                    // If we are on the orderData step, we want to go to the next step
                    // noop
                } else if (
                    state.step === BookingCreationStep.confirmation &&
                    paymentProvider &&
                    paymentProvider.length === 1
                ) {
                    // If we are on the payment step and there is only one payment type configured, skip the next step
                    nextStep = BookingCreationStep.payment;
                }

                if (state.step === BookingCreationStep.confirmation) {
                    // temporary skip the payment step
                    nextStep = BookingCreationStep.payment;
                }
                const nextStepName = getEnumNameByNumber(BookingCreationStep, nextStep);
                if (!nextStepName) {
                    throw new Error('Invalid step ');
                }
                if (state.bookingFlow.indexOf(nextStepName) === -1) {
                    // break;
                }
            } while (
                state.bookingFlow.indexOf(
                    getEnumNameByNumber(BookingCreationStep, nextStep),
                ) === -1
            );
            const nextEnabled =
                nextStep === BookingCreationStep.confirmation ||
                (nextStep === BookingCreationStep.personalData &&
                    isPersonalDataValid(validatePersonalData(state.personalData)));

            return {
                ...state,
                step: nextStep,
                nextEnabled: nextEnabled,
            };
        },
        [Event.BookingCreation.navigatedToPreviousStep.toString()]: (
            state,
            action: Action<{}>,
        ): BookingCreationState => {
            // get the configured booking flow
            const bookingFlow = state.bookingFlow;

            // get the index of the current step in the configured booking flow
            const currentStepIndex = bookingFlow.indexOf(
                getEnumNameByNumber(BookingCreationStep, state.step),
            );
            // get the previous step in the configured booking flow
            const previousStepName = bookingFlow[currentStepIndex - 1];
            // get the step number of the previous step
            const previousStep = getEnumNumberByName(BookingCreationStep, previousStepName);

            const resetBoardSelection = previousStep === 0;

            return {
                ...state,
                step: previousStep,
                assetGroupId: resetBoardSelection
                    ? initialState.assetGroupId
                    : state.assetGroupId,
                nextEnabled: true,
            };
        },
        [Event.BookingCreation.dateSelected.toString()]: (
            state,
            action: Action<DateSelectedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                nextEnabled: false,
                orderData: {
                    ...initialState.orderData,
                    date: action.payload.date,
                },
            };
        },
        [Event.BookingCreation.dateSelectedClear.toString()]: (
            state,
            action: Action<{}>,
        ): BookingCreationState => {
            return {
                ...state,
                // nextEnabled: false,
                orderData: {
                    ...initialState.orderData,
                    date: null,
                },
            };
        },
        [Event.BookingCreation.timeSelected.toString()]: (
            state,
            action: Action<TimeSelectedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                nextEnabled: false,
                orderData: {
                    ...state.orderData,
                    time: action.payload.time,
                    duration: 0,
                    amount: 1,
                },
            };
        },
        [Event.BookingCreation.durationSelected.toString()]: (
            state,
            action: Action<DurationSelectedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                nextEnabled: Boolean(action.payload.duration),
                orderData: {
                    ...state.orderData,
                    duration: action.payload.duration,
                    amount: 1,
                },
            };
        },
        [Event.BookingCreation.durationSelectedClear.toString()]: (
            state,
            action: Action<{}>,
        ): BookingCreationState => {
            return {
                ...state,
                // nextEnabled: Boolean(action.payload.duration),
                orderData: {
                    ...state.orderData,
                    duration: 0,
                    amount: 0,
                },
            };
        },
        [Event.BookingCreation.assetCountSelected.toString()]: (
            state,
            action: Action<AssetCountSelectedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                orderData: {
                    ...state.orderData,
                    amount: action.payload.assetCount,
                },
            };
        },
        [Event.BookingCreation.assetGroupActivated.toString()]: (
            state,
            action: Action<AssetGroupActivatedPayload>,
        ): BookingCreationState => {
            // only allow one asset group to be selected
            // initial set the amount of asset groups to 1
            const assetGroupId = action.payload.id;

            return {
                ...state,
                assetGroupId: assetGroupId,
                nextEnabled: Object.keys(assetGroupId).length > 0,
            };
        },
        [Event.BookingCreation.assetGroupDeactivated.toString()]: (
            state,
            action: Action<AssetGroupDeactivatedPayload>,
        ): BookingCreationState => {
            // only allow one asset group to be selected, so if one is deactivated, remove all from the selection
            return {
                ...state,
                assetGroupId: null,
                nextEnabled: false,
            };
        },
        [Event.BookingCreation.assetGroupCountChanged.toString()]: (
            state,
            action: Action<AssetGroupCountChangedPayload>,
        ): BookingCreationState => {
            // const totalSelectionCount = calculateTotalBoardSelectionCount(newBoardSelection);
            return {
                ...state,
                assetGroupId: action.payload.id,
                nextEnabled:
                    state.step === 1 ? action.payload.count >= 1 : state.nextEnabled,
            };
        },
        [Event.BookingCreation.reservationCreated.toString()]: (
            state,
            action: Action<ReservationCreatedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                reservation: action.payload.reservation,
            };
        },
        [Event.BookingCreation.reservationRemoved.toString()]: (
            state,
            action: Action<ReservationRemovedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                reservation: null,
            };
        },
        [Event.BookingCreation.firstNameChanged.toString()]: (
            state,
            action: Action<FirstNameChangedPayload>,
        ): BookingCreationState => {
            const personalData = {
                ...state.personalData,
                firstName: action.payload.firstName.trim(),
            };
            const validation = validatePersonalData(personalData);

            return {
                ...state,
                personalData,
                personalDataValidation: validation,
                nextEnabled: isPersonalDataValid(validation),
            };
        },
        [Event.BookingCreation.lastNameChanged.toString()]: (
            state,
            action: Action<LastNameChangedPayload>,
        ): BookingCreationState => {
            const personalData = {
                ...state.personalData,
                lastName: action.payload.lastName.trim(),
            };
            const validation = validatePersonalData(personalData);

            return {
                ...state,
                personalData,
                personalDataValidation: validation,
                nextEnabled: isPersonalDataValid(validation),
            };
        },
        [Event.BookingCreation.phoneChanged.toString()]: (
            state,
            action: Action<PhoneChangedPayload>,
        ): BookingCreationState => {
            const personalData = {
                ...state.personalData,
                phone: action.payload.phone.trim(),
            };
            const validation = validatePersonalData(personalData);

            return {
                ...state,
                personalData,
                personalDataValidation: validation,
                nextEnabled: isPersonalDataValid(validation),
            };
        },
        [Event.BookingCreation.emailChanged.toString()]: (
            state,
            action: Action<EmailChangedPayload>,
        ): BookingCreationState => {
            const personalData = {
                ...state.personalData,
                email: action.payload.email.trim(),
            };
            const validation = validatePersonalData(personalData);

            return {
                ...state,
                personalData,
                personalDataValidation: validation,
                nextEnabled: isPersonalDataValid(validation),
            };
        },
        [Event.BookingCreation.newsletterConsentChanged.toString()]: (
            state,
            action: Action<NewsletterConsentChangedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                personalData: {
                    ...state.personalData,
                    newsletterConsent: action.payload.newsletterConsent,
                },
            };
        },
        [Event.BookingCreation.paymentTypeChanged.toString()]: (
            state,
            action: Action<PaymentTypeChangedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    paymentType: action.payload.paymentType,
                },
            };
        },
        [Event.BookingCreation.uscCustomerIdChanged.toString()]: (
            state,
            action: Action<UscCustomerIdChangedPayload>,
        ): BookingCreationState => {
            const personalData = {
                ...state.personalData,
                uscCustomerId: action.payload.uscCustomerId.trim(),
            };
            const validation = validatePersonalData(personalData);

            return {
                ...state,
                personalData,
                personalDataValidation: validation,
                nextEnabled: isPersonalDataValid(validation),
            };
        },
        [Event.BookingCreation.personalDataChanged.toString()]: (
            state,
            action: Action<PersonalDataChangedPayload>,
        ): BookingCreationState => {
            const personalData = {
                ...state.personalData,
                ...action.payload,
            };

            return {
                ...state,
                personalData,
                personalDataValidation: validatePersonalData(personalData),
            };
        },
        [Event.BookingCreation.couponRedemptionLoading.toString()]: (
            state,
            action: Action<CouponRedemptionLoadingPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    couponRedemption: action.payload.loading,
                },
            };
        },
        [Event.BookingCreation.braintreePaymentInitialized.toString()]: (
            state,
            action: Action<BraintreePaymentInitializedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    provider: 'braintree',
                    braintree: {
                        paymentToken: action.payload.braintreePaymentToken,
                    },
                },
            };
        },
        [Event.BookingCreation.vippsPaymentInitialized.toString()]: (
            state,
            action: Action<VippsPaymentInitializedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    provider: 'vipps',
                    vipps: {
                        token: action.payload.vippsToken,
                        checkoutFrontendUrl: action.payload.checkoutFrontendUrl,
                    },
                },
            };
        },
        [Event.BookingCreation.stripePaymentInitialized.toString()]: (
            state,
            action: Action<StripePaymentInitializedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    provider: 'stripe',
                    stripe: {
                        token: action.payload.stripeToken,
                        checkoutFrontendUrl: action.payload.checkoutFrontendUrl,
                    },
                },
            };
        },
        [Event.BookingCreation.paymentInitializationFailed.toString()]: (
            state,
            action: Action<PaymentInitializationFailedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    paymentInitFailed: true,
                },
            };
        },
        [Event.BookingCreation.paymentInitializationRetryStarted.toString()]: (
            state,
            action: Action<PaymentInitializationRetryStartedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    paymentInitFailed: false,
                },
            };
        },
        [Event.BookingCreation.paymentCompletionBegan.toString()]: (
            state,
            action: Action<PaymentCompletedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    paymentCompletion: true,
                },
            };
        },
        [Event.BookingCreation.paymentCompleted.toString()]: (
            state,
            action: Action<PaymentCompletedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                payment: {
                    ...state.payment,
                    orderId: action.payload.orderId,
                },
                loading: {
                    ...state.loading,
                    paymentCompletion: false,
                },
            };
        },
        [Event.BookingCreation.paymentCompletionFailed.toString()]: (
            state,
            action: Action<PaymentCompletionFailedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                loading: {
                    ...state.loading,
                    paymentCompletion: false,
                },
            };
        },
        [Event.BookingCreation.bookingStepChanged.toString()]: (
            state,
            action: Action<BookingStepChangedPayload>,
        ): BookingCreationState => {
            return {
                ...state,
                step: action.payload.step,
                nextEnabled: action.payload.nextEnabled,
            };
        },
        [Event.BookingCreation.bookingCreationCompleted.toString()]: (
            state,
            action: Action<BookingCreationCompletedPayload>,
        ): BookingCreationState => {
            return initialState;
        },
    },
    initialState,
);

// @todo validating usc customer id like that is probably something that easily breaks at some point
// @todo this function should actually get the information whether this is a USC booking or not
const validatePersonalData = (data: PersonalData): PersonalDataValidation => {
    const firstNameValid = data.firstName.length > 0;
    const lastNameValid = data.lastName.length > 0;
    const phoneValid = data.phone.length > 0 && isValidPhone(data.phone);
    const emailValid = data.email.length > 0 && isValidEmail(data.email);
    const uscCustomerIdValid =
        data.uscCustomerId === undefined ||
        (data.uscCustomerId.length > 0 && isValidUscCustomerId(data.uscCustomerId));

    return {
        firstName: firstNameValid,
        lastName: lastNameValid,
        phone: phoneValid,
        email: emailValid,
        uscCustomerId: uscCustomerIdValid,
    };
};

const isPersonalDataValid = (validation: PersonalDataValidation): boolean => {
    return (
        validation.firstName &&
        validation.lastName &&
        validation.phone &&
        validation.email &&
        validation.uscCustomerId
    );
};
