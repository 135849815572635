import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Command from '../../action/command';
import Event from '../../action/event';
import Assets from '../../assets/assets';
import routes from '../../routes';
import Selector from '../../selector';
import { usePrevious } from '../../util/usePrevious';
import { useQueryParams } from '../../util/useQueryParams';
import LoaderOverlay from '../layout/components/LoaderOverlay';
import Logo from '../layout/components/Logo';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        paddingTop: theme.spacing(2),
        boxSizing: 'border-box',
        backgroundColor: 'var(--dark-background-color)',
    },
    contentWrapper: {
        maxWidth: 1000,
        margin: '0 auto',
        textAlign: 'center',
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
    header: {
        marginTop: theme.spacing(4),
    },
    info: {
        marginTop: theme.spacing(3),
    },
    button: {
        marginTop: theme.spacing(4),
    },
    moneyTransferIllustration: {
        height: 180,
        marginTop: theme.spacing(5),
    },
    warningIllustration: {
        height: 160,
        marginTop: theme.spacing(5),
    },
}));

const StripePaymentReturnPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const queryParams = useQueryParams();
    const paymentCompletionLoading = useSelector(Selector.BookingCreation.makeLoading('paymentCompletion'));
    const previousPaymentCompletionLoading = usePrevious(paymentCompletionLoading);
    const orderId = useSelector(Selector.BookingCreation.orderId);
    const [completionFailed, setCompletionFailed] = useState<boolean>(false);

    useEffect(() => {
        const reservationId = queryParams.get('reservationId');

        if (typeof reservationId !== 'string') {
            setCompletionFailed(true);
            return;
        }

        dispatch(Command.BookingCreation.completeStripePayment({
            reservationId,
        }));
    }, []);

    useEffect(() => {
        if (!paymentCompletionLoading && previousPaymentCompletionLoading) {
            if (orderId) {
                history.push(routes.ordersPath);
                dispatch(Event.BookingCreation.bookingCreationCompleted({}));
            } else {
                setCompletionFailed(true);
            }
        }
    }, [paymentCompletionLoading, orderId]);

    const navigateToBookings = () => {
        history.push(routes.ordersPath);
        dispatch(Event.BookingCreation.bookingCreationCompleted({}));
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.contentWrapper}>
                <Logo center={true} />

                {Boolean(orderId) && (<>
                    <Typography variant={'h1'} className={classes.header}>
                        {t('payments.stripe.return.header')}
                    </Typography>
                    <img
                        src={Assets.unDraw.transferMoney}
                        className={classes.moneyTransferIllustration}
                        alt="transferMoney"
                    />
                    <Typography variant={'body1'} className={classes.info}>
                        {t('payments.stripe.return.info')}
                    </Typography>
                </>)}

                {completionFailed && (<>
                    <Typography variant={'h1'} className={classes.header}>
                        {t('payments.stripe.return.error_header')}
                    </Typography>
                    <img src={Assets.unDraw.warning} className={classes.warningIllustration} alt="warning" />
                    <Typography variant={'body1'} className={classes.info}>
                        {t('payments.stripe.return.error_info')}
                    </Typography>
                </>)}

                {/* <Button variant={'contained'} onClick={navigateToBookings} className={classes.button}>
                    {t('payments.stripe.return.go_to_bookings')}
                </Button> */}
            </Box>
            <LoaderOverlay open={paymentCompletionLoading} />
        </Box>
    );
};

export default StripePaymentReturnPage;
