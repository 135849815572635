import { InteractionType } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalAuthenticationTemplate } from '@azure/msal-react';
import React, { ReactNode } from 'react';

export default function EntraIdAuthProvider (
    {children }: { readonly children: ReactNode }) {

    const authRequest = {
        scopes: ['openid', 'profile'],
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect} 
            authenticationRequest={authRequest} 
        >
            <AuthenticatedTemplate>
                {children}
            </AuthenticatedTemplate>
        </MsalAuthenticationTemplate>
    );
};
