
/* eslint-disable */

export const TermsOfService = {


de: `
## Präambel 

a. Der Eigentümer und Anbieter der Plattform ist eine Gesellschaft i.S.d. §§ 15 ff. AktG im Vonovia-Konzern. Vonovia ist mit über 500.000 eigen- und fremdverwalteten Wohnungen das größte börsennotierte Wohnungsunternehmen Deutschlands, deutschlandweit vertreten. Sie bzw. die mit ihr verbundenen Konzerngesellschaften im Sinne von §§ 15 ff. AktG sind Eigentümer der Immobilienobjekte. Die Bereitstellung der Plattform erfolgt durch den Online-Plattform Betreiber „kolula“. 
b. Über die leihbar können diverse Produkte und Geräte im Bereich von Sport- und Freizeit, Handwerk oder Haushalt gesucht, gebucht („Buchung“) und online bezahlt werden. Das Angebot wird von Vonovia selbst, oder von Partnerunternehmen („Betreiber“) in Eigenverantwortung in eigenem Namen und auf eigene Rechnung umgesetzt. 

kolula unterstützt den Eigentümer mit dem kolula-System gegenüber den Nutzern dabei im Wesentlichen durch die übersichtliche Darstellung und Abwicklung des Buchungsangebot, aber nicht bei der Erfüllung des Nutzungs- /Dienstleistungsvertrages zwischen Betreiber und Nutzer. 

c. Begriffsbestimmungen für diese Nutzungsbedingungen/AGB: 

Der Begriff „Nutzer“ beschreibt jene Personen (insb. Verbraucher), die die leihbar Web-App nutzen und sich über die kolula-Plattform registriert haben. 
Der Begriff „kolula-System“ umfasst die kolula-Plattform sowie sonstige Verwaltungs- und Buchungsdienste von kolula (online und offline). 
Der Begriff „Betreiber“ beschreibt jene natürliche oder juristische Person, die mit kolula einen Vertrag zur Inanspruchnahme des kolula-Systems als Anbieter von Sport- und Freizeitangeboten abgeschlossen hat („Partnervertrag“). 

d. Personenbezogene Daten (des Betreibers, seiner Kunden und seiner Mitarbeiter) im Sinne des Datenschutzgesetzes (insb. Telefonnummer, Adresse, Emailadresse, sonstige Inhaltsdaten etc.) werden gemäß der Datenschutzerklärung verarbeitet. Die Datenschutzerklärung ist unter www.kolula.com abrufbar. 

# Allgemeine Geschäftsbedingungen der kolula solutions UG

## 1. Geltungsbereich

Die nachfolgenden allgemeinen Geschäftsbedingungen („AGB“) gelten für die Inanspruchnahme von Lieferungen, Leistungen oder sonstigen Angeboten von

Living Innovations- & Beteiligungsgesellschaft mbH 
Universitätsstraße 133 
44803 Bochum 

E-Mail: [innovation@vonovia.de](mailto:innovation@vonovia.de)
Vertretungsberechtigter Geschäftsführer: 
Alexander Weihe 

(im Folgenden auch „Anbieter“ oder „wir“) durch Sie (im Folgenden auch „Nutzer“ oder „Kunde“), für sämtliche Geschäftsbeziehungen zwischen Ihnen und uns, sowie für die Nutzung unserer Website www.vonovia.de/leihbar, (im Folgenden auch „Website“), unserer Progressive Web App „leihbar“ (im Folgenden auch „App“), sowie unserer sonstigen Dienste.

Abweichende Bedingungen erkennen wir nicht an, es sei denn, wir hätten ausdrücklich schriftlich ihrer Geltung zugestimmt. Wir behalten uns das Recht vor, die AGB jederzeit zu ändern.

## 2. Nutzung / Nutzungsrechte 

Dem User wird ein zeitlich und räumlich unbeschränktes, kostenloses Nutzungsrecht an der App, deren Informationen und Diensten eingeräumt. Das Nutzungsrecht wird ausschließlich für den eigenen Bedarf und im Rahmen der Nutzung der App und deren Diensten unter Ausschluss jeglicher anderen Zwecke gewährt. 

Es ist dem User gestattet, Angebote in der App zu suchen, Angebote unter den Vermietungsstationen zu vergleichen und mit einem Anbieter Verträge abzuschließen. 

Hierfür ist der User verpflichtet, wahrheitsgetreue und vollständige Angaben für die Erfassung der Daten beim Anmeldeprozess anzugeben. 

Wenn Inhalte, die durch die vom Anbieter zustehenden geistigen Eigentumsrechte geschützt sind, durch den User verwendet werden, bleiben sämtliche Rechte hinsichtlich dieser Inhalte dem Anbieter erhalten. Die Verwendung von Urheberrechten und/oder Marken des Anbieters ist nicht gestattet. 

Der User darf namentlich die Inhalte der App nicht dazu verwenden, um diese in irgendwelcher Form zu ändern, zu kopieren, herunterzuladen, nachzukonstruieren, zu duplizieren, zu publizieren oder zu vertreiben. 

## 3. Angebote und Leistungsbeschreibungen 

### 3.1 leihbar - Plattform 

Über die Buchungsplattform bieten wir Ihnen unterschiedliche Haushalts- und Freizeitgeräte inkl. Zubehör zur personalfreien Vermietung an unseren digitalen Verleihstationen an. Über die Plattform können das Angebot sowie die jeweiligen Verfügbarkeiten eingesehen werden. Bei Bedarf können die benötigten Geräte ausgewählt und für den gewünschten Zeitraum reserviert und gebucht werden. Die Reservierung sowie der Entnahme der Produkte kann ausschließlich über die im Buchungsprozess ausgewählte Verleihstation erfolgen und ist an den jeweils zuvor angegebenen Zeitraum gebunden 

### 3.2 leihbar – Verleihstationen 

Die über die Plattform angebotenen Produkte sind an verschiedenen Standorten in unseren digitalisierten Verleihstationen -leihbar- hinterlegt und können nur über die Plattform gebucht und abgerufen werden. Das jeweilige Angebot kann gemäß der Kundenwünsche sowie der Anzahl der Fächer je nach Standort variieren. 

## 4. Persönliche Teilnahmevoraussetzungen, Bestellvorgang und Vertragsabschluss 

### 4.1 Inanspruchnahme der Leistungen 

Sie können unsere Leistungen über den Online-Buchungsmanager auf unserer Website, www.leihbar.vonovia.de in Anspruch nehmen. 

Sie müssen für den Abschluss einer Buchung volljährig sein. Wenn Sie minderjährige Personen an der Nutzung unserer Leistungen teilhaben lassen, erklären Sie, dass die erforderliche Zustimmung der gesetzlichen Vertreter vorliegt. Wir setzen bei der Auswahl der Produkte voraus, dass ein konkreter Bedarf für die Nutzung dieser vorliegt. 

Wir behalten uns vor, individuelle Regeln für die Buchung und Entnahme einzelner Geräte festzulegen, je nach Anwendungsbereich. 

Wir empfehlen allen Kunden, sich vor Abschluss der Buchung mit der Handhabung und den Anwendungsmöglichkeiten der jeweiligen Geräte auseinanderzusetzen, unabhängig von der Produktkategorie. Die spätere Anwendung der Geräte erfolgt in eigener Verantwortung. 

Während des gesamten Buchungszeitraums sind die Kunden verantwortlich für den Zustand der gebuchten Geräte und verpflichten sich, diese in einem funktionsfähigen Zustand zurückzugeben. Eventuelle Schäden oder Funktionsfehler müssen dem Anbieter umgehend gemeldet werden. 

Mit der Buchung erklären Sie, dass für alle Teilnehmer die genannten Voraussetzungen erfüllt sind. 

Der Anbieter behält sich vor, einzelne Kunden temporär und/oder dauerhaft von der Nutzung auszuschließen, sollte es zu einer Verletzung der Voraussetzungen 

## 4.2 Buchung unserer Leistung „Vermietung“ 

Wählen Sie in der App den gewünschten Ort aus, um zur Produktauswahl zu gelangen. Nicht verliehene Artikel sind mit „sofort verfügbar“ gekennzeichnet und können ohne Wartezeit direkt ausgeliehen werden. Bereits verliehen Artikel sind mit „reserviert“ gekennzeichnet und können erst zu wieder gebucht werden, sobald die vorherige Buchung abgeschlossen ist. 

Wählen Sie die gewünschten Artikel aus und klicken Sie auf „BUCHEN“. Wählen Sie anschließend, Datum und Dauer der Buchung aus. Über die Schaltfläche „Weiter“ gelangen sie zum nächsten Schritt, dort teilen Sie uns Ihren Vornamen, Nachnamen, Mobiltelefon-Nr. sowie ihre E-Mail mit. Anschließend gelangen Sie über die Schaltfläche „Weiter“ zur Übersicht und Auswahl der Zahlmethode, über die Schaltfläche „Bestellen“ gelangen Sie in den Bezahlprozess. Hierdurch geben Sie einen verbindlichen Antrag zum zahlungspflichtigen Abruf der im Warenkorb befindlichen Leistungen ab. Vor Abschicken der Bestellung können Sie die eingegebenen Daten jederzeit ändern. Mit der Schaltfläche „zurück“ können zur Eingabe ihrer Kontaktdaten sowie zur Auswahl der Leistung zurückkehren oder den Bestellvorgang insgesamt abbrechen. 

Nach erfolgreicher Prüfung ist der Bestellvorgang abgeschlossen.

Nach Abschicken der Bestellung erhalten Sie eine Eingangsbestätigung per E-Mail zu Ihrer Bestellnummer.

Sie erhalten anschließend eine Buchungsbestätigung per E-Mail mit dem Buchungslink und dem Kaufbeleg. Sollten Sie über einen Desktop-PC die Buchung ausgeführt haben, können Sie mittels des Buchungslink Ihre gebuchte Leistung auf Ihr Mobiltelefon übertragen. Mit dem Versand der Buchungsbestätigung ist der Vertrag zwischen Ihnen und uns wirksam zu Stande gekommen.

Der Anbieter ist verpflichtet, die gewählten Produkte gemäß der Buchung zu reservieren und die Verfügbarkeit zum Zeitpunkt der Entnahme zu garantieren.

Ab dem Zeitpunkt der Entnahme können die Kunden für die gesamte Buchungsdauer über die Produkte verfügen. Auch bei einer vorzeitigen Rückgabe, haben Kunden die Möglichkeit, die Produkte erneut zu entnehmen und bis zur vorgegebenen Rückgabezeit zu nutzen. Die Rückgabe erfolgt am gleichen Standort, wie die Entnahme.

## 5. Zahlungsmodalitäten und Preise 

Sie haben die Möglichkeit, zwischen Zahlung per Kreditkarte oder per PayPal zu wählen. Die jeweils gültigen Preise für unsere Leistungen finden Sie auf unserer Website. Der aufgeführte Preis ist der Endpreis inkl. der jeweils gültigen gesetzlichen MwSt. 

## 6. Stornoregelung, Rücktritt und Überschreitung der Buchungszeit 

### 6.1 Terminverschiebung 

Wir bieten Ihnen die Möglichkeit, den gewählten Termin bis einschließlich 48 Stunden vor Beginn des Termins zu ändern (Terminverschiebung). Bitte kontaktieren Sie uns unter leihbar@vonovia.de. 

### 6.2 Stornierung

Im Fall einer Stornierung bis einschließlich zum 3. Tag vor dem Tag der Leistungsdurchführung erhalten Sie von uns eine Erstattung in Höhe von 90% des bezahlten Betrages, die Sie wahlweise als Gelderstattung oder in Form eines Wertgutscheins erhalten. 

Im Fall einer späteren Stornierung (ab 47 Stunden bis zum Beginn des Termins) können wir eine Erstattung oder eine Terminverschiebung nicht anbieten. 

Sollten Sie für Ihre Buchung einen Gutschein eingesetzt haben, kann die Erstattung gemäß obenstehenden Bedingungen nur gegen Ausgabe eines Wertgutscheins, nicht jedoch in Form einer Gelderstattung erfolgen. 

### 6.3 Höhere Gewalt

Sollte aufgrund von witterungsbedingten Umständen (Sturmschäden, starker Frost, etc.) oder Vandalismusschäden sowie sonstigen technischen Problemen Ihre Buchung nicht durchführbar sein (im Folgenden „höhere Gewalt“), haben Sie das Recht, vom Vertrag zurückzutreten. Sofern ein Fall der höheren Gewalt tatsächlich vorliegt, werden wir Ihnen die bezahlten Beträge zurückerstatten. Bitte haben Sie Verständnis, dass diese Entscheidung durch uns als Anbieter im Einzelfall getroffen wird. Bei Unsicherheit über die Durchführbarkeit der Leistung erreichen Sie uns per E-Mail (leihbar@vonovia.de) oder per Telefon zur Abstimmung.

### 6.4 Rücktritt vom Vertrag

Der Anbieter hat das Recht, bei Vorliegen sonstiger Gründe, die der Durchführbarkeit der Leistung entgegenstehen, vom Vertrag zurückzutreten. Sie erhalten in diesem Fall eine Erstattung der bezahlten Beträge in Geld. Schadensersatzansprüche Ihrerseits wegen der Nichtdurchführung der Leistung sind ausgeschlossen.

### 6.5 Überschreitung der Buchungszeit

Bei Überschreitung der Buchungszeit, behalten wir uns vor, für die jeweils angefangene Stunde die volle Stunde zum aktuell gültigen Stundenpreis nachzuberechnen, zuzüglich einer Bearbeitungsgebühr in Höhe von 10,00€ inkl. aktuell gültiger MwSt.

Bei verspäteter Rückgabe haftet der Mieter außerdem für eventuelle Folgeschäden. Dies gilt insbesondere für den Fall, dass durch die verspätete Rückgabe ein anderer Kunde Wartezeiten hinnehmen muss. Für den Fall, dass nachfolgende Kunden ihre Buchung aufgrund der Verspätung widerrufen, hat der verspätete Kunde den Ausfall zu kompensieren.

Bei nicht fristgerechter Rückgabe der gemieteten Produkte behält sich der Anbieter eine Anzeige wegen Unterschlagung gegen den Kunden sowie weitere rechtliche Schritte vor.

Die verspätete Ausgabe gebuchten Verleih-Materials, die der Anbieter nicht zu vertreten hat, berechtigen den Kunden nicht zur Überschreitung des gebuchten Zeitraums.

Eine vorzeitige Rückgabe berechtigt nicht zur Rückforderung des Mietpreises.

Für den Fall einer verkürzten Nutzungsdauer erhält der Kunde einen Gutschein bzw. den Buchungspreis zeitanteilig erstattet.

Erscheint ein Kunde nicht zu einem gebuchten Termin, sind wir ab einer Verspätung von mehr als 15 Minuten berechtigt, das Material anderweitig zu vergeben.

## 7. Widerrufsbelehrung

Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können. 

### 7.1 Widerrufsrecht

Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.

### 7.2 Folgen des Widerrufs

Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.

### 7.3 Ausschluss bzw. vorzeitiges Erlöschen des Widerrufsrechts

Das Widerrufsrecht besteht nicht bei Verträgen zur Erbringung von Dienstleistungen im Zusammenhang mit Freizeitbetätigungen, wenn der Vertrag für die Erbringung einen spezifischen Termin oder Zeitraum vorsieht.

Gemäß § 312g Abs. 2 Nr. 9 BGB besteht kein Widerrufsrecht für die von Ihnen getätigte Buchung unserer Leistungen „Vermietung“.

## 8. Teilnahme an unseren Leistungen

### 8.1 Buchung

Sie haben mit Ihrer Buchung die, von Ihnen ausgewählten, Geräte von uns zur Vermietung reserviert. Bitte rufen Sie Ihren Buchungslink auf. Ihnen wird angezeigt, in welchem Schließfach der von Ihnen gewählten leihbar die gebuchten Geräte für Sie bereit liegen. Sie können das Schließfach frühestens 15 Minuten vor Beginn der Nutzungszeit öffnen und das Produkt entnehmen.

Sie sind aufgefordert, den Zustand des Produktes vor der Entnahme auf etwaige Schäden und Vollständigkeit zu prüfen und dies vorab zu bestätigen. Sollten Sie Schäden oder sonstige Einschränkungen feststellen, melden Sie diese binnen der ersten 10 Minuten der Nutzungszeit unter Verwendung der Funktion „Schaden melden“.

Diese finden Sie in der Ansicht der Station unter (i). Sie können in das Textfeld Ihre Feststellung eingeben und die Schadensmeldung durch Klicken auf „Bestätigen“ an uns absenden.

Alternativ können Sie beim Kundensupport anrufen, der die Buchung dann ändert und das betreffende Material sperrt. Sofern die Schäden die Nutzbarkeit des Produkts nicht beeinträchtigen, kann die Buchung im Regelfall fortgesetzt werden, andernfalls legen Sie nach Durchführung der Schadensmeldung das Material zurück in das Schließfach und beenden Sie die Nutzungszeit. In diesem Fall erhalten Sie, sofern verfügbar, Zugriff auf ein Ausweichprodukt erhalten. Sollte kein Alternativprodukt verfügbar sein, so erhalten Sie automatisch eine Gutschrift über den gebuchten Betrag.

Sollten Sie hierzu Fragen haben, erreichen Sie uns durchgehend unter den in der App angegebenen Kontaktmöglichkeiten.

Sofern Sie in den ersten 10 Minuten der Nutzungszeit keine Schadensmeldung abgeben, gehen wir davon aus, dass das von Ihnen vorgefundene Produkt vollständig und unbeschädigt im Schließfach vorgefunden wurde. 

### 8.2 Ablauf der Nutzungszeit 

Bitte geben Sie die gemieteten Produkte mit Ablauf der Nutzungszeit pünktlich, vollständig und in einem sauberen Zustand zurück, indem Sie dieses in das Schließfach zurücklegen und die Nutzungszeit durch Klicken auf die Schaltfläche „Miete Beenden“ und anschließendes wählen der Option „Türen öffnen & Miete beenden“ abschließen. Mit Abschluss der Miete bestätigen Sie, dass während Ihres Buchungszeitraums keine weiteren Schäden an den Produkten entstanden. Sollte während der Nutzungszeit ein Schaden an den gemieteten Produkten entstehen oder Teile des Materials verloren gehen bzw. abhandenkommen, müssen Sie uns dies mitteilen. Bitte verwenden Sie hierzu die Funktion „Schaden melden“. Sie können das aufgetretene Problem in dem hierfür bereit gestellten Textfeld darstellen. 

Wird eine Miete nicht ordnungsgemäß, wie hier beschrieben beendet, behalten wir uns vor, die Zeit der Überbuchung nachzuberechnen, zuzüglich einer Bearbeitungsgebühr in Höhe von 7,90€ inkl. aktuell gültiger MwSt. 

Nach dem Ende der Nutzungszeit können Sie das Schließfach für weitere 15 Minuten öffnen, in denen Sie das gemietete Material zurücklegen können. Nach Ablauf der Rückgabezeit können sie das Schließfach nicht mehr öffnen. Bitte beachten Sie, dass von Ihnen im Schließfach abgelegte persönliche Gegenstände zuvor entnommen werden. 

Schäden des Anbieters aufgrund von Überschreitung der Nutzungszeit hat der Nutzer zu erstatten, auch, soweit die Überschreitung durch einen weiteren Teilnehmer erfolgt (siehe 6.5. dieser AGBs). 

## 9. Verhaltensregeln und Haftung des Nutzers

### 9.1 Sicherheit und Betriebsbereitschaft der Produkte

Die Sicherheit und Betriebsbereitschaft der Produkte werden durch regelmäßige Inspektionen sichergestellt. Sie verpflichten sich, die Mietgegenstände mit Sorgfalt zu behandeln. Schäden und Verschmutzungen, die während Ihrer Nutzungszeit auftreten, werden auf Ihre Kosten entfernt. Die Kosten für den Ersatz beschädigten oder abhanden gekommenen Materials (auch Diebstahl) werden Ihnen weiterberechnet. Zur Schadensmeldungspflicht siehe oben unter 6.3. 

Treten unvorhergesehene Umstände ein, die eine rechtzeitige Rückgabe der Mietsache unmöglich machen, so sind Sie verpflichtet, uns umgehend davon in Kenntnis zu setzen, damit eine einfache und kostengünstige Lösung herbeigeführt werden kann. Etwaig entstehende Bergungskosten haben Sie als Nutzer zu tragen. 

Sofern Sie unsere Leistungen für mehrere Personen gebucht haben, haften Sie gesamtschuldnerisch mit den jeweils haftenden Teilnehmern. Sollte der Schadensverursacher nicht zu ermitteln sein, haften Sie allein, es sei denn, Sie weisen nach, dass der Schaden nicht aus dem Kreis der von Ihnen gewählten Teilnehmer verursacht oder mit verursacht wurde. 

### 9.4 Nutzung ausschließlich gemäß dem vorgesehenen Zweck

Bitte beachten Sie, die jeweiligen Produkte nur gemäß ihres vorgehsehen Zwecks zu verwenden. Sollte Unklarheit über die Anwendung bestehen, bitte lesen vorab die beigefügten Gebrauchsanweisungen oder informieren Sie sich anderweit über die korrekte Nutzung der jeweiligen Produkte. Der Anbieter übernimmt keine Haftung im Falle von Schadensfällen die durch Anwendungsfehler des Nutzers verursacht wurden. Der Nutzer stellt den Anbieter von sämtlichen Ansprüchen aufgrund von Unfällen, aus der Verletzung von Gesetzen, Verordnungen oder sonstigen Vorschriften im Zusammenhang mit der Inanspruchnahme unserer Leistungen durch ihn oder einen anderen Teilnehmer an dieser Leistung frei. Wir weisen auf die gesamtschuldnerische Haftung des Nutzers mit weiteren Teilnehmern der Leistung hin. 

## 10. Haftung des Anbieters 

Der Anbieter haftet für die leicht fahrlässige Verletzung von wesentlichen Pflichten, deren Verletzung die Erreichung des Vertragszwecks gefährdet, oder für die Verletzung von Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertraut. In diesen Fällen haftet der Anbieter jedoch nur für den vorhersehbaren, vertragstypischen Schaden. Der Anbieter haftet nicht für die leicht fahrlässige Verletzung anderer als der in den vorstehenden Sätzen genannten Pflichten. Die vorstehenden Haftungsbeschränkungen geltend nicht bei Verletzung von Leben, Körper und Gesundheit, für einen Mangel nach Übernahme einer Garantie für die Beschaffenheit des Produktes und bei arglistig verschwiegenen Mängeln. Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt. 

Soweit die Haftung des Verkäufers ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung von Arbeitnehmern, Vertretern und Erfüllungsgehilfen. 

## 11. Schlussbestimmungen

Gerichtsstand und Erfüllungsort ist der Sitz des Anbieters, soweit sich aus dem geltenden Recht nicht etwas anderes ergibt.

Die Vertragssprache ist deutsch.

Es bestehen keine mündlichen Nebenabreden. Sämtliche Änderungen und Ergänzungen dieser AGB bedürfen der Textform, wobei E-Mail genügt. 

Sollten einzelne oder mehrere Bestimmungen dieses Vertrages unwirksam oder nichtig sein, so berührt dies nicht die Wirksamkeit des Vertrages in seinen übrigen Bestimmungen. Etwaige Lücken werden durch diejenige Bestimmung ersetzt, die dem mutmaßlichen Willen der Parteien am nächsten kommt.

Plattform der Europäischen Kommission zur Online-Streitbeilegung (OS) für Verbraucher: http://ec.europa.eu/consumers/odr/. Wir sind nicht bereit und nicht verpflichtet, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.

Stand der AGB: 01.03.202  
Vonovia SE
`,



en: `
## Präambel 

a. Der Eigentümer und Anbieter der Plattform ist eine Gesellschaft i.S.d. §§ 15 ff. AktG im Vonovia-Konzern. Vonovia ist mit über 500.000 eigen- und fremdverwalteten Wohnungen das größte börsennotierte Wohnungsunternehmen Deutschlands, deutschlandweit vertreten. Sie bzw. die mit ihr verbundenen Konzerngesellschaften im Sinne von §§ 15 ff. AktG sind Eigentümer der Immobilienobjekte. Die Bereitstellung der Plattform erfolgt durch den Online-Plattform Betreiber „kolula“. 
b. Über die leihbar können diverse Produkte und Geräte im Bereich von Sport- und Freizeit, Handwerk oder Haushalt gesucht, gebucht („Buchung“) und online bezahlt werden. Das Angebot wird von Vonovia selbst, oder von Partnerunternehmen („Betreiber“) in Eigenverantwortung in eigenem Namen und auf eigene Rechnung umgesetzt. 

kolula unterstützt den Eigentümer mit dem kolula-System gegenüber den Nutzern dabei im Wesentlichen durch die übersichtliche Darstellung und Abwicklung des Buchungsangebot, aber nicht bei der Erfüllung des Nutzungs- /Dienstleistungsvertrages zwischen Betreiber und Nutzer. 

c. Begriffsbestimmungen für diese Nutzungsbedingungen/AGB: 

Der Begriff „Nutzer“ beschreibt jene Personen (insb. Verbraucher), die die leihbar Web-App nutzen und sich über die kolula-Plattform registriert haben. 
Der Begriff „kolula-System“ umfasst die kolula-Plattform sowie sonstige Verwaltungs- und Buchungsdienste von kolula (online und offline). 
Der Begriff „Betreiber“ beschreibt jene natürliche oder juristische Person, die mit kolula einen Vertrag zur Inanspruchnahme des kolula-Systems als Anbieter von Sport- und Freizeitangeboten abgeschlossen hat („Partnervertrag“). 

d. Personenbezogene Daten (des Betreibers, seiner Kunden und seiner Mitarbeiter) im Sinne des Datenschutzgesetzes (insb. Telefonnummer, Adresse, Emailadresse, sonstige Inhaltsdaten etc.) werden gemäß der Datenschutzerklärung verarbeitet. Die Datenschutzerklärung ist unter www.kolula.com abrufbar. 

# Allgemeine Geschäftsbedingungen der kolula solutions UG

## 1. Geltungsbereich

Die nachfolgenden allgemeinen Geschäftsbedingungen („AGB“) gelten für die Inanspruchnahme von Lieferungen, Leistungen oder sonstigen Angeboten von

Living Innovations- & Beteiligungsgesellschaft mbH 
Universitätsstraße 133 
44803 Bochum 

E-Mail: [innovation@vonovia.de](mailto:innovation@vonovia.de)
Vertretungsberechtigter Geschäftsführer: 
Alexander Weihe 

(im Folgenden auch „Anbieter“ oder „wir“) durch Sie (im Folgenden auch „Nutzer“ oder „Kunde“), für sämtliche Geschäftsbeziehungen zwischen Ihnen und uns, sowie für die Nutzung unserer Website www.vonovia.de/leihbar, (im Folgenden auch „Website“), unserer Progressive Web App „leihbar“ (im Folgenden auch „App“), sowie unserer sonstigen Dienste.

Abweichende Bedingungen erkennen wir nicht an, es sei denn, wir hätten ausdrücklich schriftlich ihrer Geltung zugestimmt. Wir behalten uns das Recht vor, die AGB jederzeit zu ändern.

## 2. Nutzung / Nutzungsrechte 

Dem User wird ein zeitlich und räumlich unbeschränktes, kostenloses Nutzungsrecht an der App, deren Informationen und Diensten eingeräumt. Das Nutzungsrecht wird ausschließlich für den eigenen Bedarf und im Rahmen der Nutzung der App und deren Diensten unter Ausschluss jeglicher anderen Zwecke gewährt. 

Es ist dem User gestattet, Angebote in der App zu suchen, Angebote unter den Vermietungsstationen zu vergleichen und mit einem Anbieter Verträge abzuschließen. 

Hierfür ist der User verpflichtet, wahrheitsgetreue und vollständige Angaben für die Erfassung der Daten beim Anmeldeprozess anzugeben. 

Wenn Inhalte, die durch die vom Anbieter zustehenden geistigen Eigentumsrechte geschützt sind, durch den User verwendet werden, bleiben sämtliche Rechte hinsichtlich dieser Inhalte dem Anbieter erhalten. Die Verwendung von Urheberrechten und/oder Marken des Anbieters ist nicht gestattet. 

Der User darf namentlich die Inhalte der App nicht dazu verwenden, um diese in irgendwelcher Form zu ändern, zu kopieren, herunterzuladen, nachzukonstruieren, zu duplizieren, zu publizieren oder zu vertreiben. 

## 3. Angebote und Leistungsbeschreibungen 

### 3.1 leihbar - Plattform 

Über die Buchungsplattform bieten wir Ihnen unterschiedliche Haushalts- und Freizeitgeräte inkl. Zubehör zur personalfreien Vermietung an unseren digitalen Verleihstationen an. Über die Plattform können das Angebot sowie die jeweiligen Verfügbarkeiten eingesehen werden. Bei Bedarf können die benötigten Geräte ausgewählt und für den gewünschten Zeitraum reserviert und gebucht werden. Die Reservierung sowie der Entnahme der Produkte kann ausschließlich über die im Buchungsprozess ausgewählte Verleihstation erfolgen und ist an den jeweils zuvor angegebenen Zeitraum gebunden 

### 3.2 leihbar – Verleihstationen 

Die über die Plattform angebotenen Produkte sind an verschiedenen Standorten in unseren digitalisierten Verleihstationen -leihbar- hinterlegt und können nur über die Plattform gebucht und abgerufen werden. Das jeweilige Angebot kann gemäß der Kundenwünsche sowie der Anzahl der Fächer je nach Standort variieren.

## 4. Persönliche Teilnahmevoraussetzungen, Bestellvorgang und Vertragsabschluss

### 4.1 Inanspruchnahme der Leistungen

Sie können unsere Leistungen über den Online-Buchungsmanager auf unserer Website, www.leihbar.vonovia.de in Anspruch nehmen.

Sie müssen für den Abschluss einer Buchung volljährig sein. Wenn Sie minderjährige Personen an der Nutzung unserer Leistungen teilhaben lassen, erklären Sie, dass die erforderliche Zustimmung der gesetzlichen Vertreter vorliegt. Wir setzen bei der Auswahl der Produkte voraus, dass ein konkreter Bedarf für die Nutzung dieser vorliegt.

Wir behalten uns vor, individuelle Regeln für die Buchung und Entnahme einzelner Geräte festzulegen, je nach Anwendungsbereich.

Wir empfehlen allen Kunden, sich vor Abschluss der Buchung mit der Handhabung und den Anwendungsmöglichkeiten der jeweiligen Geräte auseinanderzusetzen, unabhängig von der Produktkategorie. Die spätere Anwendung der Geräte erfolgt in eigener Verantwortung.

Während des gesamten Buchungszeitraums sind die Kunden verantwortlich für den Zustand der gebuchten Geräte und verpflichten sich, diese in einem funktionsfähigen Zustand zurückzugeben. Eventuelle Schäden oder Funktionsfehler müssen dem Anbieter umgehend gemeldet werden.

Mit der Buchung erklären Sie, dass für alle Teilnehmer die genannten Voraussetzungen erfüllt sind.

Der Anbieter behält sich vor, einzelne Kunden temporär und/oder dauerhaft von der Nutzung auszuschließen, sollte es zu einer Verletzung der Voraussetzungen

## 4.2 Buchung unserer Leistung „Vermietung“

Wählen Sie in der App den gewünschten Ort aus, um zur Produktauswahl zu gelangen. Nicht verliehene Artikel sind mit „sofort verfügbar“ gekennzeichnet und können ohne Wartezeit direkt ausgeliehen werden. Bereits verliehen Artikel sind mit „reserviert“ gekennzeichnet und können erst zu wieder gebucht werden, sobald die vorherige Buchung abgeschlossen ist.

Wählen Sie die gewünschten Artikel aus und klicken Sie auf „BUCHEN“. Wählen Sie anschließend, Datum und Dauer der Buchung aus. Über die Schaltfläche „Weiter“ gelangen sie zum nächsten Schritt, dort teilen Sie uns Ihren Vornamen, Nachnamen, Mobiltelefon-Nr. sowie ihre E-Mail mit. Anschließend gelangen Sie über die Schaltfläche „Weiter“ zur Übersicht und Auswahl der Zahlmethode, über die Schaltfläche „Bestellen“ gelangen Sie in den Bezahlprozess. Hierdurch geben Sie einen verbindlichen Antrag zum zahlungspflichtigen Abruf der im Warenkorb befindlichen Leistungen ab. Vor Abschicken der Bestellung können Sie die eingegebenen Daten jederzeit ändern. Mit der Schaltfläche „zurück“ können zur Eingabe ihrer Kontaktdaten sowie zur Auswahl der Leistung zurückkehren oder den Bestellvorgang insgesamt abbrechen.

Nach erfolgreicher Prüfung ist der Bestellvorgang abgeschlossen.

Nach Abschicken der Bestellung erhalten Sie eine Eingangsbestätigung per E-Mail zu Ihrer Bestellnummer.

Sie erhalten anschließend eine Buchungsbestätigung per E-Mail mit dem Buchungslink und dem Kaufbeleg. Sollten Sie über einen Desktop-PC die Buchung ausgeführt haben, können Sie mittels des Buchungslink Ihre gebuchte Leistung auf Ihr Mobiltelefon übertragen. Mit dem Versand der Buchungsbestätigung ist der Vertrag zwischen Ihnen und uns wirksam zu Stande gekommen.

Der Anbieter ist verpflichtet, die gewählten Produkte gemäß der Buchung zu reservieren und die Verfügbarkeit zum Zeitpunkt der Entnahme zu garantieren.

Ab dem Zeitpunkt der Entnahme können die Kunden für die gesamte Buchungsdauer über die Produkte verfügen. Auch bei einer vorzeitigen Rückgabe, haben Kunden die Möglichkeit, die Produkte erneut zu entnehmen und bis zur vorgegebenen Rückgabezeit zu nutzen. Die Rückgabe erfolgt am gleichen Standort, wie die Entnahme.

## 5. Zahlungsmodalitäten und Preise

Sie haben die Möglichkeit, zwischen Zahlung per Kreditkarte oder per PayPal zu wählen. Die jeweils gültigen Preise für unsere Leistungen finden Sie auf unserer Website. Der aufgeführte Preis ist der Endpreis inkl. der jeweils gültigen gesetzlichen MwSt.

## 6. Stornoregelung, Rücktritt und Überschreitung der Buchungszeit

### 6.1 Terminverschiebung

Wir bieten Ihnen die Möglichkeit, den gewählten Termin bis einschließlich 48 Stunden vor Beginn des Termins zu ändern (Terminverschiebung). Bitte kontaktieren Sie uns unter leihbar@vonovia.de.

### 6.2 Stornierung

Im Fall einer Stornierung bis einschließlich zum 3. Tag vor dem Tag der Leistungsdurchführung erhalten Sie von uns eine Erstattung in Höhe von 90% des bezahlten Betrages, die Sie wahlweise als Gelderstattung oder in Form eines Wertgutscheins erhalten. 

Im Fall einer späteren Stornierung (ab 47 Stunden bis zum Beginn des Termins) können wir eine Erstattung oder eine Terminverschiebung nicht anbieten.

Sollten Sie für Ihre Buchung einen Gutschein eingesetzt haben, kann die Erstattung gemäß obenstehenden Bedingungen nur gegen Ausgabe eines Wertgutscheins, nicht jedoch in Form einer Gelderstattung erfolgen.

### 6.3 Höhere Gewalt

Sollte aufgrund von witterungsbedingten Umständen (Sturmschäden, starker Frost, etc.) oder Vandalismusschäden sowie sonstigen technischen Problemen Ihre Buchung nicht durchführbar sein (im Folgenden „höhere Gewalt“), haben Sie das Recht, vom Vertrag zurückzutreten. Sofern ein Fall der höheren Gewalt tatsächlich vorliegt, werden wir Ihnen die bezahlten Beträge zurückerstatten. Bitte haben Sie Verständnis, dass diese Entscheidung durch uns als Anbieter im Einzelfall getroffen wird. Bei Unsicherheit über die Durchführbarkeit der Leistung erreichen Sie uns per E-Mail (leihbar@vonovia.de) oder per Telefon zur Abstimmung.

### 6.4 Rücktritt vom Vertrag

Der Anbieter hat das Recht, bei Vorliegen sonstiger Gründe, die der Durchführbarkeit der Leistung entgegenstehen, vom Vertrag zurückzutreten. Sie erhalten in diesem Fall eine Erstattung der bezahlten Beträge in Geld. Schadensersatzansprüche Ihrerseits wegen der Nichtdurchführung der Leistung sind ausgeschlossen.

### 6.5 Überschreitung der Buchungszeit

Bei Überschreitung der Buchungszeit, behalten wir uns vor, für die jeweils angefangene Stunde die volle Stunde zum aktuell gültigen Stundenpreis nachzuberechnen, zuzüglich einer Bearbeitungsgebühr in Höhe von 10,00€ inkl. aktuell gültiger MwSt.

Bei verspäteter Rückgabe haftet der Mieter außerdem für eventuelle Folgeschäden. Dies gilt insbesondere für den Fall, dass durch die verspätete Rückgabe ein anderer Kunde Wartezeiten hinnehmen muss. Für den Fall, dass nachfolgende Kunden ihre Buchung aufgrund der Verspätung widerrufen, hat der verspätete Kunde den Ausfall zu kompensieren.

Bei nicht fristgerechter Rückgabe der gemieteten Produkte behält sich der Anbieter eine Anzeige wegen Unterschlagung gegen den Kunden sowie weitere rechtliche Schritte vor.

Die verspätete Ausgabe gebuchten Verleih-Materials, die der Anbieter nicht zu vertreten hat, berechtigen den Kunden nicht zur Überschreitung des gebuchten Zeitraums.

Eine vorzeitige Rückgabe berechtigt nicht zur Rückforderung des Mietpreises.

Für den Fall einer verkürzten Nutzungsdauer erhält der Kunde einen Gutschein bzw. den Buchungspreis zeitanteilig erstattet.

Erscheint ein Kunde nicht zu einem gebuchten Termin, sind wir ab einer Verspätung von mehr als 15 Minuten berechtigt, das Material anderweitig zu vergeben.

## 7. Widerrufsbelehrung

Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden können.

### 7.1 Widerrufsrecht

Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns mittels einer eindeutigen Erklärung (z.B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.

### 7.2 Folgen des Widerrufs

Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.

### 7.3 Ausschluss bzw. vorzeitiges Erlöschen des Widerrufsrechts

Das Widerrufsrecht besteht nicht bei Verträgen zur Erbringung von Dienstleistungen im Zusammenhang mit Freizeitbetätigungen, wenn der Vertrag für die Erbringung einen spezifischen Termin oder Zeitraum vorsieht.

Gemäß § 312g Abs. 2 Nr. 9 BGB besteht kein Widerrufsrecht für die von Ihnen getätigte Buchung unserer Leistungen „Vermietung“.

## 8. Teilnahme an unseren Leistungen

### 8.1 Buchung

Sie haben mit Ihrer Buchung die, von Ihnen ausgewählten, Geräte von uns zur Vermietung reserviert. Bitte rufen Sie Ihren Buchungslink auf. Ihnen wird angezeigt, in welchem Schließfach der von Ihnen gewählten leihbar die gebuchten Geräte für Sie bereit liegen. Sie können das Schließfach frühestens 15 Minuten vor Beginn der Nutzungszeit öffnen und das Produkt entnehmen.

Sie sind aufgefordert, den Zustand des Produktes vor der Entnahme auf etwaige Schäden und Vollständigkeit zu prüfen und dies vorab zu bestätigen. Sollten Sie Schäden oder sonstige Einschränkungen feststellen, melden Sie diese binnen der ersten 10 Minuten der Nutzungszeit unter Verwendung der Funktion „Schaden melden“. 

Diese finden Sie in der Ansicht der Station unter (i). Sie können in das Textfeld Ihre Feststellung eingeben und die Schadensmeldung durch Klicken auf „Bestätigen“ an uns absenden.

Alternativ können Sie beim Kundensupport anrufen, der die Buchung dann ändert und das betreffende Material sperrt. Sofern die Schäden die Nutzbarkeit des Produkts nicht beeinträchtigen, kann die Buchung im Regelfall fortgesetzt werden, andernfalls legen Sie nach Durchführung der Schadensmeldung das Material zurück in das Schließfach und beenden Sie die Nutzungszeit. In diesem Fall erhalten Sie, sofern verfügbar, Zugriff auf ein Ausweichprodukt erhalten. Sollte kein Alternativprodukt verfügbar sein, so erhalten Sie automatisch eine Gutschrift über den gebuchten Betrag.

Sollten Sie hierzu Fragen haben, erreichen Sie uns durchgehend unter den in der App angegebenen Kontaktmöglichkeiten.

Sofern Sie in den ersten 10 Minuten der Nutzungszeit keine Schadensmeldung abgeben, gehen wir davon aus, dass das von Ihnen vorgefundene Produkt vollständig und unbeschädigt im Schließfach vorgefunden wurde. 

### 8.2 Ablauf der Nutzungszeit

Bitte geben Sie die gemieteten Produkte mit Ablauf der Nutzungszeit pünktlich, vollständig und in einem sauberen Zustand zurück, indem Sie dieses in das Schließfach zurücklegen und die Nutzungszeit durch Klicken auf die Schaltfläche „Miete Beenden“ und anschließendes wählen der Option „Türen öffnen & Miete beenden“ abschließen. Mit Abschluss der Miete bestätigen Sie, dass während Ihres Buchungszeitraums keine weiteren Schäden an den Produkten entstanden. Sollte während der Nutzungszeit ein Schaden an den gemieteten Produkten entstehen oder Teile des Materials verloren gehen bzw. abhandenkommen, müssen Sie uns dies mitteilen. Bitte verwenden Sie hierzu die Funktion „Schaden melden“. Sie können das aufgetretene Problem in dem hierfür bereit gestellten Textfeld darstellen.

Wird eine Miete nicht ordnungsgemäß, wie hier beschrieben beendet, behalten wir uns vor, die Zeit der Überbuchung nachzuberechnen, zuzüglich einer Bearbeitungsgebühr in Höhe von 7,90€ inkl. aktuell gültiger MwSt.

Nach dem Ende der Nutzungszeit können Sie das Schließfach für weitere 15 Minuten öffnen, in denen Sie das gemietete Material zurücklegen können. Nach Ablauf der Rückgabezeit können sie das Schließfach nicht mehr öffnen. Bitte beachten Sie, dass von Ihnen im Schließfach abgelegte persönliche Gegenstände zuvor entnommen werden.

Schäden des Anbieters aufgrund von Überschreitung der Nutzungszeit hat der Nutzer zu erstatten, auch, soweit die Überschreitung durch einen weiteren Teilnehmer erfolgt (siehe 6.5. dieser AGBs).

## 9. Verhaltensregeln und Haftung des Nutzers

### 9.1 Sicherheit und Betriebsbereitschaft der Produkte

Die Sicherheit und Betriebsbereitschaft der Produkte werden durch regelmäßige Inspektionen sichergestellt. Sie verpflichten sich, die Mietgegenstände mit Sorgfalt zu behandeln. Schäden und Verschmutzungen, die während Ihrer Nutzungszeit auftreten, werden auf Ihre Kosten entfernt. Die Kosten für den Ersatz beschädigten oder abhanden gekommenen Materials (auch Diebstahl) werden Ihnen weiterberechnet. Zur Schadensmeldungspflicht siehe oben unter 6.3.

Treten unvorhergesehene Umstände ein, die eine rechtzeitige Rückgabe der Mietsache unmöglich machen, so sind Sie verpflichtet, uns umgehend davon in Kenntnis zu setzen, damit eine einfache und kostengünstige Lösung herbeigeführt werden kann. Etwaig entstehende Bergungskosten haben Sie als Nutzer zu tragen.

Sofern Sie unsere Leistungen für mehrere Personen gebucht haben, haften Sie gesamtschuldnerisch mit den jeweils haftenden Teilnehmern. Sollte der Schadensverursacher nicht zu ermitteln sein, haften Sie allein, es sei denn, Sie weisen nach, dass der Schaden nicht aus dem Kreis der von Ihnen gewählten Teilnehmer verursacht oder mit verursacht wurde.

### 9.4 Nutzung ausschließlich gemäß dem vorgesehenen Zweck 

Bitte beachten Sie, die jeweiligen Produkte nur gemäß ihres vorgehsehen Zwecks zu verwenden. Sollte Unklarheit über die Anwendung bestehen, bitte lesen vorab die beigefügten Gebrauchsanweisungen oder informieren Sie sich anderweit über die korrekte Nutzung der jeweiligen Produkte. Der Anbieter übernimmt keine Haftung im Falle von Schadensfällen die durch Anwendungsfehler des Nutzers verursacht wurden. Der Nutzer stellt den Anbieter von sämtlichen Ansprüchen aufgrund von Unfällen, aus der Verletzung von Gesetzen, Verordnungen oder sonstigen Vorschriften im Zusammenhang mit der Inanspruchnahme unserer Leistungen durch ihn oder einen anderen Teilnehmer an dieser Leistung frei. Wir weisen auf die gesamtschuldnerische Haftung des Nutzers mit weiteren Teilnehmern der Leistung hin.

## 10. Haftung des Anbieters 

Der Anbieter haftet für die leicht fahrlässige Verletzung von wesentlichen Pflichten, deren Verletzung die Erreichung des Vertragszwecks gefährdet, oder für die Verletzung von Pflichten, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertraut. In diesen Fällen haftet der Anbieter jedoch nur für den vorhersehbaren, vertragstypischen Schaden. Der Anbieter haftet nicht für die leicht fahrlässige Verletzung anderer als der in den vorstehenden Sätzen genannten Pflichten. Die vorstehenden Haftungsbeschränkungen geltend nicht bei Verletzung von Leben, Körper und Gesundheit, für einen Mangel nach Übernahme einer Garantie für die Beschaffenheit des Produktes und bei arglistig verschwiegenen Mängeln. Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.

Soweit die Haftung des Verkäufers ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung von Arbeitnehmern, Vertretern und Erfüllungsgehilfen.

## 11. Schlussbestimmungen

Gerichtsstand und Erfüllungsort ist der Sitz des Anbieters, soweit sich aus dem geltenden Recht nicht etwas anderes ergibt.

Die Vertragssprache ist deutsch.

Es bestehen keine mündlichen Nebenabreden. Sämtliche Änderungen und Ergänzungen dieser AGB bedürfen der Textform, wobei E-Mail genügt.

Sollten einzelne oder mehrere Bestimmungen dieses Vertrages unwirksam oder nichtig sein, so berührt dies nicht die Wirksamkeit des Vertrages in seinen übrigen Bestimmungen. Etwaige Lücken werden durch diejenige Bestimmung ersetzt, die dem mutmaßlichen Willen der Parteien am nächsten kommt.

Plattform der Europäischen Kommission zur Online-Streitbeilegung (OS) für Verbraucher: http://ec.europa.eu/consumers/odr/. Wir sind nicht bereit und nicht verpflichtet, an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.

Stand der AGB: 01.03.202  
Vonovia SE
`,
};
