import {call, fork, put, take} from 'redux-saga/effects';
import Command from '../action/command';
import Event from '../action/event';
import {Action} from 'redux-actions';
import {BookingFlowPayload} from '../action/bookingCreationEvent';
import Logger from '../util/logger';
import KolulaStorageManager from '../storage/KolulaStorageManager';
import ErrorTracker from '../util/errorTracker';
import {IndexedDBBookingFlow} from '../types/bookingCreation';

const kolulaStorageManager = new KolulaStorageManager();

export const onUpdateBookingFlow = function* (bookingFlow: string[]) {
    yield call(onPersistBookingFlow, bookingFlow);
    yield put(Event.BookingCreation.bookingFlowChanged({
        bookingFlow,
    }));
};

export const onLoadBookingFlow = function* () {
    try {
        const indexedDBBookingFlows: IndexedDBBookingFlow[] = yield call(
            [kolulaStorageManager, kolulaStorageManager.getBookingFlow],
        );
        if (indexedDBBookingFlows?.length) {
            const bookingFlowInfo = indexedDBBookingFlows[0];
            yield put(Event.BookingCreation.bookingFlowChanged({
                bookingFlow: bookingFlowInfo?.bookingFlow,
            }));
        }
    } catch (error) {
        ErrorTracker.trackException(error);
        Logger.error(error);

        // Just act as if there are no orders to be loaded if IndexedDB is not available
        yield put(Event.Order.ordersLoaded({orders: []}));
    }
};

export function* loadBookingFlow() {
    while (true) {
        yield take(Command.BookingCreation.loadBookingFlow.toString());
        yield fork(onLoadBookingFlow);
    }
}


export const onPersistBookingFlow = function* (bookingFlow: string[]) {
    try {
        yield call([kolulaStorageManager, kolulaStorageManager.setBookingFlowData], bookingFlow);
    } catch (error) {
        Logger.error(error);
    }
};

/* eslint-disable max-len */
export function* manageBookingFlow() {
    while (true) {
        const action: Action<BookingFlowPayload> = yield take(Command.BookingCreation.bookingFlowChanged.toString());
        yield fork(onUpdateBookingFlow, action.payload.bookingFlow);
    }
}


