import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    button: {
        position: 'absolute',
        top: 0,
        right: 0,
        marginRight: `-${theme.spacing(1.5)}px`,
        marginTop: `-${theme.spacing(1)}px`,
    },
    icon: {
        color: 'white',
    },
    text: {
        marginLeft: theme.spacing(2.25),
        width: `calc(100% - ${theme.spacing(4.5)}px)`,
        paddingTop: theme.spacing(3),
        fontSize: theme.typography.h1.fontSize,
        textAlign: 'center',
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(6),
        },
        textTransform: 'none',
    },
}));

interface BookLocationHeaderProps {
    text: string;
}

const BookLocationHeader = (props: BookLocationHeaderProps) => {
    const classes = useStyles();

    return (
        <>
            <Typography className={classes.text} variant={'h1'}>
                {props.text}
            </Typography>
        </>
    );
};

export default BookLocationHeader;
