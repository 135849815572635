import { getI18n } from 'react-i18next';
import { Action } from 'redux-actions';
import { call, fork, put, take } from 'redux-saga/effects';
import Command from '../action/command';
import Event from '../action/event';
import {FetchAssetGroupsByLocationIdsPayload, FetchAssetGroupsPayload} from '../action/locationCommand';
import Api from '../api';
import { AssetGroup } from '../types/assetGroup';
import ErrorTracker from '../util/errorTracker';
import Logger from '../util/logger';
import { onEnqueueErrorSnackbar } from './enqueueSnackbarFlow';

export const onFetchAssetGroups = function* (locationId: string) {
    try {
        const assetGroups: AssetGroup[] = yield call(Api.fetchLocationAssetGroups, locationId);
        yield put(Event.Location.assetGroupsFetched({ locationId, assetGroups }));
    } catch (error) {
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);
        yield fork(onEnqueueErrorSnackbar, getI18n().t('assetGroups.error.fetch_assetGroups'));
    }
};

export const onFetchAssetGroupsByLocationIds = function* (locationIds: string[]) {
    try {
        if (locationIds.length === 0) return;
        const assetGroups: AssetGroup[] = yield call(Api.fetchLocationAssetGroupsByLocationIds, locationIds);
        yield put(Event.Location.assetGroupsFetchedByLocationIds({locationIds, assetGroups}));
    } catch (error) {
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);
        yield fork(onEnqueueErrorSnackbar, getI18n().t('assetGroups.error.fetch_assetGroups'));
    }
};


export function* fetchAssetGroupsFlow() {
    while (true) {
        const action: Action<FetchAssetGroupsPayload> = yield take(Command.Location.fetchAssetGroups.toString());
        yield fork(onFetchAssetGroups, action.payload.locationId);
    }
}

export function* fetchAssetGroupsByLocationIdsFlow() {
    while (true) {
        const action: Action<FetchAssetGroupsByLocationIdsPayload> = yield take(
            Command.Location.fetchAssetGroupsByLocationIds.toString());
        yield fork(onFetchAssetGroupsByLocationIds, action.payload.locationIds);
    }
}

