import {FormControl, OutlinedInput, Select} from '@material-ui/core';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
    root: {
        // width: `calc(50% - ${theme.spacing(2)}px)`,
        // margin: theme.spacing(1),
        paddingTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
    },    
    icon: {
        fill: theme.palette.primary.dark,
    },
    selectMenuPaper: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        '& .MuiSelect-select:focus': {
            width: '60%',
            backgroundColor: 'rgba(255, 255, 255, 0)',
        },
        '& .MuiSelect-select:not(:focus)': {
            backgroundColor: 'rgba(255, 255, 255, 0)',
            width: '100%',
        },
        height: '62px',
        '& .MuiInputBase-input': {
            color: theme.palette.primary.dark,
        },
    },
    menuItem: {
        color: theme.palette.background.default,
    },
}));

const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        '& $notchedOutline': {
            borderColor: theme.palette.primary.dark,
        },
        '&:hover $notchedOutline': {
            borderColor: theme.palette.primary.dark,
        },
        '&$focused $notchedOutline': {
            borderColor: theme.palette.primary.dark,
        },
        color: theme.palette.primary.dark, 
        opacity: '0.8',
    },
    focused: {},
    notchedOutline: {
        color: theme.palette.primary.dark,
        '& legend': {
            opacity: 1,
            visibility: 'visible',
            paddingBottom: '10px',
        },
    },
}));

interface BookingDurationSelectProps {
    value: number;
    onChange: (value: number) => void;
    availableDurations: number[];
    disabled: boolean;
}

const BookingDurationSelect = (props: BookingDurationSelectProps) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const outlinedInputClasses = useOutlinedInputStyles();

    return (
        <FormControl variant='outlined' className={classes.root}>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select-helper"
                native={true}
                variant='outlined'
                disabled={props.disabled || props.availableDurations.length === 0}
                value={props.value}
                autoFocus={true}
                input={
                    <OutlinedInput 
                        label={t('general.duration')}
                        classes={outlinedInputClasses}
                        fullWidth={true}
                    />
                }
                fullWidth={true}
                onChange={event => props.onChange(Number(event.target.value))}
                inputProps={{
                    classes: {
                        icon: classes.icon,
                    },
                }}
                className={classes.selectMenuPaper}
                children={<>
                    <option value={0} className={classes.menuItem} />
                    {props.availableDurations.map(duration => (
                        <option key={duration} value={duration} className={classes.menuItem}>
                            {/* {t('general.days_val', { count: (duration / 60) / 24 })} */}
                            {duration < 1440 
                                ? t('general.hours_val', { count: duration / 60 }) 
                                : t('general.days_val', { count: duration / 1440 })}
                        </option>
                    ))}
                </>}
            />
        </FormControl>
    );
};

export default BookingDurationSelect;
