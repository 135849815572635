import moment, {Moment} from 'moment';
import { AssetGroupAvailability, AvailabilitySlot } from '../../../types/assetGroup';
import { SpecificOption } from '../components/BoardSelectionStep/BoardSelectionItem';
import {i18n} from '../../../i18n/i18n';

// @todo some these should probably be moved to the Selectors

/**
 * Checks if a given date is available for booking
 *
 * @param availability
 * @param date
 * @param isPartnerUsc
 */
export const isDateAvailable = (
    availability: AssetGroupAvailability,
    date: moment.Moment,
    isPartnerUsc?: boolean,
): boolean => {
    // Note: Allowed days for partner USC are Mo (1) through Fr (5); Sa (6) and Su (7) are supposed to be disabled
    if (isPartnerUsc && date.isoWeekday() > 5) {
        return false;
    }

    // if (!availability.availabilities[date.format('YYYY-MM-DD')]) {
    //     return false;
    // }

    return getAvailableTimeSlots(availability, date).length > 0;
};

/**
 * Returns all time slots on the given date with at least one duration that has at least one available board
 *
 * @param availability
 * @param selectedDate
 */
export const getAvailableTimeSlots = (
    availability: AssetGroupAvailability,
    selectedDate: moment.Moment,
): string[] => {
    const dateKey = selectedDate.format('DD.MM.YYYY');
    if (!availability.hasOwnProperty(dateKey)) {
        return [];
    }
    const allTimeSlots = Object.keys(availability[dateKey]);
    const localTimeZone = moment.tz.guess();

    const filteredTimeSlots = allTimeSlots
        .map(timeKey => {
            const europeanTimeZone = 'Europe/Berlin';

            const timeInEurope = moment.tz(`${dateKey} ${timeKey}`, 'DD.MM.YYYY HH:mm', europeanTimeZone);

            const localTime = timeInEurope.tz(localTimeZone);

            return localTime.format('HH:mm');
        })
        .filter(timeKey => {
            // const timeSlot: AvailabilitySlot = availability[dateKey][timeKey];
            // for (const durationRecord of Object.values(timeSlot)) {
            //     for (const availableCount of Object.values(durationRecord)) {
            //         if (availableCount > 0) {
            //             return true;
            //         }
            //     }
            // }

            // return false;
            return true;
        })
        .sort();
    return filteredTimeSlots;
};

/**
 * Returns all durations on the given date and time-slot with at least on available board
 *
 * @param availability
 * @param selectedDate
 * @param selectedTime
 */
export const getAvailableDurations = (
    availability: AssetGroupAvailability,
    selectedDate: moment.Moment,
    selectedTime: string,
): number[] => {
    const dateKey = selectedDate!.format('DD.MM.YYYY');
    if (!availability.hasOwnProperty(dateKey)) {
        return [];
    }

    const localTimeZone = moment.tz.guess(); // Get user's local timezone
    const europeanTimeZone = 'Europe/Berlin';

    const localTime = moment.tz(`${dateKey} ${selectedTime}`, 'DD.MM.YYYY HH:mm', localTimeZone);

    const timeInEurope = localTime.tz(europeanTimeZone).format('HH:mm');


    const timeSlot: AvailabilitySlot = availability[dateKey][timeInEurope];

    const durations: number[] = [];

    for (const duration in timeSlot) {
        if (timeSlot[duration] < 1) {
            // either the duration does not exist or there are no assets available for it
            continue;
        }
        durations.push(Number(duration));

        // const durationValue = availability[dateKey][selectedTime].duration;

        // // Note: For partner USC only a duration of 1 hour is available
        // if (isPartnerUsc && durationValue !== 1) {
        //     continue;
        // }

        // if (!durations.includes(durationValue)) {
        //     durations.push(durationValue);
        // }
    }

    // TODO check why a custom sort is needed
    return durations.sort((a, b) => a - b);
};

/**
 * Returns the sum of the available boards of all price lists that match date, time-slot and duration
 *
 * @param availability
 * @param selectedDate
 * @param selectedTime
 * @param selectedDuration
 */
// export const getMaxPaddlerCount = (
//     availability: AssetGroupAvailability,
//     selectedDate: moment.Moment,
//     selectedTime: string,
//     selectedDuration: number,
// ): number => {
//     const dateKey = selectedDate!.format('YYYY-MM-DD');
//     const timeSlot: AvailabilitySlot = availability.availabilities[dateKey][selectedTime];

//     const possibleDurationIds = getPossibleDurationIdsForDuration(availability.options, selectedDuration);

//     let availableCount = 0;
//     Object.values(timeSlot).forEach(durationRecord => {
//         possibleDurationIds.forEach(possibleDurationId => {
//             availableCount += durationRecord[possibleDurationId] || 0;
//         });
//     });

//     return availableCount;
// };

/**
 * Returns all durationIds that have matching duration value to the given duration (equal period)
 *
 * @param options
 * @param duration
 */
// export const getPossibleDurationIdsForDuration = (options: Options, duration: number): string[] => {
//     const possibleDurationIds: string[] = [];

//     Object.values(options).forEach(option => {
//         for (const durationId in option) {
//             if (option[durationId].duration === duration) {
//                 possibleDurationIds.push(durationId);
//             }
//         }
//     });

//     return possibleDurationIds;
// };

/**
 * Returns all possible board options for a given date, time and duration
 *
 * @param availability
 * @param selectedDate
 * @param selectedTime
 * @param selectedDuration
 */
export const getPossibleOptions = (
    availability: AssetGroupAvailability,
    selectedDate: moment.Moment,
    selectedTime: string,
    selectedDuration: number,
): SpecificOption[] => {
    const possibleOptions: SpecificOption[] = [];

    // const dateKey = selectedDate!.format('YYYY-MM-DD');
    // const timeSlot: AvailabilitySlot = availability[dateKey][selectedTime];
    // const durations = Object.keys(timeSlot);

    // durations.forEach(duration => {
    //     const options = availability.options[duration];
    //     Object.values(options).forEach(option => {
    //         const maxQuantity = timeSlot[duration];

    //         if (option === selectedDuration && maxQuantity > 0) {
    //             possibleOptions.push({
    //                 id: option.toString(),
    //                 // price: option.price,
    //                 duration: option * 60,
    //                 // name: option.name,
    //                 // description: option.description,
    //                 maxQuantity: timeSlot[duration],
    //             });
    //         }
    //     });
    // });

    return possibleOptions;
};

// export const getMinPossiblePrice = (
//     availability: AssetGroupAvailability,
//     selectedDate: moment.Moment,
//     selectedTime: string,
//     selectedDuration: number,
// ): number => {
//     const options = getPossibleOptions(availability, selectedDate, selectedTime, selectedDuration);
//     return Math.min(...options.map(option => Number(option.price)));
// };


export const getTimeFormat = (date: Moment) => {
    const currentLanguage = i18n.language;
    if (currentLanguage === 'en') {
        return date.format('hh:mm A');
    } else {
        return date.format('HH:mm');
    }
};
