import { Action, handleActions } from 'redux-actions';
import Event from '../action/event';
import {
    AssetGroupAvailabilitiesFetchedPayload, AssetGroupFetchedByLocationIdsPayload,
    AssetGroupFetchedPayload,
    LocationByIdFetchedPayload,
    LocationsFetchedPayload,
} from '../action/locationEvent';
import { Location } from '../types/location';

export interface LocationState {
    locations: Location[];
    // availabilities: Record<string, AssetGroupAvailability>;
    // assetGroups: Record<string, AssetGroups>;
}

export const initialState: LocationState = {
    locations: [],
    // availabilities: {},
    // assetGroups: {},
};

export const reducer = handleActions<LocationState, any>(
    {
        [Event.Location.locationsFetched.toString()]:
            (state, action: Action<LocationsFetchedPayload>): LocationState => {
                return {
                    ...state,
                    locations: action.payload.locations,
                };
            },
        [Event.Location.locationByIdFetched.toString()]:
            (state, action: Action<LocationByIdFetchedPayload>): LocationState => {
                return {
                    ...state,
                    locations: [
                        ...state.locations.filter(item => item.id !== action.payload.location.id),
                        action.payload.location,
                    ],
                };
            },
        [Event.Location.assetGroupsFetched.toString()]:
            (state, action: Action<AssetGroupFetchedPayload>): LocationState => {
                return {
                    ...state,
                    locations: state.locations.map((location) => {
                        if (location.id === action.payload.locationId) {
                            return {
                                ...location,
                                assetGroups: action.payload.assetGroups,
                            };
                        }
                        return location;
                    }),
                };
            },
        [Event.Location.assetGroupsFetchedByLocationIds.toString()]:
            (state, action: Action<AssetGroupFetchedByLocationIdsPayload>): LocationState => {
                return {
                    ...state,
                    locations: state.locations.map((location) => {
                        if (action.payload.locationIds.includes(location.id)) {
                            return {
                                ...location,
                                assetGroups: action.payload.assetGroups,
                            };
                        }
                        return location;
                    }),
                };
            },
        [Event.Location.assetGroupAvailabilitiesFetched.toString()]:
            (state, action: Action<AssetGroupAvailabilitiesFetchedPayload>): LocationState => {
                return {
                    ...state,
                    locations: state.locations.map((location) => {
                        if (location.id === action.payload.locationId) {
                            return {
                                ...location,
                                assetGroups: location.assetGroups.map((assetGroup) => {
                                    if (assetGroup.id === action.payload.assetGroup.id) {
                                        return {
                                            ...assetGroup,
                                            availabilities: action.payload.assetGroup.availabilities,
                                        };
                                    }
                                    return assetGroup;
                                }),
                            };
                        }
                        return location;
                    }),
                };
            },
    },
    initialState,
);
