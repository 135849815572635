import {Box, IconButton} from '@material-ui/core';
import Assets from '../../../assets/assets';
import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import {persistLanguage} from '../../../i18n/i18n';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Selector from '../../../selector';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2),
    },
    inactiveButton: {
        opacity: 0.5,
    },
    flagImage: {
        height: '30px',
        borderRadius: '15px',
        width: '30px',
        objectFit: 'cover',
    },
}));

const languageOptions = [
    {
        language: 'de',
        flag: Assets.flags.germany,
        alt: 'Deutsch',
    },
    {
        language: 'en',
        flag: Assets.flags.unitedKingdom,
        alt: 'English',
    },
    {
        language: 'nb',
        flag: Assets.flags.norway,
        alt: 'norsk',
    },
];

const LanguageSwitcher = () => {
    const classes = useStyles();
    const {i18n} = useTranslation();
    const tenantConfig = useSelector(Selector.Config.config);

    const setLanguage = (language: string) => {
        i18n.changeLanguage(language);
        persistLanguage(language);
        moment.locale(language);
    };

    return (
        <Box className={classes.root}>
            {languageOptions
                .filter(option => tenantConfig?.languages.includes(option.language))
                .map(option => (
                    <IconButton
                        key={option.language}
                        className={i18n.language !== option.language ? classes.inactiveButton : undefined}
                        onClick={() => setLanguage(option.language)}
                        children={<img src={option.flag} className={classes.flagImage} alt={option.alt} />}
                    />
                ))
            }
        </Box>
    );
};

export default LanguageSwitcher;
