import React, {useCallback, useEffect, useState} from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import _debounce from 'lodash/debounce';
import CheckIcon from '@material-ui/icons/Check';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import {useTranslation} from 'react-i18next';
import { useSelector } from 'react-redux';
import Selector from '../../../../selector';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'relative',
    },
    container: {
        marginTop: theme.spacing(2),
        width: '100%',
        '&.react-tel-input .form-control': {
            width: '100%',
            background: 'none',
            color: 'white',
            paddingTop: '12px',
            paddingBottom: '8px',
            borderColor: 'white',
            borderRadius: '10px',
            [theme.breakpoints.up('md')]: {
                fontSize: '19px',
            },
            '&:hover': {
                borderColor: theme.palette.primary.dark,
            },
            '&:focus': {
                borderColor: theme.palette.primary.dark,
                boxShadow: `0 0 0 1px ${theme.palette.primary.dark}`,
            },
        },
        '&.react-tel-input .special-label': {
            background: theme.palette.background.default,
            color: 'rgba(255, 255, 255, 0.8)',
            left: '10px',
            fontSize: '12px',
            [theme.breakpoints.up('md')]: {
                fontSize: '14px',
            },
        },
        '&.react-tel-input .selected-flag .arrow': {
            borderTopColor: 'white',
            borderLeftWidth: '4px',
            borderRightWidth: '4px',
            marginTop: 0,
        },
        '&.react-tel-input .selected-flag:focus .arrow': {
            borderTopColor: 'white',
        },
        '&.react-tel-input .selected-flag .arrow.up': {
            borderBottomColor: 'white',
        },
        '&.react-tel-input .country-list': {
            background: theme.palette.background.default,
            color: 'white',
            '& .country .dial-code': {
                color: 'lightgray',
            },
            '& .country.highlight, & .country:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.2)',
            },
        },
    },
    checkIcon: {
        position: 'absolute',
        right: '14px',
        top: '8px',
    },
}));

interface DebouncedTextInputProps {
    label?: string;
    initialValue?: string;
    onChange: (value: string) => void;
    valid?: boolean;
    autoComplete?: string;
}

const DebouncedTextInput = (props: DebouncedTextInputProps) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [value, setValue] = useState<string>('');
    const tenantConfig = useSelector(Selector.Config.config);
    
    // For German numbers, automatically remove the leading 0 if someone enters it even though the +49 is there
    const normalizeValue = () => {
        if (value.startsWith('490')) {
            setValue('49' + value.slice(3));
        }
    };

    const debouncedOnChange = useCallback(
        _debounce(props.onChange, 700, {leading: false, trailing: true}),
        [],
    );

    useEffect(() => {
        if (props.initialValue) {
            setValue(props.initialValue);
        }
    }, []);

    useEffect(() => {
        debouncedOnChange(value);
    }, [value]);

    return (
        <Box className={classes.root}>
            <PhoneInput
                country={tenantConfig?.phoneInput.defaultCountry}
                preferredCountries={tenantConfig?.phoneInput.preferredCountries}
                value={value}
                onChange={setValue}
                onBlur={normalizeValue}
                specialLabel={t('book_location.personal_data.phone_number') + ' *'}
                containerClass={classes.container}
                countryCodeEditable={false}
            />
            {props.valid && <CheckIcon color={'primary'} className={classes.checkIcon} />}
        </Box>
    );
};

export default DebouncedTextInput;
