import { fork, take } from 'redux-saga/effects';
import Command from '../action/command';

export const onCalculateDefaultBoardTypeCounts = function*() {
    // const locationId: string|null = yield select(Selector.BookingCreation.locationId);
    // const orderData: BookingCreationSelection = yield select(Selector.BookingCreation.orderData);
    // const locationAvailability: AssetGroupAvailability|null = yield select(
    //     Selector.Location.makeLocationAvailabilities(locationId || ''),
    // );

    // if (!locationAvailability || !orderData.date || !orderData.time || !orderData.duration) {
    //     return;
    // }

    // const possibleOptions: SpecificOption[] = getPossibleOptions(
    //     locationAvailability,
    //     orderData.date,
    //     orderData.time,
    //     orderData.duration,
    // );

    // if (possibleOptions.length < 1) {
    //     return;
    // }

    // let paddlerCountToDistribute = orderData.paddlerCount;
    // for (const option of possibleOptions) {
    //     if (paddlerCountToDistribute < 1) {
    //         break;
    //     }

    //     yield put(Event.BookingCreation.assetGroupCountChanged({
    //         id: option.id,
    //         count: Math.min(paddlerCountToDistribute, option.maxQuantity),
    //     }));

    //     paddlerCountToDistribute -= option.maxQuantity;
    // }
};

export function* calculateDefaultBoardTypeCountsFlow() {
    while (true) {
        yield take(Command.BookingCreation.calculateDefaultBoardTypeCounts.toString());
        yield fork(onCalculateDefaultBoardTypeCounts);
    }
}
