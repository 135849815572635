import { call, fork, put, take } from 'redux-saga/effects';
import Command from '../action/command';
import Event from '../action/event';

export const onLoadTenantConfig = function*() {
    const tenantName = process.env.REACT_APP_TENANT;
    try {
        const {Config} = yield call(() => import(`../tenants/${tenantName}/config`));

        yield put (Event.Config.configLoaded({config: Config}));
    } catch (error) {
        yield put(Event.Config.configLoadingFailed);
    }
};

export function* loadTenantConfigFlow() {
    while (true) {
        yield take(Command.Config.loadConfig.toString());
        yield fork(onLoadTenantConfig);
    }
}
