// Function to get enum name by number
export const getEnumNameByNumber = (enumObject: any, value: number): string => {
    for (const key in enumObject) {
        if (typeof enumObject[key] === 'number' && enumObject[key] === value) {
            return key;
        }
    }
    return '';
};

// Function to get enum number by name
export const getEnumNumberByName = (enumObject: any, name: string): number => {
    const keys = Object.keys(enumObject).filter(key => isNaN(Number(key))); // Get only the string keys
    return keys.indexOf(name);
};
