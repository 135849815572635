import { Box, Button, Checkbox, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Command from '../../action/command';
import { signUp } from '../../util/cognitoAuthService';
import RegistrationInput from './components/RegistrationInput';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        overflow: 'auto',
        textAlign: 'center',
        padding: theme.spacing(0.5),
        paddingTop: theme.spacing(2),
    },
    form: {
        margin: 'auto',
    },
    info1: {
        textAlign: 'center',
        fontFamily: 'Source Sans 3',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
    },
    button: {
        height: '54px',
        width: '380px',
        borderRadius: '4px',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    checkbox: {
        color: 'white',
    },
    link: {
        color: 'orange',
    },
}));

const Register = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [streetAddress, setStreetAddress] = useState('');
    const [zip, setZip] = useState('');
    const [city, setCity] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
    const history = useHistory();

    const handleSignUp = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        try {
            await signUp(
                firstName,
                lastName,
                city,
                streetAddress,
                zip,
                email,
                password,
            );
            history.push('/confirm', { state: { email } });
        } catch (error) {
            dispatch(Command.Notification.enqueueNotification({
                severity: 'error',
                message: t(`Sign up failed: ${error}`),
            }));
        }
    };

    return (
        <Box className={classes.root}>
            <Typography className={classes.info1}>
                {t('authentication.registration')}
            </Typography>
            <form className={classes.form} onSubmit={handleSignUp}>
                <Grid container={true} spacing={2}>
                    <RegistrationInput
                        label="First Name"
                        name="firstName"
                        type="name"
                        value={firstName}
                        placeholder="Please enter your first name"
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <RegistrationInput
                        label="Last Name"
                        name="lastName"
                        type="name"
                        value={lastName}
                        placeholder="Please enter your last name"
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <RegistrationInput
                        label="Street Address"
                        name="stressAddress"
                        type="address"
                        value={streetAddress}
                        placeholder="Please enter your street Address"
                        onChange={(e) => setStreetAddress(e.target.value)}
                    />
                    <RegistrationInput
                        label="ZIP"
                        name="zip"
                        type="zip"
                        value={zip}
                        placeholder="Please enter your zip code"
                        onChange={(e) => setZip(e.target.value)}
                    />
                    <RegistrationInput
                        label="City"
                        name="city"
                        type="city"
                        value={city}
                        placeholder="Please enter the name of your city"
                        onChange={(e) => setCity(e.target.value)}
                    />
                    <RegistrationInput
                        label="Email"
                        name="email"
                        type="email"
                        value={email}
                        placeholder="Please enter your email address"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <RegistrationInput
                        label="Password"
                        name="password"
                        type="password"
                        value={password}
                        placeholder="Please enter your password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Grid
                        item={true}
                        xs={12}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                        }}
                    >
                        <Checkbox 
                            className={classes.checkbox}
                            color={'primary'}
                        />
                        <Typography>
                            Agree to Terms & Conditions
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Button type="submit" variant="contained" className={classes.button} color="primary">
                            Continue
                        </Button>
                    </Grid>
                </Grid>
                <Typography variant="body1">
                    <Link to="/register" className={classes.link}>Back</Link>
                </Typography>
            </form>
        </Box>
    );
};

export default Register;
