import { createAction } from 'redux-actions';

export interface SetConsentPayload {
    consent?: boolean;
}

export interface LoadConsentPayload {
}

const ConsentEvent = {
    loadConsent: createAction<LoadConsentPayload>('@@KL_EVT/LOAD_CONSENT'),
    setConsent: createAction<SetConsentPayload>('@@KL_EVT/SET_CONSENT'),
};

export default ConsentEvent;
