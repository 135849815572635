import {Box, Button, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import Command from '../../../action/command';
import Routes from '../../../routes';
import Selector from '../../../selector';
import {AssetGroup} from '../../../types/assetGroup';
import {Location} from '../../../types/location';
import InfoBox from '../../layout/components/InfoBox';

const useStyles = makeStyles((theme) => ({
    header: {
        width: 'calc(100% - 40px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textAlign: 'center',
        color: theme.palette.primary.contrastText,
    },
    text: {
        marginTop: theme.spacing(1),
        color: theme.palette.primary.contrastText,
    },
    assetList: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(5),
        color: theme.palette.primary.contrastText,
    },
    bookButton: {
        margin: `${theme.spacing(1)}px 0`,
    },
    safeAreaSpacer: {
        height: 'env(safe-area-inset-bottom, 0)',
    },
}));

interface LocationDetailsProps {
    location: Location;
}

const LocationDetails = (props: LocationDetailsProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();

    const locationAssetGroups: AssetGroup[] | null = useSelector(
        Selector.Location.makeLocationAssetGroups(props.location?.id || ''),
    );

    // TODO check asset group loading
    useEffect(() => {
        if (props.location?.id) {
            dispatch(Command.Location.fetchAssetGroups({locationId: props.location.id}));
        }
    }, [props.location?.id]);

    useEffect(() => {
        if (props.location?.id) {
            dispatch(Command.Location.fetchAssetGroups({locationId: props.location.id}));
        }
    }, []);

    return (
        <>
            <Typography variant={'h6'} className={classes.header}>
                {props.location.name}
            </Typography>
            <Button
                children={t('general.book')}
                variant={'contained'}
                disableElevation={true}
                fullWidth={true}
                disabled={!props.location.status.bookable}
                onClick={() => history.push(Routes.makeBookLocationUrl(props.location!.id))}
                className={classes.bookButton}
            />
            <Button
                children={'Google'}
                startIcon={<PlaceOutlinedIcon />}
                variant={'text'}
                component={'a'}
                href={`https://www.google.com/maps/search/?api=1&query=${props.location.gmapsQuery}`}
                target={'_blank'}
                rel='noopener noreferrer'
            />
            {props.location.status.notification && (
                <Box marginTop={1}>
                    <InfoBox
                        variant={props.location.status.notification.type}
                        text={props.location.status.notification.text}
                    />
                </Box>
            )}
            <Typography
                className={classes.text}
                dangerouslySetInnerHTML={{__html: props.location.description}}
            />
            {locationAssetGroups && (
                <Box marginTop={1}>
                    <Typography variant={'h6'} style={{color: 'white'}}>
                        {t('book_location.location.asset_groups')}
                    </Typography>
                    {locationAssetGroups.map((assetGroup) => (
                        <Typography key={assetGroup.id} className={classes.assetList}>
                            {assetGroup.name}
                        </Typography>
                    ))}
                </Box>
            )}
            <Box className={classes.safeAreaSpacer} />
        </>
    );
};

export default LocationDetails;
