import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Command from '../../action/command';
import Selector from '../../selector';
import { AssetGroup } from '../../types/assetGroup';
import { Location } from '../../types/location';
import GTM from '../../util/gtm';
import { usePrevious } from '../../util/usePrevious';
import { useQueryParams } from '../../util/useQueryParams';
import LoaderOverlay from '../layout/components/LoaderOverlay';
import AssetGroupSelectionStep from './components/AssetGroupSelectionStep';
import BookLocationHeader from './components/BookLocationHeader';
import ConfirmationStep from './components/ConfirmationStep';
import OrderDataStep from './components/OrderDataStep';
import PaymentStep from './components/PaymentStep';
import PaymentTypeSelectionStep from './components/PaymentTypeSelectionStep';
import PersonalDataStep from './components/PersonalDataStep';
import TenantLogo from '../layout/components/TenantLogo';
import BookLocationNavigateNextAction from './components/BookLocationNavigateNextAction';
import BookLocationNavigateBackAction from './components/BookLocationNavigateBackAction';
import BookLocationCancelButton from './components/BookLocationCancelButton';
import GeneralDialog from '../common/GeneralDialog';
import Event from '../../action/event';

export enum BookingCreationStep {
    assetGroupSelection,
    orderData,
    boardSelection,
    personalData,
    confirmation,
    paymentTypeSelection,
    payment,
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
        color: 'white',
        height: '100%',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.default,
        paddingBottom: `calc(${theme.spacing(1)}px + env(safe-area-inset-bottom, 0))`,
        display: 'flex',
        flexDirection: 'column',
    },
    headerWrapper: {
        position: 'relative',
    },
    stepWrapper: {
        flex: 1,
        width: '100%',
        maxWidth: 1000,
        margin: '0 auto',
        overflowY: 'auto',
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(6),
        },
        paddingTop: theme.spacing(3),
    },
    actionsWrapper: {
        width: 'calc(100% - 42px)',
        maxWidth: 1000,
        margin: '0 auto',
        [theme.breakpoints.up('lg')]: {
            paddingBottom: theme.spacing(5),
        },
        paddingBottom: theme.spacing(5),
    },
    Box: {
        width: '100%',
        display: 'flex',
        // justifyContent: '',
        alignItems: 'center',

    },
    logoBox: {
        width: '150px',
        marginTop: theme.spacing(1),
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
    },
    backButton: {
        boxSizing: 'border-box',
        marginRight: 'auto',
    },
    cancelButton: {
        boxSizing: 'border-box',
        marginLeft: 'auto',
    },
    backIcon: {
        color: 'white',
    },
    backText: {
        color: 'white',
    },
}));


interface BookLocationDialogProps {
    locationId: string;
    returnPage: string;
}

const BookLocationPage = (props: BookLocationDialogProps) => {
    const stepWrapperRef = useRef<HTMLElement>();
    const [pageDialogOpen, setPageDialogOpen] = useState<boolean>(false);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const queryParams = useQueryParams();
    const locationId = props.locationId;
    const step = useSelector(Selector.BookingCreation.step);
    const previousStep = usePrevious(step);
    const orderData = useSelector(Selector.BookingCreation.orderData);
    const reservation = useSelector(Selector.BookingCreation.reservation);
    const assetGroupId = useSelector(Selector.BookingCreation.assetGroupSelection);
    const location: Location | null = useSelector(Selector.Location.makeLocation(locationId));
    const assetGroupSelectionId = useSelector(Selector.BookingCreation.assetGroupSelection);

    const locationAssetGroups: AssetGroup[] | null = useSelector(Selector.Location.makeLocationAssetGroups(locationId));

    const braintreePayment = useSelector(Selector.BookingCreation.braintreePayment);
    const paymentInitFailed = useSelector(Selector.BookingCreation.paymentInitFailed);
    const paymentProvider = useSelector(Selector.BookingCreation.paymentProvider);

    useEffect(() => {
        dispatch(Command.BookingCreation.loadBookingFlow({}));
        stepWrapperRef.current = document.getElementById('bookingProcessStepWrapper')!;
        // @todo body scroll lock breaks the phone number input. Maybe remove it entirely?
        //disableBodyScroll(stepWrapperRef.current);
        //return () => enableBodyScroll(stepWrapperRef.current!);
        dispatch(Command.BookingCreation.bookingStepChanged({
            step: BookingCreationStep.assetGroupSelection,
            nextEnabled: !!assetGroupSelectionId,
        }));
    }, []);

    useEffect(() => {
        if (location) {
            const autoSelectNextSlot = typeof queryParams.get('now') === 'string';

            if (autoSelectNextSlot) {
                queryParams.delete('now');
                history.replace({ search: queryParams.toString() });
            }

            dispatch(Command.BookingCreation.startBookingCreation({ locationId }));
        }
    }, [location?.id]);

    useEffect(() => {
        const assetGroupLoaded =
            (locationAssetGroups && Array.isArray(locationAssetGroups) && locationAssetGroups.length > 0);

        if (step === BookingCreationStep.assetGroupSelection && !assetGroupLoaded) {
            dispatch(Command.Location.fetchAssetGroups({ locationId }));
        }
    }, []);

    useEffect(() => {
        if (step === BookingCreationStep.assetGroupSelection) {
            // Clean data
            dispatch(Event.BookingCreation.dateSelectedClear({}));
            dispatch(Event.BookingCreation.durationSelectedClear({}));

            dispatch(Command.Location.fetchAssetGroups({ locationId }));
        } else if (step === BookingCreationStep.orderData && assetGroupId) {
            // TODO change to user input
            const fromDate = moment().tz('Europe/Berlin').toDate();
            const toDate = moment().tz('Europe/Berlin').add(1, 'months').endOf('month').toDate();
            dispatch(Command.Location.fetchAssetGroupAvailabilities({ locationId, assetGroupId, fromDate, toDate }));
        }
        // previous step is orderData, current step is personal data
        if (previousStep === BookingCreationStep.orderData && step === BookingCreationStep.personalData) {
            dispatch(Command.BookingCreation.calculateDefaultBoardTypeCounts({}));
            dispatch(Command.BookingCreation.createNewReservation({}));
        }
        if (previousStep === BookingCreationStep.orderData && step === BookingCreationStep.confirmation) {
            dispatch(Command.BookingCreation.calculateDefaultBoardTypeCounts({}));
            dispatch(Command.BookingCreation.createNewReservation({}));
        }
        // previous step is asset group selection, current step is personal data
        // TODO check if this is still needed
        if (false && previousStep === BookingCreationStep.boardSelection && step === BookingCreationStep.personalData) {
            dispatch(Command.BookingCreation.createNewReservation({}));
        }

        // previous step is personal data, current step is board selection
        // or previous step is personal data, current step is orderData
        if (
            (step === BookingCreationStep.orderData) &&
            (previousStep && (previousStep > BookingCreationStep.orderData))
        ) {
            // user navigated back, so delete reservation
            dispatch(Command.BookingCreation.removeReservation({}));
        }

        if (previousStep === BookingCreationStep.personalData
            && step === BookingCreationStep.confirmation
            && paymentProvider
            && paymentProvider.length > 1) {
            // when there is more than one payment provider configured, we need to initialize payment type selection
            dispatch(Command.BookingCreation.initializePaymentTypeSelection({}));
        }
        // previous step is payment summary, current step is payment
        // or previous step is payment type selection, current step is payment
        if (
            (previousStep === BookingCreationStep.confirmation && step === BookingCreationStep.payment) ||
            (previousStep === BookingCreationStep.paymentTypeSelection && step === BookingCreationStep.payment)
        ) {
            dispatch(Command.BookingCreation.initializePaymentProvider({}));
        }

        GTM.trackEecCheckoutStep(step);
    }, [step]);

    const headerText = useMemo(() => {
        switch (step) {
            case BookingCreationStep.assetGroupSelection: return t('book_location.assetgroup_selection.header_text');
            case BookingCreationStep.orderData: return t('book_location.order_data.header_text');
            case BookingCreationStep.boardSelection: return t(
                'book_location.board_selection.header_text',
                { date: orderData.date!.format(t('formats.date')) },
            );
            case BookingCreationStep.personalData: return t('book_location.personal_data.header_text');
            case BookingCreationStep.confirmation: return t('book_location.confirmation.header_text');
            case BookingCreationStep.payment: return braintreePayment.paymentToken
                ? t('book_location.payment.header_text')
                : (paymentInitFailed ? t('book_location.payment.header_text_init_error') : '');
            default: return '';
        }
    }, [step, orderData.date, braintreePayment.paymentToken, paymentInitFailed]);

    const isLoading = () => {
        if (step === BookingCreationStep.assetGroupSelection) {
            // first step, select asset group
            return Boolean(_.isEmpty(locationAssetGroups));
        } else if (step === BookingCreationStep.orderData) {
            // Loader is triggered by the OrderDataStep component
        } else if (step === BookingCreationStep.personalData || step === BookingCreationStep.confirmation) {
            // third step, enter personal data
            return !reservation;
        }
        return false;
    };

    const closePage = () => {
        history.push('/');
    };


    const isLastStep = step === BookingCreationStep.payment;
    return (
        <Box className={classes.root}>
            <Box className={classes.headerWrapper}>
                <Box className={classes.Box}>
                    <Box className={classes.backButton}>
                        <BookLocationNavigateBackAction />
                    </Box>
                    <Box className={classes.logoBox}>
                        <TenantLogo type='default' size='large' />
                    </Box>
                    <Box className={classes.cancelButton}>
                        <BookLocationCancelButton onClose={() => setPageDialogOpen(true)} />
                    </Box>
                </Box>
                <BookLocationHeader text={headerText} />
            </Box>
            <Box className={classes.stepWrapper} id={'bookingProcessStepWrapper'}>
                {step === BookingCreationStep.assetGroupSelection &&
                    <AssetGroupSelectionStep locationId={locationId} />}
                {step === BookingCreationStep.orderData &&
                    <OrderDataStep locationId={locationId} />}
                {step === BookingCreationStep.boardSelection &&
                    <AssetGroupSelectionStep locationId={locationId} />}
                {step === BookingCreationStep.personalData &&
                    <PersonalDataStep />}
                {(step === BookingCreationStep.confirmation && !isLoading()) &&
                    <ConfirmationStep locationId={locationId} />}
                {step === BookingCreationStep.paymentTypeSelection &&
                    <PaymentTypeSelectionStep locationId={locationId} />}
                {step === BookingCreationStep.payment &&
                    <PaymentStep />}
            </Box>
            <Box className={classes.actionsWrapper}>
                {!isLastStep && <BookLocationNavigateNextAction />}
            </Box>
            <LoaderOverlay open={isLoading()} />
            <GeneralDialog
                open={pageDialogOpen}
                onClose={() => setPageDialogOpen(false)}
                onContinue={closePage}
                content={t('use_order.closing_dialog.content')}
            />
        </Box>
    );
};

export default BookLocationPage;
