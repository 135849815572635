import { Action, handleActions } from 'redux-actions';
import Event from '../action/event';
import { SetConsentPayload } from '../action/consentCommand';

export interface ConsentState {
    consent: boolean | null;
    loaded: boolean;
}

export const initialState: ConsentState = {
    consent: null,
    loaded: false,
};

export const reducer = handleActions<ConsentState, any>(
    {
        [Event.Consent.setConsent.toString()]:
            (state, action: Action<SetConsentPayload>): ConsentState => {
                return {
                    ...state,
                    consent: action.payload.consent === undefined ? null : action.payload.consent,
                    loaded: true,
                };
            },
    },
    initialState,
);
