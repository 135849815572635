import BookingCreationSelector from './bookingCreationSelector';
import ConfigSelector from './configSelector';
import ConsentSelector from './consentSelector';
import LocationSelector from './locationSelector';
import NotificationSelector from './notificationSelector';
import OrderSelector from './orderSelector';
import RentalSelector from './rentalSelector';

const Selector = {
    Location: LocationSelector,
    BookingCreation: BookingCreationSelector,
    Notification: NotificationSelector,
    Order: OrderSelector,
    Rental: RentalSelector,
    Config: ConfigSelector,
    Consent: ConsentSelector,
};

export default Selector;
