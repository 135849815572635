import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Command from '../../../action/command';
import Event from '../../../action/event';
import Selector from '../../../selector';
import {AssetGroup} from '../../../types/assetGroup';
import AssetGroupSelectionItem from './AssetGroupSelectionStep/AssetGroupSelectionItem';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(1),
    },
}));

interface AssetGroupSelectionStepProps {
    locationId: string;
}

const AssetGroupSelectionStep = (props: AssetGroupSelectionStepProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const assetGroups: AssetGroup[] = useSelector(
        Selector.Location.makeLocationAssetGroups(props.locationId),
    )!;
    const assetGroupSelectionId = useSelector(Selector.BookingCreation.assetGroupSelection);

    const handleAssetGroupToggle = (assetGroupId: string) => {
        const isActive = Boolean(assetGroupSelectionId === assetGroupId);
        dispatch(
            isActive
                ? Event.BookingCreation.assetGroupDeactivated({id: assetGroupId})
                : Event.BookingCreation.assetGroupActivated({id: assetGroupId}),
        );
    };

    useEffect(() => {
        dispatch(Command.BookingCreation.calculateDefaultBoardTypeCounts({}));
    }, []);
        
    return (
        <Box className={classes.root}>
            {assetGroups?.map((assetGroup) => (
                <AssetGroupSelectionItem
                    key={assetGroup.id}
                    assetGroup={assetGroup}
                    active={Boolean(assetGroupSelectionId === assetGroup.id)}
                    onToggle={() => handleAssetGroupToggle(assetGroup.id)}
                />
            ))}
        </Box>
    );
};

export default AssetGroupSelectionStep;
