
/* eslint-disable */

export const DataProtection = {
    en: `
# Terms of Use

We are pleased that you are visiting our website and thank you for your interest in our company, our products and our web pages. The protection of your privacy and the protection of your personal data when using our website is very important to us.
 
<br />
### Scope of application.

This privacy policy applies to the website of segmenta communications GmbH (in particular under the domain https://segmenta.de). It does not apply to third-party websites to which we merely refer by means of a link. Our website is aimed exclusively at customers with their habitual residence in the Federal Republic of Germany and Austria.

The entity responsible for the collection, processing and use of your personal data within the meaning of the Federal Data Protection Act is segmenta communications GmbH, Neumühlen 1, 22763 Hamburg, info@segmenta.de

Contact data protection officer: 

Lawyer and specialist lawyer for information technology law Dr. Christian Rauda GRAEF Rechtsanwälte Digital PartG mbB Jungfrauenthal 8 20149 Hamburg Email: datenschutz@segmenta.de https://www.graef.eu
 
<br />
### Access to web pages and files on our website.

Every time a user accesses a page on our website and every time a file is retrieved, data about this process is automatically stored and processed in a log file on our web server. In detail, the following data is stored for each access/retrieval:
* IP address of the requesting computer
* Date and time of the request
* Page called up/name of the file called up
* Amount of data transferred
* Message as to whether the access/retrieval was successful
* Internet address from which the page or file was retrieved or the desired function was initiated
* The web browser used

The aforementioned data, including the IP address, is stored for the duration of the communication process in order to enable the use of our website. On the basis of our legitimate interests (Art. 6 para. 1 lit. f) GDPR), the IP address is also stored for a maximum period of seven calendar days to ensure IT security, in particular to protect our IT systems from misuse and to defend against attacks.

We reserve the right to anonymize the data and to evaluate it in anonymized form for statistical purposes and to improve our website.
  
<br />
### Cookies

What are cookies?

We use so-called cookies for our website. A cookie is a small file in which certain information (e.g. settings and data for exchange with our server) is stored on a data carrier (e.g. a hard disk) of the end device you are using (e.g. your computer). Some web browsers create a separate file for each cookie, while other web browsers store all cookies in a single file. Cookies may contain information that makes you personally identifiable.

Which cookies do we use?

We use cookies whose data we delete when you close your web browser (session cookies).

We use session cookies, in which we store a randomly generated unique identification number, a so-called session ID (i.e. a pseudonym for you as a user), as well as the history of the web pages you have visited on our website. You can view the session cookies we use via the link below “Customize consent”.

Some functions of our website cannot be offered without the use of cookies. For these it is necessary that the browser is recognized even after a page change. The user data collected with technically necessary cookies is not used to determine the identity of the user or to create user profiles. The legal basis for the processing of personal data using technically necessary cookies is Art. 6 para. 1 lit. f) GDPR.

When you access web pages on our website, cookies for which we are not responsible, but third parties, may also be stored on your end device (so-called third-party cookies), e.g. by Google for Google Analytics and Google Remarketing (see below).

The processing of these cookies is dependent on your consent (Art. 6 para. 1 lit. a GDPR). You can revoke your consent at any time by clicking on the following link, where you will also find all further information on third-party cookies. All processing operations carried out before the withdrawal of consent remain unaffected:

<br />
### Customize consent

How can you prevent the storage of cookies?

You can prevent the storage of cookies via the settings of your web browser software. Web browsers usually provide you with various functions for this purpose. You can usually set these so that all cookies are automatically blocked or so that only cookies from certain websites are permitted or so that you are informed before a cookie is stored.

You can obtain information on how to block and delete cookies using your web browser by using the help function of your web browser.

Internet Explorer, for example, allows you to block cookies as follows: Open Internet Explorer and click on the “Tools” button and then on “Internet Options”. Then click on the “Privacy” tab and move the slider to the privacy level you want to use. If you select the “Block all cookies” privacy level, all cookies from all websites will be blocked; cookies that are already on your computer cannot be read by websites. You can also define custom settings for websites. 

<br />
### Set data protection settings.

Accepting cookies is not a prerequisite for visiting our website. However, we would like to point out that blocking cookies may restrict the functionality of our website and the websites of other service providers.
 
<br />
### Google Analytics

On the basis of consent within the meaning of Art. 6 para. 1 lit. a. GDPR), we use Google Analytics, a web analytics service provided by Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland (“Google”). In accordance with its terms of use, Google reserves the right to use personal data for its own purposes. However, Google does not disclose whether and which personal data is used by Google.

If you have given your consent, Google uses cookies. The information generated by a cookie about the use of the online offer by the user is usually transmitted to a Google LLC server in the USA and stored there. Google processes the data in the USA on the basis of EU standard contractual clauses and thus offers sufficient guarantees within the meaning of Art. 46 para. 1, para. 2 lit. c) GDPR. Personal data will only be processed by Google if you have given this consent.

Further information on the cookies used by Google and the option to withdraw your consent can be found under the following link to our Consent Manager:

<br />
### Adapt consent

Google will use this information on our behalf to evaluate the use of our online offer by users, to compile reports on the activities within this online offer and to provide us with further services associated with the use of this online offer and the use of the Internet. Pseudonymous user profiles can be created from the processed data.

We only use Google Analytics with activated IP anonymization. This means that the IP address of users is truncated by Google within member states of the European Union or in other states party to the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and truncated there.

The IP address transmitted by the user's browser will not be merged with other Google data. You can - in addition to the default setting at the beginning of the use of the website - prevent the storage of cookies by setting your browser software accordingly; you can also prevent the collection of data generated by the cookie and related to your use of the online offer to Google and the processing of this data by Google by downloading and installing the browser plugin available at the following link: http://tools.google.com/dlpage/gaoptout?hl=de.

You can find more information about Google's use of data, settings and objection options on Google's websites: (“Google's use of data when you use our partners' websites or apps”), http://www.google.com/policies/technologies/ads (“Use of data for advertising purposes”), http://www.google.de/settings/ads (“Manage information that Google uses to show you advertising”).

<br />
### LinkedIn conversion tracking

Our website uses the “LinkedIn Insight Tag” conversion tool from LinkedIn Ireland Unlimited Company. If you have given your consent, this tool creates a cookie in your web browser, which enables the collection of the following data, among others: IP address, device and browser properties and page events (e.g. page views). The legal basis is your consent (Art. 6 para. 1 lit. a) GDPR). This data is encrypted, anonymized within seven days and the anonymized data is deleted within 90 days. The information generated by a cookie can be transferred to a LinkedIn Corp. server in the USA. LinkedIn Ireland Unlimited Company processes the data in the USA on the basis of EU standard contractual clauses with LinkedIn Corp. and thus offers sufficient guarantees within the meaning of Art. 46 para. 

1, para. 2 lit. c) GDPR. LinkedIn does not share any personal data with us, but offers anonymized reports on the website target group and ad performance. In addition, LinkedIn offers the possibility of retargeting via the Insight Tag. We can use this data to display targeted advertising outside our website without identifying you as a website visitor. You can find more information on data protection at LinkedIn in the LinkedIn privacy policy https://de.linkedin.com/legal/privacy-policy.

In addition, LinkedIn members can control the use of their personal data for advertising purposes in their account settings.

For more information about the cookies used by LinkedIn and how to withdraw your consent, please see the following link to our Consent Manager:

<br />
### Customize Consent

#### Facebook Custom Audiences (pixels/cookies)

We use a so-called tracking pixel from Meta Platforms Ireland Ltd, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland on our website, a subsidiary of Meta Platforms Inc, 1601, Willow Road Menlo Park, CA 94025, USA, (“Facebook”). We use Facebook Pixel to track the success of our own Facebook advertising campaigns and to optimize the delivery of Facebook advertising campaigns to interested target groups.

After clicking on a Facebook ad or visiting our website, a cookie is stored on your device using the pixel on our website. The cookie processes data about whether you have reached our website via a Facebook ad and enables us to analyze the user's behavior until the purchase is completed. This allows us to track the success rate of our Facebook advertising campaigns. In addition, the pixel processes data about the fact that you have visited our website and makes it possible to adapt the advertising displayed on Facebook to your interests.

The Facebook pixel integrated on our website establishes a direct connection to the Facebook servers when you visit our website. The information generated by the cookie about your use of this website (including your IP address) is transmitted to Facebook in the USA.

In addition to us, the recipients of the data are
* Meta Platforms Ireland Ltd, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland (as processor pursuant to Art. 28 GDPR)
* Meta Platforms Inc, 1601, Willow Road Menlo Park, CA 94025, USA (as sub-processor pursuant to Art. 28 GDPR)

Meta Platforms Ireland Ltd. has concluded EU standard contractual clauses with Meta Platforms Inc., which is based in California, USA, to establish appropriate safeguards within the meaning of Art. 46 GDPR. A copy of the standard contractual clauses can be found here: https://www.facebook.com/legal/EU_data_transfer_addendum. The transfer of data to the USA and access by US authorities to the data stored by Facebook cannot be ruled out. The USA is currently considered a third country from a data protection perspective. You do not have the same rights there as within the EU/EEA. You may not be entitled to any legal remedies against access by authorities.

The data collected is anonymous to us and does not allow us to draw any conclusions about the user. If you are registered with Facebook, Facebook can assign the information collected to your account. Even if you do not have a Facebook account or are not logged in when you visit our website, your IP address and other identification data may be processed and stored by Facebook.

The legal basis for data processing is your consent in accordance with § 25 para. 1 TTDSG i.V.m. Art. 6 para. 1 sentence 1 lit. a GDPR.  You can revoke your consent at any time with effect for the future by accessing the cookie settings [HERE] and changing your selection there. There you will also find information on the cookies processed. This does not affect the lawfulness of the processing carried out on the basis of the consent until revocation.

Furthermore, you can prevent the setting of cookies by adjusting the corresponding settings in your Facebook account at https://www.facebook.com/settings?tab=ads prevent.

<br />
### Customize Consent

#### Vimeo Video

We have integrated Vimeo Video on our website. Vimeo Video is a component of the video platform of Vimeo, LLC, 555 W 18th St, New York, New York 10011 (“Vimeo”), where users can upload content, share it over the Internet and receive detailed statistics. Vimeo Video enables us to integrate content from the platform into our website. When you access this content, you establish a connection to Vimeo servers, whereby your IP address and possibly browser data such as your user agent are transmitted.

If you have given your consent, Vimeo uses cookies and other browser technologies to evaluate user behaviour, recognize users and create user profiles. This information is used, among other things, to analyze the activity of the content listened to and to create reports. The information generated by a cookie about the use of the online offer by users is usually transmitted to a Vimeo server in the USA and stored there. Vimeo processes the data in the USA on the basis of EU standard contractual clauses and thus offers sufficient guarantees within the meaning of Art. 46 para. 1, para. 2 lit. c) GDPR. Personal data will only be processed by Vimeo if you have given this consent.

For more information on the cookies used by Vimeo and how to withdraw your consent, please see the following link to our Consent Manager:

<br />
### Customize Consent

The use of the service is based on our legitimate interests, i.e. interest in a platform-independent provision of content in accordance with Art. 6 para. 1 lit. f) GDPR.

The integration of the videos causes the Vimeo servers to be called up for technical reasons. For the associated use of data from your browser or end device, we refer you to Vimeo's data protection information, as Vimeo is responsible for the corresponding data processing. The specific storage period of the processed data cannot be influenced by us, but is determined by Vimeo. Further information can be found in Vimeo's privacy policy: https://vimeo.com/privacy.
 
<br />
### Contact form; e-mail

When using our contact form and sending e-mails to us, you are free to provide fictitious data about your identity or just an e-mail address.

If you send us an unencrypted message, the message is not protected against unauthorized access or alteration by third parties during transmission.

We store your details, including your e-mail address, insofar as we need your data to process your request or message, Art. 6 para. 1 lit. b) GDPR.

We use filters against unwanted advertising (“SPAM filters”); although it is not intended, it cannot be ruled out that even desired e-mails are automatically and incorrectly classified as unwanted advertising and therefore moved to a separate SPAM folder and deleted.

We also use software to protect e-mails that contain malware such as computer viruses. Such e-mails are also deleted by us.
    
<br />
### Social media links

#### Facebook, Instagram

We have set a link to the Facebook and Instagram pages of Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland (“Facebook”). There is no further data exchange with Facebook on our website. When accessing the respective networks and platforms, the Terms and conditions and the data processing guidelines of their respective operators. Unless otherwise stated in our privacy policy, we process users' data if they communicate with us within the social networks and platforms, e.g. write posts on our online presences or send us messages. Privacy policy of Facebook https://de-de.facebook.com/policy.php/

#### XING

We have set a link to the XING pages of New Network SE, Dammtorstraße 30, 20354 Hamburg (“XING”). There is no further data exchange with XING on our website. When accessing the respective networks and platforms, the terms and conditions and data processing guidelines of their respective operators apply. Unless otherwise stated in our privacy policy, we process users' data if they communicate with us within the social networks and platforms, e.g. write posts on our online presences or send us messages. Privacy policy of XING: https://privacy.xing.com/de/datenschutzerklaerung

#### LinkedIn link

We have also set a link to LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Ireland (“LinkedIn”). There is no further data exchange with LinkedIn on our website. When accessing the respective networks and platforms, the terms and conditions and data processing guidelines of their respective operators apply. Unless otherwise stated in our privacy policy, we process users' data if they communicate with us within the social networks and platforms, e.g. write posts on our online presences or send us messages. LinkedIn privacy policy: https://www.linkedin.com/legal/privacy-policy
 
<br />
### Your rights

You have a right to free information about your stored data and, if applicable, a right to rectification of incorrect data, restriction of processing and erasure of your personal data. Where applicable, you can also exercise your right to data portability or lodge a complaint with a competent supervisory authority if unlawful data processing has taken place. If you wish to assert these rights, please contact our data protection officer.

The same applies if you have any further questions about the collection, processing or use of your personal data or if you wish to withdraw your consent. Consent can be withdrawn at any time.

Right to object You can object to the future processing of your personal data in accordance with the legal requirements at any time, in particular to processing for direct marketing purposes.

Changes to the data protection declaration We reserve the right to change this data protection declaration if this is necessary in order to adapt it to a changed legal situation or a change in the processing procedures carried out by us. If your consent is or becomes necessary for data processing, we will of course obtain it.

Please inform yourself regularly about the content of this privacy policy. 

`,
    de: `
# Datenschutzrichtlinien
   
Wir freuen uns, dass Sie unser Internet-Angebot besuchen, und bedanken uns für Ihr Interesse an unserem Unternehmen, unseren Produkten und unseren Web-Seiten. Der Schutz Ihrer Privatsphäre und der Schutz Ihrer persönlichen Daten bei der Nutzung unseres Internet-Angebots ist uns ein wichtiges Anliegen.

<br />
### Anwendungsbereich.

Diese Datenschutzerklärung gilt für das Internet-Angebot der segmenta communications GmbH (insbesondere unter der Domain https://segmenta.de). Sie gilt nicht für Internet-Angebote Dritter, auf die wir durch einen Link lediglich verweisen. Unser Internet-Angebot richtet sich ausschließlich an Kunden mit gewöhnlichem Aufenthalt in der Bundesrepublik Deutschland und in Österreich.

Verantwortliche Stelle für die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten im Sinne des Bundesdatenschutzgesetzes ist die segmenta communications GmbH, Neumühlen 1, 22763 Hamburg, info@segmenta.de

Kontakt Datenschutzbeauftragter: 

Rechtsanwalt und Fachanwalt für Informationstechnologierecht Dr. Christian Rauda GRAEF Rechtsanwälte Digital PartG mbB Jungfrauenthal 8 20149 Hamburg Email: datenschutz@segmenta.de https://www.graef.eu
 
<br />
### Zugriff auf Web-Seiten und Dateien unseres Internet-Angebots.

Bei jedem Zugriff eines Nutzers auf eine Seite aus unserem Internet-Angebot und bei jedem Abruf einer Datei werden auf unserem Web-Server automatisch Daten über diesen Vorgang in einer Protokolldatei gespeichert und verarbeitet. Im Einzelnen werden über jeden Zugriff/Abruf folgende Daten gespeichert:
* IP-Adresse des anfragenden Rechners
* Datum und Uhrzeit der Anfrage
* Aufgerufene Seite/Name der abgerufenen Datei
* Übertragene Datenmenge
* Meldung, ob der Zugriff/Abruf erfolgreich war
* Internet-Adresse, von der aus die Seite bzw. Datei abgerufen bzw. die gewünschte Funktion veranlasst wurde
* Verwendeter Web-Browser

Die zuvor genannten Daten einschließlich der IP-Adresse werden für die Dauer des Kommunikationsvorgangs gespeichert, um die Nutzung unseres Internet-Angebots zu ermöglichen. Auf Grundlage unserer berechtigten Interessen (Art. 6 Abs. 1 lit. f) DSGVO) erfolgt darüber hinaus für einen Zeitraum von maximal sieben Kalendertagen eine Speicherung der IP-Adresse zur Gewährleistung der IT-Sicherheit, insbesondere zum Schutz unserer IT-Systeme vor Missbrauch und zur Abwehr von Angriffen.

Wir behalten uns vor, die Daten zu anonymisieren und in anonymisierter Form für statistische Zwecke und zur Verbesserung unseres Internet-Angebots auszuwerten.
 
<br />
### Cookies

Was sind Cookies?

Für unser Internet-Angebot verwenden wir sog. Cookies. Ein Cookie ist eine kleine Datei, in de auf einem Datenträger (z.B. einer Festplatte) des von Ihnen genutzten Endgeräts (z.B. Ihres Computers) bestimmte Informationen (z.B. Einstellungen und Daten zum Austausch mit unserem Server) gespeichert werden. Einige Web-Browser legen für jeden Cookie eine eigene Datei an, andere Web-Browser speichern alle Cookies in einer einzigen Datei. Cookies können Informationen enthalten, welche Sie persönlich identifizierbar machen.

Welche Cookies verwenden wir?

Wir verwenden Cookies, deren Daten wir löschen, wenn Sie Ihren Web-Browser schließen (Session Cookies).

Wir verwenden Session-Cookies, in denen wir eine zufällig erzeugte eindeutige Identifikationsnummer, eine sog. Session-ID (d.h. ein Pseudonym für Sie als Nutzer), sowie den Verlauf der Web-Seiten speichern, die Sie in unserem Internet-Angebot besucht haben. Die von uns verwendeten Session Cookies können Sie über den Link unten "Consent anpassen" einsehen.

Einige Funktionen unserer Webseite können ohne den Einsatz von Cookies nicht angeboten werden. Für diese ist es notwendig, dass der Browser auch nach einem Seitenwechsel erkannt wird. Die mit technisch notwendigen Cookies erhobenen Benutzerdaten werden nicht dazu verwendet, die Identität des Nutzers zu ermitteln oder Benutzerprofile zu erstellen. Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten mittels technisch notwendiger Cookies ist Art. 6 Abs. 1 lit. f) DSGVO.

Beim Aufruf von Web-Seiten unseres Internet-Angebots können auch Cookies, für die nicht wir, sondern Dritte verantwortlich sind, auf Ihrem Endgerät gespeichert werden (sog. Third-Party-Cookies), z.B. von Google für Google Analytics und Google Remarketing (s.u.).

Die Verarbeitung dieser Cookies ist von Ihrer Einwilligung abhängig (Art. 6 Abs. 1 lit a DSGVO). Die Einwilligung können Sie jederzeit unter dem nachfolgendem Link widerrufen, wo Sie auch alle weiteren Informationen zu den Third-Party-Cookies finden. Alle bis zum Widerruf der Einwilligung erfolgten Verarbeitungsvorgänge bleiben davon unberührt:

<br />
### Consent anpassen

Wie können Sie die Speicherung von Cookies auch noch verhindern?

Sie können die Speicherung von Cookies über die Einstellungen Ihrer Web-Browser-Software verhindern. Web-Browser stellen Ihnen hierzu in der Regel verschiedene Funktionen zur Verfügung. Sie können diese in der Regel so einstellen, dass alle Cookies automatisch blockiert werden oder dass nur Cookies bestimmter Internet-Angebote zulässig sind oder dass Sie informiert werden, bevor ein Cookie gespeichert wird.

Informationen darüber, wie Sie Cookies mit Hilfe Ihres Web-Browsers blockieren und löschen können, können Sie mittels der Hilfe-Funktion Ihres Web-Browsers erhalten.
 
Der Internet-Explorer ermöglicht beispielsweise die Blockierung von Cookies wie folgt: Öffnen Sie den Internet-Explorer und klicken Sie auf die Schaltfläche „Extras“ und dann auf „Internetoptionen“. Klicken Sie anschließend auf die Registerkarte „Datenschutz“ und bewegen Sie den Schieberegler auf die Datenschutzstufe, die Sie verwenden möchten. Bei Auswahl der Datenschutzstufe „Alle Cookies blocken“ werden alle Cookies von allen Web-Sites geblockt; Cookies, die sich bereits auf Ihrem Computer befinden, können von Web-Sites nicht gelesen werden. Sie können für Web-Sites auch benutzerdefinierte Datenschutzeinstellungen festlegen.

Das Akzeptieren von Cookies ist keine Voraussetzung für den Besuch unseres Internet-Angebots. Wir weisen allerdings darauf hin, dass mit der Blockierung von Cookies eine funktionale Einschränkung der Nutzung unseres Internet-Angebots und auch der Internet-Angebote anderer Diensteanbieter verbunden sein kann.
 
<br />
### Google Analytics

Wir setzen auf Grundlage einer Einwilligung im Sinne des Art. 6 Abs. 1 lit. a. DSGVO) Google Analytics, einen Webanalysedienst der Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland („Google“) ein. Gemäß deren Nutzungsbedingungen behält sich Google vor, personenbezogene Daten auch für eigene Zwecke zu nutzen. Ob und welche personenbezogene Daten von Google genutzt werden, teilt Google jedoch nicht mit.

Google verwendet, soweit Sie Ihre Einwilligung dazu erteilt haben, Cookies. Die durch ein Cookie erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an einen Server von Google LLC in den USA übertragen und dort gespeichert. Google verarbeitet die Daten in den USA auf Grundlage von EU-Standardvertragsklauseln und bietet damit hinreichende Garantien i.S.d. Art. 46 Abs. 1, Abs. 2 lit. c) DSGVO. Nur wenn Sie diese Einwilligung erteilt haben, werden personenbezogene Daten von Google verarbeitet.

Weitere Informationen zu den von Google eingesetzten Cookies sowie die Widerrufsmöglichkeit für Ihre Einwilligung finden Sie unter dem folgenden Link zu unserem Consent-Manager:

<br />
### Consent anpassen

Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres Onlineangebotes durch die Nutzer auszuwerten, um Reports über die Aktivitäten innerhalb dieses Onlineangebotes zusammenzustellen und um weitere, mit der Nutzung dieses Onlineangebotes und der Internetnutzung verbundene Dienstleistungen, uns gegenüber zu erbringen. Dabei können aus den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt werden.

Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer wird von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.

Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Sie können – neben der Voreinstellung zu Beginn der Nutzung der Webseite – die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie das unter folgendem Link verfügbare Browser-Plugin herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de.

Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsmöglichkeiten erfahren Sie auf den Webseiten von Google: („Datennutzung durch Google bei Ihrer Nutzung von Websites oder Apps unserer Partner“), http://www.google.com/policies/technologies/ads („Datennutzung zu Werbezwecken“), http://www.google.de/settings/ads („Informationen verwalten, die Google verwendet, um Ihnen Werbung einzublenden“).

<br />
### LinkedIn Conversion Tracking

Unsere Webseite verwendet das Conversion Tool „LinkedIn Insight Tag“ der LinkedIn Ireland Unlimited Company. Dieses Tool erstellt, sofern Sie hierfür eine Einwilligung erteilt haben, ein Cookie in Ihrem Webbrowser, welches die Erfassung u.a. folgender Daten ermöglicht: IP-Adresse, Geräte- und Browsereigenschaften und Seitenereignisse (z.B. Seitenabrufe). Rechtsgrundlage ist Ihre Einwilligung (Art. 6 Abs. 1 lit. a) DSGVO). Diese Daten werden verschlüsselt, innerhalb von sieben Tagen anonymisiert und die anonymisierten Daten werden innerhalb von 90 Tagen gelöscht. Die durch ein Cookie erzeugten Informationen können an einen Server der LinkedIn Corp. in den USA übertragen werden. Die LinkedIn Ireland Unlimited Company verarbeitet die Daten in den USA auf Grundlage von EU-Standardvertragsklauseln mit der LinkedIn Corp. und bietet damit hinreichende Garantien i.S.d. Art. 46 Abs. 1, Abs. 2 lit. c) DSGVO. LinkedIn teilt keine personenbezogenen Daten mit uns, sondern bietet anonymisierte Berichte über die Webseiten-Zielgruppe und die Anzeigeleistung an. Ergänzend bietet LinkedIn über das Insight Tag die Möglichkeit eines Retargetings. Wir können mit Hilfe dieser Daten zielgerichtete Werbung außerhalb unserer Webseite anzeigen, ohne dass Sie als Webseitenbesucher dabei identifiziert werden. Nähere Informationen zum Datenschutz bei LinkedIn können Sie den LinkedIn Datenschutzhinweisen entnehmen https://de.linkedin.com/legal/privacy-policy.

Im Übrigen können Mitglieder von LinkedIn die Nutzung ihrer personenbezogenen Daten zu Werbezwecken in ihren Kontoeinstellungen steuern.

Weitere Informationen zu den von LinkedIn eingesetzten Cookies sowie die Widerrufsmöglichkeit für Ihre Einwilligung finden Sie unter dem folgenden Link zu unserem Consent-Manager:

<br />
### Consent anpassen

#### Facebook Custom Audiences (Pixel/Cookies)

Wir setzen auf unserer Webseite ein sogenanntes Tracking Pixel der Meta Platforms Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland, einer Tochtergesellschaft der Meta Platforms Inc., 1601, Willow Road Menlo Park, CA 94025, USA, („Facebook“) ein. Wir nutzen Facebook Pixel, um den Erfolg eigener Facebook Werbekampagnen nachzuvollziehen und die Ausspielung von Facebook-Werbekampagnen an interessierte Zielgruppen zu optimieren.

Nach einem Klick auf eine Facebook-Werbeanzeige oder bei Besuch unserer Webseite wird mithilfe des Pixels auf unserer Webseite ein Cookie auf Ihrem Endgerät gespeichert. Das Cookie verarbeitet Daten darüber, ob Sie über eine Facebook-Werbeanzeige auf unsere Webseite gelangt sind und ermöglicht es das Verhalten des Nutzers bis zum Kaufabschluss zu analysieren. Dies erlaubt es uns, die Erfolgsrate unserer Facebook-Werbekampagnen nachzuvollziehen. Zusätzlich verarbeitet das Pixel Daten darüber, dass Sie unsere Webseite besucht haben und ermöglicht es, die auf Facebook ausgespielte Werbung an Ihre Interessen anzupassen.

Über das auf unserer Webseite integrierte Facebook-Pixel wird beim Besuch unserer Webseite eine direkte Verbindung zu den Servern von Facebook hergestellt. Die durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website (einschließlich Ihrer IP-Adresse) werden an Facebook in die USA übermittelt.

Empfänger der Daten sind neben uns:
* Meta Platforms Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland (als Auftragsverarbeiter nach Art. 28 DSGVO)
* Meta Platforms Inc., 1601, Willow Road Menlo Park, CA 94025, USA (als Unterauftragsverarbeiter nach Art. 28 DSGVO)

Meta Platforms Ireland Ltd. hat zur Herstellung geeigneter Garantien im Sinne von Art. 46 DSGVO mit Meta Platforms Inc., welche ihren Sitz in Kalifornien, USA, hat, EU-Standardvertragsklauseln abgeschlossen. Eine Kopie der Standardvertragsklauseln finden Sie hier: https://www.facebook.com/legal/EU_data_transfer_addendum. Eine Übermittlung von Daten in die USA und ein Zugriff US-amerikanischer Behörden auf die bei Facebook gespeicherten Daten kann nicht ausgeschlossen werden. Die USA gelten derzeit aus datenschutzrechtlicher Sicht als Drittland. Sie haben dort nicht die gleichen Rechte wie innerhalb der EU/ des EWR. Ggf. stehen Ihnen keine Rechtsbehelfe gegen Zugriffe von Behörden zu.

Die erhobenen Daten sind für uns anonym und lassen für uns keinen Rückschluss auf den Nutzer zu. Sofern Sie bei Facebook registriert sind, kann Facebook die erfassten Informationen Ihrem Account zuordnen. Auch wenn Sie nicht über einen Facebook- Account verfügen bzw. bei Besuch unserer Webseite nicht eingeloggt sind, ist eine Verarbeitung und Speicherung Ihrer IP-Adresse und weiterer Identifizierungsdaten durch Facebook möglich.

Rechtsgrundlage für die Datenverarbeitung ist Ihre Einwilligung nach § 25 Abs. 1 TTDSG i.V.m. Art. 6 Abs. 1 S. 1 lit. a DSGVO.  Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie die Cookie-Einstellungen [HIER] aufrufen und dort Ihre Auswahl ändern. Dort finden Sie auch Informationen zu den verarbeiteten Cookies. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung bleibt davon unberührt.

Weiterhin können Sie das Setzen von Cookies durch eine Anpassung der entsprechenden Einstellungen in Ihrem Facebook-Account unter https://www.facebook.com/settings?tab=ads verhindern.

<br />
### Consent anpassen

#### Vimeo Video

Wir haben auf unserer Website Vimeo Video integriert. Vimeo Video ist eine Komponente der Videoplattform von Vimeo, LLC, 555 W 18th St, New York, New York 10011 („Vimeo“), auf der Nutzer Inhalte hochladen, über das Internet teilen und detaillierte Statistiken erhalten können. Vimeo Video ermöglicht es uns, Inhalte der Plattform in unsere Website zu integrieren. Wenn Sie auf diese Inhalte zugreifen, stellen Sie eine Verbindung zu Servern von Vimeo her, wobei Ihre IP-Adresse und ggf. Browserdaten wie Ihr User-Agent übermittelt werden.

Vimeo verwendet, soweit Sie Ihre Einwilligung dazu erteilt haben, Cookies und weitere Browser-Technologien, um Nutzerverhalten auszuwerten, Nutzer wiederzuerkennen und Nutzerprofile zu erstellen. Diese Informationen werden unter anderem genutzt, um die Aktivität der angehörten Inhalte zu analysieren und Berichte zu erstellen. Die durch ein Cookie erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an einen Server von Vimeo in den USA übertragen und dort gespeichert. Vimeo verarbeitet die Daten in den USA auf Grundlage von EU-Standardvertragsklauseln  und bietet damit hinreichende Garantien i.S.d. Art. 46 Abs. 1, Abs. 2 lit. c) DSGVO. Nur wenn Sie diese Einwilligung erteilt haben, werden personenbezogene Daten von Vimeo verarbeitet.

Weitere Informationen zu den von Vimeo eingesetzten Cookies sowie die Widerrufsmöglichkeit für Ihre Einwilligung finden Sie unter dem folgenden Link zu unserem Consent-Manager:

<br />
### Consent anpassen

Die Nutzung des Dienstes erfolgt auf Grundlage unserer berechtigten Interessen, d.h. Interesse an einer plattformunabhängigen Bereitstellung von Inhalten gemäß Art. 6 Abs. 1 lit. f) DSGVO.

Durch die Einbindung der Videos kommt es technisch bedingt zu Aufrufen der Server von Vimeo. Für die damit verbundene Verwendung von Daten Ihres Browsers bzw. Endgerätes verweisen wir auf die Datenschutzhinweise von Vimeo, da Vimeo für die entsprechende Datenverarbeitung verantwortlich ist. Die konkrete Speicherdauer der verarbeiteten Daten ist nicht durch uns beeinflussbar, sondern wird von Vimeo bestimmt. Weitere Hinweise finden Sie in der Datenschutzerklärung von Vimeo: https://vimeo.com/privacy.

<br />
### Kontaktformular; E-Mail

Bei Nutzung unseres Kontaktformulars und der Versendung von E-Mails an uns steht es Ihnen frei, fiktive Daten über Ihre Identität oder lediglich eine E-Mail-Adresse anzugeben.

Wenn Sie uns eine Nachricht unverschlüsselt übersenden, ist die Nachricht gegen unbefugte Kenntnisnahme oder auch Veränderung durch Dritte während der Übertragung nicht geschützt.

Wir speichern Ihre Angaben einschließlich Ihrer E-Mail-Adresse, soweit wir Ihre Daten zur Bearbeitung Ihrer Anfrage oder Mitteilung benötigen, Art. 6 Abs. 1 lit. b) DSGVO .

Wir setzen Filter gegen unerwünschte Werbung („SPAM-Filter“) ein; es ist zwar nicht beabsichtigt, aber auch nicht ausgeschlossen, dass auch erwünschte E-Mails fälschlicherweise automatisch als unerwünschte Werbung eingeordnet und aufgrund dessen in einen gesonderten SPAM-Ordner verschoben und gelöscht werden.

Wir setzen ferner Software zum Schutz von E-Mails ein, die Schadsoftware wie z.B. Computerviren enthalten. Solche E-Mails werden von uns ebenfalls gelöscht.
 
<br />
### Social Media Links

#### Facebook, Instagram

Wir haben zu den Seiten von Facebook und Instagram der Meta Platforms Ireland Limited, 4 Grand Canal Square, Dublin 2, Ireland („Facebook“), einen Link gesetzt. Ein weiterer Datenaustausch mit Facebook findet auf unserer Seite nicht statt. Beim Aufruf der jeweiligen Netzwerke und Plattformen gelten die Geschäftsbedingungen und die Datenverarbeitungsrichtlinien deren jeweiligen Betreiber. Soweit nicht anders im Rahmen unserer Datenschutzerklärung angegeben, verarbeiten wir die Daten der Nutzer, sofern diese mit uns innerhalb der sozialen Netzwerke und Plattformen kommunizieren, z.B. Beiträge auf unseren Onlinepräsenzen verfassen oder uns Nachrichten zusenden. Datenschutzerklärung von Facebook https://de-de.facebook.com/policy.php/

#### XING

Wir haben zu den Seiten von XING der Fa. New Network SE, Dammtorstraße 30, 20354 Hamburg („XING“), einen Link gesetzt. Ein weiterer Datenaustausch mit XING findet auf unserer Seite nicht statt. Beim Aufruf der jeweiligen Netzwerke und Plattformen gelten die Geschäftsbedingungen und die Datenverarbeitungsrichtlinien deren jeweiligen Betreiber. Soweit nicht anders im Rahmen unserer Datenschutzerklärung angegeben, verarbeiten wir die Daten der Nutzer, sofern diese mit uns innerhalb der sozialen Netzwerke und Plattformen kommunizieren, z.B. Beiträge auf unseren Onlinepräsenzen verfassen oder uns Nachrichten zusenden. Datenschutzerklärung von XING: https://privacy.xing.com/de/datenschutzerklaerung

#### LinkedIn Link

Wir haben auch zu der Fa. LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Ireland („LinkedIn“), einen Link gesetzt. Ein weiterer Datenaustausch mit LinkedIn findet auf unserer Seite nicht statt. Beim Aufruf der jeweiligen Netzwerke und Plattformen gelten die Geschäftsbedingungen und die Datenverarbeitungsrichtlinien deren jeweiligen Betreiber. Soweit nicht anders im Rahmen unserer Datenschutzerklärung angegeben, verarbeiten wir die Daten der Nutzer, sofern diese mit uns innerhalb der sozialen Netzwerke und Plattformen kommunizieren, z.B. Beiträge auf unseren Onlinepräsenzen verfassen oder uns Nachrichten zusenden. Datenschutzerklärung von LinkedIn: https://www.linkedin.com/legal/privacy-policy
 
<br />
### Ihre Rechte

Sie haben ein Recht auf unentgeltliche Auskunft über Ihre gespeicherten Daten sowie gegebenenfalls ein Recht auf Berichtigung falscher Daten, Einschränkung der Verarbeitung und Löschung Ihrer personenbezogenen Daten. Außerdem können Sie, soweit zutreffend, Ihr Recht auf Datenportabilität ausüben oder eine Beschwerde bei einer zuständen Aufsichtsbehörde einreichen, wenn eine unrechtmäßige Datenverarbeitung erfolgt ist. Wenn Sie diese Rechte geltend machen möchten, wenden Sie sich bitte an unseren Datenschutzbeauftragten.

Gleiches gilt, wenn Sie weitere Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten haben oder erteilte Einwilligungen widerrufen möchten. Ein Widerruf einer Einwilligung ist jederzeit möglich.

Widerspruchsrecht

Sie können der künftigen Verarbeitung ihrer personenbezogenen Daten entsprechend den gesetzlichen Vorgaben jederzeit widersprechen, insbesondere gegen die Verarbeitung zu Zwecken der Direktwerbung.

Änderungen der Datenschutzerklärung

Wir behalten uns vor, diese Datenschutzerklärung zu ändern, soweit dies erforderlich ist, um sie an eine geänderte Rechtslage oder eine Änderung der von uns durchgeführten Verarbeitungsprozesse anzupassen. Wenn für eine Datenverarbeitung Ihre Einwilligung erforderlich ist oder wird, werden wir diese selbstverständlich einholen.

Bitte informieren Sie sich regelmäßig über den Inhalt dieser Datenschutzerklärung.

`
};

