import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AddNewOrderForm from '../orders/components/AddNewOrderForm';
import HintsDialog from './components/HintsDialog';
import {Box} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import Command from '../../action/command';
import LoaderOverlay from '../layout/components/LoaderOverlay';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import Selector from '../../selector';
import {usePrevious} from '../../util/usePrevious';
import Routes from '../../routes';
import CustomButton from '../../tenants/segmenta/component/CustomButton';


const useStyles = makeStyles(theme => ({
    root: {
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    buttonWrapper: {
        height: '160px',
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '12px 16px',
        boxSizing: 'border-box',
    },
}));

interface AddNewOrderDialogProps {
    open: boolean;
}

const AddNewOrderDialog = (props: AddNewOrderDialogProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation();
    const fetchingOrder = useSelector(Selector.Order.fetchingOrder);
    const fetchingOrderPrevious = usePrevious(fetchingOrder);
    const [orderNumber, setOrderNumber] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const tenantConfig = useSelector(Selector.Config.config);


    const isValidEmail = (email: string): boolean => {
        if (tenantConfig.authentication !== 'none') {
            return true;
        }
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const addNewOrder = () => {
        setLoading(true);
        if (email !== '') {
            dispatch(Command.Order.fetchOrder({ 
                internalOrderId: orderNumber, 
                createdBy: 'user', 
                showFinishedWarning: true, 
                email: email,
            }));
        } else {
            dispatch(Command.Order.fetchOrder({ 
                internalOrderId: orderNumber, 
                createdBy: 'admin', 
                showFinishedWarning: true,
            }));
        }
    };

    useEffect(() => {
        if (fetchingOrderPrevious && !fetchingOrder && loading) {
            setLoading(false);
            history.push(Routes.ordersPath);
            setOrderNumber('');
        }
    }, [fetchingOrder]);

    return (
        <HintsDialog open={props.open}>
            <Box className={classes.root}>
                <AddNewOrderForm 
                    orderNumbervalue={orderNumber} 
                    emailValue={email} 
                    onChangeOrderNumber={setOrderNumber} 
                    onChangeEmail={setEmail} 
                />
                <Box className={classes.buttonWrapper}>
                    <CustomButton
                        isButtonDark={true}
                        // variant={'contained'}
                        onClick={addNewOrder}
                        disabled={orderNumber.length < 10 || !isValidEmail(email)}
                        children={t('hints.fetch_ticket')}
                        fullWidth={true}
                        // color='primary'
                    />
                </Box>
            </Box>
            <LoaderOverlay open={fetchingOrder} />
        </HintsDialog>
    );
};

export default AddNewOrderDialog;
