import { ReduxState } from '../reducer';

const stateKey = 'consent';

const ConsentSelector = {
    consent: (state: ReduxState) => state[stateKey].consent,
    loaded: (state: ReduxState) => state[stateKey].loaded,
};

export default ConsentSelector;
