import {Box, Collapse, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import _ from 'lodash';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Event from '../../../action/event';
import {formatMoney} from '../../../i18n/i18n';
import Selector from '../../../selector';
import InfoBox from '../../layout/components/InfoBox';
import LoaderOverlay from '../../layout/components/LoaderOverlay';
import {getAvailableDurations, getAvailableTimeSlots} from '../util/orderDataStepHelpers';
import BookingDatePicker from './SelectionStep/BookingDatePicker';
import BookingDurationSelect from './SelectionStep/BookingDurationSelect';
import BookingTimeSelect from './SelectionStep/BookingTimeSelect';
import PaddlerCountSelect from './SelectionStep/PaddlerCountSelect';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(0.5),
        paddingTop: 0,
    },
    dateTimeWrapper: {
        width: '350px',
        display: 'flex',
        flexDirection: 'column',
        margin: `${theme.spacing(5)}px auto 0 auto`,
        [theme.breakpoints.up('lg')]: {
            width: '350px',
        },
        // border: '1px solid'
    },
    inputWrapper: {
        width: '350px',
        display: 'flex',
        flexDirection: 'column',
        margin: `${theme.spacing(3)}px auto 0 auto`,
        [theme.breakpoints.up('lg')]: {
            width: '350px',
        },
    },
    couponInfoText: {
        textAlign: 'center',
        marginBottom: theme.spacing(1.5),
    },
    couponWrapper: {
        marginTop: theme.spacing(2.5),
        '@media(min-height: 620px)': {
            marginTop: theme.spacing(3),
        },
    },
    priceInfoWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderTop: '1px solid white',
        borderBottom: '1px solid white',
        margin: `${theme.spacing(2)}px auto 0 auto`,
        padding: `${theme.spacing(0.5)}px 0`,
        [theme.breakpoints.up('sm')]: {
            width: 500,
        },
        [theme.breakpoints.up('md')]: {
            width: 600,
        },
        '& p': {
            fontWeight: 500,
        },
        '@media(min-height: 620px)': {
            margin: `${theme.spacing(4)}px auto 0 auto`,
            padding: `${theme.spacing(1)}px 0`,
        },
    },
    bookingTimeInfo: {
        margin: '0 auto',
        [theme.breakpoints.up('sm')]: {
            width: 500,
        },
        [theme.breakpoints.up('md')]: {
            width: 600,
        },
        '@media(min-height: 620px)': {
            marginTop: theme.spacing(1),
        },
    },
    errorInfoText: {
        marginTop: '10px', 
        color: 'red',
        opacity: 0.6,
        textAlign: 'left',
        fontSize: '12px',
        marginLeft: '5px',
    },
}));

interface OrderDataStepProps {
    locationId: string;
}

const OrderDataStep = (props: OrderDataStepProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const tenantConfig = useSelector(Selector.Config.config);
    const orderData = useSelector(Selector.BookingCreation.orderData);
    const selectedAssetGroupId = useSelector(Selector.BookingCreation.assetGroupSelection);

    if (!selectedAssetGroupId) {
        // eslint-disable-next-line no-console
        console.error('group selection not unique or empty');
    }

    const assetGroups = useSelector(Selector.Location.makeLocationAssetGroups(props.locationId));
    const assetGroup = assetGroups?.find((group) => group.id === selectedAssetGroupId);

    const groupAvailabilities = useSelector(
        Selector.Location.makeLocationAssetGroupAvailabilities(
            props.locationId,
            selectedAssetGroupId ?? '',
        ),
    );

    let availableDates;
    if (groupAvailabilities) {
        availableDates = Object.keys(groupAvailabilities).filter(dateKey => {
            const timeSlots = groupAvailabilities[dateKey];
            return Object.values(timeSlots).some(slot => 
                Object.values(slot).some(value => value > 0),
            );
        });  
    }
    
    const enableTimeSelect = Boolean(groupAvailabilities && orderData.date);
    const enableDurationSelect = Boolean(
        groupAvailabilities && orderData.date && orderData.time !== '',
    );
    const enablePaddlerSelect = Boolean(
        groupAvailabilities && orderData.date && orderData.time !== '' && orderData.duration,
    );

    const availableTimeSlots =
        groupAvailabilities && orderData.date
            ? getAvailableTimeSlots(groupAvailabilities, orderData.date)
            : [];

    const availableDurations =
        groupAvailabilities && orderData.date && orderData.time
            ? getAvailableDurations(groupAvailabilities, orderData.date, orderData.time)
            : [];

    // const maxAssetCount = (locationAvailability && orderData.date && orderData.time && orderData.duration)
    //     ? getMaxPaddlerCount(locationAvailability, orderData.date, orderData.time, orderData.duration)
    //     : 0;

    // const minTotalPrice = orderData.paddlerCount * (
    //     (locationAvailability && orderData.date && orderData.time && orderData.duration)
    //         ? getMinPossiblePrice(locationAvailability, orderData.date, orderData.time, orderData.duration)
    //         : 0

    const maxPaddlerCount = 0;

    const minTotalPrice = 0;

    const handleDateChange = (date: moment.Moment | null) => {
        dispatch(Event.BookingCreation.dateSelected({date}));
        // if isFullDay is true, time should be selected automatically
        if (groupAvailabilities && assetGroup && assetGroup.isFullDay && date) {
            const timeSlots = getAvailableTimeSlots(groupAvailabilities, date);
            dispatch(Event.BookingCreation.timeSelected({time: timeSlots[0]}));
        }
    };

    const handleTimeChange = (time: string) => {
        dispatch(Event.BookingCreation.timeSelected({time}));

        /* After user selects a time and if the duration 1.5h is available it should be selected automatically */
        // if (locationAvailability && orderData.date && time) {
        //     const newAvailableDurations = getAvailableDurations(locationAvailability, orderData.date, time);
        //     if (newAvailableDurations.includes(1.5)) {
        //         dispatch(Event.BookingCreation.durationSelected({ duration: 1.5 }));
        //     }
        // }
    };

    const showBookingTimeInfo = Boolean(orderData.duration && orderData.duration < 2);

    const isLoading = groupAvailabilities === null || groupAvailabilities === undefined;

    const showPaddlerCountSelect =
        groupAvailabilities &&
        Object.values(groupAvailabilities).some((date) =>
            Object.values(date).some((timeSlot) =>
                Object.values(timeSlot).some((value) => value > 1),
            ),
        );

    const showStartTimeSelect = groupAvailabilities && assetGroup && !assetGroup.isFullDay;

    return (
        <>
            <Box className={classes.root}>
                {
                    groupAvailabilities && 
                    (_.isEmpty(groupAvailabilities) ? (
                        <div>{t('book_location.order_data.error.no_availabilities_for_group')}</div>
                    ) : (
                        <>
                            <Box className={classes.dateTimeWrapper}>
                                {availableDates && (
                                    <BookingDatePicker
                                        value={orderData.date}
                                        onChange={(date) => handleDateChange(date)}
                                        shouldDisableDate={(date) => false}
                                        availableDates={availableDates}
                                    />
                                )}
                            </Box>
        
                            {showStartTimeSelect && (
                                <Box className={classes.inputWrapper}>
                                    <BookingTimeSelect
                                        disabled={!enableTimeSelect}
                                        availableSlots={availableTimeSlots}
                                        value={orderData.time}
                                        onChange={handleTimeChange}
                                        availableDurations={availableDurations}
                                    />
                                </Box>
                            )}
        
                            <Box className={classes.inputWrapper}>
                                <BookingDurationSelect
                                    value={orderData.duration}
                                    onChange={(duration) =>
                                        dispatch(Event.BookingCreation.durationSelected({ duration }))
                                    }
                                    disabled={!enableDurationSelect}
                                    availableDurations={availableDurations}
                                />
                            </Box>
        
                            <Box className={classes.inputWrapper}>
                                {showPaddlerCountSelect && (
                                    <PaddlerCountSelect
                                        label={t('general.amount')}
                                        value={orderData.amount}
                                        onChange={(assetCount) =>
                                            dispatch(Event.BookingCreation.assetCountSelected({ assetCount }))
                                        }
                                        max={maxPaddlerCount}
                                        disabled={!enablePaddlerSelect}
                                    />
                                )}
                            </Box>
        
                            {/* Uncomment this block if needed */}
                            {/* {orderData.date && availableDurations && availableDurations.length === 0 && (
                                <Box className={classes.errorInfoText}>
                                    <span>{t('book_location.error.no_availabilities')}</span>
                                </Box>
                            )} */}
        
                            <Collapse in={showBookingTimeInfo} className={classes.bookingTimeInfo}>
                                <InfoBox
                                    variant={'secondary'}
                                    text={t('book_location.order_data.book_more_time')}
                                />
                            </Collapse>
        
                            {minTotalPrice > 0 && (
                                <Box className={classes.priceInfoWrapper}>
                                    <Typography variant={'body1'}>
                                        {t('book_location.order_data.your_rental_price')}
                                    </Typography>
                                    <Typography variant={'body1'}>
                                        {formatMoney(minTotalPrice, tenantConfig.currency)}
                                    </Typography>
                                </Box>
                            )}
        
                            {tenantConfig.allowCoupons && (
                                <Box className={classes.couponWrapper}>
                                    <Typography variant={'body2'} className={classes.couponInfoText}>
                                        {t('book_location.order_data.coupon_info_text')}
                                    </Typography>
                                </Box>
                            )}
                        </>
                    ))

                }
            </Box>
            <LoaderOverlay open={isLoading} />
        </>
    );
    
};

export default OrderDataStep;
