import { Action, handleActions } from 'redux-actions';
import Event from '../action/event';
import {
    OrderFetchedPayload,
    OrderFetchingBeganPayload,
    OrderFetchingEmptyPayload,
    OrderFetchingFailedPayload,
    OrdersLoadedPayload,
} from '../action/orderEvent';
import { Order } from '../types/order';
import { Rental } from '../types/rental';

export interface OrderState {
    orders: Record<string, Order>;
    ordersLoaded: boolean;
    rental: Rental | null;
    fetchingOrder: boolean;
    startingOrder: boolean;
    doorsVisibleTimestamp: Date | null;
    orderFinished: boolean;
    reportingDamage: boolean;
}

export const initialState: OrderState = {
    orders: {},
    ordersLoaded: false,
    rental: null,
    fetchingOrder: false,
    startingOrder: false,
    doorsVisibleTimestamp: null,
    orderFinished: false,
    reportingDamage: false,
};

export const reducer = handleActions<OrderState, any>(
    {
        [Event.Order.orderFetchingBegan.toString()]:
            (state, action: Action<OrderFetchingBeganPayload>): OrderState => {
                return {
                    ...state,
                    fetchingOrder: true,
                };
            },
        [Event.Order.noOrdersByUser.toString()]:
            (state, action: Action<OrderFetchingEmptyPayload>): OrderState => { 
                // Case no order retrieved we stop the loading
                return {
                    ...state,
                    fetchingOrder: false,
                };
            },
        [Event.Order.orderFetched.toString()]:
            (state, action: Action<OrderFetchedPayload>): OrderState => {
                // const internalOrderId = action.payload.internalOrder.orderId;
                const filteredOrders = Object.keys(state.orders)
                    // .filter(id => id.split('_')[0] !== internalOrderId)
                    .filter(id => id !== action.payload.internalOrder.id)
                    .reduce((object, key) => {
                        object[key] = state.orders[key];
                        return object;
                    }, {} as Record<string, Order>);

                
                const newOrders: Record<string, Order> = {};
                // action.payload.internalOrder.forEach(order => {
                newOrders[action.payload.internalOrder.id] = action.payload.internalOrder;
                // });
                

                return {
                    ...state,
                    orders: {
                        ...filteredOrders,
                        ...newOrders,
                    },
                    fetchingOrder: false,
                };
            },
        [Event.Order.orderFetchingFailed.toString()]:
            (state, action: Action<OrderFetchingFailedPayload>): OrderState => {
                return {
                    ...state,
                    fetchingOrder: false,
                };
            },
        [Event.Order.ordersLoaded.toString()]:
            (state, action: Action<OrdersLoadedPayload>): OrderState => {
                const orders: Record<string, Order> = {};
                action.payload.orders.forEach(order => {
                    orders[order.id] = order;
                });
                
                
                return {
                    ...state,
                    ordersLoaded: true,
                    orders,
                };
            },
    },
    initialState,
);
