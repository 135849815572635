import {
    CognitoIdentityProvider,
    CognitoIdentityProviderClient,
    ConfirmForgotPasswordCommand,
    ConfirmSignUpCommand,
    ForgotPasswordCommand,
    GetUserCommandOutput,
    InitiateAuthCommand,
    InitiateAuthCommandInput,
    SignUpCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import * as Sentry from '@sentry/browser';

const cognitoIdentityProviderService = new CognitoIdentityProvider({
    region: process.env.REACT_APP_REGION,
});
export const cognitoClient = new CognitoIdentityProviderClient({
    region: process.env.REACT_APP_REGION,
});

export const signUp = async (
    firstName: string,
    lastName: string,
    streetAddress: string,
    zip: string,
    city: string,
    email: string,
    password: string,
    // agreeToTerms: boolean
) => {
    const params = {
        ClientId: process.env.REACT_APP_CLIENT_ID,
        Username: email,
        Password: password,
        UserAttributes: [
            {
                Name: 'given_name',
                Value: firstName,
            },
            {
                Name: 'family_name',
                Value: lastName,
            },
            {
                Name: 'email',
                Value: email,
            },
            {
                Name: 'address',
                Value: `${city}$${streetAddress}$${zip}`,
            },
            // {
            //     Name: 'custom:agree_to_terms',
            //     Value: agreeToTerms.toString(),
            // },
        ],
    };
    try {
        const command = new SignUpCommand(params);
        const response = await cognitoClient.send(command);
        Sentry.captureMessage('Sign up success: ' + JSON.stringify(response));
        return response;
    } catch (error) {
        Sentry.captureException(error);
        throw error;
    }
};

export const signIn = async (email: string, password: string) => {
    const params: InitiateAuthCommandInput = {
        AuthFlow: 'USER_PASSWORD_AUTH',
        ClientId: process.env.REACT_APP_CLIENT_ID,
        AuthParameters: {
            USERNAME: email,
            PASSWORD: password,
        },
    };
    try {
        const command = new InitiateAuthCommand(params);
        const { AuthenticationResult } = await cognitoClient.send(command);
        if (AuthenticationResult) {
            sessionStorage.setItem('idToken', AuthenticationResult.IdToken || '');
            sessionStorage.setItem(
                'accessToken',
                AuthenticationResult.AccessToken || '',
            );
            sessionStorage.setItem(
                'refreshToken',
                AuthenticationResult.RefreshToken || '',
            );
            return AuthenticationResult;
        }
    } catch (error) {
        Sentry.captureException('Error signing in:', error);
        throw error;
    }
};

export const confirmSignUp = async (username: string, code: string) => {
    const params = {
        ClientId: process.env.REACT_APP_CLIENT_ID,
        Username: username,
        ConfirmationCode: code,
    };
    try {
        const command = new ConfirmSignUpCommand(params);
        await cognitoClient.send(command);
        Sentry.captureMessage('Sign up success: ');
        return true;
    } catch (error) {
        Sentry.captureException('Error confirming sign up:', error);
        throw error;
    }
};

export const sendConfirmationCode = async (email: string) => {
    const params = {
        ClientId: process.env.REACT_APP_CLIENT_ID,
        Username: email,
    };
    try {
        const command = new ForgotPasswordCommand(params);
        await cognitoClient.send(command);
    } catch (error) {
        Sentry.captureException('Error sending confirmation code:', error);
        throw error;
    }
};

export const resetPassword = async (email: string, newPassword: string, confirmationCode: string) => {
    try {
        // Initiate the forgot password process
        const forgotPasswordParams = {
            ClientId: process.env.REACT_APP_CLIENT_ID,
            Username: email,
        };
        await cognitoClient.send(new ForgotPasswordCommand(forgotPasswordParams));
        const confirmForgotPasswordParams = {
            ClientId: process.env.REACT_APP_CLIENT_ID,
            Username: email,
            ConfirmationCode: confirmationCode.trim(), // Confirmation code sent to user's email
            Password: newPassword,
        };
        await cognitoClient.send(new ConfirmForgotPasswordCommand(confirmForgotPasswordParams));
        return true;
    } catch (error) {
        Sentry.captureException('Error resetting password:', error);
        throw error;
    }
};

export const validateCognitoAccessToken = async (accessToken: string): Promise<any> => {
    try {
        const response: GetUserCommandOutput = await cognitoIdentityProviderService.getUser({
            AccessToken: accessToken,
        });
        return response;
    } catch (error) {
        Sentry.captureException('Error validating Cognito Access token:', error);
        throw error;
    }
};
