import {call, fork, put, select, take} from 'redux-saga/effects';
import Command from '../../action/command';
import Logger from '../../util/logger';
import {onEnqueueErrorSnackbar} from '../enqueueSnackbarFlow';
import {getI18n} from 'react-i18next';
import Api from '../../api';
import Selector from '../../selector';
import {Reservation} from '../../types/bookingCreation';
import {Action} from 'redux-actions';
import {CompleteBraintreePaymentPayload} from '../../action/bookingCreationCommand';
import {OrderId} from '../../types/order';
import Event from '../../action/event';
import {onFetchOrder} from '../fetchOrderFlow';
import ErrorTracker from '../../util/errorTracker';
import {trackGTMPlaceOrder} from '../helpers/gtm';

const onCompleteBraintreePayment = function*(payload: CompleteBraintreePaymentPayload) {
    yield put(Event.BookingCreation.paymentCompletionBegan({}));
    const reservation: Reservation = yield select(Selector.BookingCreation.reservation);

    try {
        const orderId: OrderId = yield call(Api.completeBraintreePayment, {
            reservationId: reservation.reservationId,
            braintreeNonce: payload.braintreeNonce,
            braintreeDeviceData: payload.braintreeDeviceData,
        });

        yield put(Event.BookingCreation.paymentCompleted({ orderId }));
        yield fork(onFetchOrder, orderId, 'any');
        yield fork(trackGTMPlaceOrder, orderId);
    } catch (error) {
        yield fork(onEnqueueErrorSnackbar, getI18n().t('book_location.error.complete_payment'));
        yield put(Event.BookingCreation.paymentCompletionFailed({}));
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);
    }
};

export function* completeBraintreePaymentFlow() {
    while (true) {
        const action: Action<CompleteBraintreePaymentPayload> = yield take(
            Command.BookingCreation.completeBraintreePayment.toString(),
        );

        yield fork(onCompleteBraintreePayment, action.payload);
    }
}
