import React, {useEffect} from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import Selector from '../../../../selector';
import Event from '../../../../action/event';
import Command from '../../../../action/command';
import {useTranslation} from 'react-i18next';
import { loadStripeCheckout } from '../../../../util/stripe';

declare global {
    interface Window {
        StripeCheckout: (options: {
            checkoutFrontendUrl: string;
            iFrameContainerId: string;
            language: string;
            token: string;
        }) => void;
    }
}

const STRIPE_CHECKOUT_FRAME_CONTAINER_ID = 'stripe-checkout-frame-container';

// TODO: needed?
// const getCurrentStripeLocale = () => {
//     switch (i18n.language) {
//         case 'nb': return 'no';
//         default: return 'en';
//     }
// };

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        boxSizing: 'border-box',
        paddingTop: theme.spacing(2),
    },
}));

const StripePayment = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const stripePayment = useSelector(Selector.BookingCreation.stripePayment);

    useEffect(() => {
        // if (!window.StripeCheckout || !stripePayment.checkoutFrontendUrl || !stripePayment.token) {
        if (!stripePayment.checkoutFrontendUrl || !stripePayment.token) {
            dispatch(Event.BookingCreation.paymentInitializationFailed({}));
            dispatch(Command.Notification.enqueueNotification({
                severity: 'error',
                message: t('book_location.error.payment_initialization'),
            }));
        } else {
            const initStripe = async () => {
                const stripe = await loadStripeCheckout();
                return stripe;
            };
            initStripe().then((stripe) => {
                if (stripe && stripePayment.token) {
                    stripe.redirectToCheckout({
                        sessionId: stripePayment.token,
                    });
                }
            });
        }

        // window.StripeCheckout({
        //     checkoutFrontendUrl: stripePayment.checkoutFrontendUrl!,
        //     iFrameContainerId: STRIPE_CHECKOUT_FRAME_CONTAINER_ID,
        //     language: getCurrentStripeLocale(),
        //     token: stripePayment.token!,
        // });
    }, []);


    

    return (
        <Box className={classes.root}>
            <section id={STRIPE_CHECKOUT_FRAME_CONTAINER_ID}>
            </section>
        </Box>
    );
};

export default StripePayment;
