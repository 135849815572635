/* eslint-disable */
export const Faq = {
    en: {
        title: 'FAQ',
        content: [
            {
                title: 'How do I create a booking?',
                content: `<p><li>To create a booking, click on “Stations” in the lower menu and then select the desired location on the map view.</li>
<li>Alternatively, click on the “New booking” button and then select the desired location on the map view.</li>
<li>In both cases, a pop-up will appear and you can access the booking route by clicking on the “Book” button.</li>
                </p>`,
            },
            {
                title: 'How do I add my booking to my phone?',
                content: `<p><li>If you have booked with your phone, your ticket is already on leihbar.vonovia.de.</li>
<li>Alternatively, you can add your booking by entering the booking number.</li>
<li>As usual, you will also receive the link to your booking in the booking confirmation email.</li>
                </p>`,
            },
            {
                title: 'Where can I find instructions for using the loan items?',
                content: `<p><li>Most rental items come with printed instructions on how to use them. Read through these.</li>
<li>Go to YouTube and watch general videos on how to use the item you have borrowed.</li>
                </p>`,
            },
            {
                title: 'What happens if I damage the equipment or it is already damaged?',
                content: `<p><li>If your equipment is already damaged at the start of the rental or if something breaks during the rental, you can report this in the station view under.</li>
<li>In the event of serious damage to the equipment, please call us immediately. You will then be assigned another board and we will take care of the damage and the next steps.</li>
                </p>`,
            },
            {
                title: "My locker won't open, what do I do now?",
                content: `<p><li>Unfortunately, mechanical or technical problems cannot always be ruled out 100%. Please try opening your locker several times with your phone and follow the instructions in the app step by step.</li>
<li>If the door is stuck, please help a little with your hand. If this is unsuccessful, please email us at leihbar@vonovia.de and we will be happy to help.</li>
                </p>`,
            },
            {
                title: 'My plans have changed, can I rebook or cancel?',
                content: `<p><li>Bookings cannot be changed.</li>
<li>You will find the right of withdrawal under point 5 of our terms and conditions.</li>
<li>If you are unable to attend your booked date for a really important reason, please send us an email at leihbar@vonovia.de. We will then try to find a fair solution.</li>
                </p>`,
            },
        ],
    },
    de: {
        title: 'Häufige Fragen',
        content: [
            {
                title: 'Wie erstelle ich eine Buchung?',
                content: `<p><li>Um eine Buchung zu erstellen, klicke im unteren Menü auf „Stationen“ und wähle dann auf der Kartenansicht den gewünschten Standort aus.</li>
<li>Alternativ klicke auf den Button “Neue Buchung” und wähle dann auf der Kartenansicht den gewünschten Standort aus.</li>
<li>In beiden Fällen erscheint ein Pop-up und über den Button „Buchen“ kommst du zur Buchungsstrecke.</li>
                </p>`,
            },
            {
                title: 'Wie füge ich eine bestehende Buchung meinem Handy hinzu?',
                content: `<p><li>Wenn du mit deinem Telefon gebucht hast, ist dein Ticket bereits auf leihbar.vonovia.de zu finden.</li>
<li>Alternativ kannst du deine Buchung mit Eingabe der Buchungsnummer hinzufügen.</li>
<li>Wie gewohnt gibt es auch den Link zu deiner Buchung in der Buchungsbestätigung per Mail.</li>
                </p>`,
            },
            {
                title: 'Wo finde ich Anleitungen für die Benutzung der Leihgegenstände?',
                content: `<p><li>Die meisten Leihgegenstände kommen mit gedruckten Anleitungen für ihre Benutzung. Lies dir diese durch.</li>
<li>Gehe auf Youtube und schaue dir allgemeinen Videos zur Nutzung des Gegenstand den du dir ausgeliehen hast an.</li>
                </p>`,
            },
            {
                title: 'Was passiert, wenn ich das Material beschädige oder es bereits beschädigt ist?',
                content: `<p><li>Falls dein Material zu Beginn der Miete bereits beschädigt ist oder während der Miete etwas kaputtgeht, kannst du das in der Stationsansicht unter  melden.</li>
<li>Bei schweren Schäden am Material rufst du uns bitte sofort an. Du bekommst von uns dann ein anderes Board zugewiesen und wir kümmern uns um den Schaden und das weitere Vorgehen.</li>
                </p>`,
            },
            {
                title: 'Mein Schliessfach öffnet sich nicht, was mache ich nun?',
                content: `<p><li>Leider lassen sich mechanische oder technische Probleme nicht immer zu 100 % ausschließen. Bitte versuche mehrmals, mit dem Telefon dein Fach zu öffnen und folge den Anweisungen in der App Schritt für Schritt.</li>
<li>Sollte die Tür klemmen, helfe bitte etwas mit der Hand nach. Ist dies erfolglos, schreibe uns bitte eine E-Mail an leihbar@vonovia.de, wir helfen gerne weiter.</li>
                </p>`,
            },
            {
                title: 'Meine Pläne haben sich geändert, kann ich umbuchen oder stornieren?',
                content: `<p><li>Buchungen können nicht geändert werden.</li>
<li>In unseren AGB findest du unter Punkt 5 das Widerrufsrecht.</li>
<li>Wenn du aus einem wirklich wichtigen Grund an deinem gebuchten Termin nicht erscheinen kannst, schreib uns unter leihbar@vonovia.de eine Mail. Wir versuchen dann, eine faire Lösung zu finden.</li>
                </p>`,
            },
        ],
    },
};
