/* eslint-disable */

export const Imprint = {

en: `
# Imprint
    
Living Innovations- & Beteiligungsgesellschaft mbH
Universitätsstraße 133, 44803 Bochum

Telefon: +49-15257928502
E-Mail: innovation@vonovia.de
Vertretungsberechtigte(r) Geschäftsführer: Alexander Weihe

Registergericht: Amtsgericht Bochum

Registernummer: HRB 20260 
Umsatzsteuer Identifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE354812026

Inhaltlich Verantwortlicher gemäß § 55 Absatz 2 RStV: Maximilian Breß, Maximilian.Bress@vonovia.de
`,



de: `
# Impressum
    
Living Innovations- & Beteiligungsgesellschaft mbH
Universitätsstraße 133, 44803 Bochum

Telefon: +49-15257928502
E-Mail: innovation@vonovia.de
Vertretungsberechtigte(r) Geschäftsführer: Alexander Weihe

Registergericht: Amtsgericht Bochum

Registernummer: HRB 20260 
Umsatzsteuer Identifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE354812026

Inhaltlich Verantwortlicher gemäß § 55 Absatz 2 RStV: Maximilian Breß, Maximilian.Bress@vonovia.de
`,

};
