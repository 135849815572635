import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RegistrationLogo from './components/RegistrationLogo';
import RegistrationInput from './components/RegistrationInput';
import { Link, useHistory } from 'react-router-dom';
import { sendConfirmationCode } from '../../util/cognitoAuthService';
import Command from '../../action/command';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        padding: theme.spacing(0.5),
        paddingTop: theme.spacing(2),
    },
    info1: {
        textAlign: 'center',
        fontFamily: 'Source Sans 3', // Changed to the specified font family
        fontSize: '20px', // Changed to the specified font size
        fontStyle: 'normal', // Changed to the specified font style
        fontWeight: 700, // Changed to the specified font weight
        lineHeight: 'normal',
    },
    form: {
        paddingBottom: theme.spacing(5),
        margin: 'auto',
    },
    button: {
        height: '54px',
        width: '380px',
        borderRadius: '4px',
    },
    link: {
        color: 'orange',
    },
}));

const ForgottenPassword = () => {
    const classes = useStyles();
    const [email, setEmail] = useState(''); 
    const history = useHistory();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const handleSendCode = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        try{
            await sendConfirmationCode(email);
            history.push(`/reset-password?email=${email}`);
        } catch (error) {
            dispatch(Command.Notification.enqueueNotification({
                severity: 'error',
                message: t(`Error sending confirmation code: ${error}`),
            }));
        }
    };

    return (
        <Box className={classes.root}>
            <Typography className={classes.info1}>
                {t('authentication.forgotten_Password')}
            </Typography>
            <RegistrationLogo />
            <Grid item={true}>
                <Typography variant="body1">
                    Input your email address to receive code
                    to reset password
                </Typography>
            </Grid>
            <form className={classes.form} onSubmit={handleSendCode}>
                <Grid container={true} spacing={2}>
                    <RegistrationInput
                        label="Email"
                        name="email"
                        type="email"
                        value={email}
                        placeholder="Please enter your Email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Grid item={true} xs={12}>
                        <Button type="submit" variant="contained" className={classes.button} color="primary">
                            Send Confirmation code
                        </Button>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Link className={classes.link} to="/login">Back</Link>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default ForgottenPassword;
