import { createAction } from 'redux-actions';

export interface LoadConfigPayload {

}

const ConfigCommand = {
    loadConfig: createAction<LoadConfigPayload>('@@KL_CMD/LOAD_CONFIG'),
};

export default ConfigCommand;
