import React from 'react';
import {Button, ButtonProps} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Define the props interface
interface CustomButtonProps extends ButtonProps {
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
    children?: React.ReactNode;
    isButtonDark?: boolean;
    color?: 'primary' | 'secondary' | 'inherit' | 'default';
    variant?: 'outlined' | 'contained';
}

const useStyles = makeStyles(theme => ({
    buttonLight: {
        maxWidth: 1000,
        fontSize: theme.typography.button.fontSize,
        fontWeight: theme.typography.button.fontWeight,
        fontStyle: theme.typography.button.fontStyle,
        lineHeight: theme.typography.button.lineHeight,
        height: theme.typography.button.height,
        fontFamily: theme.typography.fontFamily,
        textTransform: 'none',
        marginBottom: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.dark,
        // '&:hover': {
        //     backgroundColor: (props: CustomButtonProps) =>
        //         props.color === 'primary' ? theme.palette.primary.dark : theme.palette.primary.main,
        //     color: (props: CustomButtonProps) =>
        //         props.color === 'primary' ? theme.palette.primary.main : theme.palette.primary.dark,
        // },
    },
    buttonDark: {
        maxWidth: 1000,
        fontSize: theme.typography.button.fontSize,
        fontWeight: theme.typography.button.fontWeight,
        fontStyle: theme.typography.button.fontStyle,
        lineHeight: theme.typography.button.lineHeight,
        height: theme.typography.button.height,
        fontFamily: theme.typography.fontFamily,
        textTransform: 'none',
        marginBottom: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        border: '1px solid white',
        '&:hover': {
            backgroundColor: (props: CustomButtonProps) =>
                props.color === 'primary' ? theme.palette.primary.main : theme.palette.primary.dark,
            color: (props: CustomButtonProps) =>
                props.color === 'primary' ? theme.palette.primary.dark : theme.palette.primary.main,
        },
    },
}));

const CustomButton: React.FC<CustomButtonProps> = (props) => {
    const classes = useStyles(props);

    return (
        <Button
            className={`${props.isButtonDark ? classes.buttonDark : classes.buttonLight} ${props.className}`}
            // className={classes.buttonDark}
            variant={props.variant}
            onClick={props.onClick}
            disabled={props.disabled}
            fullWidth={true}
            color={props.color || 'primary'}
        >
            {props.children}
        </Button>
    );
};

export default CustomButton;
