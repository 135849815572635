/* eslint-disable */
export const Faq = {
    en: {
        title: "FAQ",
        content: [
            {
                title: 'For how long can I book a locker?',
                content: `<p>You can rent one locker per day (00:00 to 23:59). The maximum rental period is 7 days.</p>`,
            },
            {
                title: 'What size locker should I choose?',
                content: `<p><li>XS: a 40 x 18 x 55 cm locker for storing small handbags, for example</li>
<li>S: a 40 x 22 x 55 cm compartment for storing e.g. laptop bags</li>
<li>M: a 40 x 30 x 55 cm compartment for storing larger bags and rucksacks, for example</li>
<li>L: a 40 x 36 x 55 cm compartment for storing e.g. wheeled suitcases</li>
                </p>`,
            },
            {
                title: 'Can I book in advance?',
                content: `<p><li>Yes, you can make unlimited advance bookings.</li>
<li>Please remember: only one locker per day.</li>
<li>And please coordinate your locker booking with the planning of your office visits.</li>
                </p>`,
            },
            {
                title: 'What happens if I have forgotten items in my locker?',
                content: `<p>Please contact Reception or Office Management.</p>`,
            },
            {
                title: 'How do I add a booking to my cell phone if I have booked on my PC?',
                content: `<p><li>When you open the app on your cell phone and log in, your bookings will be loaded automatically.</li>
<li>Alternatively, you can add a booking that Office Management has created for you, for example, by entering the booking number.</li>
                </p>`,
            },
            {
                title: "My locker won't open. What do I do now?",
                content: `<p><li>Unfortunately, mechanical and electronic problems cannot always be ruled out 100%.</li>
<li>Please try opening your locker several times with your phone and follow the instructions in the app step by step.</li>
<li>If the door is stuck, please help a little by hand. If all attempts are unsuccessful, please contact Reception or Office Management.</li>
<li>If no one is on site, please send us an e-mail to: storage@segmenta.de.</li>
                </p>`,
            },
            {
                title: 'My locker is damaged or dirty. What do I do now?',
                content: `<p>Please enter a damage report in the app via the i-icon on the "Opening locker" page, alternatively send an email to: storage@segmenta.de</p>`,
            },
            {
                title: 'My plans have changed. Can I rebook or cancel?',
                content: `<p><li>Bookings cannot be changed.</li>
<li>If you need a booking for another day, you must create a new one.</li>
                </p>`,
            },
        ],
    },
    de: {
        title: "FAQ",
        content: [
            {
                title: 'Für wie lange kann ich ein Schließfach buchen?',
                content: `<p>Du kannst pro Tag (00:00 bis 23:59) ein Schließfach mieten. Die maximale Mietdauer beträgt 7 Tage.</p>`,
            },
            {
                title: 'Welche Größe sollte ich für ein Schließfach wählen?',
                content: `<p><li>XS: ein 40 x 18 x 55 cm großes Fach zur Aufbewahrung von z.B. kleinen Handtaschen</li>
<li>S: ein 40 x 22 x 55 cm großes Fach zur Aufbewahrung von z.B. Laptop-Taschen</li>
<li>M: ein 40 x 30 x 55 cm großes Fach zur Aufbewahrung von z.B. größeren Taschen und Rucksäcken</li>
<li>L: ein 40 x 36 x 55 cm großes Fach zur Aufbewahrung von z.B. Rollkoffern</li></p>
                `,
            },
            {
                title: 'Kann ich im Voraus buchen?',
                content: `<p><li>Ja, du kannst unbegrenzt im Voraus buchen.</li>
<li>Bitte denke daran: immer nur ein Schließfach pro Tag.</li>
<li>Und bitte stimme deine Schließfachbuchung mit der Planung deiner Büroanwesenheiten ab.</li></p>
                `,
            },
            {
                title: 'Was passiert, wenn ich Gegenstände im Schließfach vergessen habe?',
                content: `<p><li>Wende dich bitte an die Rezeption oder das Office Management.</li></p>`,
            },
            {
                title: 'Wie füge ich eine Buchung meinem Handy hinzu, wenn ich auf meinem PC gebucht habe?',
                content: `<p><li>Wenn du die App auf dem Handy öffnest und dich einloggst, werden deine Buchungen automatisch geladen.</li>
<li>Alternativ kannst du eine Buchung, die beispielsweise das Office Management für dich angelegt hat, mit Eingabe der Buchungsnummer hinzufügen.</li></p>`,
            },
            {
                title: 'Mein Schließfach öffnet sich nicht. Was mache ich nun?',
                content: `<p><li>Leider lassen sich mechanische und elektronische Probleme nicht immer zu 100% ausschließen.</li>
<li>Bitte versuche mehrmals, mit dem Telefon dein Fach zu öffnen und folge den Anweisungen in der App Schritt für Schritt.</li>
<li>Sollte die Tür klemmen, helfe bitte etwas mit der Hand nach.</li>
<li>Sind alle Versuche erfolglos, wende dich bitte an die Rezeption oder das Office Management.</li>
<li>Sollte keiner mehr vor Ort sein, schreibe uns bitte eine E-Mail an: storage@segmenta.de.</li></p>`,
            },
            {
                title: 'Mein Schließfach ist beschädigt oder verdreckt. Was mache ich nun?',
                content: `<p>Bitte gibt in der App über das i-icon auf der “Öffnung Schließfach” Seite eine Schadensmeldung ein, alternativ sende eine Mail an: storage@segmenta.de</p>`,
            },
            {
                title: 'Meine Pläne haben sich geändert. Kann ich umbuchen oder stornieren?',
                content: `<p><li>Buchungen können nicht geändert werden.</li>
<li>Wenn du für einen anderen Tag eine Buchung brauchst, musst du diese neu anlegen.</li></p>`,
            },
        ],
    },
};
