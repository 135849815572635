import { ReduxState } from '../reducer';
import { Order, OrderId } from '../types/order';

const stateKey = 'order';

const OrderSelector = {
    orders: (state: ReduxState) => Object.values(state[stateKey].orders).sort((order1, order2) => {
        if (order1.items[0].fromDate.getTime() < order2.items[0].fromDate.getTime()) {
            return -1;
        } else if (order1.items[0].fromDate.getTime() > order2.items[0].fromDate.getTime()) {
            return 1;
        } else {
            /* Make sure to have a consistent sorting order for bookings with identical start time */
            if (order1.id < order2.id) return -1;
            else if (order1.id > order2.id) return 1;
            else return 0;
        }
    }),
    ordersLoaded: (state: ReduxState) => state[stateKey].ordersLoaded,
    fetchingOrder: (state: ReduxState): boolean => state[stateKey].fetchingOrder,

    makeOrder: (orderId: OrderId) => (state: ReduxState): Order | null => {
        return OrderSelector.orders(state).find(order => order.id === orderId) || null;
    },
};

export default OrderSelector;
