import { Box, Button, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Command from '../../action/command';
import Routes from '../../routes';
import Selector from '../../selector';
import LoaderOverlay from '../layout/components/LoaderOverlay';
import TenantLogo from '../layout/components/TenantLogo';
import NoActiveOrders from './components/NoActiveOrders';
import OrdersSwiper from './components/OrdersSwiper';
import { isUserGranted } from '../../util/permission';
import moment from 'moment';
import { Order } from '../../types/order';
import CustomButton from '../../tenants/segmenta/component/CustomButton';
import AccessDenied from './components/AccessDenied';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        paddingTop: '16px',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.default,

    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        display: 'grid',
        justifyItems: 'center',
        fontSize: theme.typography.h1.fontSize,
        textTransform: 'none',
    },
    contentWrapper: {
        height: 'calc(100% - 160px)',
        maxWidth: 1000,
        margin: '0 auto',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '10px', 
    },
    grow: {
        flexGrow: 1,
    },
    buttonWrapper: {
        height: '150px',
        alignItems: 'start',
        maxWidth: 1000,
        margin: '0 auto',
        padding: '0px 16px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    logo: {
        width: '200px',
        [theme.breakpoints.up('lg')]: {
            width: '300px',
        },
        margin: '0 auto',
    },
    button: {
        fontSize: theme.typography.button.fontSize,
        fontWeight: theme.typography.button.fontWeight,
        fontStyle: theme.typography.button.fontStyle,
        lineHeight: theme.typography.button.lineHeight,
        height: theme.typography.button.height,
        fontFamily: theme.typography.fontFamily,
        textTransform: 'none',
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.dark,
        borderRadius: theme.shape.borderRadius,
    },
}));

const OrdersPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const orders = useSelector(Selector.Order.orders);
    const locations = useSelector(Selector.Location.locations);
    const ordersLoaded = useSelector(Selector.Order.ordersLoaded);
    const fetchingOrder = useSelector(Selector.Order.fetchingOrder);
    const [orderIndex, setOrderIndex] = useState<number>(0);
    const extremelySmallDevice = useMediaQuery('@media(max-width: 350px)');
    const [userIsAllowedToAddOrder, setUserIsAllowedToAddOrder] = useState<boolean>(false);
    const [userIsAllowedToCreateOrder, setUserIsAllowedToCreateOrder] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const startingRentalFailed = useSelector(Selector.Rental.startingRentalFailed);
    const tenantConfig = useSelector(Selector.Config.config);
    const [openDoorbuttonDisabled, setOpenDoorButtonDisabled] = useState(true);
    const [startUsingButtonDisabled, setStartUsingButtonDisabled] = useState(true);

    const startUsingOrder = (orderId: string, itemIds: string[]) => {
        // check if items are already started
        dispatch(Command.Rental.startRentalItems({ orderId, itemIds }));

        if (!startingRentalFailed){
            // Show loader and add a 3-second timeout before redirecting
            setLoading(true);
            setTimeout(() => {
                history.push(Routes.makeOrderDetailsUrl(orders[orderIndex].id));
                setLoading(false);
            }, 3000);
        }

    };

    const checkIfOrderCanBeStarted = (order: Order) => {
        const currentLocationTime = new Date();
        const startDate = moment(order.items[0].fromDate);
        const endDate = moment(order.items[0].toDate);
        const locationDate = moment(currentLocationTime);
        return startDate >= locationDate || endDate < locationDate;
    };

    const openDoorOrder = () => {
        history.push(Routes.makeOrderDetailsUrl(orders[orderIndex].id));
    };

    const createBooking = () => {
        if (tenantConfig.authentication === 'none') {
            history.push(Routes.locationsPath);
        } else {
            history.push(Routes.makeBookLocationUrl(locations[0]?.id), { returnPage: Routes.ordersPath });
        }
    };

    useEffect(() => {
        dispatch(Command.Order.loadOrders({}));
        const fetchUserPermissions = async () => {
            const userIsAllowedToAddOrder = await isUserGranted('addOrder');
            const userIsAllowedToCreateOrder = await isUserGranted('createOrder');
            setUserIsAllowedToAddOrder(userIsAllowedToAddOrder);
            setUserIsAllowedToCreateOrder(userIsAllowedToCreateOrder);
        };
        fetchUserPermissions();
    }, []);

    useEffect(() => {
        if (ordersLoaded) {
            dispatch(Command.Location.fetchAssetGroupsByLocationIds({
                locationIds: orders.map((order) => order.locationId),
            }));
        }
    }, [ordersLoaded]);

    useEffect(() => {
        if (ordersLoaded && orders.length > 0) {
            const rental = orders[orderIndex].items[0]?.rental;
            setOpenDoorButtonDisabled(!rental?.isActive);
            setStartUsingButtonDisabled(checkIfOrderCanBeStarted(orders[orderIndex]));
        }
    }, [orders, orderIndex]);

    return (
        <Box className={classes.root}>
            <Box className={classes.contentWrapper}>
                {!extremelySmallDevice && (
                    <TenantLogo type='default' size='large' className={classes.logo} />
                )}
                {
                    (userIsAllowedToAddOrder || userIsAllowedToCreateOrder) 
                        ? 
                        <Typography variant={'h1'} className={classes.header}>
                            {t('orders.ticket_overview.header')}
                        </Typography>
                        :
                        <Typography variant={'h1'} className={classes.header}>
                            {t('orders.no_access_allowed_title')}
                        </Typography>
                }
                {
                    ordersLoaded && orders.length > 0 && (
                        <OrdersSwiper
                            orders={orders}
                            index={orderIndex}
                            onChangeIndex={setOrderIndex}
                            locations={locations}
                        />
                    )}
                {ordersLoaded && orders.length <= 0 && 
                (userIsAllowedToAddOrder || userIsAllowedToCreateOrder) && <NoActiveOrders />}
                {
                    ordersLoaded && 
                    orders.length <= 0 && 
                    !userIsAllowedToAddOrder && 
                    !userIsAllowedToCreateOrder &&
                    <AccessDenied />
                }
            </Box>

            <Box className={classes.buttonWrapper}>
                {ordersLoaded && orders.length > 0 && orders[orderIndex].items[0] &&
                    (
                        orders[orderIndex].items[0]?.rental ? (
                            <CustomButton
                                isButtonDark={true}
                                variant={
                                    orders[orderIndex]?.items[0]?.rental
                                        ?
                                        openDoorbuttonDisabled
                                            ?
                                            'contained'
                                            :
                                            'outlined'
                                        :
                                        'outlined'
                                }
                                fullWidth={true}
                                onClick={openDoorOrder}
                                color='secondary'
                                // Disable button if isActive is false
                                disabled={
                                    openDoorbuttonDisabled
                                    // orders[orderIndex]?.items[0]?.rental
                                    //     ?
                                    //     !orders[orderIndex]?.items[0]?.rental?.isActive
                                    //     :
                                    //     false
                                }
                                children={t('book_location.orders_page.open_order')}
                            />

                        ) : (
                            <CustomButton
                                isButtonDark={true}
                                fullWidth={true}
                                variant={!startUsingButtonDisabled ? 'outlined' : 'contained'}
                                disabled={startUsingButtonDisabled}
                                onClick={() => startUsingOrder(orders[orderIndex].id, [orders[orderIndex].items[0].id])}
                                color='secondary'
                                children={t('book_location.orders_page.start_using')}
                            />
                        )
                    )
                }
                {
                    ordersLoaded && 
                    !orders.some(order => order.createdBy === 'admin') && 
                    tenantConfig.authentication !== 'none' && 
                    userIsAllowedToAddOrder && (
                        <Button
                            className={classes.button}
                            variant={'contained'}
                            fullWidth={true}
                            color='primary'
                            component={RouterLink}
                            to={Routes.addOrderPath}
                        >
                            {t('orders.add_order')}
                        </Button>
                    )
                }

                {tenantConfig.authentication === 'none' && (
                    <Button
                        className={classes.button}
                        variant={'outlined'}
                        fullWidth={true}
                        // color={'primary'}
                        component={RouterLink}
                        to={Routes.addOrderPath}
                    >
                        {t('orders.add_order')}
                    </Button>
                )}

                {
                    ordersLoaded && 
                    orders.length === 0 && 
                    tenantConfig.authentication === 'none' && (
                        <CustomButton
                            isButtonDark={true}
                            fullWidth={true}
                            onClick={createBooking}
                            color={'secondary'}
                            children={t('book_location.orders_page.create_booking')}
                            style={{justifyContent: 'flex-end'}}
                        />
                    )
                }

                {ordersLoaded && orders.length === 0 && userIsAllowedToCreateOrder && (
                    <CustomButton
                        isButtonDark={false}
                        fullWidth={true}
                        onClick={createBooking}
                        variant={'contained'}
                        children={t('book_location.orders_page.create_booking')}
                        style={{justifyContent: 'flex-end'}}
                    />
                )}

                {locations.length > 0 && ordersLoaded && orders.length > 0 && (
                    <CustomButton
                        isButtonDark={true}
                        fullWidth={true}
                        onClick={createBooking}
                        color={userIsAllowedToAddOrder ? 'secondary' : 'primary'}
                        children={t('book_location.orders_page.create_booking')}
                    />
                )}
            </Box>
            <LoaderOverlay open={fetchingOrder || loading} />
        </Box>
    );
};

export default OrdersPage;
