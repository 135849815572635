import { Box, Collapse, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { AssetGroup } from '../../../../types/assetGroup';
import Assets from '../../../../assets/assets';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(2),
    },
    contentWrapperContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    radio: {
        color: 'white',
        '&.Mui-checked': {
            color: 'white',
        },
    },
    contentWrapper: {
        display: 'flex',
        width: 'calc(100% - 42px)',
        border: '1px solid white',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '&.active': {
            backgroundColor: 'white',
            borderColor: 'white',
        },
    },
    infoWrapper: {
        display: 'flex',
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1),
    },
    infoText: {
        fontSize: '14px',
    },
    radioLabel: {
        fontWeight: 'bold',
        display: 'inline',
        '&.active': {
            color: 'black',
        },
    },
}));

interface AssetGroupSelectionItemProps {
    assetGroup: AssetGroup;
    active: boolean;
    onToggle: (id: string) => void;
}

const AssetGroupSelectionItem = (props: AssetGroupSelectionItemProps) => {
    const classes = useStyles();
    const InfoIconWhiteFilled = Assets.icon.infoIconWhiteFilled.component;

    const handleToggle = (event: React.MouseEvent) => {
        event.stopPropagation();
        props.onToggle(props.assetGroup.id);
    };
    
    return (
        <Box className={classes.root}>
            <Box className={classes.contentWrapperContainer}>
                <Box
                    className={`${classes.contentWrapper} ${props.active ? 'active' : ''}`}
                    onClick={handleToggle}
                >
                    <Typography className={`${classes.radioLabel} ${props.active ? 'active' : ''}`}>
                        {props.assetGroup.name}
                    </Typography>
                </Box>
            </Box>
            <Collapse in={props.active}>
                <Box className={classes.infoWrapper}>
                    <Box style={{ marginRight: '5px' }}>
                        <InfoIconWhiteFilled 
                            style={{ 
                                width: '20px', 
                                height: '20px', 
                            }} 
                        /> 
                    </Box>
                    <Typography variant={'body2'} className={classes.infoText}>
                        {props.assetGroup.description}
                    </Typography>
                </Box>
            </Collapse>
        </Box>
    );
};

export default AssetGroupSelectionItem;
