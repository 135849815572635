import { combineReducers } from 'redux';
import {
    BookingCreationState,
    initialState as bookingCreationInitialState,
    reducer as bookingCreationReducer,
} from './reducer/bookingCreationReducer';
import {
    LocationState,
    initialState as locationInitialState,
    reducer as locationReducer,
} from './reducer/locationReducer';
import {
    NotificationState,
    initialState as notificationInitialState,
    reducer as notificationReducer,
} from './reducer/notificationReducer';
import {
    OrderState,
    initialState as orderInitialState,
    reducer as orderReducer,
} from './reducer/orderReducer';
import {
    RentalState,
    initialState as rentalInitialState,
    reducer as rentalReducer,
} from './reducer/rentalReducer';
import {
    ConfigState,
    initialState as configInitialState,
    reducer as configReducer,
} from './reducer/configReducer';
import { 
    ConsentState, 
    initialState as consentInitialState, 
    reducer as consentReducer,
} from './reducer/consentReducer';

export interface ReduxState {
    location: LocationState;
    bookingCreation: BookingCreationState;
    notification: NotificationState;
    order: OrderState;
    rental: RentalState;
    config: ConfigState;
    consent: ConsentState;
}

export const initialState: ReduxState = {
    location: locationInitialState,
    bookingCreation: bookingCreationInitialState,
    notification: notificationInitialState,
    order: orderInitialState,
    rental: rentalInitialState,
    config: configInitialState,
    consent: consentInitialState,
};

export const rootReducer = combineReducers({
    location: locationReducer,
    bookingCreation: bookingCreationReducer,
    notification: notificationReducer,
    order: orderReducer,
    rental: rentalReducer,
    config: configReducer,
    consent: consentReducer,
});
