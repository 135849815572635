import { createAction } from 'redux-actions';
import { InternalOrderId } from '../types/order';

export interface FetchOrderPayload {
    internalOrderId: InternalOrderId;
    createdBy: 'admin' | 'user' | 'any';
    showFinishedWarning?: boolean;
    email?: string;
}

export interface LoadOrdersPayload {
}

const OrderCommand = {
    fetchOrder: createAction<FetchOrderPayload>('@@KL_CMD/FETCH_ORDER'),
    fetchOrdersByUser: createAction<FetchOrderPayload>('@@KL_CMD/FETCH_ORDERS_BY_USER'),
    loadOrders: createAction<LoadOrdersPayload>('@@KL_CMD/LOAD_ORDERS'),
};

export default OrderCommand;
