import { Action, handleActions } from 'redux-actions';
import Event from '../action/event';
import {
    DamageReportedPayload,
    DoorsVisibleTimestampResetPayload,
    RentalFetchedPayload,
    RentalFetchingBeganPayload,
    RentalFetchingFailedPayload,
    RentalFinishedPayload,
    RentalStartWithTooFewResourcesPayload,
    RentalStartedPayload,
    RentalStartingBeganPayload,
    RentalStartingFailedPayload,
    RentalsLoadedPayload,
    ReportingDamageBeganPayload,
    ReportingDamageFailedPayload,
} from '../action/rentalEvent';
import { Rental } from '../types/rental';

export interface AttemptedRentalStartInfo {
    availableCount: number;
    bookedCount: number;
    orderId: string;
    itemIds: string[];
}

export interface RentalFinishInfo {
    orderId: string;
    isFinished: boolean;
}


export interface RentalState {
    rentals: Record<string, Rental>;
    rentalsLoaded: boolean;
    rental: Rental | null;
    fetchingRental: boolean;
    startingRental: boolean;
    doorsVisibleTimestamp: Date | null;
    rentalFinished: RentalFinishInfo[];
    reportingDamage: boolean;
    showTooFewResourcesDialog: boolean;
    attemptedRentalStartInfo: AttemptedRentalStartInfo;
    startingRentalFailed: boolean;
}

export const initialState: RentalState = {
    rentals: {},
    rentalsLoaded: false,
    rental: null,
    fetchingRental: false,
    startingRental: false,
    doorsVisibleTimestamp: null,
    rentalFinished: [],
    reportingDamage: false,
    showTooFewResourcesDialog: false,
    attemptedRentalStartInfo: {
        availableCount: -1,
        bookedCount: -1,
        orderId: '',
        itemIds: [],
    },
    startingRentalFailed: false,
};

export const reducer = handleActions<RentalState, any>(
    {
        [Event.Rental.rentalFetchingBegan.toString()]:
            (state, action: Action<RentalFetchingBeganPayload>): RentalState => {
                return {
                    ...state,
                    fetchingRental: true,
                };
            },
        [Event.Rental.rentalFetched.toString()]:
            (state, action: Action<RentalFetchedPayload>): RentalState => {
                // const internalRentalId = action.payload.internalRental.rentalId;
                const filteredRentals = Object.keys(state.rentals)
                    // .filter(id => id.split('_')[0] !== internalRentalId)
                    .filter(id => id !== action.payload.rental.id)
                    .reduce((object, key) => {
                        object[key] = state.rentals[key];
                        return object;
                    }, {} as Record<string, Rental>);

                const newRentals: Record<string, Rental> = {};
                // action.payload.internalRental.forEach(rental => {
                newRentals[action.payload.rental.id] = action.payload.rental;
                // });

                return {
                    ...state,
                    rentals: {
                        ...filteredRentals,
                        ...newRentals,
                    },
                    fetchingRental: false,
                };
            },
        [Event.Rental.singleRentalFetched.toString()]:
            (state, action: Action<RentalFetchedPayload>): RentalState => {

                return {
                    ...state,
                    rental: action.payload.rental,
                };
            },
        [Event.Rental.rentalFetchingFailed.toString()]:
            (state, action: Action<RentalFetchingFailedPayload>): RentalState => {
                return {
                    ...state,
                    fetchingRental: false,
                };
            },
        [Event.Rental.rentalsLoaded.toString()]:
            (state, action: Action<RentalsLoadedPayload>): RentalState => {
                const rentals: Record<string, Rental> = {};
                action.payload.rentals.forEach(rental => {
                    rentals[rental.id] = rental;
                });

                return {
                    ...state,
                    rentalsLoaded: true,
                    rentals,
                };
            },
        [Event.Rental.rentalStartingBegan.toString()]:
            (state, action: Action<RentalStartingBeganPayload>): RentalState => {
                return {
                    ...state,
                    startingRental: true,
                    doorsVisibleTimestamp: null,
                    attemptedRentalStartInfo: {
                        ...state.attemptedRentalStartInfo,
                        orderId: action.payload.orderId,
                        itemIds: action.payload.itemIds,
                    },
                    startingRentalFailed: false,
                };
            },
        [Event.Rental.rentalStarted.toString()]:
            (state, action: Action<RentalStartedPayload>): RentalState => {
                return {
                    ...state,
                    rental: action.payload.rental,
                    startingRental: false,
                    doorsVisibleTimestamp: action.payload.startedTimestamp,
                    showTooFewResourcesDialog: false,
                    startingRentalFailed: false,
                };
            },
        [Event.Rental.rentalStartingFailed.toString()]:
            (state, action: Action<RentalStartingFailedPayload>): RentalState => {
                return {
                    ...state,
                    startingRental: false,
                    doorsVisibleTimestamp: null,
                    showTooFewResourcesDialog: false,
                    attemptedRentalStartInfo: initialState.attemptedRentalStartInfo,
                    startingRentalFailed: true,
                };
            },
        [Event.Rental.rentalFinished.toString()]:
            (state, action: Action<RentalFinishedPayload>): RentalState => {
                return {
                    ...state,
                    rentalFinished: [
                        ...state.rentalFinished,
                        { orderId: action.payload.orderId, isFinished: action.payload.isFinished },
                    ],
                };
            },
        [Event.Rental.reportingDamageBegan.toString()]:
            (state, action: Action<ReportingDamageBeganPayload>): RentalState => {
                return {
                    ...state,
                    reportingDamage: true,
                };
            },
        [Event.Rental.reportingDamageFailed.toString()]:
            (state, action: Action<ReportingDamageFailedPayload>): RentalState => {
                return {
                    ...state,
                    reportingDamage: false,
                };
            },
        [Event.Rental.damageReported.toString()]:
            (state, action: Action<DamageReportedPayload>): RentalState => {
                return {
                    ...state,
                    reportingDamage: false,
                };
            },
        [Event.Rental.doorsVisibleTimestampReset.toString()]:
            (state, action: Action<DoorsVisibleTimestampResetPayload>): RentalState => {
                return {
                    ...state,
                    doorsVisibleTimestamp: new Date(),
                };
            },
        [Event.Rental.rentalStartWithTooFewResources.toString()]:
            (state, action: Action<RentalStartWithTooFewResourcesPayload>): RentalState => {
                return {
                    ...state,
                    showTooFewResourcesDialog: true,
                    attemptedRentalStartInfo: {
                        ...state.attemptedRentalStartInfo,
                        availableCount: action.payload.availableCount,
                        bookedCount: action.payload.bookedCount,
                    },
                };
            },
    },
    initialState,
);
