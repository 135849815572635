import React from 'react';
import {Box} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import Event from '../../../action/event';
import {makeStyles} from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';
import { FaApple, FaCreditCard, FaGoogle } from 'react-icons/fa';

import { ReactComponent as VippsLogo} from '../../../assets/logos/vipps-logo.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(5),
    },
    cardContent: {
        display: 'flex',
        alignItems: 'center',
        padding: `${theme.spacing(2)}px !important`,
    },
    paymentOptions: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paymentOption: {
        width: '60%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        marginBottom: theme.spacing(2),
        cursor: 'pointer',
        border: `1px solid ${theme.palette.common.white}`,
        backgroundColor: 'transparent',
        transition: 'background-color 0.3s ease',
        color: theme.palette.common.white,
        fontFamily: 'ballinger,sans-serif',
        fontWeight: 400,
        lineHeight: '1.1876em',
        '&:hover': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
        },
    },
    paymentOptionIcon: {
        fontSize: '2rem',
        paddingRight: theme.spacing(2),
    },
    paymentOptionDescription: {
        fontSize: '1rem',
    },
    vippsLogo: {
        width: '2em',
        height: 'auto',
        paddingRight: theme.spacing(2),

    },
}));
  
interface PaymentTypeSelectionStepProps {
    locationId: string;
}

const PaymentTypeSelectionStep = (props: PaymentTypeSelectionStepProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handlePaymentOptionClick = (paymentType: string) => {
        dispatch(Event.BookingCreation.paymentTypeChanged({
            paymentType: paymentType,
        }));
        dispatch(Event.BookingCreation.navigatedToNextStep({}));
    };

    return (
        <Box className={classes.root}>
            <div className={classes.paymentOptions}>
                <Card className={classes.paymentOption} onClick={() => handlePaymentOptionClick('vipps')}>
                    <CardContent className={classes.cardContent}>
                        <VippsLogo className={classes.vippsLogo} />
                        <Typography className={classes.paymentOptionDescription}>Pay with vipps</Typography>
                    </CardContent>
                </Card>
        
                <Card className={classes.paymentOption} onClick={() => handlePaymentOptionClick('creditcard')}>
                    <CardContent className={classes.cardContent}>
                        <FaCreditCard className={classes.paymentOptionIcon} />
                        <Typography className={classes.paymentOptionDescription}>Pay with card</Typography>
                    </CardContent>
                </Card>
        
                <Card className={classes.paymentOption} onClick={() => handlePaymentOptionClick('applepay')}>
                    <CardContent className={classes.cardContent}>
                        <FaApple className={classes.paymentOptionIcon} />
                        <Typography className={classes.paymentOptionDescription}>Pay with Apple Pay</Typography>
                    </CardContent>
                </Card>
            
                <Card className={classes.paymentOption} onClick={() => handlePaymentOptionClick('googlepay')}>
                    <CardContent className={classes.cardContent}>
                        <FaGoogle className={classes.paymentOptionIcon} />
                        <Typography className={classes.paymentOptionDescription}>Pay with Google Pay</Typography>
                    </CardContent>
                </Card>
            </div>
        </Box>
    );
};

export default PaymentTypeSelectionStep;
