import moment from 'moment';
import { getI18n } from 'react-i18next';
import { call, fork, put, select, take } from 'redux-saga/effects';
import Command from '../action/command';
import Event from '../action/event';
import Api from '../api';
import { CreateNewReservationData } from '../api/types';
import { BookingCreationSelection } from '../reducer/bookingCreationReducer';
import Selector from '../selector';
import { Reservation } from '../types/bookingCreation';
import ErrorTracker from '../util/errorTracker';
import Logger from '../util/logger';
import { onEnqueueErrorSnackbar } from './enqueueSnackbarFlow';
import { trackGTMAddToCart } from './helpers/gtm';
import { TenantConfig } from '../types/tenantConfig';

export const onCreateNewReservation = function* () {
    const tenantConfig: TenantConfig = yield select(Selector.Config.config);

    const assetGroupSelection: string = yield select(Selector.BookingCreation.assetGroupSelection);
    if (!assetGroupSelection) {
        return;
    }
    const orderData: BookingCreationSelection = yield select(Selector.BookingCreation.orderData);
    let fromDate;
    if (orderData.time) {
        fromDate = moment(`${orderData.date!.format('YYYY-MM-DD')} ${orderData.time}`, 'YYYY-MM-DD HH:mm');
    } else {
        fromDate = moment(orderData.date!).startOf('day');
    }
    let toDate = fromDate.clone().add(orderData.duration, 'minutes');        
    if (!toDate.isSame(fromDate, 'day') && tenantConfig.tenant === 'segmenta') {
        const days = Math.ceil(orderData.duration / 1440); // 1440 minutes in a day
        toDate = fromDate.clone().add(days, 'days').endOf('day');
    }

    const newReservation: Array<CreateNewReservationData> = [{
        groupId: assetGroupSelection,
        amount: orderData.amount,
        fromDate: fromDate.toISOString(),
        toDate: orderData.time !== '00:00' ? toDate.toISOString() : toDate.subtract(1, 'days').toISOString(),
        isFullDay: tenantConfig.tenant === 'segmenta' ? true : false,
    }];
    try {
        const reservation: Reservation = yield call(Api.createNewReservation, newReservation);
        yield put(Event.BookingCreation.reservationCreated({ reservation }));
    } catch (error) {
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);
        yield fork(onEnqueueErrorSnackbar, getI18n().t('book_location.error.create_reservation'));
    }

    yield fork(trackGTMAddToCart);
};

export function* createNewReservationFlow() {
    while (true) {
        yield take(Command.BookingCreation.createNewReservation.toString());
        yield fork(onCreateNewReservation);
    }
}
