import React, {useEffect} from 'react';
import {Box} from '@material-ui/core';
import { i18n } from '../../../../i18n/i18n';
import {makeStyles} from '@material-ui/core/styles';
import {useDispatch, useSelector} from 'react-redux';
import Selector from '../../../../selector';
import Event from '../../../../action/event';
import Command from '../../../../action/command';
import {useTranslation} from 'react-i18next';

declare global {
    interface Window {
        VippsCheckout: (options: {
            checkoutFrontendUrl: string;
            iFrameContainerId: string;
            language: string;
            token: string;
        }) => void;
    }
}

const VIPPS_CHECKOUT_FRAME_CONTAINER_ID = 'vipps-checkout-frame-container';

const getCurrentVippsLocale = () => {
    switch (i18n.language) {
        case 'nb': return 'no';
        default: return 'en';
    }
};

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        boxSizing: 'border-box',
        paddingTop: theme.spacing(2),
    },
}));

const VippsPayment = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const vippsPayment = useSelector(Selector.BookingCreation.vippsPayment);

    useEffect(() => {
        if (!window.VippsCheckout || !vippsPayment.checkoutFrontendUrl || !vippsPayment.token) {
            dispatch(Event.BookingCreation.paymentInitializationFailed({}));
            dispatch(Command.Notification.enqueueNotification({
                severity: 'error',
                message: t('book_location.error.payment_initialization'),
            }));
            return;
        }

        window.VippsCheckout({
            checkoutFrontendUrl: vippsPayment.checkoutFrontendUrl!,
            iFrameContainerId: VIPPS_CHECKOUT_FRAME_CONTAINER_ID,
            language: getCurrentVippsLocale(),
            token: vippsPayment.token!,
        });
    }, []);

    return (
        <Box className={classes.root}>
            <section id={VIPPS_CHECKOUT_FRAME_CONTAINER_ID}>
            </section>
        </Box>
    );
};

export default VippsPayment;
