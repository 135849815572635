
/* eslint-disable */

export const TermsOfUse = {

de: `
# Nutzungsbedingungen

Die Regeln für die Nutzung der Schließfächer sind in der segmenta-Wissensdatenbank https://segmenta365.sharepoint.com/sites/Wissensdatenbank/SitePages/Storage.aspx gespeichert.
​
`,



en: `
# Terms of Use

The rules for using the lockers are stored in the segmenta knowledge database https://segmenta365.sharepoint.com/sites/Wissensdatenbank/SitePages/Storage.aspx.
`,
};
