import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Command from '../../action/command';
import { signIn } from '../../util/cognitoAuthService';
import RegistrationInput from './components/RegistrationInput';
import RegistrationLogo from './components/RegistrationLogo';
import Selector from '../../selector';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        height: '100%',
        padding: theme.spacing(0.5),
        paddingTop: theme.spacing(2),
    },
    info1: {
        textAlign: 'center',
        fontFamily: 'Source Sans 3', // Changed to the specified font family
        fontSize: '20px', // Changed to the specified font size
        fontStyle: 'normal', // Changed to the specified font style
        fontWeight: 700, // Changed to the specified font weight
        lineHeight: 'normal',
    },
    form: {
        paddingBottom: theme.spacing(5),
        margin: 'auto',
    },
    button: {
        height: '54px',
        width: '380px',
        borderRadius: '4px',
    },
    link: {
        color: 'orange',
    },

}));

const Login = () => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const firstScreen = useSelector(Selector.Config.firstScreenDisplay);
    
    const handleSignIn = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        try {
            const session = await signIn(email, password);
            dispatch(Command.Notification.enqueueNotification({
                severity: 'success',
                message: t('Sign in successful'),
            }));
            if (session && typeof session.AccessToken !== 'undefined') {
                sessionStorage.setItem('accessToken', session.AccessToken);
                window.location.href = firstScreen;
            } else {
                dispatch(Command.Notification.enqueueNotification({
                    severity: 'warning',
                    message: t('SignIn session or AccessToken is undefined.'),
                }));
            }
        } catch (error) {
            dispatch(Command.Notification.enqueueNotification({
                severity: 'error',
                message: t(`Sign in failed: ${error}`),
            }));
        }
    };

    return (
        <Box className={classes.root}>
            <Typography className={classes.info1}>
                {t('authentication.login')}
            </Typography>
            <RegistrationLogo />
            <Grid item={true}>
                <Typography variant="body1">Welcome to kolula hub!</Typography>
            </Grid>
            <form className={classes.form} onSubmit={handleSignIn}>
                <Grid container={true} spacing={2}>
                    <RegistrationInput
                        label="Email"
                        name="email"
                        type="email"
                        value={email}
                        placeholder="Please enter your Email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <RegistrationInput
                        label="Password"
                        name="password"
                        type="password"
                        value={password}
                        placeholder="Please enter your Password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Grid item={true} xs={12}>
                        <Button type="submit" variant="contained" className={classes.button} color="primary">
                            Login
                        </Button>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Link className={classes.link} to="/reset-password">Forgotten Password?</Link>
                    </Grid>
                </Grid>
            </form>
            <Grid item={true} xs={12}>
                <Link to="/register">
                    <Button 
                        type="submit" 
                        variant="contained" 
                        size={'small'}
                        className={classes.button}
                    >
                        Create new account
                    </Button>
                </Link>
            </Grid>
        </Box>
    );
};

export default Login;
