import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TodayIcon from '@material-ui/icons/TodayOutlined';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    textColumn: {
        paddingRight: '5px',
    },
    iconColumn: {
        display: 'flex',
        alignItems: 'center',
    },
    infoPanel: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: '27px',
        paddingBottom: '20px',
    },
    icon: {
        paddingRight: '5px',
        color: 'white',
    },
}));

interface RentalTimeProps {
    startDateTime: Date;
    endDateTime: Date | null;
    preparationTime: number;
}

const RentalTime = (props: RentalTimeProps) => {
    const classes = useStyles();
    const {t} = useTranslation();

    // const pickUpDateTime = moment(props.startDateTime).subtract(props.preparationTime, 'minutes');
    // const duration = props.endDateTime
    //     ? moment.duration(moment(props.endDateTime).diff(moment(props.startDateTime))).asHours()
    //     : 0;

    // TODO AWO: implement translataion
    return (
        <Box className={classes.infoPanel}>
            <Box className={classes.textColumn}>
                <Typography>
                    <TodayIcon fontSize={'small'} className={classes.icon} />
                    {t('orders.start')}: {
                        moment(props.startDateTime).format(`${t('formats.date')} ${t('formats.time')
                        }`)}
                </Typography>
            </Box>
            <Box className={classes.textColumn}>
                <Typography>
                    <TodayIcon fontSize={'small'} className={classes.icon} />
                    {t('orders.end')}: {moment(props.endDateTime).format(`${t('formats.date')} ${t('formats.time')}`)}
                </Typography>
            </Box>
        </Box>
    );
};

export default RentalTime;
