import React from 'react';
import Assets from '../../../assets/assets';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    logo: {
        width: '100px',
        [theme.breakpoints.up('lg')]: {
            width: '150px',
        },
    },
    center: {
        margin: '0 auto',
    },
}));

const KolulaLogo = Assets.logo.kolula.component;

interface LogoProps {
    center?: boolean;
}

const OldLogo = (props: LogoProps) => {
    const classes = useStyles();

    return (
        <Box className={props.center ? `${classes.logo} ${classes.center}` : classes.logo}>
            <KolulaLogo />
        </Box>
    );
};

export default OldLogo;
