import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import RegistrationLogo from './components/RegistrationLogo';
import { FaApple, FaFacebook } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        overflow: 'auto',
        textAlign: 'center',
        padding: theme.spacing(0.5),
        paddingTop: theme.spacing(2),
    },
    info1: {
        textAlign: 'center',
        fontFamily: 'Source Sans 3', // Changed to the specified font family
        fontSize: '20px', // Changed to the specified font size
        fontStyle: 'normal', // Changed to the specified font style
        fontWeight: 700, // Changed to the specified font weight
        lineHeight: 'normal',
    },
    form: {
        margin: 'auto',
    },
    button: {
        height: '54px',
        width: '380px',
        borderRadius: '4px',
    },
    link: {
        color: 'orange',
    },
}));

const RegistrationByMeans = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    return (
        <Box className={classes.root}>
            <Typography className={classes.info1}>
                {t('authentication.register')}
            </Typography>
            <RegistrationLogo />
            <Grid item={true}>
                <Typography variant="body1">Create your account to get started!</Typography>
            </Grid>
            <form className={classes.form}>
                <Grid container={true} spacing={5}>
                    <Grid item={true} xs={12}>
                        <Link to="/viaMail">
                            <Button type="submit" variant="contained" color="primary" className={classes.button}>
                                Continue with email
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Button type="submit" variant="contained" className={classes.button}>
                            <FaApple />
                        </Button>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Button type="submit" variant="contained" className={classes.button}>
                            <FcGoogle />
                        </Button>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Button type="submit" variant="contained" className={classes.button}>
                            <FaFacebook />
                        </Button>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <Button type="submit" variant="contained" className={classes.button}>

                        </Button>
                    </Grid>
                </Grid>
                <Typography variant="body1">
                    Already have an account?
                    <Link to="/login" className={classes.link}>Login</Link>
                </Typography>
                <Grid item={true} xs={12}>
                    <Typography
                        variant="body1"
                        style={
                            {
                                color: '#797979', textAlign: 'center',
                                fontFamily: 'Source Sans 3', fontSize: '12px',
                                fontStyle: 'normal', fontWeight: 400, lineHeight: '20px',
                            }
                        }
                    >
                        If you are creating a new account,
                        <Typography
                            variant="body1"
                            style={
                                {
                                    color: '#797979', textAlign: 'center',
                                    fontFamily: 'Source Sans 3', fontSize: '12px',
                                    fontStyle: 'normal', fontWeight: 400, lineHeight: '20px',
                                }
                            }
                        >
                            Terms & Conditions and Privacy Policy will apply.
                        </Typography>
                    </Typography>
                </Grid>
            </form>
        </Box>
    );
};

export default RegistrationByMeans;
