import React, { useState } from 'react';
import { Box, IconButton, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Routes from '../../routes';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import LanguageSwitcher from './components/LanguageSwitcher';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SettingsMenu from './components/SettingsMenu';
import { handleLogout } from '../../authentification/AuthUtil';
import { useMsal } from '@azure/msal-react';
import TenantLogo from '../layout/components/TenantLogo';
import { useSelector } from 'react-redux';
import Selector from '../../selector';


const useStyles = makeStyles(theme => ({
    root: {
        padding: '16px',
        color: 'white',
        backgroundColor: theme.palette.background.default,
        height: '100%',
        boxSizing: 'border-box',
        overflow: 'auto',
    },
    header: {
        fontWeight: 'normal',
        marginTop: theme.spacing(4),
        display: 'grid',
        justifyItems: 'center',
        marginBottom: theme.spacing(6),
        fontSize: '22px',
    },
    hintPageBoxOne: {
        marginTop: theme.spacing(4),
        display: 'grid',
        justifyItems: 'center',
        // marginBottom: theme.spacing(4),
    },
    hintPageBoxTwo: {
        display: 'grid',
        justifyItems: 'center',
    },
    logoutBox: {
        display: 'grid',
        justifyItems: 'center',
    },
    link: {
        fontSize: '18px',
        fontWeight: 'normal',
        color: 'white',
        textTransform: 'none',
    },
    settingsButtons: {
        position: 'absolute',
        top: 0,
        right: 0,
        color: 'white',
    },
    button: {
        // height: '54px',
        // width: '380px',
        borderRadius: theme.shape.borderRadius,
        color: 'black',
        marginTop: '10px',
        margin: '0 auto',
        width: '90%',
        maxWidth: 800,
        padding: '12px 16px',
        boxSizing: 'border-box',
        textTransform: 'none',
        fontSize: '20px',
        fontWeight: 'normal',
    },
    logo: {
        width: '200px',
        [theme.breakpoints.up('lg')]: {
            width: '300px',
        },
        margin: '0 auto',
    },
}));

const TermsOfServicePage = () => {
    const classes = useStyles();
    const { i18n, t } = useTranslation();
    const history = useHistory();
    const [settingsMenuAnchor, setSettingsMenuAnchor] = useState<HTMLButtonElement | undefined>(undefined);
    const tenantConfig = useSelector(Selector.Config.config);

    const openSettingsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setSettingsMenuAnchor(event.currentTarget);
    };

    const { instance } = useMsal();

    const handleLogoutClick = (instance: any) => {
        handleLogout(tenantConfig.authentication, instance);
        history.go(0);
    };

    // Retrieve Hints and Legal Hints Page components
    const hintPages = tenantConfig?.HintPageComponents.hintPages;
    const legalPages = tenantConfig?.HintPageComponents.legalPages;

    // Render links to hint pages
    const renderHintLinks = (pages: { [key: string]: any }) => {
        return Object.keys(pages).map((key) => (
            pages[key].type === 'link'
                ?
                <a
                    key={key}
                    style={{ marginBottom: '11px', textDecoration: 'none' }}
                    href={(pages[key].content as any)?.[i18n.language]?.content}
                    target="_blank"
                    rel="noreferrer"
                >
                    <Typography className={classes.link}>
                        {(pages[key].content as any)?.[i18n.language]?.title}
                    </Typography>
                </a>

                :
                <Link
                    key={key}
                    style={{ marginBottom: '11px' }}
                    component={RouterLink}
                    to={Routes.makeHintsPath(key)}
                >
                    <Typography className={classes.link}>
                        {
                            (pages[key].content as any)?.[i18n.language]?.title
                        }
                    </Typography>
                </Link>
        ));
    };

    return (
        <Box className={classes.root}>
            <TenantLogo type='default' size='large' className={classes.logo} />

            {/* Temporarily disabled */}
            <IconButton onClick={openSettingsMenu} className={classes.settingsButtons} style={{ display: 'none' }}>
                <MoreVertIcon />
            </IconButton>
            <SettingsMenu
                anchorEl={settingsMenuAnchor}
                open={Boolean(settingsMenuAnchor)}
                onClose={() => setSettingsMenuAnchor(undefined)}
            />

            <Typography className={classes.header}>
                Info
            </Typography>

            <Box className={classes.hintPageBoxOne}>
                {hintPages && renderHintLinks(hintPages)}
                {/* <Link style={{ margin: '11px 0' }} component={RouterLink} to={Routes.addOrderPath}>
                    <Typography className={classes.link}>
                        {t('hints.more_tickets')}
                    </Typography>
                </Link> */}
            </Box>
            <Box className={classes.hintPageBoxTwo}>
                {legalPages && renderHintLinks(legalPages)}
            </Box>
            {tenantConfig.authentication !== 'none' && (
                <Box className={classes.logoutBox}>
                    <Link
                        style={{ marginBottom: '11px' }}
                        onClick={() => handleLogoutClick(instance)}
                    >
                        <Typography className={classes.link}>
                            {
                                t('authentication.logout')
                            }
                        </Typography>
                    </Link>
                </Box>
            )}
            {tenantConfig?.languages.length! > 1 && (
                <LanguageSwitcher />
            )}
        </Box>
    );
};

export default TermsOfServicePage;
