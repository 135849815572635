import { ReduxState } from '../reducer';
import { AttemptedRentalStartInfo, RentalFinishInfo } from '../reducer/rentalReducer';
import { Rental } from '../types/rental';

const stateKey = 'rental';

const RentalSelector = {
    // orders: (state: ReduxState) => Object.values(state[stateKey].orders).sort((order1, order2) => {
    //     if (order1.items[0].fromDate.getTime() < order2.items[0].fromDate.getTime()) {
    //         return -1;
    //     } else if (order1.items[0].fromDate.getTime() > order2.items[0].fromDate.getTime()) {
    //         return 1;
    //     } else {
    //         /* Make sure to have a consistent sorting order for bookings with identical start time */
    //         if (order1.id < order2.id) return -1;
    //         else if (order1.id > order2.id) return 1;
    //         else return 0;
    //     }
    // }),
    /* eslint-disable max-len */
    // ordersLoaded: (state: ReduxState) => state[stateKey].ordersLoaded,
    startingRentalFailed: (state: ReduxState): boolean | null => state[stateKey].startingRentalFailed,
    rental: (state: ReduxState): Rental | null => state[stateKey].rental,
    fetchingRental: (state: ReduxState): boolean => state[stateKey].fetchingRental,
    startingRental: (state: ReduxState): boolean => state[stateKey].startingRental,
    doorsVisibleTimestamp: (state: ReduxState): Date | null => state[stateKey].doorsVisibleTimestamp,
    rentalFinished: (state: ReduxState): RentalFinishInfo[] => state[stateKey].rentalFinished,
    reportingDamage: (state: ReduxState): boolean => state[stateKey].reportingDamage,
    showTooFewResourcesDialog: (state: ReduxState): boolean => state[stateKey].showTooFewResourcesDialog,
    failedRentalStartInfo: (state: ReduxState): AttemptedRentalStartInfo | null => state[stateKey].attemptedRentalStartInfo,

    // makeOrder: (orderId: OrderId) => (state: ReduxState): Order|null => {
    //     return OrderSelector.orders(state).find(order => order.id === orderId) || null;
    // },
};

export default RentalSelector;
