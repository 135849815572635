import { getI18n } from 'react-i18next';
import { Action } from 'redux-actions';
import { call, fork, put, take } from 'redux-saga/effects';
import Command from '../action/command';
import Event from '../action/event';
import { FetchOrderPayload } from '../action/orderCommand';
import Api from '../api';
import {InternalOrder} from '../types/order';
import ErrorTracker from '../util/errorTracker';
import Logger from '../util/logger';
import { onEnqueueErrorSnackbar } from './enqueueSnackbarFlow';
import { onPersistOrdersByUser } from './manageOrdersFlow';

export const onFetchOrdersByUser = function* (showFinishedWarning?: boolean): any {
    yield put(Event.Order.orderFetchingBegan({}));

    try {
        // Get order by id from the API
        const internalOrders: InternalOrder[] = yield call(Api.fetchOrdersByUser);

        yield call(onPersistOrdersByUser, internalOrders);


        // TODO handle finished orders
        // if (internalOrder[0]?.rental?.finishedAt) {
        //     if (showFinishedWarning) {
        //         yield fork(onEnqueueWarningSnackbar, getI18n().t('orders.warning.load_finished_order'));
        //     }

        //     yield put(Event.Order.orderFetchingFailed({}));
        //     return;
        // }



    } catch (error) {
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);

        if (error.response && error.response.status === 403) {
            yield fork(onEnqueueErrorSnackbar, getI18n().t('orders.error.order_expired'));
        } else {
            yield fork(onEnqueueErrorSnackbar, getI18n().t('orders.error.fetch_order'));
        }

        yield put(Event.Order.orderFetchingFailed({}));
    }
};

export function* fetchOrdersByUserFlow() {
    while (true) {
        const action: Action<FetchOrderPayload> = yield take(Command.Order.fetchOrdersByUser.toString());
        yield fork(onFetchOrdersByUser, action.payload.showFinishedWarning);
    }
}
