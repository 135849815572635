import _ from 'lodash';
import moment from 'moment-timezone';
import { AssetGroup, AssetGroupAvailability } from '../types/assetGroup';
import { Reservation } from '../types/bookingCreation';
import { Location } from '../types/location';
import { InternalOrder } from '../types/order';
import { Rental } from '../types/rental';

export const processLocationsData = (data: any[]): Location[] => {
    return data
        .map(processLocationData);
};

export const processLocationData = (locationData: any): Location => {
    return {
        id: locationData.id,
        name: locationData.name,
        description: locationData.description,
        openingHours: locationData.openingHours,
        preparationTime: 0, // TODO: locationData.preparationTime,
        followUpTime: 0, // TODO: locationData.followUpTime,
        address: {
            street: locationData.address.street,
            postalCode: locationData.address.postalCode,
            city: locationData.address.city,
        },
        gmapsQuery:
            `${locationData.address.street}, ${locationData.address.postalCode} ${locationData.address.city}`
        ,
        coordinates: {
            lat: locationData.address.geo_lat,
            lon: locationData.address.geo_lng,
        },
        assetGroups: [],
        depots: locationData.depots ?? [],
        status: {
            bookable: locationData.status.bookable,
            notification: !locationData.status.notification ? undefined : {
                type: locationData.status.notification.type,
                text: locationData.status.notification.text,
            },
        },
    };
};

export const processAssetGroupsData = (assetGroupsData: any[]): AssetGroup[] => {
    return assetGroupsData.map((assetGroupData: any): AssetGroup => processAssetGroupData(assetGroupData));
};

export const processAssetGroupData = (assetGroupData: any): AssetGroup => {
    return {
        id: assetGroupData.id,
        name: assetGroupData.name,
        description: assetGroupData.description,
        availabilities: assetGroupData.availibility,
        isFullDay: assetGroupData.isFullDay,
    };
};

// unused
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function adjustAvailabilityTime(
    availability: AssetGroupAvailability,
): AssetGroupAvailability {
    if (!availability) {
        return availability;
    }

    // TODO: this does not work correctly over day boundaries
    const adjustedAvailability: AssetGroupAvailability = {};
    Object.keys(availability).forEach(date => {
        adjustedAvailability[date] = {};
        Object.keys(availability[date]).forEach(time => {
            const localTime = moment(moment(date + 'T' + time + 'Z', 'DD.MM.YYYYTHH:mmZ').toLocaleString());
            const adjustedTime = localTime.format('HH:mm');
            // ensure that the local date is the same as the date in the availability.
            // because of time zones the date can be different
            if (localTime.format('DD.MM.YYYY') === date) {
                adjustedAvailability[date][adjustedTime] = availability[date][time];
            }
        });
    });
    return adjustedAvailability;
}

export const processRentalData = (data: any): Array<Rental> => {
    const rentals = data.map((rentalData: any): Rental => ({
        id: rentalData.id,
        orderId: rentalData.orderId,
        orderItemId: rentalData.orderItemId,
        token: rentalData.token,
        isActive: rentalData.isActive,
        doors: rentalData.doors.map((doorData: any) => ({
            door: Number(doorData.door),
            depotId: doorData.depotId,
        })),
        startedAtDate: rentalData.startedAtDate ? new Date(rentalData.startedAtDate * 1000) : null,
    }));
    return rentals;
};

export const getOptionDurationInHours = (durationType: string, durationValue: string): number => {
    switch (durationType) {
        case 'hour':
            return parseFloat(durationValue);
        case 'minute':
            return parseFloat(durationValue) / 60.0;
        default:
            throw new Error(`Unknown duration type ${durationType} found`);
    }
};

/**
 * @todo fix hardcoded timezone?
 * @param data
 */
export const processReservationData = (data: any): Reservation => {
    // const hasValidDiscount = false; // Boolean(data.discountInfo.length > 0 && data.discountInfo[0].is_valid);
    const hasValidDiscount = Boolean(data.payment && data.payment.voucher);

    return {
        reservationId: data.id,
        locationId: data.locationId,
        // TODO: implement discount
        // discount: null,
        discount: hasValidDiscount ? {
            isPercentage: data.payment.voucher.isPercentage,
            amount: data.payment.voucher.discount,
            code: data.payment.voucher.code,
        } : null,
        // discount: !hasValidDiscount ? null : {
        //     code: data.discountInfo[0].code,
        //     amount: data.discountInfo[0].amount,
        // },
        bookingNumber: data.bookingNumber,
        reservationItems: data.reservationItems,
        payment: {
            totalPrice: data.payment.requested_amount,
            currency: data.payment.currency,
            state: data.payment.state,
            payment_method: data.payment.payment_method,

        },
        time_to_live: data.time_to_live,
    };
};

export const processUserOrdersData = (orderData: any): InternalOrder[] => {
    return orderData.map((order: any) => processOrderData(order));
};

export const processOrderData = (orderData: any): InternalOrder => {
    // TODO build groups of items, itemd with same location, start and end date can be grouped
    // const groupedItems = groupOrderItems(orderData);
    const locationTimeZone = orderData.kolula_location_timeZone || 'Europe/Berlin';

    return {
        // TODO: check building of the id
        // id: `${data.id}_${items.map(item => item.id).join('_')}`,
        id: orderData.id,
        bookingNumber: orderData.bookingNumber,
        locationId: orderData.locationId,
        items: orderData.items.map((item: any) => ({
            id: item.id,
            groupId: item.groupId,
            amount: item.amount,
            fromDate: moment.tz(item.fromDate, locationTimeZone).toDate(),
            toDate: moment.tz(item.toDate, locationTimeZone).toDate(),
            rental: processOrderRentalData(orderData, item.rental),
        })),
        createdBy: orderData.createdBy,
    };
};

const processOrderRentalData = (orderData: any, rentalData: any): Rental | undefined => {
    if (_.isEmpty(rentalData)) {
        return;
    }

    return {
        id: rentalData.id,
        orderId: orderData.id,
        orderItemId: rentalData.orderItemId,
        isActive: rentalData.isActive,
        token: rentalData.token, // should always be undefined!
        doors: rentalData.doors.map((doorData: any) => ({
            door: Number(doorData.door),
            depotId: doorData.depotId,
        })),
        startedAtDate: rentalData.startedDate ? moment.tz(rentalData.startedDate, 'Europe/Berlin').toDate() : null,
    };
};

/**
 * Estimates the time offset between the client and the location in milliseconds
 *
 * @param locationCurrentTime
 * 
 * @deprecated
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const calculateLocationTimeOffset = (locationCurrentTime: string): number => {
    const locationTime = moment(locationCurrentTime).toDate();
    return locationTime.getTime() - new Date().getTime() + 100;
};

/**
 * Group items by
 * - Location
 * - Date & Time
 * - Duration (if available; no problem if duration is not available since the booking can't be used before it is)
 *
 * @param data
 * 
 * @deprecated
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const groupOrderItems = (data: any): any[] => {
    const itemGroups: Record<string, any[]> = {};
    const locationId = data.locationId;
    const locationTimeZone = data.kolula_location_timeZone || 'Europe/Berlin';

    data.items.forEach((item: any) => {
        const startDateTime = moment.tz(item.fromDate, locationTimeZone);
        const endDateTime = moment.tz(item.toDate, locationTimeZone);

        const start = startDateTime.toISOString();
        const end = endDateTime?.toISOString();
        const itemGroupKey = `${locationId}_${start}_${end}`;

        if (!itemGroups[itemGroupKey]) {
            itemGroups[itemGroupKey] = [];
        }

        itemGroups[itemGroupKey].push(item);
    });

    return Object.values(itemGroups);
};
