import {
    TenantConfig as TConfig,
} from '../types/tenantConfig';

export const TenantConfig = async (): Promise<TConfig> => {
    const tenantName = process.env.REACT_APP_TENANT;
    try {
        const { Config } = await import(`../tenants/${tenantName}/config`);
        return Config;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Tenant config not found for ${tenantName}`);
        const { DefaultConfig } = await import('../util/defaultConfig');
        return DefaultConfig;
    }
};

export const getLogo = async () => {
    const tenantName = process.env.REACT_APP_TENANT;
    try {
        const {Assets} = await import(
            `../tenants/${tenantName}/assets/assets`
        );
        return Assets.logo;
    } catch (error) {
        const {Assets} = await import('../assets/assets');
        return Assets.logo.kolula ;
    }
};

export default TenantConfig;
