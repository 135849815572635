import React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Event from '../../../action/event';
import Selector from '../../../selector';
import NavigateBeforeOutlined from '@material-ui/icons/NavigateBeforeOutlined';

const useStyles = makeStyles(theme => ({
    backButton: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    invisible: {
        display: 'none',
    },
    backIcon: {
        color: 'white',
    },
    backText: {
        color: 'white',
    },
}));

interface BookLocationNavigateBackActionProps {
}

const BookLocationNavigateBackAction = (props: BookLocationNavigateBackActionProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const step = useSelector(Selector.BookingCreation.step);

    const showBackButton = step !== 0;


    const handleBackClick = () => {
        dispatch(Event.BookingCreation.navigatedToPreviousStep({}));
    };

    const className = `${classes.backButton} ${!showBackButton ? classes.invisible : ''}`;


    return (
        <>
            <IconButton className={className} onClick={handleBackClick}>
                <NavigateBeforeOutlined fontSize={'small'} className={classes.backIcon} />
                <Typography className={classes.backText}>{t('general.back')}</Typography>
            </IconButton>
        </>
    );
};

export default BookLocationNavigateBackAction;
