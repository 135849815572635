import { loginRequest } from './authConfig';

export const handleEntraIdLogin = (instance: any) => {
    instance
        .loginRedirect({
            ...loginRequest,
            prompt: 'create',
        })
        .catch((error: any) => {
            // eslint-disable-next-line no-console
            console.log(error);
        });

};

export const handleEntraIdLogout = (instance: any) => {
    instance.logoutRedirect().catch((error: any) => {
        // eslint-disable-next-line no-console
        console.log(error);
    });
};
