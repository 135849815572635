import {FormControl, OutlinedInput, Select} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        // width: `calc(50% - ${theme.spacing(2)}px)`,
        // margin: theme.spacing(1),
        paddingTop: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
    },    
    icon: {
        fill: theme.palette.primary.dark,
    },
    selectMenuPaper: {
        backgroundColor: 'rgba(255, 255, 255, 0)',
        '& .MuiSelect-select:focus': {
            width: '60%',
            backgroundColor: 'rgba(255, 255, 255, 0)',
        },
        '& .MuiSelect-select:not(:focus)': {
            backgroundColor: 'rgba(255, 255, 255, 0)',
            width: '100%',
        },
        height: '62px',
        '& .MuiInputBase-input': {
            color: theme.palette.primary.dark,
        },
    },
    menuItem: {
        color: theme.palette.background.default,
    },
}));

const useOutlinedInputStyles = makeStyles(theme => ({
    root: {
        '& $notchedOutline': {
            borderColor: theme.palette.primary.dark,
        },
        '&:hover $notchedOutline': {
            borderColor: theme.palette.primary.dark,
        },
        '&$focused $notchedOutline': {
            borderColor: theme.palette.primary.dark,
        },
        color: theme.palette.primary.dark, 
        opacity: '0.8',
    },
    focused: {},
    notchedOutline: {
        color: theme.palette.primary.dark,
        '& legend': {
            opacity: 1,
            visibility: 'visible',
            paddingBottom: '10px',
        },
    },
}));

interface BookingTimeSelectProps {
    availableSlots: string[];
    value: string;
    onChange: (value: string) => void;
    availableDurations: number[];
    disabled: boolean;
}

const BookingTimeSelect = (props: BookingTimeSelectProps) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const outlinedInputClasses = useOutlinedInputStyles();

    return (
        <FormControl className={classes.root}>
            <Select
                labelId="demo-select-label"
                id="demo-select-helper"
                native={true}
                variant='outlined'
                disabled={props.disabled}
                value={props.value}
                autoFocus={true}
                input={
                    <OutlinedInput 
                        label={t('book_location.order_data.pick_up')}
                        classes={outlinedInputClasses}
                        fullWidth={true}
                        notched={true}
                    />
                }
                fullWidth={true}
                onChange={event => props.onChange(event.target.value as string)}
                inputProps={{
                    classes: {
                        icon: classes.icon,
                    },
                }}
                className={classes.selectMenuPaper}
                children={<>
                    <option value="" className={classes.menuItem} />
                    {props.availableSlots.map(timeSlot => (
                        <option key={timeSlot} value={timeSlot} className={classes.menuItem}>
                            {timeSlot}{t('formats.time_postfix')}
                        </option>
                    ))}
                </>}
            />
        </FormControl>
    );
};

export default BookingTimeSelect;
