import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        marginTop: theme.spacing(3),
        padding: theme.spacing(2),
    },
    info1: {
        textAlign: 'center',
        marginTop: theme.spacing(3),
        color: 'white',
        fontWeight: 200,
    },
}));

const AccessDenied = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Box className={classes.root}>
            <Typography className={classes.info1}>{t('orders.no_access_allowed')}</Typography>
        </Box>
    );
};

export default AccessDenied;
