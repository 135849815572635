import moment from 'moment';
import { getI18n } from 'react-i18next';
import { Action } from 'redux-actions';
import { call, fork, put, select, take } from 'redux-saga/effects';
import Command from '../action/command';
import Event from '../action/event';
import { StartRentalItemsPayload } from '../action/rentalCommand';
import Api from '../api';
import Selector from '../selector';
import { Location } from '../types/location';
import { Order } from '../types/order';
import { Rental } from '../types/rental';
import ErrorTracker from '../util/errorTracker';
import HttpStatusCodes from '../util/httpStatusCodes';
import Logger from '../util/logger';
import { onEnqueueErrorSnackbar } from './enqueueSnackbarFlow';
import { setRentalStartedFlag } from './helpers/startedRentalsStorage';

const showAttemptToStartTooEarlyInfo = function* (order: Order, currentLocationTime: Date, preparationTime: number) {
    const startDate = moment(order.items[0].fromDate).startOf('day');
    const locationDate = moment(currentLocationTime).startOf('day');
    const isMoreThanOneDayToStart = startDate > locationDate;

    if (isMoreThanOneDayToStart) {
        const startDateTimeMoment = moment(order.items[0].fromDate);
        yield fork(onEnqueueErrorSnackbar, getI18n().t(
            'use_order.start_days_before_rental_explanation',
            {
                date: startDateTimeMoment.format(getI18n().t('formats.date')),
                time: startDateTimeMoment.format(getI18n().t('formats.time')),
                preparationTime,
            },
        ));
    } else {
        yield fork(onEnqueueErrorSnackbar, getI18n().t(
            'use_order.start_before_rental_explanation', 
            { preparationTime },
        ));
    }
};

/**
 * 
 * @param order Try to start the selected items of the order
 * @param itemIds 
 * @param resourcesCount 
 */
const startOrderItems = function* (orderId: string, itemIds: string[], resourcesCount?: number) {
    yield put(Event.Rental.rentalStartingBegan({
        orderId: orderId,
        itemIds: itemIds,
    }));

    const rental: Rental = yield call(
        Api.startOrderItems,
        orderId,
        itemIds,
        resourcesCount,
    );
    const internalRentalId = `${orderId}_${rental.id}`;
    yield put(Event.Rental.rentalStarted({ rental, startedTimestamp: rental.startedAtDate ?? new Date() }));
    yield fork(setRentalStartedFlag, internalRentalId, itemIds);
};

const handleStartOrderError = function* (error: any) {
    Logger.for('Saga').error(error);
    ErrorTracker.trackException(error);
    yield fork(onEnqueueErrorSnackbar, getI18n().t('use_order.error.start_order'));
    yield put(Event.Rental.rentalStartingFailed({}));
};

const handleStartOrderErrorWithPossibleRetry = function* (error: any, order: Order) {
    const tooFewResourcesAvailable = error?.response?.status === HttpStatusCodes.PreconditionFailed;

    if (tooFewResourcesAvailable) {
        if (error?.response?.data?.available < 1) {
            yield fork(onEnqueueErrorSnackbar, getI18n().t('use_order.no_resources_available_explanation'));
            yield put(Event.Rental.rentalStartingFailed({}));
            return;
        }

        yield put(
            Event.Rental.rentalStartWithTooFewResources({
                availableCount: error?.response?.data?.available || 0,
                bookedCount: error?.response?.data?.totalNeeded || 0,
            }));
        return;
    }

    yield call(handleStartOrderError, error);
};

const onStartOrderItems = function* (orderId: string, itemIds: string[], resourcesCount?: number) {
    const order: Order | null = yield select(Selector.Order.makeOrder(orderId));
    const location: Location | null = yield select(Selector.Location.makeLocation(order?.locationId ?? ''));
    if (!order || !location) {
        // eslint-disable-next-line no-console
        console.error('Order or location not found');
        // eslint-disable-next-line no-console
        console.error('OrderId:', orderId);
        // eslint-disable-next-line no-console
        console.error('Location:', location);
        return;
    }

    const currentLocationTime = new Date();
    const minutesToStart = (order.items[0].fromDate.getTime() - currentLocationTime.getTime()) / 60000;
    const minutesToEnd = (order.items[0].toDate.getTime() - currentLocationTime.getTime()) / 60000;

    // @todo this should trigger navigation back to order list

    if (minutesToStart > location.preparationTime) {
        yield call(showAttemptToStartTooEarlyInfo, order, currentLocationTime, location.preparationTime);
        return;
    }

    if (minutesToEnd < -location.followUpTime) {
        yield fork(onEnqueueErrorSnackbar, getI18n().t(
            'use_order.start_after_rental_explanation', 
            { followUpTime: location.followUpTime },
        ));
        return;
    }

    try {
        yield call(startOrderItems, order.id, itemIds, resourcesCount);
    } catch (error) {
        yield call(handleStartOrderErrorWithPossibleRetry, error, order);
    }
};

export function* startOrderItemsFlow() {
    while (true) {
        const action: Action<StartRentalItemsPayload> = yield take(Command.Rental.startRentalItems.toString());
        yield fork(onStartOrderItems, action.payload.orderId, action.payload.itemIds, action.payload.resourcesCount);
    }
}
