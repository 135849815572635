import { createAction } from 'redux-actions';
import { OrderId } from '../types/order';
import { Rental } from '../types/rental';

export interface FetchRentalPayload {
    rentalId: string;
    showFinishedWarning?: boolean;
}

export interface LoadRentalsPayload {
}

export interface StartRentalItemsPayload {
    orderId: OrderId;
    itemIds: string[];
    resourcesCount?: number;
}

export interface OpenDoorPayload {
    depotId: string;
    door: number;
    orderId?: string;
}

export interface ReportDamagePayload {
    message: string;
    assets: OpenDoorPayload[];
    orderId?: string;
}

export interface FinishRentalPayload {
    rental: Rental;
}

export interface VerifyIndividualRentalPayload {
    locationId: string;
    door: number;
    password: string;
}

export interface OpenIndividualRentalDoorPayload {
}

const RentalCommand = {
    fetchRental: createAction<FetchRentalPayload>('@@KL_CMD/FETCH_RENTAL'),
    loadRentals: createAction<LoadRentalsPayload>('@@KL_CMD/LOAD_RENTALS'),
    startRentalItems: createAction<StartRentalItemsPayload>('@@KL_CMD/START_RENTAL_ITEMS'),
    openDoor: createAction<OpenDoorPayload>('@@KL_CMD/OPEN_DOOR'),
    reportDamage: createAction<ReportDamagePayload>('@@KL_CMD/REPORT_DAMAGE'),
    finishRental: createAction<FinishRentalPayload>('@@KL_CMD/FINISH_RENTAL'),
    verifyIndividualRental: createAction<VerifyIndividualRentalPayload>('@@KL_CMD/VERIFY_INDIVIDUAL_RENTAL'),
    openIndividualRentalDoor: createAction<OpenIndividualRentalDoorPayload>('@@KL_CMD/OPEN_INDIVIDUAL_RENTAL_DOOR'),
};

export default RentalCommand;
