import { getI18n } from 'react-i18next';
import { Action } from 'redux-actions';
import { call, fork, put, take } from 'redux-saga/effects';
import Command from '../action/command';
import Event from '../action/event';
import { FetchLocationByIdPayload } from '../action/locationCommand';
import Api from '../api';
import ErrorTracker from '../util/errorTracker';
import Logger from '../util/logger';
import { onEnqueueErrorSnackbar } from './enqueueSnackbarFlow';
import { Location } from '../types/location';

export const onFetchLocationById = function*(locationId: string) {
    try {
        const location: Location = yield call(Api.fetchLocationById, locationId);
        yield put(Event.Location.locationByIdFetched({ location }));
    } catch (error) {
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);
        yield fork(onEnqueueErrorSnackbar, getI18n().t('locations.error.fetch_location_by_id'));
    }
};

export function* fetchLocationByIdFlow() {
    while (true) {
        const action: Action<FetchLocationByIdPayload> = yield take(Command.Location.fetchLocationById.toString());
        yield fork(onFetchLocationById, action.payload.locationId);
    }
}
