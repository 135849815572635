import React from 'react';
import {Box, IconButton, Link} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import Routes from '../../../routes';
import {OrderId} from '../../../types/order';
import {Link as RouterLink} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Selector from '../../../selector';
import Assets from '../../../assets/assets';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '350px',
        height: '305px',
        margin: '0 auto',
        padding: theme.spacing(2),
        paddingTop: theme.spacing(1.5),
        boxSizing: 'border-box',
        borderRadius: '2px',
        position: 'relative',
        boxShadow: '0px 0px 0px 3px rgba(255,255,255,1)',
    },
    infoBoxItem: {
        borderBottom: '1px solid black',
        borderBottomColor: 'gray',
        marginBottom: '15px',
    },
    infoBoxCloseButton: {
        color: theme.palette.background.default,
        position: 'absolute',
        top: 8,
        right: 10,
    },
    infoBoxInformation: {
        // fontWeight: 'bold',
        color: theme.palette.background.default,
        fontSize: '15px',
        marginBottom: '15px',
    },
    infoBoxMainTitle: {
        color: theme.palette.background.default,
        marginBottom: '20px',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    infoBoxSmallTitle: {
        color: theme.palette.background.default,
        marginBottom: '2px',
        fontSize: '15px',
        fontWeight: 'bold',
    },
}));

interface KoluluBoxInfoBoxProps {
    onClose: () => void;
    orderId: OrderId;
}

const KoluluBoxInfoBox = (props: KoluluBoxInfoBoxProps) => {
    const tenantName = process.env.REACT_APP_TENANT; 
    const CloseIcon = Assets.icon.closeIcon.component;
    const classes = useStyles();
    const {t} = useTranslation();
    const tenantConfig = useSelector(Selector.Config.config);

    return (
        <Box className={classes.root}>
            <IconButton size="small" className={classes.infoBoxCloseButton} onClick={props.onClose}>
                <CloseIcon />
            </IconButton>
            <Box className={classes.infoBoxItem}>
                <Typography className={classes.infoBoxMainTitle}>
                    {t('use_order.kolula_box.title')}
                </Typography>
            </Box>
            <Box className={classes.infoBoxItem}>
                <Link href={'tel:' + tenantConfig?.contact.phone} color={'inherit'}>
                    <Typography className={classes.infoBoxSmallTitle}>
                        {t('use_order.kolula_box.call_us')}
                    </Typography>
                    <Typography className={classes.infoBoxInformation}>
                        {tenantConfig?.contact.phoneFormatted}
                    </Typography>
                </Link>
            </Box>
            <Box className={classes.infoBoxItem}>
                <Link 
                    component={RouterLink} 
                    to={
                        tenantName === 'segmenta'
                            ? Routes.makeReportIssueUrl(props.orderId) 
                            : Routes.makeReportDamageUrl(props.orderId)
                    }
                    color={'inherit'}
                >
                    
                    <Typography className={classes.infoBoxSmallTitle}>
                        {t('use_order.kolula_box.report_damage')}
                    </Typography>
                    <Typography className={classes.infoBoxInformation}>
                        {t('use_order.kolula_box.use_our_form')}
                    </Typography>
                </Link>
            </Box>
            <Box className={classes.infoBoxItem}>
                <Link component={RouterLink} to={Routes.faqsPath} color={'inherit'}>
                    <Typography className={classes.infoBoxSmallTitle}>
                        {t('use_order.kolula_box.hints_and_tips')}
                    </Typography>
                    <Typography className={classes.infoBoxInformation}>
                        {t('use_order.kolula_box.look_at_our_faqs')}
                    </Typography>
                </Link>
            </Box>
        </Box>
    );
};

export default KoluluBoxInfoBox;
