import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { i18n } from '../i18n/i18n';
import { AssetGroup } from '../types/assetGroup';
import {
    BraintreePaymentInformation,
    Reservation,
    StripePaymentInformation,
    VippsPaymentInformation,
} from '../types/bookingCreation';
import { Location } from '../types/location';
import { InternalOrder, InternalOrderId, OrderId } from '../types/order';
import { Rental } from '../types/rental';
import DefaultConfig from '../util/defaultConfig';
import Logger from '../util/logger';
import {
    processAssetGroupData,
    processAssetGroupsData,
    processLocationData,
    processLocationsData,
    processOrderData,
    processRentalData,
    processReservationData,
    processUserOrdersData,
} from './processors';
import {
    CompleteBraintreePaymentData,
    CompleteStripePaymentData,
    CompleteVippsPaymentData,
    CreateNewReservationData,
    InitializePaymentData,
    InitializeStripePaymentData,
    InitializeVippsPaymentData,
} from './types';
import { getAuthToken, getAuthType, getGroupsId, getIdToken, isAuthenticated } from '../authentification/Authorizer';
import { OpenDoorPayload } from '../action/rentalCommand';

/* eslint-disable */
const axiosInstance = axios.create({
    baseURL: DefaultConfig.api.baseUrl,
    headers: {
        'X-Api-Key': DefaultConfig.api.key,
    },
});

const sendApiRequest = async (request: AxiosRequestConfig): Promise<AxiosResponse> => {
    try {
        axiosInstance.defaults.headers.common['X-Language'] = i18n.language;
        const isLogged = await isAuthenticated();
        // Add auth token and authtype when the user is logged in
        if (isLogged === true){
            const authType = await getAuthType();
            const authToken = await getAuthToken(authType);

            if (authToken) {
                axiosInstance.defaults.headers.common['X-Auth-Token'] = authToken;
                axiosInstance.defaults.headers.common['X-Auth-Type'] = authType;
            }
            if (authType === "entraid"){
                const idToken = await getIdToken();
                const groupsId = getGroupsId(idToken);
                if (groupsId) {
                    // Here we suppose a user can be within only one group (we retrieve first group then)
                    axiosInstance.defaults.headers.common['X-Group-Id'] = groupsId[0];
                }
            }
        }
        return await axiosInstance(request);
    } catch (error) {
        Logger.for('API').error(request.url);
        throw error;
    }
};

const Api = {
    fetchLocations: async (): Promise<Location[]> => {
        const response = await sendApiRequest({
            method: 'get',
            url: 'location'
        });

        return processLocationsData(response.data);
    },
    fetchLocationById: async (locationId: string): Promise<Location> => {
        const response = await sendApiRequest({
            method: 'get',
            url: `/location/${locationId}`
        });

        return processLocationData(response.data);
    },
    fetchLocationAssetGroups: async (locationId: string): Promise<AssetGroup[]> => {
        const response = await sendApiRequest({
            method: 'get',
            url: `/location/${locationId}/groups`
        });

        return processAssetGroupsData(response.data);
    },
    fetchLocationAssetGroupsByLocationIds: async (locationIds: string[]): Promise<AssetGroup[]> => {
        const response = await sendApiRequest({
            method: 'get',
            url: `/locations/${locationIds.toString()}/groups`
        });

        return processAssetGroupsData(response.data);
    },
    // fetchLocationAvailableAssetGroups: async (locationId: string, from: Date, to: Date): Promise<AssetGroups> => {
    //     const response = await sendApiRequest({
    //         method: 'get',
    //         url: `/location/${locationId}/groups/${from}/${to}`
    //     });

    //     return {} as AssetGroups; // processAvailableAssetGroupData(locationId, response.data);
    // },
    fetchLocationAssetGroupAvailabilities: async (assetGroupId: string, from: Date, to: Date): Promise<AssetGroup> => {
        const fromDate = from.toISOString();
        const toDate = to.toISOString();
        const response = await sendApiRequest({
            method: 'get',
            url: `/group/${assetGroupId}/availabilities/${fromDate}/${toDate}`
        });

        return processAssetGroupData(response.data);
    },
    createNewReservation: async (data: Array<CreateNewReservationData>): Promise<Reservation> => {
        const response = await sendApiRequest({
            method: 'post',
            url: '/reservation',
            data: data,
        });

        return processReservationData(response.data);
    },
    removeReservation: async (reservationId: string): Promise<void> => {
        await sendApiRequest({
            method: 'delete',
            url: `/reservation/${reservationId}`,
        });
    },
    fetchReservationTotals: async (reservationId: string, couponCode: string): Promise<Reservation> => {
        const response = await sendApiRequest({
            method: 'post',
            url: `/reservation/${reservationId}/voucher`,
            data: {
                code: couponCode,
            },
        });

        return processReservationData(response.data);
    },
    initializeBraintreePayment: async (data: InitializePaymentData): Promise<BraintreePaymentInformation> => {
        const response = await sendApiRequest({
            method: 'post',
            url: '/payments/braintree',
            data: {
                contact: {
                    firstname: data.contact.firstName,
                    lastname: data.contact.lastName,
                    email: data.contact.email,
                    telephone: '+' + data.contact.phone,
                    newsletter_consent: data.contact.newsletterConsent,
                    usc_customerId: data.contact.uscCustomerId,
                },
                reservation: {
                    number: data.reservationId,
                },
            },
        });

        return {
            paymentStatus: response.data.statusCode,
            orderId: response.data.orderId || null,
            braintreePaymentToken: response.data['braintree-payment-token'] || null,
        };
    },
    initializeVippsPayment: async (data: InitializeVippsPaymentData): Promise<VippsPaymentInformation> => {
        const response = await sendApiRequest({
            method: 'post',
            url: '/payments/vipps',
            data: {
                contact: {
                    firstname: data.contact.firstName,
                    lastname: data.contact.lastName,
                    email: data.contact.email,
                    telephone: '+' + data.contact.phone,
                    newsletter_consent: data.contact.newsletterConsent,
                    usc_customerId: data.contact.uscCustomerId,
                },
                reservation: {
                    number: data.reservationId,
                },
                returnUrl: data.returnUrl,
            },
        });

        return {
            paymentStatus: response.data.statusCode,
            orderId: response.data.orderId || null,
            token: response.data.token || null,
            checkoutFrontendUrl: response.data.checkoutFrontendUrl || null,
        };
    },
    initializeStripePayment: async (data: InitializeStripePaymentData): Promise<StripePaymentInformation> => {
        const response = await sendApiRequest({
            method: 'post',
            url: '/payment/stripe',
            data: {
                contact: {
                    firstname: data.contact.firstName,
                    lastname: data.contact.lastName,
                    email: data.contact.email,
                    telephone: '+' + data.contact.phone,
                    newsletter_consent: data.contact.newsletterConsent,
                    usc_customerId: data.contact.uscCustomerId,
                },
                reservationId: data.reservationId,
                returnUrl: data.returnUrl,
            },
        });
        return {
            paymentStatus: response.data.statusCode,
            orderId: response.data.orderId || null,
            token: response.data.token || null,
            checkoutFrontendUrl: response.data.checkoutFrontendUrl || null,
        };
    },
    createOrderWithoutPayment: async (data: InitializeStripePaymentData): Promise<StripePaymentInformation> => {
        const response = await sendApiRequest({
            method: 'post',
            url: '/payment/no_payment',
            data: {
                contact: {
                    firstname: data.contact.firstName,
                    lastname: data.contact.lastName,
                    email: data.contact.email,
                    telephone: '+' + data.contact.phone,
                    newsletter_consent: data.contact.newsletterConsent,
                },
                reservationId: data.reservationId,
                returnUrl: '', // required in request but not used
            },
        });
        return {
            paymentStatus: response.data.statusCode,
            orderId: response.data.orderId || null,
            token: response.data.token || null,
            checkoutFrontendUrl: response.data.checkoutFrontendUrl || null,
        };
    },
    completeBraintreePayment: async (data: CompleteBraintreePaymentData): Promise<OrderId> => {
        const response = await sendApiRequest({
            method: 'post',
            url: '/payments/braintree/execute',
            data: {
                reservation: {
                    number: data.reservationId,
                },
                paymentNonce: data.braintreeNonce,
                deviceData: data.braintreeDeviceData,
            },
        });

        return response.data.orderId;
    },
    completeVippsPayment: async (data: CompleteVippsPaymentData): Promise<any> => {
        const response = await sendApiRequest({
            method: 'post',
            url: '/payments/vipps/execute',
            data: {
                reservation: {
                    number: data.reservationId,
                },
            },
        });

        return response.data.orderId;
    },
    completeStripePayment: async (data: CompleteStripePaymentData): Promise<any> => {
        const response = await sendApiRequest({
            method: 'post',
            url: '/payments/stripe/execute',
            data: {
                reservation: {
                    number: data.reservationId,
                },
            },
        });

        return response.data.orderId;
    },
    fetchOrder: async (internalOrderId: InternalOrderId, createdBy: 'user' | 'admin' | 'any', email?: string): Promise<InternalOrder> => {
        let response;
        if (email) {
            response = await sendApiRequest({
                method: 'get',
                url: `/order/${internalOrderId}?createdBy=${createdBy}&userEmail=${email}`,
            });
        } else {
            response = await sendApiRequest({
                method: 'get',
                url: `/order/${internalOrderId}?createdBy=${createdBy}`,
            });
        }

        return processOrderData(response.data);
    },
    fetchOrdersByUser: async (): Promise<InternalOrder[]> => {
        const response = await sendApiRequest({
            method: 'get',
            url: `/user/orders`
        });
        return processUserOrdersData(response.data);
    },
    startOrderItems: async (orderId: OrderId, items: string[], resourcesCount?: number): Promise<Rental> => {
        // TODO: handle start of partial items like 2 of 3 items, when only 2 items are available
        const response = await sendApiRequest({
            method: 'post',
            url: `/rental/start`,
            data: {
                orderId: orderId,
                orderItems: items,
                resourcesCount: resourcesCount || undefined,
            },
        });
        // TODO: handle multiple rentals
        return processRentalData(response.data)[0];
    },
    finishRental: async (orderId: string, orderItems: string[], token: string): Promise<void> => {
        await sendApiRequest({
            method: 'post',
            url: `/rental/finish/${orderId}`,
            data: {
                orderId: orderId,
                orderItems: orderItems,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    },
    openDoor: async (depotId: string, door: number, token: string): Promise<void> => {
        await sendApiRequest({
            method: 'put',
            url: `/depot/${depotId}/doors/${door}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
    },
    reportDamage: async (message: string, assets: OpenDoorPayload[], token: string): Promise<void> => {
        await sendApiRequest({
            method: 'post',
            url: '/damage-reports',
            headers: {
                Authorization: `Bearer ${token}`
            },
            data: {
                message,
                assets,
            },
        })
    },
};

export default Api;
