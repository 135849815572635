import { getI18n } from 'react-i18next';
import { Action } from 'redux-actions';
import { call, fork, put, select, take } from 'redux-saga/effects';
import Command from '../action/command';
import Event from '../action/event';
import { OpenDoorPayload, ReportDamagePayload } from '../action/rentalCommand';
import Api from '../api';
import Selector from '../selector';
import { Rental } from '../types/rental';
import ErrorTracker from '../util/errorTracker';
import Logger from '../util/logger';
import { onEnqueueErrorSnackbar, onEnqueueSuccessSnackbar } from './enqueueSnackbarFlow';
import { Order } from '../types/order';

const onReportDamage = function* (message: string, assets: OpenDoorPayload[], orderId: string | undefined) {
    const order: Order | null = yield select(Selector.Order.makeOrder(orderId ?? ''));

    let rental: Rental | undefined;
    if (order) {
        rental = order?.items[0]?.rental;
    }
    if (!rental) {
        yield fork(onEnqueueErrorSnackbar, getI18n().t('report_damage.error.report_damage'));
        return;
    }

    yield put(Event.Rental.reportingDamageBegan({}));

    try {
        yield call(Api.reportDamage, message, assets, rental.token);
        yield fork(onEnqueueSuccessSnackbar, getI18n().t('report_damage.success.report_damage'), 12000);
        yield put(Event.Rental.damageReported({}));
        return;
    } catch (error) {
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);
        yield fork(onEnqueueErrorSnackbar, getI18n().t('report_damage.error.report_damage'));
    }

    yield put(Event.Rental.reportingDamageFailed({}));
};

export function* reportDamageFlow() {
    while (true) {
        const action: Action<ReportDamagePayload> = yield take(Command.Rental.reportDamage.toString());
        yield fork(onReportDamage, action.payload.message, action.payload.assets, action.payload.orderId);
    }
}
