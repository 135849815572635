import React, {Suspense, useEffect} from 'react';
import StripePayment from './PaymentStep/StripePayment';
import VippsPayment from './PaymentStep/VippsPayment';
import {useDispatch, useSelector} from 'react-redux';
import Selector from '../../../selector';
import InitializationErrorInfo from './PaymentStep/BraintreePayment/InitializationErrorInfo';
import Routes from '../../../routes';
import Event from '../../../action/event';
import {useHistory} from 'react-router';
import LoaderOverlay from '../../layout/components/LoaderOverlay';

const BraintreePayment = React.lazy(
    () => import('./PaymentStep/BraintreePayment' /* webpackPrefetch: true, webpackChunkName: "braintree" */),
);

interface PaymentStepProps {
}

const PaymentStep = (props: PaymentStepProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const paymentProvider = useSelector(Selector.BookingCreation.paymentProvider);
    const paymentInitFailed = useSelector(Selector.BookingCreation.paymentInitFailed);
    const braintreePayment = useSelector(Selector.BookingCreation.braintreePayment);
    const vippsPayment = useSelector(Selector.BookingCreation.vippsPayment);
    const stripePayment = useSelector(Selector.BookingCreation.stripePayment);
    const orderId = useSelector(Selector.BookingCreation.orderId);

    useEffect(() => {
        if (orderId) {
            history.push(Routes.ordersPath);
            setTimeout(() => dispatch(Event.BookingCreation.bookingCreationCompleted({})), 1000);
        }
    }, [orderId]);

    if (paymentProvider !== 'no_payment' && paymentInitFailed) {
        const showTryAgain = Boolean(
            (paymentProvider === 'braintree' && braintreePayment.paymentToken) ||
            (paymentProvider === 'vipps' && vippsPayment.token && vippsPayment.checkoutFrontendUrl) ||
            (paymentProvider === 'stripe' && stripePayment.token && stripePayment.checkoutFrontendUrl),
        );

        return (
            <InitializationErrorInfo
                showTryAgain={showTryAgain}
                onBackClick={() => history.push(Routes.locationsPath)}
                onTryAgainClick={() => dispatch(Event.BookingCreation.paymentInitializationRetryStarted({}))}
            />
        );
    }

    if (!paymentProvider) {
        return <LoaderOverlay open={true} />;
    } else if (paymentProvider === 'no_payment') {

    } else if (paymentProvider === 'vipps') {
        return <VippsPayment />;
    } else if (paymentProvider === 'stripe') {
        return <StripePayment />;
    } else if (paymentProvider === 'braintree') {
        return (
            <Suspense fallback={<LoaderOverlay open={true} />}>
                <BraintreePayment />
            </Suspense>
        );
    }

    return <LoaderOverlay open={true} />;
};

export default PaymentStep;
