import { createAction } from 'redux-actions';
import { InternalOrder, Order } from '../types/order';

export interface OrderFetchingBeganPayload {
}

export interface OrderFetchedPayload {
    internalOrder: InternalOrder;
}

export interface OrderFetchingFailedPayload {
}

export interface OrdersLoadedPayload {
    orders: Order[];
}

export interface OrderFetchingEmptyPayload {
}

/* eslint-disable max-len */
const OrderEvent = {
    orderFetchingBegan: createAction<OrderFetchingBeganPayload>('@@KL_EVT/ORDER_FETCHING_BEGAN'),
    orderFetched: createAction<OrderFetchedPayload>('@@KL_EVT/ORDER_FETCHED'),
    orderFetchingFailed: createAction<OrderFetchingFailedPayload>('@@KL_EVT/ORDER_FETCHING_FAILED'),
    ordersLoaded: createAction<OrdersLoadedPayload>('@@KL_EVT/ORDERS_LOADED'),
    noOrdersByUser: createAction<OrderFetchingEmptyPayload>('@@KL_EVT/ORDER_EMPTY_FETCHING'),
};

export default OrderEvent;
