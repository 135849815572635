import {fork, put, take} from 'redux-saga/effects';
import Command from '../action/command';
import Event from '../action/event';
import {Action} from 'redux-actions';
import {BookingStepChangedPayload} from '../action/bookingCreationEvent';

export const onUpdateBookingStep = function* (step: number, nextEnabled: boolean) {
    yield put(Event.BookingCreation.bookingStepChanged({
        step,
        nextEnabled,
    }));
};

/* eslint-disable max-len */
export function* manageBookingStepChangeFlow() {
    while (true) {
        const action: Action<BookingStepChangedPayload> = yield take(Command.BookingCreation.bookingStepChanged.toString());
        yield fork(onUpdateBookingStep, action.payload.step, action.payload.nextEnabled);
    }
}
