import {Box, IconButton} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SwipeableViews from 'react-swipeable-views';
import OrderTicket from './OrderTicket';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import React, { useState } from 'react';
import {Order} from '../../../types/order';
import {makeStyles} from '@material-ui/core/styles';
import BookingTicket from '../../bookLocation/components/BookingTicket';
import moment from 'moment';
import {Location} from '../../../types/location';
import Logger from '../../../util/logger';
import KoluluBoxInfoBox from '../../useOrder/box/KolulaBoxInfoBox';

const useStyles = makeStyles(theme => ({
    root: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        overflowY: 'auto',
    },
    leftButtonWrapper: {
        display: 'none',
        width: 80,
        justifyContent: 'flex-start',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    rightButtonWrapper: {
        width: 80,
        display: 'none',
        justifyContent: 'flex-end',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    swiperButton: {
        alignSelf: 'center',
        color: 'white',
    },
    swiperButtonIcon: {
        fontSize: 50,
    },
    swipeableViews: {
        padding: '0 10px',
        width: '300px',
        overflow: 'visible',
        [theme.breakpoints.up('md')]: {
            width: '354px',
            padding: '0 100px',
        },
        '@media only screen and (max-height: 555px)': {
            // transform: 'scale(0.75)',
            transformOrigin: 'top',
            width: '260px',
        },
    },
    infoBoxWrapper: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // backgroundColor: 'rgba(0, 0, 0, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9,
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
}));

interface OrdersSwiperProps {
    orders: Order[];
    locations?: Location[];
    index: number;
    onChangeIndex: (index: number) => void;
}

const OrdersSwiper = (props: OrdersSwiperProps) => {
    const tenantName = process.env.REACT_APP_TENANT;
    const classes = useStyles();
    const [infoOpen, setInfoOpen] = useState<boolean>(false);
    const [selectedOrderId, setSelectedOrderId] = useState<string>();

    const showLeftButton = props.index !== 0;
    const showRightButton = props.index !== props.orders.length - 1;

    Logger.log('OrdersSwiper', 'props.orders', props.orders);
    
    const sortedOrders = props.orders.sort((a, b) => {
        const currentTime = new Date().getTime();
        const fromDateA = new Date(a.items[0].fromDate).getTime();
        const fromDateB = new Date(b.items[0].fromDate).getTime();
        const endDateA = new Date(a.items[0].toDate).getTime();
        const endDateB = new Date(b.items[0].toDate).getTime();
    
        const isPastEndDateA = endDateA < currentTime;
        const isPastEndDateB = endDateB < currentTime;
        const isBetweenDatesA = fromDateA <= currentTime && currentTime <= endDateA;
        const isBetweenDatesB = fromDateB <= currentTime && currentTime <= endDateB;
    
        if (isPastEndDateA && !isPastEndDateB) {
            return 1;
        }
        if (!isPastEndDateA && isPastEndDateB) {
            return -1;
        }
        if (isPastEndDateA && isPastEndDateB) {
            return fromDateA - fromDateB;
        }
    
        if (isBetweenDatesA && !isBetweenDatesB) {
            return -1;
        }
        if (!isBetweenDatesA && isBetweenDatesB) {
            return 1;
        }
        if (isBetweenDatesA && isBetweenDatesB) {
            return endDateA - endDateB;
        }
    
        const diffA = Math.abs(currentTime - fromDateA);
        const diffB = Math.abs(currentTime - fromDateB);
        return diffA - diffB;
    });
    
    

    const getGroupName = (order: Order) => {
        const location = props.locations?.find(location => location.id === order.locationId);

        const assetGroups = location?.assetGroups;
        if (assetGroups && assetGroups.length > 0) {
            const assetGroup = assetGroups.find(assetGroup => assetGroup.id === order.items[0].groupId);
            if (assetGroup) {
                return assetGroup.name;
            }
        }

        return '-';
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.leftButtonWrapper}>
                {showLeftButton && (
                    <IconButton
                        className={classes.swiperButton}
                        onClick={() => props.onChangeIndex(props.index - 1)}
                        children={<ChevronLeftIcon className={classes.swiperButtonIcon} />}
                    />
                )}
            </Box>
            <SwipeableViews
                className={classes.swipeableViews}
                resistance={true}
                enableMouseEvents={true}
                index={props.index}
                onChangeIndex={index => props.onChangeIndex(index)}
                children={sortedOrders.map((order, idx) => (
                    tenantName === 'segmenta'
                        ?
                        <BookingTicket
                            order={order}
                            size={getGroupName(order)}
                            reservationStartDate={moment(order.items[0].fromDate)}
                            reservationEndDate={moment(order.items[0].toDate)}
                            amount={order.items[0].amount}
                        />
                        :
                        <OrderTicket 
                            key={order.id} 
                            order={order} 
                            setInfoOpen={setInfoOpen} 
                            setSelectedOrderId={setSelectedOrderId} 
                        />
                ))}
            />
            <Box className={classes.rightButtonWrapper}>
                {showRightButton && (
                    <IconButton
                        className={classes.swiperButton}
                        onClick={() => props.onChangeIndex(props.index + 1)}
                        children={<ChevronRightIcon className={classes.swiperButtonIcon} />}
                    />
                )}
            </Box>
            {infoOpen && selectedOrderId && (
                <Box onClick={() => setInfoOpen(false)} className={classes.infoBoxWrapper}>
                    <KoluluBoxInfoBox onClose={() => setInfoOpen(false)} orderId={selectedOrderId!} />
                </Box>
            )}
        </Box>
    );
};

export default OrdersSwiper;
