import { getI18n } from 'react-i18next';
import { Action } from 'redux-actions';
import { call, fork, put, take } from 'redux-saga/effects';
import Command from '../action/command';
import Event from '../action/event';
import { FetchAssetGroupAvailabilitiesPayload } from '../action/locationCommand';
import Api from '../api';
import { AssetGroup } from '../types/assetGroup';
import ErrorTracker from '../util/errorTracker';
import Logger from '../util/logger';
import { onEnqueueErrorSnackbar } from './enqueueSnackbarFlow';

export const onFetchAssetGroupAvailabilities =
    function* (locationId: string, assetGroupId: string, fromDate: Date, toDate: Date) {
        try {
            const assetGroup: AssetGroup = yield call(
                Api.fetchLocationAssetGroupAvailabilities,
                assetGroupId,
                fromDate,
                toDate,
            );
            yield put(Event.Location.assetGroupAvailabilitiesFetched({ locationId, assetGroup }));
        } catch (error) {
            Logger.for('Saga').error(error);
            ErrorTracker.trackException(error);
            yield fork(onEnqueueErrorSnackbar, getI18n().t('assetGroups.error.fetch_assetGroups'));
        }
    };

/* eslint-disable max-len */
export function* fetchAssetGroupAvailabilitiesFlow() {
    while (true) {
        const action: Action<FetchAssetGroupAvailabilitiesPayload> = yield take(Command.Location.fetchAssetGroupAvailabilities.toString());
        yield fork(onFetchAssetGroupAvailabilities, action.payload.locationId, action.payload.assetGroupId, action.payload.fromDate, action.payload.toDate);
    }
}
