import { Box, Button, Link, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Selector from '../../selector';
import TenantLogo from '../layout/components/TenantLogo';
import CustomButton from '../../tenants/segmenta/component/CustomButton';
import Routes from '../../routes';
import Command from '../../action/command';
import Assets from '../../assets/assets';
import ConsentDialog from './components/ConsentDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        paddingTop: '16px',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.default,

    },
    header: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        display: 'grid',
        justifyItems: 'center',
        fontSize: '30px',
        textTransform: 'none',
    },
    contentWrapper: {
        height: 'calc(100% - 160px)',
        maxWidth: 1000,
        margin: '0 auto',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '10px', 
    },
    grow: {
        flexGrow: 1,
    },
    buttonWrapper: {
        height: '150px',
        alignItems: 'start',
        maxWidth: 1000,
        margin: '0 auto',
        padding: '0px 16px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
    },
    logo: {
        width: '200px',
        [theme.breakpoints.up('lg')]: {
            width: '300px',
        },
        margin: '0 auto',
    },
    button: {
        fontSize: theme.typography.button.fontSize,
        fontWeight: theme.typography.button.fontWeight,
        fontStyle: theme.typography.button.fontStyle,
        lineHeight: theme.typography.button.lineHeight,
        height: theme.typography.button.height,
        fontFamily: theme.typography.fontFamily,
        textTransform: 'none',
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.dark,
        borderRadius: theme.shape.borderRadius,
    },
}));

const ConsentPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const extremelySmallDevice = useMediaQuery('@media(max-width: 350px)');
    const tenantConfig = useSelector(Selector.Config.config);
    const history = useHistory();
    const location = useLocation();
    const privacyIcon = Assets.icon.map.privacyIcon.url;
    const [consentDialogOpen, setConsentDialogOpen] = useState<boolean>(false);

    const queryParams = new URLSearchParams(location.search);
    const redirectURL = queryParams.get('redirect') || '/';

    const onAcceptConsent = () => {
        dispatch(Command.Consent.setConsent({ consent: true }));
        history.push(redirectURL);
    };

    // const onDeclineConsent = () => {
    //     dispatch(Command.Consent.setConsent({ consent: false }));
    //     history.push('/locations');
    // };

    return (
        <Box className={classes.root}>
            <Box className={classes.contentWrapper}>
                {!extremelySmallDevice && (
                    <TenantLogo type='default' size='large' className={classes.logo} />
                )}
                <Typography className={classes.header}>
                    {t('consent.title')}
                </Typography>
                <Box 
                    style={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        marginTop: '20px', 
                        marginBottom: '30px',
                    }}
                >
                    <img src={privacyIcon} style={{ width: '150px', height: '150px' }} />
                </Box>
                {/* <Box 
                    style={{ 
                        display: 'flex', 
                        alignItems: 'center', height: '8vh', justifyContent: 'center',
                    }}
                >
                    <Typography 
                        style={{ 
                            textAlign: 'center',
                            width: '80%',
                            color: 'white',
                            fontWeight: 'bold',
                        }} 
                        variant={'h2'}
                    >
                        {t('consent.small_title')}
                    </Typography>

                </Box> */}
                <Box 
                    style={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography 
                        style={{ 
                            textAlign: 'center',
                            width: '90%',
                            color: 'white',
                            marginBottom: '10px',
                        }} 
                        variant={'h2'} 
                    >
                        {t('consent.description_first')}
                    </Typography>
                    <Typography 
                        style={{ 
                            textAlign: 'center',
                            width: '90%',
                            color: 'white',
                        }} 
                        variant={'h2'} 
                    >
                        {t('consent.description_second')}                        
                    </Typography>
                </Box>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Link
                        component={RouterLink}
                        to={Routes.consentGDPRPagePath}
                        underline='always'
                        style={{
                            textDecorationColor: 'white',
                        }}
                    >
                        <Typography style={{fontSize: '20px'}}>{t('consent.learn_more')}</Typography>
                    </Link>
                </Box>
                
            </Box>

            <Box className={classes.buttonWrapper}>
                
                <Button
                    className={classes.button}
                    variant={'outlined'}
                    fullWidth={true}
                    onClick={onAcceptConsent}
                    // color={'primary'}
                    // component={RouterLink}
                    // to={Routes.addOrderPath}
                >
                    {t('consent.allow')}
                </Button>

                {
                    tenantConfig.authentication === 'none' && (
                        <CustomButton
                            isButtonDark={true}
                            fullWidth={true}
                            onClick={() => setConsentDialogOpen(true)}
                            color={'secondary'}
                            children={t('consent.decline')}
                            style={{justifyContent: 'flex-end'}}
                        />
                    )
                }
            </Box>
            <ConsentDialog
                open={consentDialogOpen}
                onClose={() => setConsentDialogOpen(false)}
            />
        </Box>
    );
};

export default ConsentPage;
