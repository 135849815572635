import { getI18n } from 'react-i18next';
import { Action } from 'redux-actions';
import { call, fork, select, take } from 'redux-saga/effects';
import Command from '../action/command';
import { OpenDoorPayload } from '../action/rentalCommand';
import Api from '../api';
import Selector from '../selector';
import { Rental } from '../types/rental';
import ErrorTracker from '../util/errorTracker';
import Logger from '../util/logger';
import { onEnqueueErrorSnackbar } from './enqueueSnackbarFlow';
import {Order} from '../types/order';

export const onOpenDoor = function* (depotId: string, door: number, orderId: string | undefined) {
    // get the current running rental from the store
    const order: Order | null = yield select(Selector.Order.makeOrder(orderId ?? ''));
    let rental: Rental | undefined;
    if (order) {
        rental = order?.items[0]?.rental;
    }

    if (!rental) {
        yield fork(onEnqueueErrorSnackbar, getI18n().t('use_order.error.open_door'));
        return;
    }

    // validate, that depot and door match the rental
    const isValid: boolean = rental.doors.some((d) => d.depotId === depotId && d.door === door);

    if (!isValid) {
        yield fork(onEnqueueErrorSnackbar, getI18n().t('use_order.error.open_door'));
        return;
    }

    // @todo check rental order mismatch just to make sure
    // const tokenData = parseJwt(rental.token);

    try {
        yield call(
            Api.openDoor,
            depotId,
            door,
            rental.token,
        );
    } catch (error) {
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);
        yield fork(onEnqueueErrorSnackbar, getI18n().t('use_order.error.open_door'));
    }
};

export function* openDoorFlow() {
    while (true) {
        const action: Action<OpenDoorPayload> = yield take(Command.Rental.openDoor.toString());
        yield fork(onOpenDoor, action.payload.depotId, action.payload.door, action.payload.orderId);
    }
}
