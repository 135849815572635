const Routes = {
    registrationPath: '/register',
    confirmPath: '/confirm',
    login: '/login',
    forgottenPassword: '/forgotten-password',
    resetPassword: '/reset-password',
    registerViaMail: '/viaMail',
    welcome: '/welcome',
    locationsPath: '/locations',
    locationPath: '/locations/:locationId',
    bookLocationPath: '/locations/:locationId/book',
    ordersPath: '/orders',
    orderDetailsPath: '/orders/:orderId',
    useRentalPath: '/orders/:orderId/:rentalId',
    reportDamagePath: '/orders/:orderId/report-damage',
    reportIssuePath: '/orders/:orderId/report-issue',
    orderFaqsPath: '/orders/:orderId/faq',
    addOrderPath: '/orders/add-order',

    vippsPaymentReturnPath: '/payments/vipps/return',
    stripePaymentReturnPath: '/payments/stripe/return',

    hintsPath: '/hints',
    tipsPath: '/hints/page/tips',
    pricePath: '/hints/pricing',
    faqsPath: '/hints/page/faqs',
    termsOfServicePath: '/hints/page/termsOfService',
    dataProtectionPath: '/hints/page/dataProtection',
    imprintPath: '/hints/page/imprint',
    hintsPagePath: '/hints/page/:hintPage',

    consentPagePath: '/consent',
    consentGDPRPagePath: '/consent/dataProtection',
    
    makeLocationPath: (locationId: string) =>
        Routes.locationPath.replace(':locationId', locationId),
    makeBookLocationUrl: (locationId: string) =>
        Routes.bookLocationPath.replace(':locationId', locationId),
    makeOrderDetailsUrl: (orderId: string) =>
        Routes.orderDetailsPath.replace(':orderId', orderId),
    makeUseRentalUrl: (orderId: string, rentalId: string) =>
        Routes.useRentalPath.replace(':orderId', orderId).replace(':rentalId', rentalId),
    makeReportDamageUrl: (orderId: string) =>
        Routes.reportDamagePath.replace(':orderId', orderId),
    makeReportIssueUrl: (orderId: string) =>
        Routes.reportIssuePath.replace(':orderId', orderId),
    makeOrderFaqsUrl: (orderId: string) =>
        Routes.orderFaqsPath.replace(':orderId', orderId),
    makeHintsPath: (component: string) =>
        Routes.hintsPagePath.replace(':hintPage', component),
};

export default Routes;
