import {call, delay, fork, put, take} from 'redux-saga/effects';
import KolulaStorageManager from '../storage/KolulaStorageManager';
import {InternalOrder, Order} from '../types/order';
import Event from '../action/event';
import Command from '../action/command';
import {onFetchOrder} from './fetchOrderFlow';
import ErrorTracker from '../util/errorTracker';
import Logger from '../util/logger';
import { onFetchOrdersByUser } from './fetchOrdersByUserFlow';
const tenantName = process.env.REACT_APP_TENANT;
const { Config } = require(`../tenants/${tenantName}/config`);

export const onLoadOrders = function*() {
    try {
        if (Config.authentication !== 'none') {
            yield fork(onFetchOrdersByUser);
        }
        const kolulaStorageManager = new KolulaStorageManager();
        const orders: Order[] = yield call(kolulaStorageManager.getActiveOrders); 
        yield put(Event.Order.ordersLoaded({ orders }));


        // TODO: since we don't have pending orders anymore, check if a refetch is necessary
        // const pendingOrders: string[] = [...new Set(orders
        //     .filter(order => order.status === 'processing')
        //     .map(order => order.orderId),
        // ) as any];

        const pendingOrders: string[] = [];
        
        for (const orderId of pendingOrders) {
            // Zero retries to prevent flooding the API
            yield fork(onFetchOrder, orderId, 'any');
            yield delay(500);
        }
    } catch (error) {
        ErrorTracker.trackException(error);
        Logger.error(error);

        // Just act as if there are no orders to be loaded if IndexedDB is not available
        yield put(Event.Order.ordersLoaded({ orders: [] }));
    }
};

export const onPersistOrders = function*(internalOrder: InternalOrder) {
    try {
        const kolulaStorageManager = new KolulaStorageManager();
        yield call(kolulaStorageManager.upsertInternalOrder, internalOrder);
    } catch (error) {
        ErrorTracker.trackException(error);
        Logger.error(error);
    }
};

export const onPersistOrdersByUser = function*(internalOrders: InternalOrder[]) {
    const kolulaStorageManager = new KolulaStorageManager();

    try {
        // delete existing orders
        yield call (kolulaStorageManager.deleteAllOrders);
        if (internalOrders.length === 0){
            yield put(Event.Order.noOrdersByUser({}));
        }
        for (const internalOrder of internalOrders) {
            yield call(kolulaStorageManager.insertInternalOrder, internalOrder);
            yield put(Event.Order.orderFetched({ internalOrder }));
        }
    } catch (error) {
        ErrorTracker.trackException(error);
        Logger.error(error);
    }
};

export function* loadOrdersFlow() {
    while (true) {
        yield take(Command.Order.loadOrders.toString());
        yield fork(onLoadOrders);
    }
}
