import React from 'react';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import { Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    button: {
        marginLeft: 'auto',
    },
    icon: {
        color: 'white',
    },
    text: {
        marginLeft: theme.spacing(2.25),
        width: `calc(100% - ${theme.spacing(4.5)}px)`,
        paddingTop: theme.spacing(3),
        textAlign: 'center',
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(6),
        },
        textTransform: 'capitalize',
    },
}));

interface BookLocationCancelButtonProps {
    onClose: () => void;
}

const BookLocationCancelButton = (props: BookLocationCancelButtonProps) => {
    const classes = useStyles();

    return (
        <Box>
            <IconButton className={classes.button} onClick={props.onClose}>
                <CancelIcon fontSize={'small'} className={classes.icon} />
            </IconButton>
        </Box>
    );
};

export default BookLocationCancelButton;
