import {createAction} from 'redux-actions';

export interface StartBookingCreationPayload {
    locationId: string;
}

export interface CalculateDefaultBoardTypeCountsPayload {
}

export interface CreateNewReservationPayload {
}

export interface RemoveReservationPayload {
}

export interface CancelBookingCreationPayload {
}

export interface InitializePaymentTypePayload {
}

export interface InitializePaymentProviderPayload {
}

export interface CompleteBraintreePaymentPayload {
    braintreeDeviceData: string;
    braintreeNonce: string;
}

export interface CompleteVippsPaymentPayload {
    reservationId: string;
}

export interface CompleteStripePaymentPayload {
    reservationId: string;
}

export interface BookingStepChangedPayload {
    step: number;
    nextEnabled: boolean;
}

export interface RedeemCouponPayload {
    couponCode: string;
}

export interface BookingFlowPayload {
    bookingFlow: string[];
}


/* eslint-disable max-len */
const BookingCreationCommand = {
    startBookingCreation: createAction<StartBookingCreationPayload>('@@KL_CMD/START_BOOKING_CREATION'),
    calculateDefaultBoardTypeCounts: createAction<CalculateDefaultBoardTypeCountsPayload>('@@KL_CMD/CALCULATE_DEFAULT_BOARD_TYPE_COUNTS'),
    createNewReservation: createAction<CreateNewReservationPayload>('@@KL_CMD/CREATE_NEW_RESERVATION'),
    removeReservation: createAction<RemoveReservationPayload>('@@KL_CMD/REMOVE_RESERVATION'),
    cancelBookingCreation: createAction<CancelBookingCreationPayload>('@@KL_CMD/CANCEL_BOOKING_CREATION'),
    initializePaymentTypeSelection: createAction<InitializePaymentTypePayload>('@@KL_CMD/INITIALIZE_PAYMENT_TYPE_PAYLOAD'),
    initializePaymentProvider: createAction<InitializePaymentProviderPayload>('@@KL_CMD/INITIALIZE_PAYMENT_PROVIDER_PAYLOAD'),
    completeBraintreePayment: createAction<CompleteBraintreePaymentPayload>('@@KL_CMD/COMPLETE_BRAINTREE_PAYMENT'),
    completeVippsPayment: createAction<CompleteVippsPaymentPayload>('@@KL_CMD/COMPLETE_VIPPS_PAYMENT'),
    completeStripePayment: createAction<CompleteStripePaymentPayload>('@@KL_CMD/COMPLETE_STRIPE_PAYMENT'),
    redeemCoupon: createAction<RedeemCouponPayload>('@@KL_CMD/REDEEM_COUPON'),
    bookingFlowChanged: createAction<BookingFlowPayload>('@@KL_CMD/BOOKING_FLOW_CHANGED'),
    bookingStepChanged: createAction<BookingStepChangedPayload>('@@KL_CMD/BOOKING_STEP_CHANGED'),
    loadBookingFlow: createAction<any>('@@KL_CMD/LOAD_BOOKING_FLOW'),
};

export default BookingCreationCommand;
