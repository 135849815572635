import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Event from '../../../action/event';
import Selector from '../../../selector';
import { PersonalData, PersonalDataValidation } from '../../../types/bookingCreation';
import DebouncedPhoneNumberInput from './PersonalDataStep/DebouncedPhoneNumberInput';
import DebouncedTextInput from './PersonalDataStep/DebouncedTextInput';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(0.5),
        paddingTop: theme.spacing(2),
    },
    textInput: {
        paddingTop: theme.spacing(1),
    },
    newsletterControl: {
        marginTop: theme.spacing(2),
    },
    checkbox: {
        color: 'white',
    },
}));

const PersonalDataStep = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const personalData: PersonalData = useSelector(Selector.BookingCreation.personalData);
    const personalDataValidation: PersonalDataValidation = useSelector(Selector.BookingCreation.personalDataValidation);
    const isPartnerUsc = useSelector(Selector.BookingCreation.isPartnerUsc);

    const onFirstNameChange = (firstName: string) => dispatch(Event.BookingCreation.firstNameChanged({ firstName }));
    const onLastNameChange = (lastName: string) => dispatch(Event.BookingCreation.lastNameChanged({ lastName }));
    const onPhoneChange = (phone: string) => dispatch(Event.BookingCreation.phoneChanged({ phone }));
    const onEmailChange = (email: string) => dispatch(Event.BookingCreation.emailChanged({ email }));
    const onNewsletterConsentChange = (newsletterConsent: boolean) =>
        dispatch(Event.BookingCreation.newsletterConsentChanged({ newsletterConsent }));
    const onUscCustomerIdChange = (uscCustomerId: string) =>
        dispatch(Event.BookingCreation.uscCustomerIdChanged({ uscCustomerId }));
    const tenantConfig = useSelector(Selector.Config.config);

    return (
        <Box className={classes.root}>
            <DebouncedTextInput
                label={t('book_location.personal_data.first_name')}
                initialValue={personalData.firstName}
                onChange={onFirstNameChange}
                valid={personalDataValidation.firstName}
                autoComplete={'given-name'}
            />
            <DebouncedTextInput
                label={t('book_location.personal_data.last_name')}
                initialValue={personalData.lastName}
                onChange={onLastNameChange}
                valid={personalDataValidation.lastName}
                autoComplete={'family-name'}
            />
            <DebouncedPhoneNumberInput
                initialValue={personalData.phone}
                onChange={onPhoneChange}
                valid={personalDataValidation.phone}
            />
            <DebouncedTextInput
                label={t('book_location.personal_data.email')}
                initialValue={personalData.email}
                onChange={onEmailChange}
                valid={personalDataValidation.email}
                autoComplete={'email'}
            />
            {isPartnerUsc && (
                <DebouncedTextInput
                    label={t('book_location.personal_data.usc_customer_id')}
                    initialValue={personalData.uscCustomerId}
                    onChange={onUscCustomerIdChange}
                    valid={personalDataValidation.uscCustomerId}
                />
            )}
            {tenantConfig?.allowNewsLetter === true && (
                <FormControlLabel
                    className={classes.newsletterControl}
                    control={(
                        <Checkbox
                            className={classes.checkbox}
                            checked={personalData.newsletterConsent}
                            onChange={(event, checked) => onNewsletterConsentChange(checked)}
                            color={'primary'}
                        />
                    )}
                    label={
                        <Typography variant={'body2'}>
                            {t('book_location.personal_data.newsletter_consent')}
                        </Typography>
                    }
                />
            )}
        </Box>
    );
};

export default PersonalDataStep;
