import {createAction} from 'redux-actions';

export interface FetchLocationsPayload {
}

export interface FetchLocationByIdPayload {
    locationId: string;
}

export interface FetchAssetGroupsPayload {
    locationId: string;
}

export interface FetchAssetGroupsByLocationIdsPayload {
    locationIds: string[];
}

export interface FetchAssetGroupAvailabilitiesPayload {
    locationId: string;
    assetGroupId: string;
    fromDate: Date;
    toDate: Date;
}

/* eslint-disable max-len */
const LocationCommand = {
    fetchLocations: createAction<FetchLocationsPayload>('@@KL_CMD/FETCH_LOCATIONS'),
    fetchLocationById: createAction<FetchLocationByIdPayload>('@@KL_CMD/FETCH_LOCATION_BY_ID'),
    fetchAssetGroups: createAction<FetchAssetGroupsPayload>('@@KL_CMD/FETCH_ASSETGROUPS'),
    fetchAssetGroupsByLocationIds: createAction<FetchAssetGroupsByLocationIdsPayload>('@@KL_CMD/FETCH_ASSETGROUPS_BY_LOCATION_IDS'),
    fetchAssetGroupAvailabilities: createAction<FetchAssetGroupAvailabilitiesPayload>('@@KL_CMD/FETCH_ASSETGROUP_AVAILABILITIES'),
};

export default LocationCommand;
