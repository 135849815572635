import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { resetPassword } from '../../util/cognitoAuthService';
import RegistrationInput from './components/RegistrationInput';
import RegistrationLogo from './components/RegistrationLogo';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        padding: theme.spacing(0.5),
        paddingTop: theme.spacing(2),
    },
    info1: {
        textAlign: 'center',
        fontFamily: 'Source Sans 3', // Changed to the specified font family
        fontSize: '20px', // Changed to the specified font size
        fontStyle: 'normal', // Changed to the specified font style
        fontWeight: 700, // Changed to the specified font weight
        lineHeight: 'normal',
    },
    form: {
        paddingBottom: theme.spacing(5),
        margin: 'auto',
    },
    button: {
        height: '54px',
        width: '380px',
        borderRadius: '4px',
    },
    link: {
        color: 'orange',
    },

}));

const ResetPassword = () => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [step, setStep] = useState(1);

    
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            if (step === 1) {
            // Step 1: Submit email to initiate password reset
                const success = await resetPassword(email, 'DummyPassword123', 'DummyConfirmationCode123');
                if (success) {
                    setStep(2); // Move to step 2: Enter confirmation code
                }
            } else if (step === 2) {
            // Step 2: Submit confirmation code and new password to complete password reset
                const success = await resetPassword(email, newPassword, confirmationCode);
                if (success) {
                    // Handle success scenario (e.g., show success message, redirect user)
                }
            }
        } catch (error) {
            // Handle error scenario (e.g., show error message)
            // console.error('Error resetting password:', error);
        }
    };


    return (
        <Box className={classes.root}>
            <Typography className={classes.info1}>Reset Password</Typography>
            <RegistrationLogo />
            <Grid item={true}>
                <Typography variant="body1">
                    {step === 1 ? 'Enter your email to initiate password reset:' : 
                        'confirmation code sent to your email and create a new password to reset your account:'
                    }
                </Typography>
            </Grid>
            <form className={classes.form} onSubmit={handleSubmit}>
                <Grid container={true} spacing={2}>
                    {step === 1 && (
                        <RegistrationInput
                            label="Email"
                            name="email"
                            type="email"
                            value={email}
                            placeholder="Please enter your Email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    )}
                    {step === 2 && (
                        <>
                            <RegistrationInput
                                label="confirmation code"
                                name="confirmationCode"
                                type="code"
                                value={confirmationCode}
                                placeholder="Please enter your confirmation code"
                                onChange={(e) => setConfirmationCode(e.target.value)}
                            />
                            <RegistrationInput
                                label="Password"
                                name="password"
                                type="password"
                                value={newPassword}
                                placeholder="Please enter your new Password"
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                        </>
                    )}
                    <Grid item={true} xs={12}>
                        <Button type="submit" variant="contained" className={classes.button} color="primary">
                            {step === 1 ? 'Submit Email' : 'Confirm'}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default ResetPassword;
