import { ReduxState } from '../reducer';
import Routes from '../routes';

const stateKey = 'config';

const ConfigSelector = {
    config: (state: ReduxState) => state[stateKey].config,
    bookingPreviewView: (state: ReduxState) => ConfigSelector.config(state).bookingPreviewType || 'table',
    authentication: (state: ReduxState) => ConfigSelector.config(state).authentication,
    firstScreenDisplay: (state: ReduxState): string => {
        let firstScreen;
        const tenantName = process.env.REACT_APP_TENANT;
        const config = ConfigSelector.config(state);
        switch(config.startPage) {
            case 'none':
                firstScreen = config.toolbarItems[0];
                break;
            case 'welcomePage':
                firstScreen = Routes.welcome;
                break;
            default:
                throw new Error(`First screen is not defined for ${tenantName}`);
        }
        return firstScreen;
    },
    toolBar: (state: ReduxState) => {
        const tenantName = process.env.REACT_APP_TENANT;
        const toolbar = ConfigSelector.config(state).toolbarItems;
        if (!toolbar) {
            throw new Error(`Toolbar items are not defined for ${tenantName}`);
        }
        return toolbar;
    },
};

export default ConfigSelector;
