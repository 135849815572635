import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { getLogo } from '../../../util/tenantConfig';

 interface TenantLogoProps{
    type: 'default' | 'alt';
    size: 'small' | 'large';
    className?: string;
 }
const TenantLogo = (props: TenantLogoProps) => {
    const [logo, setLogo] = useState<any>({});

    useEffect(() => {
        const fetchAssets = async () => {
            const items = await getLogo();
            setLogo(items);
        };
        fetchAssets();
    }, []);

    const TenantLogo = logo?.[props.type]?.[props.size]?.url || '';

    return (
        <Box className={props.className}>
            {TenantLogo &&
            <img src={TenantLogo} alt="" style={{width: '100%'}} />
            }
        </Box>
    );
};

export default TenantLogo;
