import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Link} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink } from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import {Trans, useTranslation} from 'react-i18next';
import CustomButton from '../../../tenants/segmenta/component/CustomButton';
import Routes from '../../../routes';

const useStyles = makeStyles(theme => ({
    dialogText: {
        color: theme.palette.primary.main,
    },
    infoIcon: {
        width: '18px',
        fontWeight: 'bold',
        verticalAlign: 'middle',
    },
    cancelButton: {
        width: '109px',
        height: '44px',
        gap: '10px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
    },
    confirmButton: {
        width: '100px',
        height: '44px',
        gap: '10px',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        boxShadow: 'none',
    },
}));

interface PageClosingDialogProps {
    open: boolean;
    onClose: () => void;
    onContinue?: () => void;
}

const ConsentDialog = (props: PageClosingDialogProps) => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <Dialog
            open={props.open}
        >
            <DialogTitle className={classes.dialogText}>
                {t('book_location.location_selection.dialog.title')}
            </DialogTitle>
            <DialogContent>
                <Typography className={classes.dialogText} variant={'body2'}>
                    {
                        <Trans
                            i18nKey={'book_location.location_selection.dialog.content'}
                            components={{
                                datenSchutz: (
                                    <Link
                                        component={RouterLink}
                                        to={Routes.consentGDPRPagePath}
                                        underline='always'
                                        style={{
                                            textDecorationColor: '#00607B',
                                        }}
                                        onClick={props.onClose}
                                    >   
                                        Datenschutzrichtlinie
                                    </Link>
                                ),
                            }}
                        />    
                    }
                </Typography>
            </DialogContent>
            <DialogActions>
                <CustomButton
                    className={classes.cancelButton}
                    isButtonDark={false}
                    fullWidth={true}
                    variant={'outlined'}
                    onClick={() => props.onClose()}
                    color='secondary'
                    children={t('book_location.location_selection.dialog.close')}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ConsentDialog;
