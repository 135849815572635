import {call, fork, put, select, take} from 'redux-saga/effects';
import KolulaStorageManager from '../storage/KolulaStorageManager';
import Event from '../action/event';
import Command from '../action/command';
import ErrorTracker from '../util/errorTracker';
import Logger from '../util/logger';
import { TenantConfig } from '../types/tenantConfig';
import Selector from '../selector';

export const onLoadConsent = function*() {
    try {
        const tenantConfig: TenantConfig = yield select(Selector.Config.config);
        const kolulaStorageManager = new KolulaStorageManager();
        const consent: boolean | null = yield call(kolulaStorageManager.getSharingConsent); 
        
        if (!tenantConfig.consentRequired) {
            yield put(Event.Consent.setConsent({ consent: true }));
        } else {
            if (typeof consent === 'boolean') {
                yield put(Event.Consent.setConsent({ consent }));
            } else {
                yield put(Event.Consent.setConsent({}));
            }
        }


    } catch (error) {
        ErrorTracker.trackException(error);
        Logger.error(error);
    }
};

export function* loadConsentFlow() {
    while (true) {
        yield take(Command.Consent.loadConsent.toString());
        yield fork(onLoadConsent);
    }
}
