import LocationEvent from './locationEvent';
import BookingCreationEvent from './bookingCreationEvent';
import NotificationEvent from './notificationEvent';
import OrderEvent from './orderEvent';
import RentalEvent from './rentalEvent';
import ConfigEvent from './configEvent';
import ConsentEvent from './consentEvent';

const Event = {
    Location: LocationEvent,
    BookingCreation: BookingCreationEvent,
    Notification: NotificationEvent,
    Order: OrderEvent,
    Rental: RentalEvent,
    Config: ConfigEvent,
    Consent: ConsentEvent,
};

export default Event;
