const getTheme = async () => {
    const tenantName = process.env.REACT_APP_TENANT;
    try {
        const { theme } = await import(
            `../tenants/${tenantName}/layout/theme`
        );
        return theme;
    } catch (error) {
        const { theme } = await import('../material-ui/theme');
        return theme;
    }
};
export default getTheme;
 
