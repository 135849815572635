import {call, fork, put, select, take} from 'redux-saga/effects';
import Command from '../action/command';
import {Action} from 'redux-actions';
import {RedeemCouponPayload} from '../action/bookingCreationCommand';
import Api from '../api';
import {Reservation} from '../types/bookingCreation';
import Selector from '../selector';
import Event from '../action/event';
import Logger from '../util/logger';
import {onEnqueueErrorSnackbar, onEnqueueSuccessSnackbar} from './enqueueSnackbarFlow';
import {getI18n} from 'react-i18next';
import ErrorTracker from '../util/errorTracker';

export const onRedeemCouponCode = function*(couponCode: string) {
    yield put(Event.BookingCreation.couponRedemptionLoading({ loading: true }));
    const reservation: Reservation = yield select(Selector.BookingCreation.reservation);

    try {
        const newReservation: Reservation = yield call(
            Api.fetchReservationTotals,
            reservation.reservationId,
            couponCode,
        );

        // Catch error messages
        // if (!newReservation.discount || newReservation.discount.code !== couponCode) {
        //     yield fork(onEnqueueErrorSnackbar, getI18n().t('book_location.error.invalid_coupon'));
        // }

        yield put(Event.BookingCreation.reservationCreated({ reservation: newReservation }));
        yield fork(onEnqueueSuccessSnackbar, getI18n().t('book_location.confirmation.snackbar'));
    } catch (error) {
        Logger.for('Saga').error(error);
        ErrorTracker.trackException(error);
        yield fork(onEnqueueErrorSnackbar, getI18n().t('book_location.error.redeem_coupon'));
    }

    yield put(Event.BookingCreation.couponRedemptionLoading({ loading: false }));
};

export function* redeemCouponFlow() {
    while (true) {
        const action: Action<RedeemCouponPayload> = yield take(Command.BookingCreation.redeemCoupon.toString());
        yield fork(onRedeemCouponCode, action.payload.couponCode);
    }
}
